<template>
  <div class="content login">
    <div class="columns is-centered">
      <div class="column is-6">
        <form @submit.prevent="requestReset">
          <h1>Request Password Reset</h1>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                required
                v-model="username"
                type="email"
                placeholder="sam@wrios.com"
              >
            </div>
          </div>
          <hr>
          <button type="submit" class="button is-primary">Send Request</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordsApi from '@/services/api/Passwords'

export default {
  data() {
    return {
      username: null
    }
  },

  methods: {
    requestReset: function () {
      const { username } = this

      let self = this

      PasswordsApi.requestReset(username)
        .then(resp => {
          self.$store.commit('success', resp.data.message)
          self.$router.push({ name: 'login' })
        })
        .catch(err => {
          self.$store.commit('error', err.response.data.message)
        })
    }
  }
}
</script>
