<template>
  <div>
    <a @click="open">
      <IconEdit/>
    </a>
    <form-modal
      type="contactEdit"
      title="Edit Contact"
      :model="contact"
      :isModal="isModal"
      :canDelete="true"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import { CONTACT_UPDATE, CONTACT_DELETE } from '@/store/actions'
import { IconEdit } from '@/Icons/IconBase'

export default {
  name: 'ContactEditButton',
  props: {
    openOnSave: Boolean,
    contact: Object
  },
  components: {
    IconEdit
  },

  data() {
    return {
      isModal: false
    }
  },

  methods: {
    open() {
      this.isModal = true
    },

    save(input) {
      let data = Object.assign(input, {
        contact_id: input.contact ? input.contact.id : null,
        contact_type_id: input.type ? input.type.id : null
      })

      if (input.contact) {
        data = Object.assign(input, {
          contact_id: input.contact.id
        })
      }

      let self = this
      this.$store.dispatch(CONTACT_UPDATE, {
        id: this.contact.id,
        data: data
      }).then(function (resp) {
        self.clearModal()
      }, function (err) {
        alert(err)
      })
    },

    destroy(songwriter) {
      let self = this
      this.$store.dispatch(CONTACT_DELETE, songwriter.id)
        .then(function () {
          self.$router.push({ name: 'contacts.index' })
        }, function (err) {
          alert(err)
        })
      this.clearModal()
    },

    clearModal() {
      this.isModal = false
    }
  }
}
</script>
