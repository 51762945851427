<template>
  <section class="index-header p-b-15 m-b-15">
    <div class="level index-actions">
      <div class="level-left">
        <div class="level-item">
          <h1>{{ title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div v-if="exportType" class="level-item">
          <export-button :type="exportType" :search="exportSearch" :scope="exportScope"></export-button>
        </div>
        <div class="level-item">
          <a
            v-if="newButtonText"
            class="button is-primary"
            v-on:click="$emit('click-new-button')"
          >{{ newButtonText }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
    newButtonText: String,
    exportButtonText: String,
    exportScope: String,
    exportSearch: [String, Array, Object],
    exportType: String
  }
}
</script>
