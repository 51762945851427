import axios from 'axios'

import {
  ALL,
  ALL_FOR_FORM,
  READ,
  CREATE,
  UPDATE,
  DELETE
} from '../actions'

export default {
  state: {
    status: ''
  },

  actions: {
    [ALL]: ({
      commit,
      getters,
      rootState
    }, resourceName) => {
      let mutationPrefix = resourceName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
        return '_' + y.toUpperCase()
      }).replace(/^_/, '').toUpperCase()
      commit(`${mutationPrefix}_LOADING`)

      let resourceState = rootState[resourceName]

      if (!resourceState.url) {
        commit(`${mutationPrefix}_ERROR`)
        throw new Error('no url found for specified resource')
      }

      let url = resourceState.url.base

      if (resourceState.url.parentResource === 'account') {
        let accountId = getters.getActiveAccountId
        if (!accountId) {
          console.log('no account!')
          commit(`${mutationPrefix}_ERROR`)
          return
        }
        url = 'accounts/' + getters.getActiveAccountId + '/search/' + url
      }

      let params = {
        page: resourceState.currentPage,
        per_page: resourceState.perPage
      }

      if (resourceState.searchValue && resourceState.searchValue.length) {
        params.search = resourceState.searchValue
      }

      axios({
        url: url,
        params: params
      })
        .then(resp => {
          commit(`${mutationPrefix}_SUCCESS`)
          commit(`${mutationPrefix}_SET_ALL`, resp.data.data)
          commit(`${mutationPrefix}_TOTAL_PAGES_SET`, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(`${mutationPrefix}_ERROR`)
        })
    },
    [ALL_FOR_FORM]: ({
      commit,
      getters,
      rootState
    }, resourceName) => {
      return new Promise((resolve, reject) => {
        let mutationPrefix = resourceName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
          return '_' + y.toUpperCase()
        }).replace(/^_/, '').toUpperCase()
        commit(`${mutationPrefix}_LOADING`)

        let resourceState = rootState[resourceName]

        if (!resourceState.url) {
          commit(`${mutationPrefix}_ERROR`)
          throw new Error('no url found for specified resource')
        }

        let url = resourceState.url.base

        if (resourceState.url.parentResource === 'account') {
          let accountId = getters.getActiveAccountId
          if (!accountId) {
            console.log('no account!')
            commit(`${mutationPrefix}_ERROR`)
            return
          }
          url = 'accounts/' + getters.getActiveAccountId + '/' + url
        }

        axios({
          url: url
        })
          .then(resp => {
            commit(`${mutationPrefix}_SUCCESS`)
            commit(`${mutationPrefix}_SET_ALL`, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(`${mutationPrefix}_ERROR`)
            reject(err)
          })
      })
    },

    [READ]: ({
      commit,
      rootState
    }, {
      resourceName,
      id
    }) => {
      let mutationPrefix = resourceName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
        return '_' + y.toUpperCase()
      }).replace(/^_/, '').toUpperCase()
      commit(`${mutationPrefix}_LOADING`)

      let resourceState = rootState[resourceName]

      if (!resourceState.url) {
        commit(`${mutationPrefix}_ERROR`)
        throw new Error('no url found for specified resource')
      }

      let url = resourceState.url.base + '/' + id

      commit(`${mutationPrefix}_SET`, {})

      axios({
        url: url
      })
        .then(resp => {
          commit(`${mutationPrefix}_SUCCESS`)
          commit(`${mutationPrefix}_SET`, resp.data.data)
        })
        .catch(err => {
          console.log(err)
          commit(`${mutationPrefix}_ERROR`)
        })
    },

    [CREATE]: ({
      commit,
      dispatch,
      getters,
      rootState
    }, {
      resourceName,
      data
    }) => {
      return new Promise((resolve, reject) => {
        let mutationPrefix = resourceName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
          return '_' + y.toUpperCase()
        }).replace(/^_/, '').toUpperCase()
        commit(`${mutationPrefix}_LOADING`)

        let resourceState = rootState[resourceName]

        if (!resourceState.url) {
          commit(`${mutationPrefix}_ERROR`)
          reject(new Error('An error occurred'))
          throw new Error('no url found for specified resource')
        }

        let url = resourceState.url.base

        if (resourceState.url.parentResource === 'account') {
          let accountId = getters.getActiveAccountId
          if (!accountId) {
            console.log('no account!')
            commit(`${mutationPrefix}_ERROR`)
            reject(new Error('An error occurred'))
            return
          }
          url = 'accounts/' + getters.getActiveAccountId + '/' + url
        }

        axios({
          method: 'post',
          url: url,
          data: data
        })
          .then(resp => {
            commit(`${mutationPrefix}_SUCCESS`)
            commit(`${mutationPrefix}_SET`, resp.data.data)
            dispatch(ALL, resourceName)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(`${mutationPrefix}_ERROR`)
            reject(err)
          })
      })
    },

    [UPDATE]: ({
      commit,
      dispatch,
      rootState
    }, {
      resourceName,
      id,
      data
    }) => {
      return new Promise((resolve, reject) => {
        let mutationPrefix = resourceName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
          return '_' + y.toUpperCase()
        }).replace(/^_/, '').toUpperCase()
        commit(`${mutationPrefix}_LOADING`)

        let resourceState = rootState[resourceName]

        if (!resourceState.url) {
          commit(`${mutationPrefix}_ERROR`)
          reject(new Error('An error occurred'))
          throw new Error('no url found for specified resource')
        }

        let url = resourceState.url.base + '/' + id

        commit(`${mutationPrefix}_SET`, {})
        axios({
          method: 'put',
          url: url,
          data: data
        })
          .then(resp => {
            commit(`${mutationPrefix}_SET`, resp.data.data)
            dispatch(ALL, resourceName)
            commit(`${mutationPrefix}_SUCCESS`)
            resolve(resp)
          })
          .catch(err => {
            commit(`${mutationPrefix}_ERROR`)
            reject(err)
          })
      })
    },

    [DELETE]: ({
      commit,
      dispatch,
      rootState
    }, {
      resourceName,
      id
    }) => {
      let mutationPrefix = resourceName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
        return '_' + y.toUpperCase()
      }).replace(/^_/, '').toUpperCase()
      commit(`${mutationPrefix}_LOADING`)

      let resourceState = rootState[resourceName]

      if (!resourceState.url) {
        commit(`${mutationPrefix}_ERROR`)
        throw new Error('no url found for specified resource')
      }

      let url = resourceState.url.base + '/' + id

      axios({
        method: 'delete',
        url: url
      })
        .then(resp => {
          commit(`${mutationPrefix}_SET`, {})
          dispatch(ALL, resourceName)
        })
        .catch(resp => {
          commit(`${mutationPrefix}_ERROR`)
        })
    }
  }
}
