<template>
  <div class="content login">
    <div class="columns is-centered">
      <div class="column is-6">
        <form @submit.prevent="login">
          <h1>LOG IN</h1>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                required
                v-model="username"
                type="email"
                placeholder="sam@wrios.com"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input class="input" required v-model="password" type="password">
            </div>
          </div>

          <hr>
          <button type="submit" class="button is-primary">Login</button>
          <router-link
            :to="{name: 'request-password-reset'}"
            class="is-pulled-right"
          >Forget Password?</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_LOGIN } from '@/store/actions'

export default {
  data() {
    return {
      username: null,
      password: null
    }
  },

  methods: {
    login: function () {
      const { username, password } = this
      this.$store.dispatch(AUTH_LOGIN, { username, password })
        .then(() => {
          this.$router.push({ name: 'songs.index' })
        }, (err) => {
          let message = err.response.data.message
          if (err.response.status === 401) {
            message = 'Login failed.  Please check username & password and try again.'
          }
          this.$store.commit('error', message)
        })
    }
  }
}
</script>
