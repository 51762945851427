<template>
  <div>
    <div class="field m-b-15">
      <label for="songwriter_id" class="label">Songwriter</label>
      <div class="control">
        <multiselect
          v-model="contractShare.shareable"
          :options="songwriters"
          track-by="id"
          label="full_name"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label for="percentage" class="label">Split</label>
      <input
        id="percentage"
        type="number"
        max="100"
        class="input"
        v-model="contractShare.percentage"
        step="0.01"
      >
    </div>
    <div class="field m-b-15">
      <label for="songwriter_id" class="label">Administrator</label>
      <div class="control">
        <multiselect
          v-model="contractShare.administrator"
          :options="publishingCompanies"
          track-by="id"
          label="name"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
  </div>
</template>

<script>

import PublishingCompaniesApi from '@/services/api/PublishingCompanies'
import SongwritersApi from '@/services/api/Songwriters'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ContractWriterShareForm',
  components: {
    Multiselect
  },
  model: {
    prop: 'contractShare',
    event: 'change'
  },
  props: {
    contractShare: Object
  },

  data() {
    return {
      songwriters: [],
      publishingCompanies: []
    }
  },

  created() {
    let accountId = this.$store.getters.getActiveAccountId
    let self = this

    SongwritersApi.getSongwriters(accountId)
      .then(resp => {
        self.songwriters = resp.data.data
      })
      .catch(err => {
        self.$store.commit('error', 'Could not load songwriter data.')
        console.log(err)
      })

    PublishingCompaniesApi.getCompanies(accountId)
      .then(resp => {
        self.publishingCompanies = resp.data.data
      })
      .catch(err => {
        self.$store.commit('error', 'Could not load publishing company data.')
        console.log(err)
      })
  }
}
</script>
