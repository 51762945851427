<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="field m-b-15">
          <label for="hold_date" class="label">Hold Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('hold.hold_date', hold.hold_date, 'Hold Date', 'single')">
              <span v-if="hold.hold_date">
                {{ hold.hold_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="field m-b-15">
          <label for="hold_release_date" class="label">Hold Release Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('hold.hold_release_date', hold.hold_release_date, 'Hold Release Date', 'single')">
              <span v-if="hold.hold_release_date">
                {{ hold.hold_release_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field m-b-15">
      <label for="artist_id" class="label">Primary Artist</label>
      <multiselect v-model="hold.artist" :multiple="false" :options="artists" label="name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addArtist" tag-placeholder="Press enter to add new artist"
        placeholder="Search or add new artist"></multiselect>
    </div>
    <div class="field m-b-15">
      <label for="backup_artist_id" class="label">Backup Artist</label>
      <multiselect v-model="hold.backup_artist" :multiple="false" :options="artists" label="name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addBackupArtist" tag-placeholder="Press enter to add new artist"
        placeholder="Search or add new artist"></multiselect>
    </div>
    <div class="field m-b-15">
      <label for="hold_contact_id" class="label">Hold Contact</label>
      <multiselect v-model="hold.hold_contact" :multiple="false" :options="contacts" label="full_name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addHoldContact" tag-placeholder="Press enter to add new contact"
        placeholder="Search or add new contact"></multiselect>
    </div>
    <div class="field m-b-30">
      <label for="pitch_by_user_id" class="label">Pitched By User</label>
      <multiselect v-model="hold.pitch_by_user" :multiple="false" :options="users" label="full_name" track-by="id"
        :close-on-select="true" placeholder="Search users"></multiselect>
    </div>
    <div class="field m-b-30">
      <label for="pitch_by_contact_id" class="label">Pitched By Contact</label>
      <multiselect v-model="hold.pitch_by_contact" :multiple="false" :options="contacts" label="full_name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addPitchByContact"
        tag-placeholder="Press enter to add new contact" placeholder="Search or add new contact"></multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {
  ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  USER_ALL,
  CREATE,
  CONTACT_CREATE
} from '@/store/actions'
import datepicker from '@/mixins/datepicker'

export default {
  name: 'HoldForm',
  components: {
    Multiselect
  },
  mixins: [datepicker],
  model: {
    prop: 'hold',
    event: 'change'
  },
  props: {
    hold: Object
  },
  created() {
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    this.$store.dispatch(USER_ALL)
  },
  computed: {
    artists() {
      return this.$store.state.artist.artists
    },
    contacts() {
      return this.$store.state.contact.contacts
    },
    users() {
      return this.$store.state.user.users
    }
  },
  methods: {
    addArtist(name) {
      let self = this
      this.storeArtist(name)
        .then(function (data) {
          self.hold.artist = data
        })
    },

    addBackupArtist(name) {
      let self = this
      this.storeArtist(name)
        .then(function (data) {
          self.hold.backup_artist = data
        })
    },

    addHoldContact(name) {
      let self = this
      let nameParts = name.split(' ')
      this.$store.dispatch(CONTACT_CREATE, {
        first_name: nameParts[0],
        last_name: nameParts[1]
      })
        .then(function (data) {
          self.hold.hold_contact = data
        })
    },

    addPitchByContact(name) {
      let self = this
      let nameParts = name.split(' ')
      this.$store.dispatch(CONTACT_CREATE, {
        first_name: nameParts[0],
        last_name: nameParts[1]
      })
        .then(function (data) {
          self.hold.pitch_by_contact = data
        })
    },

    storeArtist(name) {
      return this.$store.dispatch(CREATE, {
        resourceName: 'artist',
        data: {
          name: name
        }
      })
    }
  }
}
</script>
