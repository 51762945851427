import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import Login from './views/Login'
import PasswordResetRequest from './views/PasswordResetRequest'
import PasswordReset from './views/PasswordReset'
import AudioPlayerQueue from './views/AudioPlayerQueue'
import Industry from './views/Industry'
import User from './views/User'
import Home from './views/Home'

import AccountSettings from './views/User/AccountSettings'
import AccountSubscription from './views/User/AccountSubscription'
import AccountTags from './views/User/AccountTags'
import AccountAudioFileTypes from './views/User/AccountAudioFileTypes'
import AccountUsers from './views/User/AccountUsers'
import Exports from './views/User/Exports'
import Tags from './views/User/Tags'
import TagShow from './views/User/TagShow'
import UserProfile from './views/User/UserProfile'
import UserPassword from './views/User/UserPassword'
import UserSettings from './views/User/UserSettings'
import Notifications from './views/User/Notifications'

import AlbumIndex from './views/Albums/AlbumIndex'
import ArtistIndex from './views/Artists/ArtistIndex'
import ContactIndex from './views/Contacts/ContactIndex'
import ContactShow from './views/Contacts/ContactShow'
import PitchIndex from './views/Pitches/PitchIndex'
import PitchShow from './views/Pitches/PitchShow'
import PitchGroupShow from './views/Playlists/PitchGroupShow'
import PlaylistPitchMeetingShow from './views/Playlists/PlaylistPitchMeetingShow'
import PlaylistPitchMeetingAudioFileShow from './views/Playlists/PlaylistPitchMeetingAudioFileShow'
import PlaylistIndex from './views/Playlists/PlaylistIndex'
import PlaylistShow from './views/Playlists/PlaylistShow'
import ProjectIndex from './views/Projects/ProjectIndex'
import PublishingCompanyIndex from './views/PublishingCompanies/PublishingCompanyIndex'
import PublishingCompanyShow from './views/PublishingCompanies/PublishingCompanyShow'
import RecordLabelIndex from './views/RecordLabels/RecordLabelIndex'
import RecordLabelShow from './views/RecordLabels/RecordLabelShow'
import SongIndex from './views/Songs/SongIndex'
import SongShow from './views/Songs/SongShow'
import Songwriters from './views/Songwriters'
import SongwriterIndex from './views/Songwriters/SongwriterIndex'
import SongwriterShow from './views/Songwriters/SongwriterShow'
import TokenizedViewShow from './views/TokenizedViews/TokenizedViewShow'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/songs')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    component: Home,
    meta: { publicLayout: true }
  },
  {
    path: '/links/:token',
    name: 'tokenized-view-show',
    component: TokenizedViewShow
  },
  {
    path: '/industry',
    redirect: '/industry/artists'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: PasswordResetRequest
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    component: PasswordReset
  },
  {
    path: '/play-queue',
    name: 'audio-player-queue',
    component: AudioPlayerQueue,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/songs',
    name: 'songs.index',
    component: SongIndex,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/songs/:id',
    name: 'songs.show',
    component: SongShow,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/playlists',
    name: 'playlists.index',
    component: PlaylistIndex,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/playlists/:id',
    name: 'playlists.show',
    component: PlaylistShow,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/pitches',
    name: 'pitches.index',
    component: PitchIndex,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/playlists/:id/pitch-meeting',
    name: 'playlists.pitch-meeting.show',
    component: PlaylistPitchMeetingShow,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/playlists/:playlist_id/pitch-meeting/audio-files/:id',
    name: 'playlists.pitch-meeting.audio-files.show',
    component: PlaylistPitchMeetingAudioFileShow,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/pitches/:id',
    name: 'pitches.show',
    component: PitchShow,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/pitch-group/:id',
    name: 'pitch-group.show',
    component: PitchGroupShow,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/industry',
    name: 'industry',
    component: Industry,
    beforeEnter: ifAuthenticated,
    children: [{
      path: 'artists',
      name: 'artists.index',
      component: ArtistIndex,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'contacts',
      name: 'contacts.index',
      component: ContactIndex,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'contacts/:id',
      name: 'contacts.show',
      component: ContactShow,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'publishing-companies',
      name: 'publishing-companies.index',
      component: PublishingCompanyIndex,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'publishing-companies/:id',
      name: 'publishing-companies.show',
      component: PublishingCompanyShow,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'record-labels',
      name: 'record-labels.index',
      component: RecordLabelIndex,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'record-labels/:id',
      name: 'record-labels.show',
      component: RecordLabelShow,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'songwriters',
      component: Songwriters,
      beforeEnter: ifAuthenticated,
      children: [{
        path: '',
        name: 'songwriters.index',
        component: SongwriterIndex,
        beforeEnter: ifAuthenticated
      },
      {
        path: ':id',
        name: 'songwriters.show',
        component: SongwriterShow,
        props: true,
        beforeEnter: ifAuthenticated
      }
      ]
    },
    {
      path: 'albums',
      name: 'albums.index',
      component: AlbumIndex,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'projects',
      name: 'projects.index',
      component: ProjectIndex,
      beforeEnter: ifAuthenticated
    }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    beforeEnter: ifAuthenticated,
    children: [{
      path: 'profile',
      name: 'user.profile',
      component: UserProfile,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'password',
      name: 'user.password',
      component: UserPassword,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'settings',
      name: 'user.settings',
      component: UserSettings,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'notifications',
      name: 'user.notifications',
      component: Notifications,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'account/profile',
      name: 'user.account.profile',
      component: AccountSettings,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'account/users',
      name: 'user.account.users',
      component: AccountUsers,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'account/subscription',
      name: 'user.account.subscription',
      component: AccountSubscription,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'account/tags',
      name: 'user.account.tags',
      component: AccountTags,
      beforeEnter: ifAuthenticated,
      children: [{
        path: ':id',
        name: 'user.account.tags.show',
        component: TagShow,
        props: true,
        beforeEnter: ifAuthenticated
      }]
    },
    {
      path: 'account/audio-file-types',
      name: 'user.account.audio-file-types',
      component: AccountAudioFileTypes,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'tags',
      name: 'user.tags',
      component: Tags,
      beforeEnter: ifAuthenticated,
      children: [{
        path: ':id',
        name: 'user.tags.show',
        component: TagShow,
        props: true,
        beforeEnter: ifAuthenticated
      }]
    },
    {
      path: 'exports',
      name: 'user.exports',
      component: Exports,
      beforeEnter: ifAuthenticated
    }
    ]
  }
  ]
})
