<template>
  <thead class="is-size-7">
    <tr class="index-table-header-row">
      <th class="has-text-grey-light"></th>
      <!-- <th class="has-text-grey-light">
        <input
          class="is-checkradio"
          id="selectAllCheckbox"
          type="checkbox"
          name="selectAllCheckbox"
          @change="$emit('select-all', $event)"
          :model="selectAll"
        >
        <label for="selectAllCheckbox"></label>
      </th> -->
      <th v-if="searchScope !== 'submitted'" class="has-text-grey-light has-text-centered">Activity
      </th>
      <th v-else class="has-text-grey-light is-sortable-column has-text-centered" @click="updateSortColumn('submission_status')">Submission Status
        <column-sort-indicator name="submission_status" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('title')">Title
        <column-sort-indicator name="title" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th
        v-if="columnIsActive(viewName, 'date_written')"
        class="has-text-grey-light is-sortable-column"
        @click="updateSortColumn('date_written')"
      >Date Written
        <column-sort-indicator
          name="date_written"
          :column="localSortColumn"
          :order="localSortOrder"
        ></column-sort-indicator>
      </th>
      <th
        v-if="columnIsActive(viewName, 'date_modified')"
        class="has-text-grey-light is-sortable-column"
        @click="updateSortColumn('date_modified')"
      >Date Modified
        <column-sort-indicator
          name="date_modified"
          :column="localSortColumn"
          :order="localSortOrder"
        ></column-sort-indicator>
      </th>
      <th
        v-if="columnIsActive(viewName, 'song_rating')"
        class="has-text-grey-light is-sortable-column"
        @click="updateSortColumn('song_rating')"
      >Song Rating
        <column-sort-indicator
          name="song_rating"
          :column="localSortColumn"
          :order="localSortOrder"
        ></column-sort-indicator>
      </th>
      <th
        class="has-text-grey-light is-sortable-column"
        @click="updateSortColumn('songwriters')"
      >Songwriter(s)
        <column-sort-indicator name="songwriters" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th
        class="has-text-grey-light is-sortable-column"
        @click="updateSortColumn('artists')"
      >Artist(s)
        <column-sort-indicator name="artists" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light" colspan="2"></th>
    </tr>
  </thead>
</template>

<script>

import columnPreferences from '@/mixins/columnPreferences'

export default {
  name: 'TableSongsHead',
  mixins: [columnPreferences],

  props: {
    sortColumn: String,
    sortOrder: String,
    selectAll: Boolean,
    viewName: String
  },

  data() {
    return {
      localSortColumn: null,
      localSortOrder: null
    }
  },

  created: function () {
    this.localSortColumn = this.sortColumn
    this.localSortOrder = this.sortOrder
  },

  methods: {
    updateSortColumn: function (column) {
      let newOrder = null

      let newColumn = null

      if (column !== this.localSortColumn || this.localSortOrder === null) {
        newOrder = 'asc'
        newColumn = column
      } else if (this.localSortOrder === 'asc') {
        newOrder = 'desc'
        newColumn = column
      }

      this.localSortOrder = newOrder
      this.localSortColumn = newColumn

      this.$emit('sort-change', newColumn, newOrder)
    }
  },
  computed: {
    searchScope() {
      return this.$store.state.song.searchScope
    }
  }
}
</script>
