import axios from 'axios'

import {
  TAGGED_ITEM_CREATE,
  TAGGED_ITEM_DELETE,
  TAGGED_ITEM_DELETE_BY_TAGGABLE,
  TAGGED_ITEM_ALL_FOR_TAG
} from '../actions'

import {
  TAGGED_ITEM_LOADING,
  TAGGED_ITEM_SUCCESS,
  TAGGED_ITEM_ERROR
} from '../mutations'

export default {
  state: {
    status: ''
  },

  mutations: {
    [TAGGED_ITEM_LOADING]: (state) => {
      state.status = 'loading'
    },
    [TAGGED_ITEM_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [TAGGED_ITEM_ERROR]: (state) => {
      state.status = 'error'
    }
  },

  actions: {
    [TAGGED_ITEM_ALL_FOR_TAG]: ({
      commit
    }, tag) => {
      return new Promise((resolve, reject) => {
        commit(TAGGED_ITEM_LOADING)

        axios({
          url: 'tags/' + tag.id + '/tagged-items'
        })
          .then(resp => {
            commit(TAGGED_ITEM_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(TAGGED_ITEM_ERROR)
            reject(err)
          })
      })
    },

    [TAGGED_ITEM_CREATE]: ({
      commit
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(TAGGED_ITEM_LOADING)

        axios({
          method: 'post',
          url: 'tagged-items',
          data: data
        })
          .then(resp => {
            commit(TAGGED_ITEM_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(TAGGED_ITEM_ERROR)
            reject(err)
          })
      })
    },

    [TAGGED_ITEM_DELETE]: ({
      commit
    }, id) => {
      return new Promise((resolve, reject) => {
        commit(TAGGED_ITEM_LOADING)

        axios({
          method: 'delete',
          url: 'tagged-items/' + id
        })
          .then(resp => {
            commit(TAGGED_ITEM_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(TAGGED_ITEM_ERROR)
            reject(err)
          })
      })
    },

    [TAGGED_ITEM_DELETE_BY_TAGGABLE]: ({
      commit
    }, { data }) => {
      return new Promise((resolve, reject) => {
        commit(TAGGED_ITEM_LOADING)

        axios({
          method: 'delete',
          url: 'tagged-items/' + data.taggable_type + '/' + data.taggable_id + '/tags/' + data.tag_id
        })
          .then(resp => {
            commit(TAGGED_ITEM_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(TAGGED_ITEM_ERROR)
            reject(err)
          })
      })
    }
  }
}
