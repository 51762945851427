import axios from 'axios'

export default {

  getNotifications(unread = false) {
    return axios({
      url: 'notifications',
      params: {
        unread: unread
      }
    })
  }

}
