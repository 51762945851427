import Vue from 'vue'
import axios from 'axios'

import {
  TAG_ALL_FOR_TAGGABLE
} from '../actions'

import {
  CLEAR_STORE,
  TAG_LOADING,
  TAG_SUCCESS,
  TAG_ERROR,
  TAG_SET,
  TAGS_SET
} from '../mutations'

export default {
  state: {
    status: '',
    tags: [],
    tag: {}
  },

  mutations: {
    [TAG_LOADING]: (state) => {
      state.status = 'loading'
    },
    [TAG_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [TAG_ERROR]: (state) => {
      state.status = 'error'
    },
    [TAG_SET]: (state, resp) => {
      Vue.set(state, 'tag', resp)
    },
    [TAGS_SET]: (state, resp) => {
      Vue.set(state, 'tags', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'tag', {})
      Vue.set(state, 'tags', [])
    }
  },

  actions: {
    [TAG_ALL_FOR_TAGGABLE]: ({
      commit
    }, { taggableType, taggableId }) => {
      return new Promise((resolve, reject) => {
        commit(TAG_LOADING)

        axios({
          url: 'tagged-items/' + taggableType + '/' + taggableId + '/tags'
        })
          .then(resp => {
            commit(TAG_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(TAG_ERROR)
            reject(err)
          })
      })
    }
  }
}
