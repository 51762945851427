<template>
  <div>
    <div class="m-b-15">
      <div class="field m-b-15">
        <label for="artist_ids" class="label">Release Type</label>
        <div class="control">
          <multiselect v-model="release.type" :options="types" label="name" :close-on-select="true"></multiselect>
        </div>
      </div>
      <div class="field m-b-15">
        <label for="release_date" class="label">Release Date</label>
        <div class="control">
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('release.release_date', release.release_date, 'Release Date', 'single')">
              <span v-if="release.release_date">
                {{ release.release_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="field m-b-15">
        <label for="album_id" class="label">Album</label>
        <multiselect v-model="release.album" :multiple="false" :taggable="true" @tag="addAlbum"
          tag-placeholder="Album not found. Press enter to create" :options="albums" label="title" track-by="id"
          :close-on-select="true"></multiselect>
      </div>
      <div class="field m-b-15">
        <label for="record_label_id" class="label">Record Label</label>
        <multiselect v-model="release.record_label" :multiple="false" :taggable="true" @tag="addRecordLabel"
          tag-placeholder="Record Label not found. Press enter to create" :options="recordLabels" label="name" track-by="id"
          :close-on-select="true"></multiselect>
      </div>
      <div class="field m-b-15">
        <label for="label_contact_id" class="label">Label Contact</label>
        <multiselect v-model="release.label_contact" :multiple="false" :options="contacts" label="full_name"
          track-by="id" :close-on-select="true"></multiselect>
      </div>
      <div class="field m-b-15">
        <label for="isrc_number" class="label">ISRC Number</label>
        <input id="isrc_number" type="text" class="input" placeholder="ISRC Number" v-model="release.isrc_number">
      </div>
      <!-- <div class="field m-b-30 m-t-15">
        <label for="released" class="label">
          <input id="released" type="checkbox" class="checkbox m-r-10" v-model="release.released">
          Released?
        </label>
      </div> -->
    </div>
    <hr>
    <div v-if="release.cut" class="m-b-15">
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Cut Date</label>
            <div class="control">{{ release.cut.cut_date | momentDate }}</div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Producers</label>
            <div class="control">{{ release.cut.producer_contacts | arrayString('full_name') | orBlank() }}</div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <div class="field">
            <label class="label">Artists</label>
            <div class="control">
              {{ release.cut.artists | arrayString('name') }}
              <span v-if="release.cut.featured_artists && release.cut.featured_artists.length">feat.
                {{ release.cut.featured_artists | arrayString('name') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m-b-15">
      <h3>Could not find cut for this release.</h3>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {
  ALL_FOR_FORM,
  CREATE,
  CONTACT_ALL_FOR_FORM,
  RECORD_LABEL_ALL_FOR_FORM,
  RECORD_LABEL_CREATE
} from '@/store/actions'
import datepicker from '@/mixins/datepicker'

export default {
  name: 'ReleaseForm',
  components: {
    Multiselect
  },
  mixins: [datepicker],
  model: {
    prop: 'release',
    event: 'change'
  },
  props: {
    release: Object
  },
  data() {
    return {
      types: [{
        'name': 'Radio Single',
        'value': 'radio_single'
      },
      {
        'name': 'Single',
        'value': 'single'
      },
      {
        'name': 'Commercial',
        'value': 'commercial'
      },
      {
        'name': 'Album',
        'value': 'album'
      }
      ]
    }
  },
  created() {
    this.$store.dispatch(ALL_FOR_FORM, 'album')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    this.$store.dispatch(RECORD_LABEL_ALL_FOR_FORM)
  },
  computed: {
    albums() {
      return this.$store.state.album.albums
    },
    contacts() {
      return this.$store.state.contact.contacts
    },
    recordLabels() {
      return this.$store.state.recordLabel.recordLabels
    }
  },
  methods: {
    addAlbum(newAlbum) {
      let self = this
      this.$store.dispatch(CREATE, {
        resourceName: 'album',
        data: {
          title: newAlbum
        }
      })
        .then(resp => {
          self.$set(self.release, 'album', resp)
        })
    },

    addRecordLabel(newRecordLabel) {
      let self = this
      this.$store.dispatch(RECORD_LABEL_CREATE, {
        name: newRecordLabel
      })
        .then(resp => {
          self.$set(self.release, 'record_label', resp)
        })
    }
  }
}
</script>
