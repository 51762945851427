<template>
  <div>
    <div class="field m-b-15">
      <label for="name" class="label">Project Name</label>
      <input id="name" type="text" class="input" placeholder="Project Name" v-model="project.name">
    </div>
    <div class="field m-b-15">
      <label for="media_type" class="label">Media Type</label>
      <multiselect
        v-model="project.media_type"
        :options="['TV Show', 'Film', 'Ad', 'All Media', 'Internet/Streaming', 'Digital Platforms', 'Other']"
        :close-on-select="true"
      ></multiselect>
    </div>
    <div class="field m-b-15">
      <label class="label">Project Contact</label>
      <div class="control">
        <multiselect
          v-model="project.contact"
          :options="contacts"
          track-by="id"
          label="full_name"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

import { CONTACT_ALL_FOR_FORM } from '@/store/actions'

export default {
  name: 'ProjectForm',
  components: {
    Multiselect
  },
  model: {
    prop: 'project',
    event: 'change'
  },
  props: {
    project: Object
  },

  created: function () {
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
  },
  computed: {
    contacts() {
      return this.$store.state.contact.contacts
    }
  }
}
</script>
