<template>
  <div>
    <a @click="open">
      <IconEdit/>
    </a>
    <form-modal
      type="songwriterEdit"
      title="Edit Songwriter"
      :model="songwriter"
      :isModal="isModal"
      :canDelete="$can.includes('delete-songwriters')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import { SONGWRITER_UPDATE, SONGWRITER_DELETE } from '@/store/actions'
import { IconEdit } from '@/Icons/IconBase'

export default {
  name: 'SongwriterEditButton',
  props: {
    openOnSave: Boolean,
    songwriter: Object
  },
  components: {
    IconEdit
  },

  data() {
    return {
      isModal: false
    }
  },

  methods: {
    open() {
      this.isModal = true
    },

    save(input) {
      let data = Object.assign(input, {
        user_id: null
      })

      if (input.user) {
        data = Object.assign(input, {
          user_id: input.user.id
        })
      }
      if (input.current_publishing_company) {
        data = Object.assign(input, {
          publishing_company_id: input.current_publishing_company.id
        })
      } else {
        data = Object.assign(input, {
          publishing_company_id: null
        })
      }
      if (input.pro) {
        data = Object.assign(input, {
          pro: input.pro.value
        })
      }

      let self = this
      this.$store.dispatch(SONGWRITER_UPDATE, {
        id: this.songwriter.id,
        data: data
      }).then(function (resp) {
        self.clearModal()
      }, function (err) {
        alert(err)
      })
    },

    destroy(songwriter) {
      let self = this
      this.$store.dispatch(SONGWRITER_DELETE, songwriter.id)
        .then(function () {
          self.clearModal()
          self.$router.push({ name: 'songwriters.index' })
        }, function (err) {
          self.clearModal()
          self.$store.commit('error', err.response.data.message)
        })
    },

    clearModal() {
      this.isModal = false
    }
  }
}
</script>
