export default function (value) {
  if (!value) return ''
  value = value.toString()
  value = value.replace(/_activity/g, '')
  value = value.replace(/pitch_artists/g, 'pitched_for')
  value = value.replace(/_/g, ' ')
  var strSplit = value.split(' ')
  for (var i = 0; i < strSplit.length; i++) {
    strSplit[i] = strSplit[i].charAt(0).toUpperCase() + strSplit[i].slice(1)
  }
  return strSplit.join(' ')
}
