<template>
  <div class="aside-inner">
    <div class="control has-icons-right">
      <input class="input is-medium" type="search" placeholder="Search Playlists" v-model="title">
      <span class="icon is-right">
        <IconSearch />
      </span>
    </div>
    <Tabs class="tabs m-b-15">
      <section class="p-t-0 p-b-30">
        <div class="form advanced-search">
          <div class="field">
            <label class="label">
              <a @click="openInput !== 1 ? openInput = 1 : openInput = 0">Date Created</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 1}">
              <button class="button is-fullwidth is-primary is-inverted"
                @click="openDatePicker('creation_date', creation_date, 'Date Created')">
                <span v-if="creation_date">
                  {{ creation_date.start | momentDate }} to {{ creation_date.end | momentDate }}
                </span>
                <span v-else>
                  Select Date Range
                </span>
              </button>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a type="search" @click="openInput !== 2 ? openInput = 2 : openInput = 0">Song Title</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 2}">
              <multiselect
                placeholder="Type to search songs."
                v-model="song_titles"
                :options="songs"
                track-by="id"
                label="title"
                :multiple="true"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="true"
                open-direction="bottom"
                select-label=""
                @select="clearSongTitleResults"
                @search-change="searchSongs">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a @click="openInput !== 3 ? openInput = 3 : openInput = 0">Pitching To</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 3}">
              <multiselect v-model="pitch_to_contact" :options="contactFullNames" track-by="id" label="full_name"
                :close-on-select="true">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a @click="openInput !== 4 ? openInput = 4 : openInput = 0">Pitching For</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 4}">
              <multiselect v-model="pitch_artists" :multiple="true" :options="artistNames" track-by="id"
                label="name" :close-on-select="true">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a type="search" @click="openInput !== 5 ? openInput = 5 : openInput = 0">Notes</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 5}">
              <input class="input" type="search" placeholder="Notes" v-model="notes">
            </div>
          </div>

          <!-- Description is legacy attribute from v1.
          We are not alowing for this to be set in client currently.
          Commenting this out as it may be needed when we migrate old data -->
          <!-- <div class="field">
            <label class="label">
              <a
                type="search"
                @click="openInput !== 6 ? openInput = 6 : openInput = 0"
              >Description</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 6}">
              <input class="input" type="search" placeholder="Description" v-model="description">
            </div>
          </div> -->

          <div class="field">
            <label class="label">
              <a @click="openInput !== 7 ? openInput = 7 : openInput = 0">Type</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 7}">
              <multiselect v-model="type" :options="playlistTypes" :close-on-select="true">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <!-- TAGS -->
          <div class="field">
            <label class="label">
              <a @click="searchTags !== 1 ? searchTags = 1 : searchTags = 0">Tags</a>
            </label>
            <div class="control" :class="{'is-hidden' : searchTags !== 1}">
              <div class="field">
                <label class="label">
                  <a @click="openInput !== 8 ? openInput = 8 : openInput = 0">
                    <span class="field-sub">User Tags</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 8}">
                  <multiselect v-model="userTag" :options="userTags" track-by="id" label="value" :multiple="true"
                    :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 9 ? openInput = 9 : openInput = 0">
                    <span class="field-sub">Account Tags</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 9}">
                  <multiselect v-model="accountTag" :options="accountTags" track-by="id" label="value" :multiple="true"
                    :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 10 ? openInput = 10 : openInput = 0">
                    <span class="field-sub">Tempo</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 10}">
                  <multiselect v-model="tempoTag" :options="tempoTagValues" :multiple="true" :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 11 ? openInput = 11 : openInput = 0">
                    <span class="field-sub">Vocal</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 11}">
                  <multiselect v-model="vocalTag" :options="vocalTagValues" :multiple="true" :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 12 ? openInput = 12 : openInput = 0">
                    <span class="field-sub">Audio</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 12}">
                  <multiselect v-model="audioTag" :options="audioTagValues" :multiple="true" :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 13 ? openInput = 13 : openInput = 0">
                    <span class="field-sub">Genre</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 13}">
                  <multiselect v-model="genreTag" :options="genreTagValues" :multiple="true" :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 14 ? openInput = 14 : openInput = 0">
                    <span class="field-sub">Emotion</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 14}">
                  <multiselect v-model="emotionTag" :options="emotionTagValues" :multiple="true"
                    :close-on-select="true">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Tabs>
  </div>
</template>

<script>
import _ from 'lodash'
import SongsApi from '@/services/api/Songs'
import TagsApi from '@/services/api/Tags'
import {
  Tabs
} from 'vue-bulma-tabs'
import Multiselect from 'vue-multiselect'
import {
  IconSearch
} from '@/Icons/IconBase'
import {
  DATEPICKER_OPEN
} from '@/store/actions'

export default {
  name: 'AsidePlaylists',
  components: {
    IconSearch,
    Tabs,
    Multiselect
  },

  props: {
    searchQuery: Object
  },

  data() {
    return {
      searchTags: 0,
      searching: false,
      openInput: 0,
      title: null,
      pitch_to_contact: null,
      pitch_artists: null,
      notes: null,
      description: null,
      song_titles: null,
      type: null,
      playlistTypes: ['Comp', 'Pitch'],
      creation_date: null,
      userTag: null,
      accountTag: null,
      tempoTag: null,
      tempoTagValues: [],
      vocalTag: null,
      vocalTagValues: [],
      audioTag: null,
      audioTagValues: [],
      genreTag: null,
      genreTagValues: [],
      emotionTag: null,
      emotionTagValues: [],
      songs: []
    }
  },

  created: function () {
    this.updateLocalValues(this.searchQuery)
    this.loadTags()
  },

  computed: {
    query() {
      return {
        title: this.title,
        pitch_to_contact: this.pitch_to_contact,
        pitch_artists: this.pitch_artists,
        notes: this.notes,
        description: this.description,
        song_titles: this.song_titles,
        type: this.type,
        creation_date: this.creation_date,
        userTag: this.userTag,
        accountTag: this.accountTag,
        tempoTag: this.tempoTag,
        vocalTag: this.vocalTag,
        audioTag: this.audioTag,
        genreTag: this.genreTag,
        emotionTag: this.emotionTag
      }
    },
    userTags() {
      return this.$store.state.userTag.userTags.map(tag => tag).sort((a, b) => (
        a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))
    },
    accountTags() {
      return this.$store.state.accountTag.accountTags.map(tag => tag).sort((a, b) => (
        a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))
    },
    artistNames() {
      return this.$store.state.artist.artists.map(function (artist) {
        return {
          id: artist.id,
          name: artist.name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1
          : 0))
    },
    contactFullNames() {
      return this.$store.state.contact.contacts.map(function (contact) {
        return {
          id: contact.id,
          full_name: contact.full_name
        }
      }).sort((a, b) => (
        a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name
          .toLowerCase()) ? -1 : 0))
    },
    datepickerData() {
      return this.$store.getters.getDatepickerData
    }
  },

  watch: {
    searchQuery: function (newValue) {
      this.updateLocalValues(this.searchQuery)
    },

    query: function (newValue) {
      Object.keys(newValue).forEach(function (key) {
        if (newValue[key] === '') {
          newValue[key] = null
        }
      })

      this.updateSearchQuery(newValue)
    },

    datepickerData: function (newValue, oldValue) {
      if (newValue.key) {
        this[newValue.key] = newValue.data
      }
    }
  },

  methods: {
    updateSearchQuery: function (query) {
      this.$emit('update-query', query)
    },

    updateLocalValues: function (obj) {
      this.title = obj.title
      this.pitch_to_contact = obj.pitch_to_contact
      this.pitch_artists = obj.pitch_artists
      this.notes = obj.notes
      this.description = obj.description
      this.song_titles = obj.song_titles
      this.type = obj.type
      this.creation_date = obj.creation_date
      this.userTag = obj.userTag
      this.accountTag = obj.accountTag
      this.tempoTag = obj.tempoTag
      this.vocalTag = obj.vocalTag
      this.audioTag = obj.audioTag
      this.genreTag = obj.genreTag
      this.emotionTag = obj.emotionTag
    },

    openDatePicker: function (key, value, title) {
      this.$store.dispatch(DATEPICKER_OPEN, {
        data: value,
        title: title,
        key: key
      })
    },

    loadTags: function () {
      let self = this
      TagsApi.getTags()
        .then(resp => {
          let systemTags = resp.data.data
          self.tempoTags = systemTags.filter(tag => tag.scope === 'tempo')
          self.vocalTags = systemTags.filter(tag => tag.scope === 'vocal')
          self.audioTags = systemTags.filter(tag => tag.scope === 'audio')
          self.genreTags = systemTags.filter(tag => tag.scope === 'genre')
          self.emotionTags = systemTags.filter(tag => tag.scope === 'emotion')
        })
        .catch(resp => {
          self.$store.commit('error', 'Could not load system tags')
        })
    },
    searchSongs: _.debounce(function (songTitle) {
      let self = this
      if (songTitle.length > 0) {
        SongsApi.getSongs(this.$store.getters.getActiveAccountId, songTitle)
          .then(resp => {
            self.songs = resp.data.data.map(function (song) {
              return {
                id: song.id,
                title: song.abbreviated_songwriter_names ? song.title + ' (' + song.abbreviated_songwriter_names + ')' : song.title
              }
            })
          })
      }
    }, 250),
    clearSongTitleResults: function () {
      this.songs = []
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
