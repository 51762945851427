<template>
  <div v-if="song" class="content">
    <progress v-if="isResponding" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <section class="m-t-30 m-r-30 m-l-30">
      <div class="level">
        <div class="level-item">
          <span
            class="has-text-grey-light has-text-weight-bold"
          >{{email.created_by.full_name}} has sent you a song from Wrios!</span>
        </div>
      </div>
    </section>
    <section>
      <div class="container pitch-email">
        <div class="card has-text-centered">
          <div class="card-content m-b-5">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right pitch-view--group-actions">
                <div class="level-item">
                  <play-button
                    @click.native="notifySongListenedTo(song)"
                    v-if="song.default_audio_file_id"
                    :audioFileId="song.default_audio_file_id"
                  ></play-button>
                </div>
                <div class="level-item">
                  <a
                    v-if="song.lyrics"
                    @click="viewLyrics(song)"
                    class="is-primary tooltip"
                    data-tooltip="View Lyrics"
                  >
                    <IconEye/>
                  </a>
                  <a
                    v-else
                    href="#"
                    class="tooltip disabled has-text-grey-light"
                    style="cursor: not-allowed;"
                    data-tooltip="No Lyrics"
                  >
                    <IconEye/>
                  </a>
                </div>
                <div class="level-item">
                  <a
                    v-if="song.lyrics"
                    @click="downloadLyrics(song)"
                    class="is-primary tooltip"
                    data-tooltip="Download Lyrics"
                  >
                    <IconFileText/>
                  </a>
                  <a
                    v-else
                    href="#"
                    class="tooltip disabled has-text-grey-light"
                    style="cursor: not-allowed;"
                    data-tooltip="No Lyrics"
                  >
                    <IconFileText/>
                  </a>
                </div>
                <div class="level-item">
                  <a
                    @click="downloadAudio(song.default_audio_file_id)"
                    v-if="allowAudioDownload"
                    class="is-primary tooltip"
                    data-tooltip="Download Song"
                  >
                    <IconDownload/>
                  </a>
                </div>
              </div>
            </div>
            <div class="level">
              <div class="level-item" style="max-width: 100%; word-break:break-all">
                <h2 class="title">{{ song.title }}</h2>
              </div>
            </div>
            <div class="column">
                <div class="columns">
                  <label for="Songwriters" class="label m-b-0">Songwriters:</label>
                  <p>{{ song.songwriter_names }}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { IconDownload, IconEye, IconFileText } from '@/Icons/IconBase'
import { AUDIO_FILE_DOWNLOAD, NOTIFY_SONG_LISTENED_TO, FILE_DOWNLOAD } from '@/store/actions'

export default {
  name: 'SongEmailView',
  components: {
    IconDownload,
    IconEye,
    IconFileText
  },
  props: {
    email: Object
  },
  data() {
    return {
      isResponding: false
    }
  },

  beforeCreate: function () {
    document.body.className = 'pitch-view'
  },

  computed: {
    song() {
      return this.email.emailable
    },

    allowAudioDownload() {
      return this.email.options.allow_audio_download
    }
  },

  methods: {
    notifySongListenedTo(song) {
      this.$store.dispatch(NOTIFY_SONG_LISTENED_TO, { song: song, contact_id: this.email.contact_id })
    },

    downloadAudio(id) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, id)
        .then(function (resp) {
          self.isResponding = false
        }).catch(function (err) {
          alert(err)
        })
    },
    downloadLyrics(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(FILE_DOWNLOAD, song.lyrics.id)
        .then(function (resp) {
          self.isResponding = false
        }).catch(function (err) {
          alert(err)
        })
    },
    viewLyrics(song) {
      if (song.lyrics.content_type === 'application/msword' || song.lyrics.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        window.open('https://docs.google.com/viewerng/viewer?url=' + encodeURI(song.lyrics.url), '_blank')
        // window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURI(song.lyrics.url), '_blank')
      } else {
        window.open(song.lyrics.url, '_blank')
      }
    }
  }
}
</script>
