<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="field m-b-15">
          <label for="cut_date" class="label">Cut Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('cut.cut_date', cut.cut_date, 'Cut Date', 'single')">
              <span v-if="cut.cut_date">
                {{ cut.cut_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field m-b-15">
      <label for="artist_ids" class="label">Artists</label>
      <multiselect v-model="cut.artists" :multiple="true" :options="artists" label="name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addArtist" tag-placeholder="Press enter to add new artist"
        placeholder="Search or add new artist"></multiselect>
    </div>
    <div class="field m-b-15">
      <label for="featured_artist_ids" class="label">Featured Artists</label>
      <multiselect v-model="cut.featured_artists" :multiple="true" :options="featuredArtists" label="name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addFeaturedArtist"
        tag-placeholder="Press enter to add new artist" placeholder="Search or add new artist"></multiselect>
    </div>
    <div class="field m-b-15">
      <label for="producer_contact_ids" class="label">Producers</label>
      <multiselect v-model="cut.producer_contacts" :multiple="true" :options="contacts" label="full_name" track-by="id"
        :close-on-select="true" :taggable="true" @tag="addCutProducerContact"
        tag-placeholder="Press enter to add new producer contact" placeholder="Search or add new producer contact"></multiselect>
    </div>
    <div class="field m-b-30">
      <label for="pitch_id" class="label">Related Pitch</label>
      <multiselect v-model="cut.pitch" :multiple="false" :options="pitches" label="title" track-by="id"
        :close-on-select="true"></multiselect>
    </div>
  </div>
</template>

<script>
import PitchesApi from '@/services/api/Pitches'
import Multiselect from 'vue-multiselect'
import {
  ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  CONTACT_CREATE,
  CREATE
} from '@/store/actions'

import datepicker from '@/mixins/datepicker'

export default {
  name: 'CutForm',
  components: {
    Multiselect
  },
  mixins: [datepicker],
  model: {
    prop: 'cut',
    event: 'change'
  },
  props: {
    cut: Object
  },
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      selectedRelease: {},
      isSaving: false,
      pitches: []
    }
  },
  created() {
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    let songId

    if (this.$props.cut.song_id) {
      songId = this.$props.cut.song_id
    } else {
      songId = this.$route.params.id
    }

    if (songId) {
      PitchesApi.getPitchesForSong(songId)
        .then(resp => {
          this.pitches = resp.data.data
        })
        .catch(err => {
          this.$store.commit('error', 'Could not load pitches.')
          console.log(err)
        })
    }
  },
  computed: {
    artists() {
      return this.$store.state.artist.artists
    },
    featuredArtists() {
      return this.$store.state.artist.artists
    },
    contacts() {
      return this.$store.state.contact.contacts
    }
  },
  methods: {
    addArtist(name) {
      let self = this
      this.storeArtist(name)
        .then(function (data) {
          if (self.cut.artists) {
            let index = self.cut.artists.length
            self.$set(self.cut.artists, index, data)
          } else {
            self.$set(self.cut, 'artists', [])
            self.cut.artists.push(data)
          }
        })
    },

    addFeaturedArtist(name) {
      let self = this
      this.storeArtist(name)
        .then(function (data) {
          if (self.cut.featured_artists) {
            let index = self.cut.featured_artists.length
            self.$set(self.cut.featured_artists, index, data)
          } else {
            self.$set(self.cut, 'featured_artists', [])
            self.cut.featured_artists.push(data)
          }
        })
    },

    addCutProducerContact(name) {
      let self = this
      let nameParts = name.split(' ')
      this.$store.dispatch(CONTACT_CREATE, {
        first_name: nameParts[0],
        last_name: nameParts[1]
      })
        .then(function (data) {
          if (self.cut.producer_contacts) {
            let index = self.cut.producer_contacts.length
            self.$set(self.cut.producer_contacts, index, data)
          } else {
            self.$set(self.cut, 'producer_contacts', [])
            self.cut.producer_contacts.push(data)
          }
        })
    },

    storeArtist(name) {
      return this.$store.dispatch(CREATE, {
        resourceName: 'artist',
        data: {
          name: name
        }
      })
    }
  }
}
</script>
