<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="modal" class="modal is-active master-owner-modal" v-if="isModal">
      <div class="modal-background" @click="close"></div>

      <div class="modal-content card resource-modal-content">
        <div class="card-content" v-if="!initialized">
          <p>Loading...</p>
        </div>
        <div class="card-content" v-else>
          <h2 class="title">
            Master Owners
            <span v-if="masterOwners && masterOwners.length" class="is-pulled-right">
              <a
                class="is-primary tooltip is-tooltip-left edit-button is-hidden-mobile"
                data-tooltip="Add New Master Owner"
                @click="create"
                v-if="$can.includes('create-master-rights')"
              >
                <IconPlus/>
              </a>
            </span>
          </h2>

          <form class="form m-t-20">
            <div>
              <h4>{{ model.file_name }}</h4>
              <table
                v-if="masterOwners && masterOwners.length"
                class="table is-fullwidth is-responsive is-hoverable master-owners-table"
              >
                <thead>
                  <tr>
                    <th class="master-owners-column">Master Owner</th>
                    <th class="split-column">Split</th>
                    <th class="is-narrow">
                      <a
                        v-if="allowManualPercentages && $can.includes('update-master-rights')"
                        @click="edit"
                        class="is-primary edit-button"
                        :disabled="isEditing"
                      >
                        <IconEdit/>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="owner in masterOwners" :key="owner.id">
                    <td>
                      {{ owner | orBlank('owner_name') }}
                      <br>
                      <small>{{ owner.owner_type | phpClassName }}</small>
                    </td>
                    <td v-if="isEditing">
                      <div class="field">
                        <div class="control">
                          <input
                            class="input is-small"
                            type="number"
                            step="0.01"
                            v-model="owner.percentage"
                          >
                        </div>
                      </div>
                    </td>
                    <td v-else>{{ owner.percentage }}%</td>
                    <td class="is-narrow">
                      <a class="is-primary delete-button" @click="remove(owner)" v-if="$can.includes('delete-master-rights')">
                        <IconX/>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="field m-b-15" v-if="isCreating">
                <label for="owner_type" class="label">Owner Type</label>
                <div class="control">
                  <multiselect
                    @input="getOwnerTypes($event)"
                    v-model="newOwnerType"
                    :options="ownerTypes"
                    label="name"
                    :close-on-select="true"
                  ></multiselect>
                </div>
              </div>
              <div v-if="ownerOptions && isCreating" class="field m-b-15">
                <label for="owner" class="label">Owner</label>
                <multiselect
                  v-model="newOwner"
                  :options="ownerOptions"
                  label="name"
                  track-by="id"
                  :close-on-select="true"
                  :allow-empty="false"
                  :disabled="isLoading"
                ></multiselect>
              </div>
              <hr>
              <div v-if="ownerOptions && isCreating" class="field m-b-30 m-t-15">
                <label
                  for="manual-writing-credit-input"
                  class="label"
                >Allow Manual Master Owner Splits?</label>
                <div class="control">
                    <input
                      type="radio"
                      class="m-r-5"
                      @change="updateManualMasterRightsPercentagesBoolean($event)"
                      name="allowManualMasterRightsPercentages"
                      v-model="allowManualPercentages"
                      value="true"
                      :disabled="isEditing"
                      required
                    >
                    <span class="tooltip is-tooltip-right tooltip-span-label" data-tooltip="If 'Yes', splits will not be calculated automatically" >Yes</span>

                    <input
                      type="radio"
                      class="m-l-5 m-r-5"
                      @change="updateManualMasterRightsPercentagesBoolean($event)"
                      name="allowManualMasterRightsPercentages"
                      v-model="allowManualPercentages"
                      value="false"
                      :disabled="isEditing"
                    >
                    <span class="tooltip is-tooltip-right tooltip-span-label" data-tooltip="If 'No', splits will be not calculated automatically" >No</span>
                </div>
              </div>
              <progress v-if="isSaving || isLoading" class="progress is-small is-primary" max="100"></progress>
              <div class="level m-t-30 is-mobile">
                <div class="level-left">
                  <div v-if="isCreating" class="level-item">
                    <a @click="save" class="button is-primary" :disabled="isSaving">Save</a>
                  </div>
                  <div v-if="isEditing" class="level-item">
                    <a
                      @click="updateManualMasterRightsPercentages"
                      class="button is-primary"
                      :disabled="isSaving"
                    >Update</a>
                  </div>
                  <div class="level-item">
                    <a
                      v-if="isEditing"
                      @click="cancelEditing"
                      class="button is-danger"
                      :disabled="isSaving"
                    >Cancel</a>
                    <a
                      v-if="isCreating"
                      @click="cancelCreating"
                      class="button is-danger"
                      :disabled="isSaving"
                    >Cancel</a>
                    <a
                      v-if="!isEditing && !isCreating"
                      href="#"
                      class="button is-primary is-inverted"
                      @click="close"
                    >Close</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <button class="modal-close is-large" @click="close"></button>
      </div>
    </div>
  </transition>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { IconPlus, IconX, IconEdit } from '@/Icons/IconBase'
import MasterOwnersApi from '@/services/api/MasterOwners'

import {
  ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  SONGWRITER_ALL_FOR_FORM,
  RECORD_LABEL_ALL_FOR_FORM,
  PUBLISHING_COMPANY_ALL_FOR_FORM,
  AUDIO_FILE_ALL_FOR_SONG,
  AUDIO_FILE_UPDATE
} from '@/store/actions'

export default {
  name: 'MasterOwnerForm',
  components: {
    Multiselect,
    IconPlus,
    IconX,
    IconEdit
  },
  model: {
    prop: 'audioFile',
    event: 'change'
  },
  props: {
    model: Object,
    isModal: Boolean
  },
  data() {
    return {
      initialized: false,
      isLoading: false,
      isSaving: false,
      isEditing: false,
      isCreating: false,
      newOwnerType: null,
      newOwner: null,
      ownerOptions: null,
      masterOwners: [],
      allowManualPercentages: false,
      ownerTypes: [
        { 'name': 'Songwriter', 'value': 'songwriter' },
        { 'name': 'Contact', 'value': 'contact' },
        { 'name': 'Artist', 'value': 'artist' },
        { 'name': 'Publishing Company', 'value': 'pubco' },
        { 'name': 'Record Label', 'value': 'label' }
      ]
    }
  },

  created() {
    this.loadMasterOwners()
    this.allowManualPercentages = this.model.allow_manual_master_rights_percentages
  },

  watch: {
    model: function () {
      this.loadMasterOwners()
      this.allowManualPercentages = this.model.allow_manual_master_rights_percentages
    }
  },

  methods: {
    loadMasterOwners() {
      let self = this
      if (this.model.id) {
        MasterOwnersApi.getMasterOwnersForAudioFile(this.model.id)
          .then(function (resp) {
            self.masterOwners = resp.data.data
            self.initialized = true

            if (!self.masterOwners.length) {
              self.isCreating = true
            }
          }, function (err) {
            alert(err)
          })
      }
    },

    create() {
      if (this.isEditing) {
        this.cancelEditing()
      }
      this.isCreating = true
    },

    cancelCreating() {
      this.ownerOptions = null
      this.newOwner = null
      this.newOwnerType = null
      this.isCreating = false
    },

    edit() {
      if (this.isCreating) {
        this.cancelCreating()
      }
      this.isEditing = true
    },

    cancelEditing() {
      this.isEditing = false
      this.loadMasterOwners()
    },

    getOwnerTypes(event) {
      this.isLoading = true
      this.newOwner = null
      let self = this
      if (event.value === 'artist') {
        this.$store.dispatch(ALL_FOR_FORM, 'artist')
          .then(resp => {
            self.isLoading = false
            self.ownerOptions = resp
          })
          .catch(err => {
            alert(err)
          })
      } else if (event.value === 'contact') {
        this.$store.dispatch(CONTACT_ALL_FOR_FORM)
          .then(resp => {
            self.isLoading = false
            self.ownerOptions = resp
          })
          .catch(err => {
            alert(err)
          })
      } else if (event.value === 'songwriter') {
        this.$store.dispatch(SONGWRITER_ALL_FOR_FORM)
          .then(resp => {
            self.isLoading = false
            self.ownerOptions = resp
          })
          .catch(err => {
            alert(err)
          })
      } else if (event.value === 'pubco') {
        this.$store.dispatch(PUBLISHING_COMPANY_ALL_FOR_FORM)
          .then(resp => {
            self.isLoading = false
            self.ownerOptions = resp
          })
          .catch(err => {
            alert(err)
          })
      } else if (event.value === 'label') {
        this.$store.dispatch(RECORD_LABEL_ALL_FOR_FORM)
          .then(resp => {
            self.isLoading = false
            self.ownerOptions = resp
          })
          .catch(err => {
            alert(err)
          })
      }
    },

    close() {
      this.$store.dispatch(AUDIO_FILE_ALL_FOR_SONG, this.$route.params.id)
      this.$emit('clear-modal')
      this.isCreating = false
      this.isSaving = false
      this.isEditing = false
      this.initialized = false
      this.masterOwners = []
    },

    save() {
      this.isSaving = true
      let self = this
      if (this.newOwnerType && this.newOwner) {
        MasterOwnersApi.storeMasterOwner(this.model.id, this.newOwnerType.value, this.newOwner.id, 0)
          .then(function (resp) {
            self.isSaving = false
            self.masterOwners.push(resp.data.data)
            self.loadMasterOwners()
            self.cancelCreating()
          }, function (err) {
            self.isSaving = false
            alert(err)
          })
      } else {
        alert('Please select an owner')
        this.isSaving = false
      }
    },

    remove(owner) {
      this.$store.dispatch('confirm', `Are you sure you want to remove ${owner.owner_name} as a master owner?`).then(resp => {
        if (resp === true) {
          let self = this
          this.isSaving = true
          MasterOwnersApi.deleteMasterOwner(owner.id)
            .then(resp => {
              self.loadMasterOwners()
              self.isSaving = false
            })
            .catch(err => {
              self.$store.dispatch('alert', err)
            })
        }
      }).catch(err => {
        console.log(err)
      })
    },

    updateManualMasterRightsPercentagesBoolean(event) {
      let audioFileId = this.model.id
      let self = this
      this.$store.dispatch(AUDIO_FILE_UPDATE, {
        id: audioFileId,
        data: {
          allow_manual_master_rights_percentages: event.target.value,
          type: this.model.type
        }
      })
        .then(resp => {
          self.isEditing = false

          if (resp.allow_manual_master_rights_percentages === 'true') {
            self.allowManualPercentages = true
          } else {
            self.allowManualPercentages = false
          }
        })
        .catch(err => {
          alert(err)
        })
    },

    updateManualMasterRightsPercentages() {
      this.isSaving = true
      let self = this
      let updatePromises = []

      for (let i = 0; i < this.masterOwners.length; i++) {
        updatePromises.push(MasterOwnersApi.updateMasterOwner(this.masterOwners[i].id, this.masterOwners[i].percentage))
      }

      Promise.all(updatePromises).then(function () {
        self.isSaving = false
        self.isEditing = false
        self.loadMasterOwners()
      })
    }
  }
}
</script>
