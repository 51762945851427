<template>
  <div class="aside-inner">
    <section class="m-b-30">
     <div class="field">
     </div>
   </section>
   <section class="m-b-30 single-aside-details--data">
     <fieldset disabled>
       <div class="field">
         <label class="label">Pitch Date</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="pitchGroup.date | moment-date">
         </div>
       </div>
       <div class="field" v-if="email">
         <label class="label">View Count</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="email | orBlank('view_count')">
         </div>
       </div>
       <div class="field" v-if="email">
         <label class="label">Staus</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="email.status | snakeToTitle">
         </div>
       </div>
     </fieldset>
   </section>
  </div>
</template>

<script>
export default {
  name: 'AsidePitchGroup',
  props: {
    pitchGroup: Object,
    email: Object
  }

}
</script>
