<template>
  <div class="form">
    <div class="field m-b-15">
      <label class="label">Who would you like to hold this for? </label>
      <div class="control">
      <label class="label">Primary Artist Hold </label>
          <input class="input" type="text" name="primary_artist_hold" v-model="pitch.primary_artist_hold" required>
      </div>
      <div class="control">
      <label class="label">Backup Artist Hold </label>
          <input class="input" type="text" name="backup_artist_hold" v-model="pitch.backup_artist_hold">
      </div>
    </div>
    </div>
</template>

<script>

export default {
  name: 'HoldRequestForm',
  model: {
    prop: 'pitch',
    event: 'change'
  },

  props: {
    pitch: Object
  }

}
</script>
