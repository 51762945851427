import Vue from 'vue'
import axios from 'axios'

import {
  HOLD_ALL_FOR_SONG,
  HOLD_CREATE,
  HOLD_UPDATE,
  HOLD_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  HOLD_LOADING,
  HOLD_SUCCESS,
  HOLD_ERROR,
  HOLD_SET,
  HOLDS_SET
} from '../mutations'

export default {
  state: {
    status: '',
    holds: {},
    hold: {}
  },

  getters: {
  },

  mutations: {
    [HOLD_LOADING]: (state) => {
      state.status = 'loading'
    },
    [HOLD_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [HOLD_ERROR]: (state) => {
      state.status = 'error'
    },
    [HOLD_SET]: (state, resp) => {
      Vue.set(state, 'hold', resp)
    },
    [HOLDS_SET]: (state, resp) => {
      Vue.set(state, 'holds', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'hold', {})
      Vue.set(state, 'holds', {})
    }
  },

  actions: {
    [HOLD_ALL_FOR_SONG]: ({
      commit
    }, songId) => {
      commit(HOLD_LOADING)

      axios({
        url: 'songs/' + songId + '/holds'
      })
        .then(resp => {
          commit(HOLD_SUCCESS)
          commit(HOLDS_SET, resp.data.data)
        })
        .catch(resp => {
          commit(HOLD_ERROR)
        })
    },

    [HOLD_CREATE]: ({
      commit,
      dispatch
    }, { songId, data }) => {
      return new Promise(function (resolve, reject) {
        commit(HOLD_LOADING)
        commit(HOLD_SET, {})

        axios({
          method: 'post',
          url: 'songs/' + songId + '/holds',
          data: data
        })
          .then(resp => {
            commit(HOLD_SUCCESS)
            commit(HOLD_SET, resp.data.data)
            dispatch(HOLD_ALL_FOR_SONG, songId)
            resolve(resp.data.data.song_id)
          })
          .catch(resp => {
            commit(HOLD_ERROR)
            reject(resp)
          })
      })
    },

    [HOLD_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data,
      songId
    }) => {
      commit(HOLD_LOADING)
      commit(HOLD_SET, {})
      axios({
        method: 'put',
        url: 'holds/' + id,
        data: data
      })
        .then(resp => {
          commit(HOLD_SUCCESS)
          commit(HOLD_SET, resp.data.data)
          dispatch(HOLD_ALL_FOR_SONG, songId)
        })
        .catch(resp => {
          commit(HOLD_ERROR)
        })
    },

    [HOLD_DELETE]: ({
      commit,
      dispatch
    }, { id, songId }) => {
      return new Promise(function (resolve, reject) {
        commit(HOLD_LOADING)

        axios({
          method: 'delete',
          url: 'holds/' + id
        })
          .then(resp => {
            commit(HOLD_SET, {})
            dispatch(HOLD_ALL_FOR_SONG, songId)
            resolve(resp)
          })
          .catch(resp => {
            commit(HOLD_ERROR)
            reject(resp)
          })
      })
    }
  }
}
