import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

import {
  EMAIL_ALL,
  EMAIL_CREATE,
  EMAIL_READ,
  EMAIL_READ_BY_TOKEN,
  EMAIL_UPDATE,
  EMAIL_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  EMAIL_LOADING,
  EMAIL_SUCCESS,
  EMAIL_DELETED,
  EMAIL_ERROR,
  EMAIL_UPDATED,
  EMAIL_SET,
  EMAILS_SET,
  EMAIL_CURRENT_PAGE_SET,
  EMAIL_PER_PAGE_SET,
  EMAIL_TOTAL_PAGES_SET,
  EMAIL_SORT_SET
} from '../mutations'

export default {
  state: {
    status: '',
    emails: {},
    email: null,
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    sortColumn: null,
    sortOrder: null
  },

  getters: {
    getEmails: state => state.emails,
    isEmailsLoaded: state => !!state.emails,
    getEmail: state => state.email,
    isEmailLoaded: state => !!state.email
  },

  mutations: {
    [EMAIL_LOADING]: (state) => {
      state.status = 'loading'
    },
    [EMAIL_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [EMAIL_DELETED]: (state, id) => {
      let index = state.emails.findIndex(email => email.id === id)
      state.emails.splice(index, 1)
    },
    [EMAIL_UPDATED]: (state, data) => {
      if (state.emails.length) {
        let index = state.emails.findIndex(email => email.id === data.id)
        state.emails[index].title = data.title
        state.emails[index].date = data.date
      }

      if (state.email && state.email.id === data.id) {
        state.emails.title = data.title
        state.emails.date = data.date
      }
    },
    [EMAIL_ERROR]: (state) => {
      state.status = 'error'
    },
    [EMAIL_SET]: (state, resp) => {
      Vue.set(state, 'email', resp)
    },
    [EMAILS_SET]: (state, resp) => {
      Vue.set(state, 'emails', resp)
    },
    [EMAIL_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [EMAIL_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [EMAIL_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [EMAIL_SORT_SET]: (state, {
      column,
      order
    }) => {
      state.sortColumn = column
      state.sortOrder = order
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'email', {})
      Vue.set(state, 'emails', {})
    }
  },

  actions: {
    [EMAIL_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(EMAIL_LOADING)

      let user = getters.getProfile

      if (!user.id) {
        commit(EMAIL_ERROR)
        return false
      }

      axios({
        url: 'emails'
      })
        .then(resp => {
          commit(EMAIL_SUCCESS)
          commit(EMAILS_SET, resp.data.data)
          // commit(EMAIL_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(EMAIL_ERROR)
        })
    },

    [EMAIL_READ]: ({
      commit
    }, id) => {
      commit(EMAIL_LOADING)
      commit(EMAIL_SET, {})
      axios({
        url: 'emails/' + id
      })
        .then(resp => {
          commit(EMAIL_SUCCESS)
          commit(EMAIL_SET, resp.data.data)
        })
        .catch(resp => {
          commit(EMAIL_ERROR)
        })
    },

    [EMAIL_READ_BY_TOKEN]: ({
      commit
    }, token) => {
      return new Promise((resolve, reject) => {
        commit(EMAIL_LOADING)
        axios({
          url: 'external/emails/' + token
        })
          .then(resp => {
            commit(EMAIL_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(EMAIL_ERROR)
            reject(err)
          })
      })
    },

    [EMAIL_CREATE]: ({
      commit
    }, { type, id, allowAudioDownload, linkOnly, subject, body, contactIds, pitchForArtists, playlistId, force = null }) => {
      var formattedData = {
        emailable_id: id,
        emailable_type: type,
        allow_audio_download: allowAudioDownload ? allowAudioDownload === '1' : false,
        link_only: linkOnly,
        subject: subject,
        body: body,
        contact_ids: contactIds,
        artist_ids: pitchForArtists && pitchForArtists.length ? pitchForArtists.map(function (artist) { return artist.id }) : pitchForArtists ? [pitchForArtists.id] : [],
        playlist_id: playlistId,
        force: !!force
      }

      return new Promise(function (resolve, reject) {
        commit(EMAIL_LOADING)
        commit(EMAIL_SET, {})

        axios({
          method: 'post',
          url: 'emails',
          data: formattedData,
          paramsSerializer: function (data) {
            return Qs.stringify(data, {
              arrayFormat: 'brackets'
            })
          }
        })
          .then(resp => {
            commit(EMAIL_SUCCESS)
            commit(EMAIL_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(EMAIL_ERROR)
            reject(err)
          })
      })
    },

    [EMAIL_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      let formattedData = {}
      return new Promise(function (resolve, reject) {
        commit(EMAIL_LOADING)

        axios({
          method: 'put',
          url: 'emails/' + id,
          data: formattedData
        })
          .then(resp => {
            commit(EMAIL_SUCCESS)
            commit(EMAIL_UPDATED, resp.data.data)
            commit(EMAIL_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(EMAIL_ERROR)
            reject(err)
          })
      })
    },

    [EMAIL_DELETE]: ({
      commit
    }, id) => {
      return new Promise(function (resolve, reject) {
        commit(EMAIL_LOADING)

        axios({
          method: 'delete',
          url: 'emails/' + id
        })
          .then(resp => {
            commit(EMAIL_SUCCESS)
            commit(EMAIL_DELETED, id)
            commit(EMAIL_SET, {})
            resolve()
          })
          .catch(err => {
            commit(EMAIL_ERROR)
            reject(err)
          })
      })
    }
  }
}
