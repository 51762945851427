import Vue from 'vue'
import axios from 'axios'

import {
  WRITING_CREDIT_ALL_FOR_SONG,
  WRITING_CREDIT_CREATE,
  WRITING_CREDIT_UPDATE,
  WRITING_CREDIT_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  WRITING_CREDIT_LOADING,
  WRITING_CREDIT_SUCCESS,
  WRITING_CREDIT_ERROR,
  WRITING_CREDIT_SET,
  WRITING_CREDITS_SET
} from '../mutations'

export default {
  state: {
    status: '',
    writingCredits: [],
    writingCredit: {}
  },

  getters: {
  },

  mutations: {
    [WRITING_CREDIT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [WRITING_CREDIT_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [WRITING_CREDIT_ERROR]: (state) => {
      state.status = 'error'
    },
    [WRITING_CREDIT_SET]: (state, resp) => {
      Vue.set(state, 'writingCredit', resp)
    },
    [WRITING_CREDITS_SET]: (state, resp) => {
      Vue.set(state, 'writingCredits', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'writingCredit', {})
      Vue.set(state, 'writingCredits', [])
    }
  },

  actions: {
    [WRITING_CREDIT_ALL_FOR_SONG]: ({
      commit
    }, songId) => {
      commit(WRITING_CREDIT_LOADING)

      axios({
        url: 'songs/' + songId + '/writing-credits'
      })
        .then(resp => {
          commit(WRITING_CREDIT_SUCCESS)
          commit(WRITING_CREDITS_SET, resp.data.data)
        })
        .catch(resp => {
          commit(WRITING_CREDIT_ERROR)
        })
    },

    [WRITING_CREDIT_CREATE]: ({
      commit,
      dispatch
    }, { songId, data }) => {
      commit(WRITING_CREDIT_LOADING)
      commit(WRITING_CREDIT_SET, {})

      if (data.contract) {
        data.contract_id = data.contract.id
      }

      if (data.songwriter) {
        data.songwriter_id = data.songwriter.id
      }

      axios({
        method: 'post',
        url: 'songs/' + songId + '/writing-credits',
        data: data
      })
        .then(resp => {
          commit(WRITING_CREDIT_SUCCESS)
          commit(WRITING_CREDIT_SET, resp.data.data)
          dispatch(WRITING_CREDIT_ALL_FOR_SONG, songId)
        })
        .catch(resp => {
          commit(WRITING_CREDIT_ERROR)
        })
    },

    [WRITING_CREDIT_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data,
      songId
    }) => {
      commit(WRITING_CREDIT_LOADING)
      commit(WRITING_CREDIT_SET, {})

      if (data.contract) {
        data.contract_id = data.contract.id
      }

      if (data.songwriter) {
        data.songwriter_id = data.songwriter.id
      }

      axios({
        method: 'put',
        url: 'writing-credits/' + id,
        data: data
      })
        .then(resp => {
          commit(WRITING_CREDIT_SUCCESS)
          commit(WRITING_CREDIT_SET, resp.data.data)
          dispatch(WRITING_CREDIT_ALL_FOR_SONG, songId)
        })
        .catch(resp => {
          commit(WRITING_CREDIT_ERROR)
        })
    },

    [WRITING_CREDIT_DELETE]: ({
      commit,
      dispatch
    }, { id, songId }) => {
      commit(WRITING_CREDIT_LOADING)

      axios({
        method: 'delete',
        url: 'writing-credits/' + id
      })
        .then(resp => {
          commit(WRITING_CREDIT_SET, {})
          dispatch(WRITING_CREDIT_ALL_FOR_SONG, songId)
        })
        .catch(resp => {
          commit(WRITING_CREDIT_ERROR)
        })
    }
  }
}
