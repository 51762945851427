import Vue from 'vue'
import axios from 'axios'

import {
  CUT_ALL_FOR_SONG,
  CUT_CREATE,
  CUT_UPDATE,
  CUT_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  CUT_LOADING,
  CUT_SUCCESS,
  CUT_ERROR,
  CUT_SET,
  CUTS_SET
} from '../mutations'

export default {
  state: {
    status: '',
    cuts: [],
    cut: {}
  },

  getters: {
  },

  mutations: {
    [CUT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [CUT_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [CUT_ERROR]: (state) => {
      state.status = 'error'
    },
    [CUT_SET]: (state, resp) => {
      Vue.set(state, 'cut', resp)
    },
    [CUTS_SET]: (state, resp) => {
      Vue.set(state, 'cuts', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'cut', {})
      Vue.set(state, 'cuts', [])
    }
  },

  actions: {
    [CUT_ALL_FOR_SONG]: ({
      commit,
      dispatch
    }, songId) => {
      commit(CUT_LOADING)

      axios({
        url: 'songs/' + songId + '/cuts'
      })
        .then(resp => {
          commit(CUT_SUCCESS)
          commit(CUTS_SET, resp.data.data)
        })
        .catch(resp => {
          commit(CUT_ERROR)
        })
    },

    [CUT_CREATE]: ({
      commit,
      dispatch
    }, { songId, data }) => {
      return new Promise(function (resolve, reject) {
        commit(CUT_LOADING)
        commit(CUT_SET, {})

        axios({
          method: 'post',
          url: 'songs/' + songId + '/cuts',
          data: data
        })
          .then(resp => {
            commit(CUT_SUCCESS)
            commit(CUT_SET, resp.data.data)
            dispatch(CUT_ALL_FOR_SONG, songId)
            resolve(resp.data.data)
          })
          .catch(resp => {
            commit(CUT_ERROR)
            reject(resp)
          })
      })
    },

    [CUT_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data,
      songId
    }) => {
      commit(CUT_LOADING)
      commit(CUT_SET, {})
      axios({
        method: 'put',
        url: 'cuts/' + id,
        data: data
      })
        .then(resp => {
          commit(CUT_SUCCESS)
          commit(CUT_SET, resp.data.data)
          dispatch(CUT_ALL_FOR_SONG, songId)
        })
        .catch(resp => {
          commit(CUT_ERROR)
        })
    },

    [CUT_DELETE]: ({
      commit,
      dispatch
    }, { id, songId }) => {
      return new Promise(function (resolve, reject) {
        commit(CUT_LOADING)

        axios({
          method: 'delete',
          url: 'cuts/' + id
        })
          .then(resp => {
            commit(CUT_SET, {})
            dispatch(CUT_ALL_FOR_SONG, songId)
            resolve(resp)
          })
          .catch(resp => {
            commit(CUT_ERROR)
            reject(resp)
          })
      })
    }
  }
}
