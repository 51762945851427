import {
  DATEPICKER_OPEN,
  DATEPICKER_CLOSE
} from '../actions'

import {
  DATEPICKER_TYPE_SET,
  DATEPICKER_DATA_SET,
  DATEPICKER_CLEAR
} from '../mutations'

export default {
  state: {
    type: null,
    data: null
  },

  getters: {
    getDatepickerType: state => state.type,
    getDatepickerData: state => state.data
  },

  mutations: {
    [DATEPICKER_TYPE_SET]: (state, value) => {
      state.type = value
    },
    [DATEPICKER_DATA_SET]: (state, value) => {
      state.data = value
    },
    [DATEPICKER_CLEAR]: (state) => {
      state.type = null
    }
  },

  actions: {
    [DATEPICKER_OPEN]: ({
      commit
    }, data) => {
      const type = data.type ? data.type : 'range'
      commit(DATEPICKER_DATA_SET, data)
      commit(DATEPICKER_TYPE_SET, type)
    },

    [DATEPICKER_CLOSE]: ({
      commit
    }) => {
      commit(DATEPICKER_CLEAR)
    }
  }
}
