<template>
  <div>
    <a class="button is-primary" @click="open">Add Songwriter</a>
    <form-modal
      type="songwriter"
      title="Add Songwriter"
      :model="songwriter"
      message="You can add more details later."
      :isModal="isModal"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
    ></form-modal>
  </div>
</template>

<script>

import { SONGWRITER_CREATE } from '@/store/actions'

export default {
  name: 'SongwriterCreateButton',
  props: {
    openOnSave: Boolean
  },

  data() {
    return {
      isModal: false,
      songwriter: {}
    }
  },

  methods: {
    open() {
      this.clearModal()
      this.isModal = !this.isModal
    },

    save(data) {
      let self = this
      this.$store.dispatch(SONGWRITER_CREATE, data)
        .then(function (songwriter) {
          self.clearModal()
          if (self.openOnSave) {
            self.$router.push({ name: 'songwriters.show', params: { id: songwriter.id, propped: songwriter } })
          }
        }, function (err) {
          self.clearModal()
          alert(err)
        })
    },

    clearModal() {
      this.songwriter = {}
      this.isModal = false
    }
  }
}
</script>
