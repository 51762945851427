<template>
  <div class="image-editor">
    <div class="level has-text-left">
      <div class="level-item has-text-left">
        <a href="#" @click.prevent="edit">
          <figure class="image main-figure is-marginless" v-bind:class="imageSize">
            <img class="is-rounded" v-bind:src="localImage.url" v-if="hasLocalImage">
            <img class="is-rounded" src="/img/image-placeholder.png" v-else>
            <div class="image-overlay">
              <div class="text has-text-primary">
                <IconUpload/>
              </div>
            </div>
          </figure>
        </a>
      </div>
    </div>

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
    >
      <div id="modal" class="modal is-active" v-if="isImageEditorModal">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content card resource-modal-content">
          <div class="card-content">
            <h2 v-if="title" class="title">{{ title }}</h2>
            <h2 v-else class="title">Upload New Image</h2>
            <div class="level">
              <div class="level-item has-text-centered">
                <div class="field">
                  <figure class="image is-128x128">
                    <img v-bind:src="localImage.url" v-if="hasLocalImage">
                    <img src="http://placehold.it/200x200" v-else>
                  </figure>
                </div>
              </div>
            </div>
            <form class="form" enctype="multipart/form-data">
              <div class="column">
                <div class="field">
                  <div class="file is-primary is-fullwidth has-name">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="file"
                        accept="image/*"
                        ref="fileInput"
                        @change="onFileChanged"
                      >
                      <span class="file-cta">
                        <span class="file-icon">
                          <IconUpload/>
                        </span>
                        <span class="file-label">Browse</span>
                      </span>
                      <span class="file-name" v-if="selectedFile">{{ selectedFile.name }}</span>
                      <span class="file-name" v-else>Select file to upload...</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="level m-t-30 is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <div>
                      <button
                        class="button is-primary"
                        v-if="localImage.id"
                        v-bind:disabled="selectedFile == null || isSaving"
                        @click.prevent="update"
                      >UPLOAD</button>
                      <button
                        class="button is-primary"
                        v-else
                        v-bind:disabled="selectedFile == null || isSaving"
                        @click.prevent="create"
                      >UPLOAD</button>
                    </div>
                  </div>
                  <div class="level-item">
                    <a
                      href="#"
                      class="button is-primary is-inverted"
                      @click="close"
                      v-bind:disabled="isSaving"
                    >Cancel</a>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item" v-if="hasLocalImage">
                    <delete-button v-on:delete-confirmed="destroy" :disabled="isSaving"></delete-button>
                  </div>
                </div>
              </div>

              <div class="level m-t-30" v-if="isSaving">
                <div class="level-item">
                  <progress class="progress is-small is-primary" max="100"></progress>
                </div>
              </div>
            </form>
          </div>
          <button class="modal-close is-large" @click="close"></button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { IconUpload } from '@/Icons/IconBase'

import { IMAGE_CREATE, IMAGE_UPDATE, IMAGE_DELETE } from '@/store/actions'

export default {
  name: 'ImageEditor',
  props: ['image', 'parentType', 'parentId', 'title', 'size'],
  components: {
    IconUpload
  },

  data() {
    return {
      selectedFile: null,
      localImage: {},
      isImageEditorModal: false,
      isSaving: false
    }
  },

  created() {
    this.localImage = Object.assign({}, this.$props.image)
  },

  watch: {
    image: function () {
      this.localImage = Object.assign({}, this.$props.image)
    }
  },

  computed: {
    hasLocalImage: function () {
      return this.localImage.id
    },

    imageSize: function () {
      return this.size ? this.size : 'is-128x128'
    }
  },

  methods: {

    edit() {
      this.isImageEditorModal = !this.isImageEditorModal
    },

    close() {
      this.clearModal()
    },

    clearModal() {
      this.selectedFile = null
      this.isImageEditorModal = false
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0]
    },

    create() {
      const formData = new FormData()
      formData.append('image', this.selectedFile, this.selectedFile.name)
      formData.append('type', this.parentType)
      formData.append('imageable_id', this.parentId)
      this.isSaving = true
      let self = this
      this.$store.dispatch(IMAGE_CREATE, formData)
        .then(function (resp) {
          self.isSaving = false
          self.localImage = resp
          self.clearModal()
          self.report()
        }, function (err) {
          this.isSaving = false
          self.$store.commit('error', 'Image upload failed.')
          console.log(err)
        })
    },

    update() {
      const formData = new FormData()
      formData.append('image', this.selectedFile, this.selectedFile.name)
      this.isSaving = true
      let self = this
      this.$store.dispatch(IMAGE_UPDATE, {
        id: this.localImage.id,
        formData: formData
      }).then(function (resp) {
        self.isSaving = false
        self.localImage = resp
        self.clearModal()
        self.report()
      }, function (err) {
        this.isSaving = false
        self.$store.commit('error', 'Image upload failed.')
        console.log(err)
      })
    },

    destroy() {
      let self = this
      this.$store.dispatch(IMAGE_DELETE, this.localImage.id)
        .then(function (resp) {
          self.localImage = {}
          self.report()
          self.clearModal()
        }, function (err) {
          alert(err)
        })
    },

    report(image) {
      this.$emit('image-updated', self.localImage)
    }

  }
}
</script>
