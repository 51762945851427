<template>
  <div class="content single">
    <PageHeaderPitch :pitch="pitch" v-on:edit-pitch="edit"></PageHeaderPitch>
    <section class="section is-paddingless">
      <div class="columns is-marginless">
        <aside class="column is-2 hero">
          <AsidePitch :pitch="pitch"></AsidePitch>
        </aside>
        <main class="column is-paddingless">
          <div class="container is-hidden-mobile">
            <section class="single-info">
              <table class="table is-fullwidth">
                <thead class="single-info">
                  <tr>
                    <th class="has-text-grey-light">Pitched To</th>
                    <th class="has-text-grey-light">Pitched By</th>
                    <th class="has-text-grey-light">Pitched For</th>
                    <th class="has-text-grey-light">Status</th>
                    <th class="has-text-grey-light">Primary Artist Hold</th>
                    <th class="has-text-grey-light">Backup Artist Hold</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pitch.pitch_to_contact | orBlank('full_name') }}</td>
                    <td>{{ pitch.pitch_by_user | orBlank('full_name') }}</td>
                    <td>{{ pitch.pitch_artist_names | orBlank('name') }}</td>
                    <td>{{ pitch | orBlank('status') }}</td>
                    <td>{{ pitch | orBlank('primary_artist_hold') }}</td>
                    <td>{{ pitch | orBlank('backup_artist_hold') }}</td>
                  </tr>
                </tbody>
              </table>
              <p class="is-italic has-text-grey-light">{{ pitch.audio_file_downloaded_at | audioFileDownloadedAtFormatting }}</p>
            </section>
            <note-control :notableId="pitch.id" notableType="pitch"></note-control>
            <AudioPlaybackActivity :pitch="pitch"/>
          </div>
          <div class="container is-hidden-tablet">
            <section class="single-info">
              <table class="table">
                <tr><th class="has-text-grey-light">Pitch Date</th><td> {{ pitch.date | momentDate }}</td></tr>
                <tr v-if="pitch.playlist"><th class="has-text-grey-light">Playlist</th><td> {{ pitch.playlist | orBlank('title') }}</td></tr>
                <tr><th class="has-text-grey-light">Pitched To</th><td> {{ pitch.pitch_to_contact | orBlank('full_name') }}</td></tr>
                <tr><th class="has-text-grey-light">Pitched By</th><td> {{ pitch.pitch_by_user | orBlank('full_name') }}</td></tr>
                <tr><th class="has-text-grey-light">Pitched For</th><td> {{ pitch.pitch_artist_names | orBlank('name') }}</td></tr>
                <tr><th class="has-text-grey-light">Status</th><td> {{ pitch | orBlank('status') }}</td></tr>
                <tr><th class="has-text-grey-light">Primary Artist Hold</th><td> {{ pitch | orBlank('primary_artist_hold') }}</td></tr>
                <tr><th class="has-text-grey-light">Backup Artist Hold</th><td> {{ pitch | orBlank('backup_artist_hold') }}</td></tr>
              </table>
              <p class="is-italic has-text-grey-light">{{ pitch.audio_file_downloaded_at | audioFileDownloadedAtFormatting }}</p>
            </section>
            <section class="single-info single-aside-details--tags">
              <label class="label">Tags</label>
              <tag-control taggableType="pitch" :taggableId="pitch.id" />
            </section>
            <note-control :notableId="pitch.id" notableType="pitch"></note-control>
            <AudioPlaybackActivity :pitch="pitch"/>
          </div>
        </main>
        <aside class="hero column is-3 has-background-light">
          <div class="aside-inner">
            <TabsPitch :pitch="pitch"/>
          </div>
        </aside>
      </div>
    </section>
    <form-modal
      type="pitch"
      :title="formTitle"
      :model="pitch"
      :isModal="isModal"
      :canDelete="$can.includes('delete-pitches')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>
import PageHeaderPitch from '@/components/Pitch/PageHeaderPitch'
import AsidePitch from '@/components/Pitch/AsidePitch'
import TabsPitch from '@/components/Pitch/TabsPitch'
import AudioPlaybackActivity from '@/components/Pitch/AudioPlaybackActivity'
import store from '@/store'

import {
  PITCH_READ,
  PITCH_DELETE,
  PITCH_UPDATE,
  CONTACT_ALL_FOR_FORM,
  USER_ALL,
  ALL_FOR_FORM
} from '@/store/actions'

export default {
  name: 'PitchShow',
  components: {
    PageHeaderPitch,
    AsidePitch,
    TabsPitch,
    AudioPlaybackActivity
  },

  props: {
    propped: Object
  },

  data() {
    return {
      formTitle: 'Edit Pitch',
      formCanDelete: false,
      isModal: false
    }
  },

  computed: {
    pitch() {
      if (this.$store.getters.getPitch.id) return this.$store.getters.getPitch
      if (this.propped) return this.propped

      return {
        title: ''
      }
    },
    storeStatus() {
      return this.$store.state.pitch.status
    },

    isLoading() {
      return this.$store.state.pitch.status === 'loading'
    }
  },

  watch: {
    storeStatus: function (newValue, oldValue) {
      if (newValue === 'deleted') {
        this.$router.push({ name: 'pitches.index' })
      }
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(PITCH_READ, to.params.id)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(PITCH_READ, to.params.id)
    next()
  },

  beforeCreate: function () {
    document.body.className = 'single'
  },

  methods: {

    destroy(pitch) {
      let self = this
      this.$store.dispatch(PITCH_DELETE, pitch.id)
        .then(function (resp) {
          self.clearModal()
          self.$router.push({ name: 'pitches.index' })
        }, function (resp) {
          self.$store.commit('error', 'Unable to delete pitch.')
        })
    },

    edit() {
      this.clearModal()
      this.formTitle = `Edit Pitch`
      this.formCanDelete = true
      this.isModal = !this.isModal
      this.$store.dispatch(USER_ALL)
      this.$store.dispatch(ALL_FOR_FORM, 'artist')
      this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    },

    save(data) {
      this.$store.dispatch(PITCH_UPDATE, {
        id: this.pitch.id,
        data: data
      })
      this.clearModal()
    },

    clearModal() {
      this.isModal = false
      this.formCanDelete = false
      this.formTitle = ''
      this.selectedPitch = {}
      this.modalMessage = null
    }
  }
}
</script>
