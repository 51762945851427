<template>
    <section class="single-info is-hidden-mobile" v-if="pitch.audio_file_events">
        <div class="level is-marginless">
            <div class="level-left">
                <h3 class="m-b-30">Audio Playback Activity</h3>
            </div>
            <div class="level-right" v-if="pitch.audio_file_events.length">
            <a class="is-primary" @click="showPlaybackActivity !== 1 ? showPlaybackActivity = 1 : showPlaybackActivity = 0">
                <IconArrowUp v-if="showPlaybackActivity === 0"/>
                <IconArrowDown v-if="showPlaybackActivity === 1"/>
            </a>
            </div>
        </div>
        <div class="level" v-if="showPlaybackActivity === 0 && pitch.audio_file_events.length">
          <div class="level-left">
          <p>There are {{pitch.audio_file_events.filter(e => e.type !== 'playing').length}} playback activities for this pitch.</p>
          </div>
        </div>
        <div class="level" v-if="showPlaybackActivity === 0 && !pitch.audio_file_events.length">
          <div class="level-left">
          <p>No playback activity to display.</p>
          </div>
        </div>
        <table class="table is-fullwidth" v-if="showPlaybackActivity === 1 && pitch.audio_file_events.length">
            <tbody v-for="event in pitch.audio_file_events" :key="event.id">
                <tr>
                <td v-if="event.type !== 'playing'" :inner-html.prop="event | eventFormatting"></td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import { IconArrowUp, IconArrowDown } from '@/Icons/IconBase'

export default {
  name: 'AudioPlaybackActivity',
  props: ['pitch'],
  components: {
    IconArrowUp,
    IconArrowDown
  },
  data() {
    return {
      showPlaybackActivity: 0
    }
  }
}
</script>
