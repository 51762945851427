<template>
  <div>
    <index-header title="Exports"></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage"></table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Date</th>
          <th class="has-text-grey-light">Type</th>
          <th class="has-text-grey-light">Status</th>
          <th class="has-text-grey-light">Name</th>
          <th class="has-text-grey-light"></th>
        </tr>
      </thead>
      <tbody>
        <tr
        v-for="userExport in userExports"
        :key="userExport.id"
        class="is-clickable"
        >
          <td>{{userExport.created_at | momentDateTime}}</td>
          <td>{{userExport.type | capitalize}}</td>
          <td>{{userExport.status | capitalize}}</td>
          <td>{{userExport.file_name}}</td>
          <td class="is-narrow">
            <a v-if="userExport.url" class="table-icon tooltip m-r-10" data-tooltip="Download Export" v-bind:href="userExport.url" download="true">
              <IconDownload/>
            </a>
            <a v-if="userExport" class="table-icon tooltip" data-tooltip="Delete Export" @click="destroy(userExport.id)">
              <IconX/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
  </div>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'
import { IconDownload, IconX } from '@/Icons/IconBase'
import { EXPORT_ALL, EXPORT_DELETE } from '@/store/actions'
import { EXPORT_CURRENT_PAGE_SET, EXPORT_PER_PAGE_SET, EXPORT_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'Exports',
  mixins: [uiHelper],
  data() {
    return {
      currentPageMutation: EXPORT_CURRENT_PAGE_SET,
      perPageMutation: EXPORT_PER_PAGE_SET,
      searchValueMutation: EXPORT_SEARCH_VALUE_SET,
      selectedExport: {}
    }
  },

  components: {
    IconDownload,
    IconX
  },

  computed: {
    resourceState() {
      return this.$store.state.exports
    },

    userExports() {
      return this.$store.state.exports.exports
    }
  },
  methods: {
    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(EXPORT_ALL)
    },

    destroy(id) {
      let self = this
      if (confirm('Are you sure?')) {
        this.$store.dispatch(EXPORT_DELETE, id)
          .then(function () {
            self.$store.commit('alert', 'Export Deleted')
          })
      }
    }
  }
}
</script>
