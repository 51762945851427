<template>
  <Tabs class="tabs m-b-15">
    <TabPane label="Activity">
      <activity-viewer v-if="pitch.id" :subjectId="pitch.id" subjectType="pitch"></activity-viewer>
    </TabPane>
  </Tabs>
</template>

<script>
import { Tabs, TabPane } from 'vue-bulma-tabs'
export default {
  components: {
    Tabs,
    TabPane
  },

  props: {
    pitch: Object
  }
}
</script>
