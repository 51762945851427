<template>
  <footer class="footer has-background-dark public-footer">
            <div class="level">
            <div class="level-left">
                <div class="level-item">
                <object type="image/svg+xml" data="img/wrios_logo.svg">
                    Wrios Logo
                </object>
                </div>
            </div>
            <div class="level-item">
                <!-- <a href="#">Terms of Service</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#">Frequently Asked Questions</a> -->
            </div>
            <div class="level-right">
                <div class="level-item">
                <a href="#top" class="to-top">Back to Top</a>
                </div>
            </div>
            </div>
        </footer>
</template>
<script>
export default {
  name: 'PublicFooter'
}
</script>
