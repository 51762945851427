<template>
  <div class="aside-inner">
    <div class="control has-icons-right">
      <input class="input is-medium" type="search" placeholder="Search Pitches" v-model="song_title">
      <span class="icon is-right">
        <IconSearch />
      </span>
    </div>
    <Tabs class="tabs m-b-15">
      <section class="p-t-0 p-b-30">
        <div class="form advanced-search">
          <div class="field">
            <label class="label">
              <a @click="openInput !== 1 ? openInput = 1 : openInput = 0">Pitch Date</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 1}">
              <button class="button is-fullwidth is-primary is-inverted"
                @click="openDatePicker('date', date, 'Pitch Date')">
                <span v-if="date">
                  {{ date.start | momentDate }} to {{ date.end | momentDate }}
                </span>
                <span v-else>
                  Select Date Range
                </span>
              </button>
            </div>
          </div>
          <div class="field">
            <label class="label">
              <a @click="openInput !== 2 ? openInput = 2 : openInput = 0">Date Created</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 2}">
              <button class="button is-fullwidth is-primary is-inverted"
                @click="openDatePicker('created_at', created_at, 'Date Created')">
                <span v-if="created_at">
                  {{ created_at.start | momentDate }} to {{ created_at.end | momentDate }}
                </span>
                <span v-else>
                  Select Date Range
                </span>
              </button>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a @click="openInput !== 3 ? openInput = 3 : openInput = 0">Pitching To</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 3}">
              <multiselect v-model="pitch_to_contact" :options="contactFullNames" track-by="id" label="full_name"
                :close-on-select="true">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a @click="openInput !== 4 ? openInput = 4 : openInput = 0">Pitching For</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 4}">
              <multiselect v-model="pitch_artists" :multiple="true" :options="artistNames" track-by="id" label="name"
                :close-on-select="true">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a @click="openInput !== 5 ? openInput = 5 : openInput = 0">Pitched By</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 5}">
              <multiselect v-model="pitch_by_user" :options="userFullNames" :close-on-select="true" track-by="id"
                label="full_name">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a type="search" @click="openInput !== 6 ? openInput = 6 : openInput = 0">Notes</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 6}">
              <input class="input" type="search" placeholder="Notes" v-model="notes">
            </div>
          </div>

          <div class="field">
            <label class="label">
              <a @click="openInput !== 7 ? openInput = 7 : openInput = 0">Status</a>
            </label>
            <div class="control" :class="{'is-hidden' : openInput !== 7}">
              <multiselect v-model="status" :options="statusOptions" :close-on-select="true">
                <span slot="noResult">No results found.</span>
              </multiselect>
            </div>
          </div>

          <!-- TAGS -->
          <div class="field">
            <label class="label">
              <a @click="searchTags !== 1 ? searchTags = 1 : searchTags = 0">Tags</a>
            </label>
            <div class="control" :class="{'is-hidden' : searchTags !== 1}">
              <div class="field">
                <label class="label">
                  <a @click="openInput !== 8 ? openInput = 8 : openInput = 0">
                    <span class="field-sub">User Tags</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 8}">
                  <multiselect v-model="userTag" :options="userTags" :multiple="true" :close-on-select="true"
                    track-by="id" label="value">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  <a @click="openInput !== 9 ? openInput = 9 : openInput = 0">
                    <span class="field-sub">Account Tags</span>
                  </a>
                </label>
                <div class="control" :class="{'is-hidden' : openInput !== 9}">
                  <multiselect v-model="accountTag" :options="accountTags" :multiple="true" :close-on-select="true"
                    track-by="id" label="value">
                    <span slot="noResult">No results found.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Tabs>
  </div>
</template>

<script>
import {
  Tabs
} from 'vue-bulma-tabs'
import Multiselect from 'vue-multiselect'
import {
  IconSearch
} from '@/Icons/IconBase'
import {
  DATEPICKER_OPEN
} from '@/store/actions'

export default {
  name: 'AsidePitches',
  components: {
    IconSearch,
    Tabs,
    Multiselect
  },

  props: {
    searchQuery: Object
  },

  data() {
    return {
      searchTags: 0,
      searching: false,
      openInput: 0,
      song_title: null,
      date: null,
      created_at: null,
      pitch_by_user: null,
      pitch_to_contact: null,
      pitch_artists: null,
      notes: null,
      status: null,
      statusOptions: ['Sent', 'Pass', 'Took Copy', 'Request Hold', 'Cut'],
      userTag: null,
      accountTag: null
    }
  },

  created: function () {
    this.updateLocalValues(this.searchQuery)
  },
  computed: {
    query() {
      return {
        song_title: this.song_title,
        date: this.date,
        created_at: this.created_at,
        pitch_by_user: this.pitch_by_user,
        pitch_to_contact: this.pitch_to_contact,
        pitch_artists: this.pitch_artists,
        notes: this.notes,
        status: this.status,
        userTag: this.userTag,
        accountTag: this.accountTag
      }
    },
    userTags() {
      return this.$store.state.userTag.userTags.map(tag => tag).sort((a, b) => (
        a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))
    },
    accountTags() {
      return this.$store.state.accountTag.accountTags.map(tag => tag).sort((a, b) => (
        a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))
    },
    artistNames() {
      return this.$store.state.artist.artists.map(function (artist) {
        return {
          id: artist.id,
          name: artist.name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1
          : 0))
    },
    contactFullNames() {
      return this.$store.state.contact.contacts.map(function (contact) {
        return {
          id: contact.id,
          full_name: contact.full_name
        }
      }).sort((a, b) => (
        a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name
          .toLowerCase()) ? -1 : 0))
    },
    userFullNames() {
      return this.$store.state.user.users.map(function (user) {
        return {
          id: user.id,
          full_name: user.full_name
        }
      }).sort((a, b) => (
        a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name
          .toLowerCase()) ? -1 : 0))
    },
    datepickerData() {
      return this.$store.getters.getDatepickerData
    }
  },

  watch: {
    searchQuery: function (newValue) {
      this.updateLocalValues(this.searchQuery)
    },

    query: function (newValue) {
      Object.keys(newValue).forEach(function (key) {
        if (newValue[key] === '') {
          newValue[key] = null
        }
      })

      this.updateSearchQuery(newValue)
    },

    datepickerData: function (newValue, oldValue) {
      if (newValue.key) {
        this[newValue.key] = newValue.data
      }
    }
  },

  methods: {
    updateSearchQuery: function (query) {
      this.$emit('update-query', query)
    },

    updateLocalValues: function (obj) {
      this.song_title = obj.song_title
      this.date = obj.date
      this.created_at = obj.created_at
      this.pitch_by_user = obj.pitch_by_user
      this.pitch_to_contact = obj.pitch_to_contact
      this.pitch_artists = obj.pitch_artists
      this.notes = obj.notes
      this.status = obj.status
      this.userTag = obj.userTag
      this.accountTag = obj.accountTag
    },

    openDatePicker: function (key, value, title) {
      this.$store.dispatch(DATEPICKER_OPEN, {
        data: value,
        title: title,
        key: key
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
