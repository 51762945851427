<template>
  <div>
    <section class="index-header p-b-15 m-b-15">
      <div class="level index-actions">
        <div class="level-left">
          <div class="level-item">
            <h1>Record Labels</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-primary is-inverted" @click="close">Back to Record Labels</a>
          </div>
        </div>
      </div>
    </section>
    <div class="content single">
      <div class="level page-actions is-marginless p-b-30 is-mobile">
        <div class="level-left">
          <div class="level-item m-r-30">
            <h3 class="m-b-30">{{ recordLabel.name }}</h3>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <RecordLabelEditButton
              :recordLabel="recordLabel"
              v-if="$can.includes('update-record-labels')"
            />
          </div>
        </div>
      </div>
      <section class="section is-paddingless">
        <div class="columns is-marginless">
          <aside class="column is-3 is-paddingless">
            <div class="aside-inner">
              <section class="m-b-30 single-aside-details--data">
                <fieldset>
                  <div class="field">
                    <label class="label">Contact</label>
                    <p>
                      <router-link
                        v-if="recordLabel.contact"
                        :to="{name: 'contacts.show', params: {id: recordLabel.contact.id, propped: recordLabel.contact}}"
                      >{{ recordLabel.contact.full_name }}</router-link>
                      <span v-else>...</span>
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Website</label>
                    <p>
                      <a
                        v-if="recordLabel.website"
                        v-bind:href="recordLabel.website"
                        target="_blank"
                      >{{ recordLabel.website }}</a>
                      <span v-else>...</span>
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Location</label>
                    <p>
                      <span v-if="recordLabel.address_line1">
                        {{ recordLabel.address_line1 }}
                        <br>
                      </span>
                      <span v-if="recordLabel.address_line2">
                        {{ recordLabel.address_line2 }}
                        <br>
                      </span>
                      <span
                        v-if="recordLabel.address_city"
                      >{{ recordLabel.address_city }},</span>
                      <span
                        v-if="recordLabel.address_state"
                      >{{ recordLabel.address_state }}</span>
                      <span
                        class="m-l-5"
                        v-if="recordLabel.address_zip"
                      >{{ recordLabel.address_zip }}</span>
                      <br>
                      {{ recordLabel.address_country }}
                    </p>
                  </div>
                </fieldset>
              </section>
              <section class="m-b-30 single-aside-details--tags">
                <label class="label">Tags</label>
                <tag-control taggableType="recordlabel" :taggableId="recordLabel.id"/>
              </section>
            </div>
          </aside>
          <div class="column is-9 is-paddingless">
            <note-control :notableId="recordLabel.id" notableType="record_label"></note-control>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { RECORD_LABEL_READ } from '@/store/actions'
import store from '@/store'

import RecordLabelEditButton from '@/components/RecordLabelEditButton'

export default {
  name: 'RecordLabelShow',
  components: {
    RecordLabelEditButton
  },
  props: {
    propped: Object
  },

  data() {
    return {
      recordLabel: null
    }
  },

  created() {
    this.recordLabel = Object.assign({}, this.model)
  },

  computed: {
    model: function () {
      if (this.$store.state.recordLabel.recordLabel.id) return Object.assign({}, this.$store.state.recordLabel.recordLabel)
      if (this.propped) return Object.assign({}, this.propped)

      return {}
    }
  },

  watch: {
    model: function (newValue, oldValue) {
      this.recordLabel = Object.assign({}, newValue)
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(RECORD_LABEL_READ, to.params.id)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(RECORD_LABEL_READ, to.params.id)
    next()
  },

  methods: {
    close() {
      this.$router.push({ name: 'record-labels.index' })
    }
  }
}
</script>
