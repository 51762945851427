<template>
  <div v-if="email">
    <PitchEmailView
      v-if="email.emailable_type === 'Wrios\\Models\\Pitch'"
      :email="email"
      v-on:reload="load"
    />
    <PitchGroupEmailView
      v-if="email.emailable_type === 'Wrios\\Models\\PitchGroup'"
      :email="email"
      v-on:reload="load"
    />
    <PlaylistEmailView
      v-if="email.emailable_type === 'Wrios\\Models\\Playlist'"
      :email="email"
      v-on:reload="load"
    />
    <SongEmailView
      v-if="email.emailable_type === 'Wrios\\Models\\Song'"
      :email="email"
      v-on:reload="load"
    />
    <UserWelcomeEmailView
      v-if="email.emailable_type === 'Wrios\\Models\\User'"
      :email="email"
      v-on:reload="load"
    />
  </div>
</template>

<script>
import { EMAIL_READ_BY_TOKEN } from '@/store/actions'

import PitchEmailView from './PitchEmailView'
import PitchGroupEmailView from './PitchGroupEmailView'
import PlaylistEmailView from './PlaylistEmailView'
import SongEmailView from './SongEmailView'
import UserWelcomeEmailView from './UserWelcomeEmailView'

export default {
  name: 'ExternalEmailShow',
  components: {
    PitchEmailView,
    PitchGroupEmailView,
    PlaylistEmailView,
    SongEmailView,
    UserWelcomeEmailView
  },

  data() {
    return {
      email: null
    }
  },

  created: function () {
    this.load()
  },

  beforeCreate: function () {
    document.body.className = 'pitch-view'
  },

  methods: {
    load() {
      let self = this
      this.$store.dispatch(EMAIL_READ_BY_TOKEN, this.$route.params.token)
        .then(function (data) {
          self.email = data
        }, function (err) {
          self.$store.commit('error', err)
        })
    }
  }

}
</script>
