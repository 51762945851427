<template>
    <div>
    <section>
      <div class="level">
        <div class="level-left">
          <p class="has-text-weight-bold">Option Periods</p>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="create">Add Option Period</a>
        </div>
      </div>
      <div class="level" v-if="!optionPeriods.length">
        <p>
          No option periods.
          <a class="m-l-5" @click="create">Add option period.</a>
        </p>
      </div>
      <div class="level" v-else>
        <table class="table is-fullwidth is-responsive is-hoverable">
          <thead>
            <tr>
              <th>Option Period</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="option-periods"
              v-for="optionPeriod in orderedOptionPeriods"
              :key="optionPeriod.id"
            >
              <td>{{ optionPeriod.title }}</td>
              <td>{{ optionPeriod.start_date | momentDate }}</td>
              <td>{{ optionPeriod.end_date | momentDate }}</td>
              <td>
                <a class="tooltip" data-tooltip="Edit Option Period" @click="edit(optionPeriod)">
                    <IconEdit/>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <form-modal
      type="contractOptionPeriod"
      :title="formTitle"
      :model="selectedOptionPeriod"
      :message="modalMessage"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import { IconEdit } from '@/Icons/IconBase'
import { OPTION_PERIOD_ALL_FOR_CONTRACT, OPTION_PERIOD_UPDATE, OPTION_PERIOD_CREATE, OPTION_PERIOD_DELETE } from '@/store/actions'

export default {
  name: 'ContractOptionPeriods',

  props: ['contractId'],

  components: {
    IconEdit
  },

  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      selectedOptionPeriod: {},
      optionPeriods: [],
      isSaving: false,
      modalMessage: ''
    }
  },

  created() {
    this.load()
  },

  computed: {
    orderedOptionPeriods: function () {
      return _.orderBy(this.optionPeriods, 'start_date')
    }
  },

  methods: {
    load() {
      if (this.contractId) {
        let self = this
        this.$store.dispatch(OPTION_PERIOD_ALL_FOR_CONTRACT, {
          contractId: this.contractId
        }).then(function (data) {
          self.optionPeriods = data
        }, function (err) {
          alert(err)
        })
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Option Period'
      this.isModal = !this.isModal
    },

    edit(optionPeriod) {
      this.formCanDelete = true
      this.formTitle = 'Update Option Period'
      this.selectedOptionPeriod = optionPeriod
      this.isModal = !this.isModal
    },

    save(input) {
      if (input.start_date && input.end_date) {
        let self = this
        if (this.selectedOptionPeriod.id) {
          this.$store.dispatch(OPTION_PERIOD_UPDATE, {
            id: this.selectedOptionPeriod.id,
            data: input
          }).then(function (data) {
            self.clearModal()
            self.load()
          }, function (err) {
            self.clearModal()
            alert(err)
          })
        } else {
          this.$store.dispatch(OPTION_PERIOD_CREATE, {
            contractId: this.contractId,
            data: input
          }).then(function (data) {
            self.clearModal()
            self.load()
          }, function (err) {
            self.clearModal()
            alert(err)
          })
        }
      } else {
        if (!input.start_date && !input.end_date) {
          alert('A start date and end date are required. Please select one.')
        } else if (!input.start_date) {
          alert('A start date is required. Please select one.')
        } else {
          alert('An end date is required. Please select one.')
        }
      }
    },

    destroy(optionPeriod) {
      let self = this
      this.$store.dispatch(OPTION_PERIOD_DELETE, { id: optionPeriod.id })
        .then(function () {
          self.clearModal()
          self.load()
        }, function (err) {
          alert(err)
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedOptionPeriod = {}
      this.modalMessage = ''
    }
  }
}
</script>
