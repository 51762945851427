<template>
<div class="tabs">
    <div class="tabs">
      <ul class="tab-list is-flex">
      <li
        @click="setTab('activity')"
        :class="{'is-active' : activeTab === 'activity'}"
      >
        <a>Activity</a>
      </li>
      <li
        @click="setTab('lyrics')"
        :class="{'is-active' : activeTab === 'lyrics'}"
      >
        <a>Lyrics</a>
      </li>
    </ul>
  </div>
      <activity-viewer v-if="(song.id && activeTab === 'activity')" :subjectId="song.id" subjectType="song"></activity-viewer>
      <song-lyrics v-if="(activeTab === 'lyrics')"  :song="song" :canBeEditedBySongwriterRole="canBeEditedBySongwriterRole"></song-lyrics>
   </div>
</template>

<script>
export default {
  props: {
    song: Object,
    canBeEditedBySongwriterRole: Boolean
  },

  data() {
    return {
      activeTab: 'activity'
    }
  },

  methods: {
    setTab: function (value) {
      this.activeTab = value
    }
  }
}
</script>
