<template>
  <div class="content home">
    <advanced-search-index-header title="Songs" newButtonText="New Song" exportType="song" :exportScope="searchScope"
      :exportSearch="searchQuery" v-on:click-new-button="create"></advanced-search-index-header>
    <section class="section is-paddingless">
      <div class="columns is-marginless ordered-mobile">
        <aside class="column is-3 has-background-light hero fl-2">
          <AsideSongs ref="asideSongs" :searchQuery="searchQuery" v-on:update-query="handleQueryUpdate"
            v-on:create-playlist="createPlaylist" />
        </aside>
        <main class="column is-paddingless fl-1">
          <SearchFilterControlSongs :searchQuery="searchQuery" v-on:update-query="handleQueryUpdate" />
          <div class="container p-b-90">
            <table-actions :perPage="perPage" :viewName="viewName" v-on:per-page-change="setPerPage" class="is-mobile">
              <TabsSongs :scope="searchScope" v-on:set-search-scope="setSearchScope" />
            </table-actions>
            <div v-if="searchScope !== 'submitted'" class="level">
              <div class="level-left">
                <span class="dot m-l-5 m-r-5 has-background-danger"></span>
                <span class="has-text-grey">Hold</span>
                <span class="dot m-l-20 m-r-5 has-background-primary"></span>
                <span class="has-text-grey">Cut</span>
                <span class="dot m-l-20 m-r-5 has-background-success"></span>
                <span class="has-text-grey">Release</span>
                <span class="dot m-l-20 m-r-5 has-background-warning"></span>
                <span class="has-text-grey">Sync</span>
              </div>
            </div>
            <div v-else class="level">
              <div class="level-left">
                <span class="dot m-l-5 m-r-5 has-background-success"></span>
                <span class="has-text-grey">Approved</span>
                <span class="dot m-l-20 m-r-5 has-background-warning"></span>
                <span class="has-text-grey">Pending</span>
                <span class="dot m-l-20 m-r-5 has-background-danger"></span>
                <span class="has-text-grey">Denied</span>
              </div>
            </div>
            <table class="table is-hoverable is-fullwidth is-responsive">
              <TableSongsHead :sortColumn="sortColumn" :sortOrder="sortOrder" :selectAll="selectAll"
                :viewName="viewName" v-on:sort-change="updateSort" v-on:select-all="handleSelectAll" />
              <tbody>
                <TableSongsRow v-for="song in songs" :key="song.id" :song="song" :selectAll="selectAll"
                  :viewName="viewName" v-on:click-edit-button="edit" v-on:click-delete-dropdown="confirmDestroy"
                  v-on:click-download-audio-button="downloadAudio" v-on:click-download-lyrics-button="downloadLyrics"
                  v-on:click-new-pitch-button="createPitch" v-on:click-send-link-button="sendLink"
                  v-on:click-generate-pitch-link-button="generatePitchLink" v-on:click-add-to-queue="enqueueAudioFile"
                  v-on:click-add-hold="addHold" v-on:click-add-cut="addCut" v-on:click-add-release="initRelease"
                  v-on:click-add-sync="addSync"></TableSongsRow>
              </tbody>
            </table>
            <pagination :currentPage="currentPage" :totalPages="totalPages" v-on:current-page-change="setCurrentPage">
            </pagination>
          </div>
        </main>
      </div>
    </section>
    <form-modal :type="formType" :message="modalMessage" :title="formTitle" :model="selectedSong" :isModal="isModal"
      :isSaving="isSaving" :canDelete="formCanDelete" v-on:clear-modal="clearModal" v-on:save-record="save"
      v-on:delete-record="destroy" v-on:item-selected="addRelease" :isSendingNow="isSendingNow"></form-modal>

    <form-modal :type="formType" :message="modalMessage" :title="formTitle" :model="quickAddModel"
      :isModal="isQuickAddModal" :isSaving="isSaving" :canDelete="false" v-on:clear-modal="clearModal"
      v-on:save-record="save" v-on:item-selected="addRelease"></form-modal>
  </div>
  <!-- /home -->
</template>

<script>
import uiHelper from '@/mixins/uiHelper'

import AsideSongs from '@/components/Songs/AsideSongs'
import SearchFilterControlSongs from '@/components/Songs/SearchFilterControlSongs'
import TableSongsHead from '@/components/Songs/TableSongsHead'
import TableSongsRow from '@/components/Songs/TableSongsRow'
import TabsSongs from '@/components/Songs/TabsSongs'

import {
  SONG_ALL,
  SONGWRITER_SONG_ALL,
  SONG_CREATE,
  SONG_DELETE,
  SONG_UPDATE,
  SONGWRITER_ALL_FOR_FORM,
  USER_TAG_ALL,
  ACCOUNT_TAG_ALL,
  RECORD_LABEL_ALL_FOR_FORM,
  ALL_FOR_FORM,
  PUBLISHING_COMPANY_ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  PLAYLIST_ALL,
  PLAYLIST_CREATE,
  PITCH_CREATE,
  EMAIL_CREATE,
  AUDIO_FILE_DOWNLOAD,
  FILE_DOWNLOAD,
  SONG_SUBMISSION_CREATE,
  QUEUED_AUDIO_FILE_USER_CREATE,
  HOLD_CREATE,
  CUT_CREATE,
  CUT_ALL_FOR_SONG,
  RELEASE_CREATE,
  SYNC_CREATE
} from '@/store/actions'

import {
  SONG_CURRENT_PAGE_SET,
  SONG_PER_PAGE_SET,
  SONG_SORT_SET,
  SONG_SEARCH_QUERY_SET,
  SONG_SEARCH_SCOPE_SET
} from '@/store/mutations'

export default {
  name: 'SongIndex',
  mixins: [uiHelper],

  data() {
    return {
      currentPageMutation: SONG_CURRENT_PAGE_SET,
      perPageMutation: SONG_PER_PAGE_SET,
      sortMutation: SONG_SORT_SET,
      searchQueryMutation: SONG_SEARCH_QUERY_SET,
      searchScopeMutation: SONG_SEARCH_SCOPE_SET,
      formTitle: 'Create Song',
      formType: null,
      formCanDelete: false,
      isModal: false,
      isQuickAddModal: false,
      quickAddModel: {},
      selectedSong: {},
      selectedSongs: {},
      modalMessage: null,
      selectAll: false,
      isSaving: false,
      isSendingNow: false
    }
  },

  components: {
    AsideSongs,
    SearchFilterControlSongs,
    TableSongsHead,
    TableSongsRow,
    TabsSongs
  },

  created: function () {
    this.selectAll = false
    this.$store.dispatch(SONGWRITER_ALL_FOR_FORM)
    this.$store.dispatch(PUBLISHING_COMPANY_ALL_FOR_FORM)
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(RECORD_LABEL_ALL_FOR_FORM)
    this.$store.dispatch(USER_TAG_ALL)
    this.$store.dispatch(ACCOUNT_TAG_ALL)
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    this.$store.dispatch(PLAYLIST_ALL)
  },

  computed: {
    resourceState() {
      return this.$store.state.song
    },

    currentPage() {
      return this.$store.state.song.currentPage
    },

    totalPages() {
      return this.$store.state.song.totalPages
    },

    searchQuery() {
      return this.$store.getters.getSongSearchQuery
    },

    songs() {
      return this.$store.state.song.songs
    },

    searchScope() {
      return this.$store.state.song.searchScope
    },

    viewName() {
      return 'songs_' + this.searchScope
    }
  },

  beforeCreate: function () {
    document.body.className = 'home'
  },

  methods: {
    handleQueryUpdate(query) {
      this.setSearchQuery(query, this)
    },

    handleSelectAll(event) {
      this.selectAll = event.target.checked
    },

    load() {
      if (this.$can.includes('read-songwriter-songs') && !this.$can.includes('read-songs')) {
        this.$store.dispatch(SONGWRITER_SONG_ALL)
      } else {
        this.$store.dispatch(SONG_ALL)
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'New Song'
      this.formType = 'song'
      this.modalMessage = 'You can add more details on the next screen.'
      this.isModal = true
    },

    createPitch(song) {
      this.clearModal()
      this.formTitle = `New Pitch - ${song.title}`
      this.formType = 'pitch'
      this.modalMessage = 'You can add more details on the next screen.'
      this.isModal = true
      this.selectedSong = song
    },

    sendLink(song) {
      this.clearModal()
      this.formTitle = `New Link For - ${song.title}`
      this.formType = 'songLink'
      this.isModal = true
      this.selectedSong = song
    },

    generatePitchLink(song) {
      this.clearModal()
      this.formTitle = `New Pitch Link For - ${song.title}`
      this.formType = 'pitchLink'
      this.isModal = true
      this.selectedSong = song
    },

    enqueueAudioFile(audioFileId) {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_USER_CREATE, {
        audio_file_id: audioFileId
      })
        .then(function (data) {
          self.$store.commit('queue', `"${data.audio_file.song.title}" added to your queue.`)
        })
        .catch(function (err) {
          self.$store.commit('error', `Could not add to queue ${err}.`)
        })
    },

    edit(song) {
      this.clearModal()
      this.formTitle = 'Edit Song'
      this.formCanDelete = true
      this.selectedSong = song
      this.formType = 'song'
      this.isModal = true
    },

    addHold(song) {
      this.clearModal()
      this.formTitle = `Add Hold for "${song.title}"`
      this.formCanDelete = false
      this.selectedSong = song
      this.quickAddModel = {}
      this.formType = 'hold'
      this.isQuickAddModal = true
    },

    addCut(song) {
      this.clearModal()
      this.formTitle = `Add Cut for "${song.title}"`
      this.formCanDelete = false
      this.selectedSong = song
      this.quickAddModel = {
        song_id: song.id
      }
      this.formType = 'cut'
      this.isQuickAddModal = true
    },

    initRelease(song) {
      this.clearModal()
      this.formTitle = 'Select Cut to Release'
      this.selectedSong = song
      this.quickAddModel = {}
      this.$store.dispatch(CUT_ALL_FOR_SONG, song.id)
      this.formType = 'release-init'
      this.isQuickAddModal = true
    },

    addRelease(cut) {
      this.isModal = false
      this.isQuickAddModal = false
      this.isSaving = false

      this.formTitle = `Add Release for "${this.selectedSong.title}"`

      this.formType = 'release'
      this.quickAddModel = {
        cut: cut
      }
      this.isQuickAddModal = true
    },

    addSync(song) {
      this.clearModal()
      this.formTitle = `Add Sync for "${song.title}"`
      this.formCanDelete = false
      this.selectedSong = song
      this.formType = 'sync'
      this.isQuickAddModal = true
    },

    saveSong(data) {
      let self = this
      if (this.selectedSong.id) {
        return this.$store.dispatch(SONG_UPDATE, {
          id: this.selectedSong.id,
          data: data
        })
          .then(function (data) {
            self.load()
            self.clearModal()
          }, function (err) {
            self.clearModal()
            self.$store.commit('error', err)
          })
      }

      let songSubmission = data.song_submission
      return this.$store.dispatch(SONG_CREATE, data)
        .then(function (data) {
          if (songSubmission) {
            let song = data
            self.$store.dispatch(SONG_SUBMISSION_CREATE, {
              song_id: song.id
            })
              .then(function (data) {
                self.$router.push({
                  name: 'songs.show',
                  params: {
                    id: song.id,
                    propped: song
                  }
                })
              })
          } else {
            self.$router.push({
              name: 'songs.show',
              params: {
                id: data.id,
                propped: data
              }
            })
          }
        })
    },

    savePitch(data) {
      let self = this
      let subject = data.subject
      let message = data.message
      let allowAudioDownload = data.allowAudioDownload
      let alreadyPitched = !!data.status
      return this.$store.dispatch(PITCH_CREATE, {
        songId: this.selectedSong.id,
        data: data
      })
        .then(function (data) {
          if (alreadyPitched) {
            self.$router.push({
              name: 'pitches.show',
              params: {
                id: data.id,
                propped: data
              }
            })
          } else {
            self.$store.dispatch(EMAIL_CREATE, {
              type: 'pitch',
              id: data.id,
              allowAudioDownload: allowAudioDownload,
              linkOnly: false,
              subject: subject,
              body: message,
              contactIds: [data.pitch_to_contact.id],
              pitchForArtists: []
            })
            self.$router.push({
              name: 'pitches.show',
              params: {
                id: data.id,
                propped: data
              }
            })
          }
        }, function (err) {
          if (err.response.data.errors && err.response.data.errors['duplicate_pitch'] !== undefined) {
            self.isModal = false
            self.$store.dispatch('confirm',
              `A similar pitch was previously created for this song.  Do you want to create a new pitch? and generate a new link?<br><br><strong>Link To Previous Pitch</strong><br><span class="message-url">${err.response.data.errors['pitch_link']}</span>`
            ).then(resp => {
              if (resp === true) {
                data.force = true
                self.savePitch(data)
              } else {
                self.clearModal()
              }
            }).catch(() => {
              self.clearModal()
            })
          } else {
            self.$store.commit('alert', `Pitch could not be created.`)
          }
        })
    },

    savePitchLink(data) {
      let self = this
      let link = data.link
      let linkUrl = data.linkUrl
      let allowAudioDownload = data.allowAudioDownload
      return this.$store.dispatch(PITCH_CREATE, {
        songId: this.selectedSong.id,
        data: data
      })
        .then(function (pitch) {
          pitch.linkOnly = link
          pitch.linkUrl = linkUrl
          pitch.allowAudioDownload = allowAudioDownload
          self.$store.dispatch(EMAIL_CREATE, {
            type: 'pitch',
            id: pitch.id,
            allowAudioDownload: allowAudioDownload,
            linkOnly: true,
            subject: '',
            body: '',
            contactIds: [pitch.pitch_to_contact.id],
            pitchForArtists: []
          })
            .then(function (emails) {
              let emailRecord = emails[0]
              self.$store.commit('modalMessage', `<div class="message-title">Link to the Song Pitch</div><div class="message-url">${emailRecord.link}</div>`)
              self.clearModal()
            })
        }, function (err) {
          if (err.response.data.errors && err.response.data.errors['duplicate_pitch'] !== undefined) {
            self.isModal = false
            self.$store.dispatch('confirm',
              `A similar pitch and pitch link were previously created for this song.  Do you want to create a new pitch and generate a new link?<br><br><strong>Previously-Generated Link</strong><br><span class="message-url">${err.response.data.errors['link']}</span>`
            ).then(resp => {
              if (resp === true) {
                data.force = true
                self.savePitchLink(data)
              } else {
                self.clearModal()
              }
            }).catch(() => {
              self.clearModal()
            })
          } else {
            self.$store.commit('alert', `Pitch could not be created.`)
          }
        })
    },

    savePlaylist(data) {
      let self = this
      return this.$store.dispatch(PLAYLIST_CREATE, data)
        .then(function (data) {
          self.isSaving = false
          self.$refs.asideSongs.loadPlaylists()
          self.clearModal()
        }, function (err) {
          self.isSaving = false
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    saveSongLink(data) {
      let self = this
      return this.$store.dispatch(EMAIL_CREATE, {
        type: 'song',
        id: data.id,
        allowAudioDownload: data.allowAudioDownload,
        linkOnly: false,
        subject: data.subject,
        body: data.message,
        contactIds: [data.send_to_contact.id],
        pitchForArtists: []
      })
        .then(function () {
          self.clearModal()
        })
    },

    saveHold(data) {
      let self = this
      let songId = this.selectedSong.id
      let songTitle = this.selectedSong.title
      if (data.artist) {
        let holdData = {
          artist_id: data.artist.id,
          backup_artist_id: data.backup_artist ? data.backup_artist.id : null,
          hold_contact_id: data.hold_contact ? data.hold_contact.id : null,
          pitch_by_user_id: data.pitch_by_user ? data.pitch_by_user.id : null,
          hold_date: data.hold_date,
          hold_release_date: data.hold_release_date
        }
        this.$store.dispatch(HOLD_CREATE, {
          songId: songId,
          data: holdData
        })
          .then(function () {
            self.$store.dispatch(SONG_UPDATE, {
              id: songId,
              data: null
            })
              .then(function () {
                self.clearModal()
                self.$store.commit('success', `Hold for "${songTitle}" created.`)
              })
          }, function (err) {
            self.clearModal()
            self.$store.commit('error', err)
          })
      } else {
        alert('A primary artist is required. Please select or create one.')
        self.isSaving = false
      }
    },

    saveCut(data) {
      let self = this
      let songId = this.selectedSong.id
      let songTitle = this.selectedSong.title
      let cutData = {
        artist_ids: data.artists ? data.artists.map(function (artist) {
          return artist.id
        }) : [],
        featured_artist_ids: data.featured_artists ? data.featured_artists.map(function (artist) {
          return artist.id
        }) : [],
        producer_contact_ids: data.producer_contacts ? data.producer_contacts.map(function (contact) {
          return contact.id
        }) : [],
        pitch_id: data.pitch ? data.pitch.id : null,
        cut_date: data.cut_date
      }
      this.$store.dispatch(CUT_CREATE, {
        songId: songId,
        data: cutData
      })
        .then(function () {
          self.$store.dispatch(SONG_UPDATE, {
            id: songId,
            data: null
          })
            .then(function () {
              self.clearModal()
              self.$store.commit('success', `Cut for "${songTitle}" created.`)
            })
        }, function (err) {
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    saveRelease(data) {
      let self = this
      let songId = this.selectedSong.id
      let songTitle = this.selectedSong.title
      let releaseData = {
        cut_id: data.cut.id,
        album_id: data.album ? data.album.id : null,
        record_label_id: data.record_label ? data.record_label.id : null,
        label_contact_id: data.label_contact ? data.label_contact.id : null,
        release_date: data.release_date,
        type: data.type ? data.type.value : null,
        isrc_number: data.isrc_number
      }
      this.$store.dispatch(RELEASE_CREATE, {
        cutId: releaseData.cut_id,
        data: releaseData,
        songId: songId
      })
        .then(function () {
          self.$store.dispatch(SONG_UPDATE, {
            id: songId,
            data: null
          })
            .then(function () {
              self.clearModal()
              self.$store.commit('success', `Release for "${songTitle}" created.`)
            })
        }, function (err) {
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    saveSync(data) {
      let self = this
      let songId = this.selectedSong.id
      let songTitle = this.selectedSong.title
      if (data.project) {
        let syncData = {
          project_id: data.project ? data.project.id : null,
          media_type: data.media_type,
          fee: data.fee,
          status: data.status,
          terms: data.terms,
          requested_date: data.requested_date,
          air_date: data.air_date
        }
        this.$store.dispatch(SYNC_CREATE, {
          songId: songId,
          data: syncData
        })
          .then(function () {
            self.$store.dispatch(SONG_UPDATE, {
              id: songId,
              data: null
            })
              .then(function () {
                self.clearModal()
                self.$store.commit('success', `Sync for "${songTitle}" created.`)
              })
          }, function (err) {
            self.clearModal()
            self.$store.commit('error', err)
          })
      } else {
        alert('Please select or create a project.')
      }
    },

    save(data) {
      this.isSaving = true

      if (this.formType === 'hold') {
        return this.saveHold(data)
      }

      if (this.formType === 'cut') {
        return this.saveCut(data)
      }

      if (this.formType === 'release') {
        return this.saveRelease(data)
      }

      if (this.formType === 'sync') {
        return this.saveSync(data)
      }

      if (this.formType === 'song') {
        return this.saveSong(data)
      }

      if (this.formType === 'pitch') {
        return this.savePitch(data)
      }

      if (this.formType === 'pitchLink') {
        return this.savePitchLink(data)
      }

      if (this.formType === 'playlist') {
        return this.savePlaylist(data)
      }

      if (this.formType === 'songLink') {
        return this.saveSongLink(data)
      }

      this.clearModal()
    },

    confirmDestroy(song) {
      this.$store.dispatch('confirm', `Are you sure you want to delete "${song.title}"?`).then(resp => {
        if (resp === true) {
          this.destroy(song)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    destroy: function (song) {
      this.$store.dispatch(SONG_DELETE, song.id)
      this.clearModal()
      this.$store.commit('alert', `"${song.title}" deleted`)
    },

    clearModal() {
      this.isModal = false
      this.isQuickAddModal = false
      this.formCanDelete = false
      this.formTitle = ''
      this.selectedSong = {}
      this.quickAddModel = {}
      this.modalMessage = null
      this.formType = null
      this.isSaving = false
      this.isSendingNow = true
    },

    downloadAudio(song) {
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, song.default_audio_file_id)
        .then(function (resp) {}).catch(function (err) {
          alert(err)
        })
    },

    downloadLyrics(song) {
      this.$store.dispatch(FILE_DOWNLOAD, song.lyrics_id)
        .then(function (resp) {}).catch(function (err) {
          alert(err)
        })
    },

    createPlaylist() {
      if (this.$can.includes('create-playlists')) {
        this.clearModal()
        this.$store.dispatch(ALL_FOR_FORM, 'artist')
        this.$store.dispatch(CONTACT_ALL_FOR_FORM)
        this.formTitle = 'New Playlist'
        this.formType = 'playlist'
        this.selectedSong.isNewPlaylist = true
        this.isModal = true
      } else {
        this.$store.commit('error', 'You are not permitted to perform this action.')
      }
    }
  }

}
</script>
