<template>
    <div class="wrap">
      <HeaderMain/>
      <message-display></message-display>
      <message-modal></message-modal>
      <confirm-display></confirm-display>
      <audio-file-select></audio-file-select>
      <datepicker-modal></datepicker-modal>
      <transition name="custom-classes-transition" enter-active-class="animated fadeIn faster">
        <router-view></router-view>
      </transition>
      <audio-player></audio-player>
    </div>
</template>

<script>
import HeaderMain from '@/layouts/Default/HeaderMain'

export default {
  components: {
    HeaderMain
  },
  name: 'DefaultLayout'
}
</script>
