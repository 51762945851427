<template>
  <div>
    <index-header title="User Profile"></index-header>
    <div class="columns">
      <div class="column is-7">
        <h3 class="m-b-30">Change Password</h3>
        <form class="form" @submit="save">
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label for="Current Password" class="label">Current Password</label>
                <div class="control">
                  <input
                    class="input"
                    type="password"
                    v-model="current_password"
                    required
                    autofocus
                  >
                </div>
              </div>
              <div class="field">
                <label for="New Password" class="label">New Password</label>
                <div class="control">
                  <input class="input" type="password" v-model="password" required>
                </div>
              </div>
              <div class="field">
                <label class="label">Confirm New Password</label>
                <div class="control">
                  <input class="input" type="password" v-model="password_confirmation" required>
                </div>
              </div>
            </div>
          </div>
          <div class="level m-t-15">
            <div class="level-left">
              <div class="level-item">
                <button type="submit" class="button is-primary">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { USER_SELF_UPDATE_PASSWORD } from '@/store/actions'

export default {
  name: 'UserPassword',
  data() {
    return {
      current_password: '',
      password: '',
      password_confirmation: ''
    }
  },

  computed: {
    currentUser: function () {
      return Object.assign({}, this.$store.getters.getProfile)
    }
  },

  methods: {
    save($event) {
      $event.preventDefault()
      let self = this
      this.$store.dispatch(USER_SELF_UPDATE_PASSWORD, this.$data)
        .then(function (resp) {
          self.$store.commit('success', 'Password updated.')
          self.reset()
        }, function (err) {
          self.$store.commit('error', err)
          self.reset()
        })
    },

    reset() {
      this.current_password = ''
      this.password = ''
      this.password_confirmation = ''
    }
  }
}
</script>
