import Vue from 'vue'
import axios from 'axios'

import {
  OPTION_PERIOD_ALL_FOR_CONTRACT,
  OPTION_PERIOD_CREATE,
  OPTION_PERIOD_UPDATE,
  OPTION_PERIOD_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  OPTION_PERIOD_LOADING,
  OPTION_PERIOD_SUCCESS,
  OPTION_PERIOD_ERROR,
  OPTION_PERIOD_SET
} from '../mutations'

export default {
  state: {
    status: '',
    optionPeriod: {}
  },

  mutations: {
    [OPTION_PERIOD_LOADING]: (state) => {
      state.status = 'loading'
    },
    [OPTION_PERIOD_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [OPTION_PERIOD_ERROR]: (state) => {
      state.status = 'error'
    },
    [OPTION_PERIOD_SET]: (state, resp) => {
      Vue.set(state, 'optionPeriod', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'optionPeriod', {})
    }
  },

  actions: {
    [OPTION_PERIOD_ALL_FOR_CONTRACT]: ({
      commit
    }, {
      contractId
    }) => {
      return new Promise((resolve, reject) => {
        commit(OPTION_PERIOD_LOADING)

        axios({
          url: 'contracts/' + contractId + '/option-periods'
        })
          .then(resp => {
            commit(OPTION_PERIOD_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(OPTION_PERIOD_ERROR)
            reject(err)
          })
      })
    },

    [OPTION_PERIOD_CREATE]: ({
      commit
    }, {
      contractId,
      data
    }) => {
      return new Promise((resolve, reject) => {
        commit(OPTION_PERIOD_LOADING)

        axios({
          method: 'post',
          url: 'contracts/' + contractId + '/option-periods',
          data: data
        })
          .then(resp => {
            commit(OPTION_PERIOD_SUCCESS)
            commit(OPTION_PERIOD_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(OPTION_PERIOD_ERROR)
            reject(err)
          })
      })
    },

    [OPTION_PERIOD_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      return new Promise((resolve, reject) => {
        commit(OPTION_PERIOD_LOADING)

        axios({
          method: 'put',
          url: 'option-periods/' + id,
          data: data
        })
          .then(resp => {
            commit(OPTION_PERIOD_SUCCESS)
            commit(OPTION_PERIOD_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(OPTION_PERIOD_ERROR)
            reject(err)
          })
      })
    },

    [OPTION_PERIOD_DELETE]: ({
      commit
    }, {
      id
    }) => {
      return new Promise((resolve, reject) => {
        commit(OPTION_PERIOD_LOADING)

        axios({
          method: 'delete',
          url: 'option-periods/' + id
        })
          .then(resp => {
            commit(OPTION_PERIOD_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(OPTION_PERIOD_ERROR)
            reject(err)
          })
      })
    }

  }
}
