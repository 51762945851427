export const ALL = 'ALL'
export const ALL_FOR_FORM = 'ALL_FOR_FORM'
export const READ = 'READ'
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'

export const ACCOUNT_READ_ACTIVE_ACCOUNT = 'ACCOUNT_READ_ACTIVE_ACCOUNT'
export const ACCOUNT_UPDATE_ACTIVE_ACCOUNT = 'ACCOUNT_UPDATE_ACTIVE_ACCOUNT'

export const ACCOUNT_USER_ALL = 'ACCOUNT_USER_ALL'
export const ACCOUNT_USER_CREATE = 'ACCOUNT_USER_CREATE'
export const ACCOUNT_USER_READ = 'ACCOUNT_USER_READ'
export const ACCOUNT_USER_UPDATE = 'ACCOUNT_USER_UPDATE'
export const ACCOUNT_USER_DELETE = 'ACCOUNT_USER_DELETE'

export const ACCOUNT_TAG_ALL = 'ACCOUNT_TAG_ALL'
export const ACCOUNT_TAG_CREATE = 'ACCOUNT_TAG_CREATE'
export const ACCOUNT_TAG_READ = 'ACCOUNT_TAG_READ'
export const ACCOUNT_TAG_UPDATE = 'ACCOUNT_TAG_UPDATE'
export const ACCOUNT_TAG_DELETE = 'ACCOUNT_TAG_DELETE'

export const AUDIO_FILE_ALL_FOR_SONG = 'AUDIO_FILE_ALL_FOR_SONG'
export const AUDIO_FILE_CREATE = 'AUDIO_FILE_CREATE'
export const AUDIO_FILE_READ = 'AUDIO_FILE_READ'
export const AUDIO_FILE_UPDATE = 'AUDIO_FILE_UPDATE'
export const AUDIO_FILE_DELETE = 'AUDIO_FILE_DELETE'
export const AUDIO_FILE_DOWNLOAD = 'AUDIO_FILE_DOWNLOAD'
export const AUDIO_FILE_ZIP_DOWNLOAD = 'AUDIO_FILE_ZIP_DOWNLOAD'

export const AUDIO_FILE_TYPE_ALL = 'AUDIO_FILE_TYPE_ALL'
export const AUDIO_FILE_TYPE_CREATE = 'AUDIO_FILE_TYPE_CREATE'
export const AUDIO_FILE_TYPE_READ = 'AUDIO_FILE_TYPE_READ'
export const AUDIO_FILE_TYPE_UPDATE = 'AUDIO_FILE_TYPE_UPDATE'
export const AUDIO_FILE_TYPE_DELETE = 'AUDIO_FILE_TYPE_DELETE'

export const AUDIO_PLAYER_LOAD = 'AUDIO_PLAYER_LOAD'
export const NOTIFY_SONG_LISTENED_TO = 'NOTIFY_SONG_LISTENED_TO'
export const NOTIFY_PITCHED_SONG_LISTENED_TO = 'NOTIFY_PITCHED_SONG_LISTENED_TO'

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const CONTACT_ALL = 'CONTACT_ALL'
export const CONTACT_ALL_FOR_FORM = 'CONTACT_ALL_FOR_FORM'
export const CONTACT_CREATE = 'CONTACT_CREATE'
export const CONTACT_READ = 'CONTACT_READ'
export const CONTACT_UPDATE = 'CONTACT_UPDATE'
export const CONTACT_DELETE = 'CONTACT_DELETE'

export const CONTRACT_ALL_FOR_SONGWRITER = 'CONTRACT_ALL_FOR_SONGWRITER'
export const CONTRACT_CREATE = 'CONTRACT_CREATE'
export const CONTRACT_READ = 'CONTRACT_READ'
export const CONTRACT_UPDATE = 'CONTRACT_UPDATE'
export const CONTRACT_DELETE = 'CONTRACT_DELETE'

export const CONTRACT_SHARE_ALL_FOR_CONTRACT = 'CONTRACT_SHARE_ALL_FOR_CONTRACT'
export const CONTRACT_SHARE_CREATE = 'CONTRACT_SHARE_CREATE'
export const CONTRACT_SHARE_READ = 'CONTRACT_SHARE_READ'
export const CONTRACT_SHARE_UPDATE = 'CONTRACT_SHARE_UPDATE'
export const CONTRACT_SHARE_DELETE = 'CONTRACT_SHARE_DELETE'

export const CUT_ALL_FOR_SONG = 'CUT_ALL_FOR_SONG'
export const CUT_CREATE = 'CUT_CREATE'
export const CUT_READ = 'CUT_READ'
export const CUT_UPDATE = 'CUT_UPDATE'
export const CUT_DELETE = 'CUT_DELETE'

export const DATEPICKER_CLOSE = 'DATEPICKER_CLOSE'
export const DATEPICKER_OPEN = 'DATEPICKER_OPEN'

export const EMAIL_ALL = 'EMAIL_ALL'
export const EMAIL_CREATE = 'EMAIL_CREATE'
export const EMAIL_READ = 'EMAIL_READ'
export const EMAIL_READ_BY_TOKEN = 'EMAIL_READ_BY_TOKEN'
export const EMAIL_UPDATE = 'EMAIL_UPDATE'
export const EMAIL_DELETE = 'EMAIL_DELETE'

export const EXPORT_ALL = 'EXPORT_ALL'
export const EXPORT_ALL_COLUMNS = 'EXPORT_ALL_COLUMNS'
export const EXPORT_CREATE = 'EXPORT_CREATE'
export const EXPORT_READ = 'EXPORT_READ'
export const EXPORT_UPDATE = 'EXPORT_UPDATE'
export const EXPORT_DELETE = 'EXPORT_DELETE'

export const FILE_CREATE = 'FILE_CREATE'
export const FILE_READ = 'FILE_READ'
export const FILE_UPDATE = 'FILE_UPDATE'
export const FILE_DELETE = 'FILE_DELETE'
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD'

export const HOLD_ALL_FOR_SONG = 'HOLD_ALL_FOR_SONG'
export const HOLD_CREATE = 'HOLD_CREATE'
export const HOLD_READ = 'HOLD_READ'
export const HOLD_UPDATE = 'HOLD_UPDATE'
export const HOLD_DELETE = 'HOLD_DELETE'

export const IMAGE_CREATE = 'IMAGE_CREATE'
export const IMAGE_READ = 'IMAGE_READ'
export const IMAGE_UPDATE = 'IMAGE_UPDATE'
export const IMAGE_DELETE = 'IMAGE_DELETE'

export const NOTIFICATION_ALL = 'NOTIFICATION_ALL'
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE'

export const OPTION_PERIOD_ALL_FOR_CONTRACT = 'OPTION_PERIOD_ALL_FOR_CONTRACT'
export const OPTION_PERIOD_CREATE = 'OPTION_PERIOD_CREATE'
export const OPTION_PERIOD_READ = 'OPTION_PERIOD_READ'
export const OPTION_PERIOD_UPDATE = 'OPTION_PERIOD_UPDATE'
export const OPTION_PERIOD_DELETE = 'OPTION_PERIOD_DELETE'

export const PITCH_ALL = 'PITCH_ALL'
export const PITCH_ALL_FOR_SONG = 'PITCH_ALL_FOR_SONG'
export const PITCH_CREATE = 'PITCH_CREATE'
export const PITCH_READ = 'PITCH_READ'
export const PITCH_UPDATE = 'PITCH_UPDATE'
export const PITCH_EMAIL_UPDATE = 'PITCH_EMAIL_UPDATE'
export const PITCH_DELETE = 'PITCH_DELETE'

export const PLAYLIST_ALL = 'PLAYLIST_ALL'
export const PLAYLIST_CREATE = 'PLAYLIST_CREATE'
export const PLAYLIST_READ = 'PLAYLIST_READ'
export const PLAYLIST_UPDATE = 'PLAYLIST_UPDATE'
export const PLAYLIST_DELETE = 'PLAYLIST_DELETE'
export const PLAYLIST_DUPLICATE = 'PLAYLIST_DUPLICATE'
export const PLAYLIST_CD_LABEL_CREATE = 'PLAYLIST_CD_LABEL_CREATE'
export const PLAYLIST_AUDIO_FILE_DELETE = 'PLAYLIST_AUDIO_FILE_DELETE'
export const PLAYLIST_AUDIO_FILE_REPOSITION = 'PLAYLIST_AUDIO_FILE_REPOSITION'

export const PROJECT_ALL = 'PROJECT_ALL'
export const PROJECT_ALL_FOR_FORM = 'PROJECT_ALL_FOR_FORM'
export const PROJECT_CREATE = 'PROJECT_CREATE'
export const PROJECT_READ = 'PROJECT_READ'
export const PROJECT_UPDATE = 'PROJECT_UPDATE'
export const PROJECT_DELETE = 'PROJECT_DELETE'

export const PUBLISHING_COMPANY_ALL = 'PUBLISHING_COMPANY_ALL'
export const PUBLISHING_COMPANY_ALL_FOR_FORM = 'PUBLISHING_COMPANY_ALL_FOR_FORM'
export const PUBLISHING_COMPANY_CREATE = 'PUBLISHING_COMPANY_CREATE'
export const PUBLISHING_COMPANY_READ = 'PUBLISHING_COMPANY_READ'
export const PUBLISHING_COMPANY_UPDATE = 'PUBLISHING_COMPANY_UPDATE'
export const PUBLISHING_COMPANY_DELETE = 'PUBLISHING_COMPANY_DELETE'

export const QUEUED_AUDIO_FILE_USER_ALL = 'QUEUED_AUDIO_FILE_USER_ALL'
export const QUEUED_AUDIO_FILE_SESSION_ALL = 'QUEUED_AUDIO_FILE_SESSION_ALL'
export const QUEUED_AUDIO_FILE_USER_CREATE = 'QUEUED_AUDIO_FILE_USER_CREATE'
export const QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST = 'QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST'
export const QUEUED_AUDIO_FILE_SESSION_CREATE = 'QUEUED_AUDIO_FILE_SESSION_CREATE'
export const QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PLAYLIST = 'QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PLAYLIST'
export const QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PITCH_GROUP = 'QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PITCH_GROUP'
export const QUEUED_AUDIO_FILE_USER_READ = 'QUEUED_AUDIO_FILE_USER_READ'
export const QUEUED_AUDIO_FILE_SESSION_READ = 'QUEUED_AUDIO_FILE_SESSION_READ'
export const QUEUED_AUDIO_FILE_USER_UPDATE = 'QUEUED_AUDIO_FILE_USER_UPDATE'
export const QUEUED_AUDIO_FILE_SESSION_UPDATE = 'QUEUED_AUDIO_FILE_SESSION_UPDATE'
export const QUEUED_AUDIO_FILE_USER_DELETE = 'QUEUED_AUDIO_FILE_USER_DELETE'
export const QUEUED_AUDIO_FILE_SESSION_DELETE = 'QUEUED_AUDIO_FILE_SESSION_DELETE'

export const RECORD_LABEL_ALL = 'RECORD_LABEL_ALL'
export const RECORD_LABEL_ALL_FOR_FORM = 'RECORD_LABEL_ALL_FOR_FORM'
export const RECORD_LABEL_CREATE = 'RECORD_LABEL_CREATE'
export const RECORD_LABEL_READ = 'RECORD_LABEL_READ'
export const RECORD_LABEL_UPDATE = 'RECORD_LABEL_UPDATE'
export const RECORD_LABEL_DELETE = 'RECORD_LABEL_DELETE'

export const RELEASE_ALL_FOR_SONG = 'RELEASE_ALL_FOR_SONG'
export const RELEASE_CREATE = 'RELEASE_CREATE'
export const RELEASE_READ = 'RELEASE_READ'
export const RELEASE_UPDATE = 'RELEASE_UPDATE'
export const RELEASE_DELETE = 'RELEASE_DELETE'

export const SONG_ALL = 'SONG_ALL'
export const SONGWRITER_SONG_ALL = 'SONGWRITER_SONG_ALL'
export const SONG_CREATE = 'SONG_CREATE'
export const SONG_READ = 'SONG_READ'
export const SONG_UPDATE = 'SONG_UPDATE'
export const SONG_DELETE = 'SONG_DELETE'
export const SONG_SET_DEFAULT_AUDIO_FILE = 'SONG_SET_DEFAULT_AUDIO_FILE'

export const SONG_SUBMISSION_CREATE = 'SONG_SUBMISSION_CREATE'
export const SONG_SUBMISSION_UPDATE = 'SONG_SUBMISSION_UPDATE'

export const SONGWRITER_ALL = 'SONGWRITER_ALL'
export const SONGWRITER_ALL_FOR_FORM = 'SONGWRITER_ALL_FOR_FORM'
export const SONGWRITER_CREATE = 'SONGWRITER_CREATE'
export const SONGWRITER_READ = 'SONGWRITER_READ'
export const SONGWRITER_UPDATE = 'SONGWRITER_UPDATE'
export const SONGWRITER_DELETE = 'SONGWRITER_DELETE'

export const SYNC_ALL_FOR_SONG = 'SYNC_ALL_FOR_SONG'
export const SYNC_CREATE = 'SYNC_CREATE'
export const SYNC_READ = 'SYNC_READ'
export const SYNC_UPDATE = 'SYNC_UPDATE'
export const SYNC_DELETE = 'SYNC_DELETE'

export const TAG_ALL_FOR_TAGGABLE = 'TAG_ALL_FOR_TAGGABLE'

export const TAGGED_ITEM_CREATE = 'TAGGED_ITEM_CREATE'
export const TAGGED_ITEM_DELETE = 'TAGGED_ITEM_DELETE'
export const TAGGED_ITEM_DELETE_BY_TAGGABLE = 'TAGGED_ITEM_DELETE_BY_TAGGABLE'
export const TAGGED_ITEM_ALL_FOR_TAG = 'TAGGED_ITEM_ALL_FOR_TAG'

export const USER_ALL = 'USER_ALL'
export const USER_CREATE = 'USER_CREATE'
export const USER_READ = 'USER_READ'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_SELF_UPDATE = 'USER_SELF_UPDATE'
export const USER_SELF_UPDATE_PASSWORD = 'USER_SELF_UPDATE_PASSWORD'
export const USER_DELETE = 'USER_DELETE'
export const USER_SELF = 'USER_SELF'

export const USER_TAG_ALL = 'USER_TAG_ALL'
export const USER_TAG_CREATE = 'USER_TAG_CREATE'
export const USER_TAG_READ = 'USER_TAG_READ'
export const USER_TAG_UPDATE = 'USER_TAG_UPDATE'
export const USER_TAG_DELETE = 'USER_TAG_DELETE'

export const WRITING_CREDIT_ALL_FOR_SONG = 'WRITING_CREDIT_ALL_FOR_SONG'
export const WRITING_CREDIT_CREATE = 'WRITING_CREDIT_CREATE'
export const WRITING_CREDIT_READ = 'WRITING_CREDIT_READ'
export const WRITING_CREDIT_UPDATE = 'WRITING_CREDIT_UPDATE'
export const WRITING_CREDIT_DELETE = 'WRITING_CREDIT_DELETE'
