<template>
  <div v-if="pitch" class="content">
    <progress v-if="isResponding" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <section class="m-t-30 m-r-30 m-l-30 m-b-30">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="m-r-30 m-b-0">
                <span class="has-text-grey-light has-text-weight-bold">Pitching To:</span>
                {{pitch.pitch_to_contact.full_name}}
              </p>
              <p>
                <span
                  class="has-text-grey-light has-text-weight-bold m-l-30"
                >Pitching For:</span>
                {{pitch.pitch_artist_names}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="card has-text-centered">
          <div class="card-content m-b-5">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right pitch-view--actions">
                <div class="level-item">
                  <play-button
                    style="height: 30px;"
                    @click.native="notifySongListenedTo(pitch.song_id, pitch)"
                    v-if="pitch.audio_file_id"
                    :audioFileId="pitch.audio_file_id"
                    :pitchId="pitch.id"
                  ></play-button>
                </div>
                  <div class="level-item">
                     <a v-if="pitch.song_lyrics" @click="viewLyrics(pitch.song_lyrics)" class="is-primary tooltip is-tooltip-left" data-tooltip="View Lyrics"><IconEye/></a>
                     <a v-else href="#" class="tooltip is-tooltip-left disabled has-text-grey-light"  style="cursor: not-allowed;" data-tooltip="No Lyrics"><IconEye/></a>
                  </div>
                  <div class="level-item">
                     <a v-if="pitch.song_lyrics" @click="downloadLyrics(pitch.song_lyrics)" class="is-primary tooltip is-tooltip-left" data-tooltip="Download Lyrics"><IconFileText/></a>
                     <a v-else href="#" class="tooltip is-tooltip-left disabled has-text-grey-light"  style="cursor: not-allowed;" data-tooltip="No Lyrics"><IconFileText/></a>
                  </div>
                <div class="level-item">
                  <a
                    @click="downloadAudio(pitch)"
                    v-if="allowAudioDownload"
                    class="is-primary tooltip is-tooltip-left"
                    data-tooltip="Download Song"
                  >
                    <IconDownload/>
                  </a>
                </div>
              </div>
            </div>
            <div class="level">
              <div class="level-item" style="max-width: 100%; word-break:break-all">
                <h2 class="title">{{ pitch.song_title }}</h2>
              </div>
            </div>
            <div class="columns">
                <div class="column">
                  <label for="Songwriters" class="label m-b-0">Songwriters:</label>
                  <p>{{ pitch.songwriters }}</p>
                </div>
            </div>
            <div v-if="pitch.status === 'Sent'" class="level m-t-30">
              <a
                @click.stop="sendResponse('Took Copy', pitch)"
                class="level-item m-l-5 m-r-5 has-background-primary button review-button"
              >
                <div class="p-t-15 p-b-15">
                  <p>Take Copy</p>
                </div>
              </a>
              <a
                @click.stop="requestHold(pitch)"
                class="level-item m-l-5 m-r-5 has-background-primary button review-button"
              >
                <div class="p-t-15 p-b-15">
                  <p>Request Hold</p>
                </div>
              </a>
              <a
                @click.stop="sendResponse('Pass', pitch)"
                class="level-item m-l-5 m-r-5 has-background-danger button review-button"
              >
                <div class="p-t-15 p-b-15">
                  <p>Pass</p>
                </div>
              </a>
            </div>
            <div v-else>
              <div class="level">
                <div class="level-item">
                  <div class="level-left">
                    <h4 class="m-b-0">{{pitch.status}}</h4>
                  </div>
                </div>
              </div>
              <div class="level">
                <div class="level-item">
                  <div class="level-left">
                    <span class="has-text-weight-light">Thanks for listening!</span>
                  </div>
                </div>
              </div>
              <div class="level" v-if="pitch.status === 'Request Hold' && pitch.pitch_by_user.full_name">
                <div class="level-item">
                  <div class="level-left">
                    <span class="has-text-weight-light">{{pitch.pitch_by_user.full_name}} will follow up regarding your hold request.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <form-modal
      type="holdRequest"
      :message="modalMessage"
      :title="formTitle"
      :model="selectedPitch"
      :isModal="isModal"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
    ></form-modal>
  </div>
</template>

<script>
import PitchesApi from '@/services/api/Pitches'
import { IconDownload, IconEye, IconFileText } from '@/Icons/IconBase'
import { PITCH_EMAIL_UPDATE, AUDIO_FILE_DOWNLOAD, NOTIFY_PITCHED_SONG_LISTENED_TO, FILE_DOWNLOAD } from '@/store/actions'

export default {
  name: 'PitchEmailView',
  components: {
    IconDownload,
    IconEye,
    IconFileText
  },
  props: {
    email: Object
  },
  data() {
    return {
      isResponding: false,
      formTitle: 'Request Hold',
      isModal: false,
      modalMessage: null,
      selectedPitch: {}
    }
  },

  beforeCreate: function () {
    document.body.className = 'pitch-view'
  },

  computed: {
    pitch() {
      return this.email.emailable
    },

    allowAudioDownload() {
      return this.email.options.allow_audio_download
    }
  },

  methods: {
    sendResponse(value, pitch) {
      let self = this
      this.isResponding = true
      this.selectedPitch = Object.assign({}, pitch)
      this.selectedPitch.status = value
      this.selectedPitch.emailId = this.$props.email.id
      this.$store.dispatch(PITCH_EMAIL_UPDATE, {
        id: this.selectedPitch.id,
        data: this.selectedPitch
      }).then(function (resp) {
        pitch.status = resp.status
        self.isResponding = false
      })
    },

    requestHold(pitch) {
      let self = this
      self.clearModal()
      self.formTitle = `Request Hold For - ${pitch.song_title}`
      self.isModal = !self.isModal
      self.selectedPitch = pitch
    },
    save(data) {
      this.clearModal()
      let self = this
      this.selectedPitch = data
      this.selectedPitch.status = 'Request Hold'
      this.selectedPitch.emailId = this.$props.email.id
      this.$store.dispatch(PITCH_EMAIL_UPDATE, {
        id: this.selectedPitch.id,
        data: this.selectedPitch
      }).then(function (resp) {
        self.$emit('reload')
        self.isResponding = false
      })
    },

    clearModal() {
      this.isModal = false
      this.formTitle = ''
      this.modalMessage = null
    },
    notifySongListenedTo(songId, pitch) {
      this.$store.dispatch(NOTIFY_PITCHED_SONG_LISTENED_TO, { song: songId, pitch: pitch.id })
    },

    downloadAudio(pitch) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, pitch.audio_file_id)
        .then(function (resp) {
          self.isResponding = false
          PitchesApi.updateAudioFileDownloadedAt(pitch.id)
            .then(resp => {
              console.log('audio file downloaded')
            })
            .catch(err => {
              console.log(err)
            })
        }).catch(function (err) {
          this.isResponding = false
          self.$store.commit('error', err)
        })
    },
    downloadLyrics(lyrics) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(FILE_DOWNLOAD, lyrics.id)
        .then(function (resp) {
          self.isResponding = false
        }).catch(function (err) {
          alert(err)
        })
    },
    viewLyrics(lyrics) {
      if (lyrics.content_type === 'application/msword' || lyrics.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        window.open('https://docs.google.com/viewerng/viewer?url=' + encodeURI(lyrics.url), '_blank')
        // window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURI(lyrics.url), '_blank')
      } else {
        window.open(lyrics.url, '_blank')
      }
    }
  }
}
</script>
