<template>
  <div class="content home">
    <section class="section is-paddingless">
      <div class="columns is-marginless">
        <aside class="column has-background-light hero menu menu-aside is-hidden-mobile">
          <p class="menu-label">User Profile</p>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'user.profile' }">Profile Info
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'user.settings' }">Settings
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'user.password' }">Change Password
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
          </ul>

          <p class="menu-label">Notifications</p>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'user.notifications' }">My Notifications
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
          </ul>

          <p class="menu-label">Account Profile</p>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'user.account.profile' }">Account Info
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
            <li v-if="$can.includes('read-audio-file-types')">
              <router-link :to="{ name: 'user.account.audio-file-types' }">Audio File Types
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'user.account.users' }">Users
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'user.account.subscription' }">Subscription
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
          </ul>

          <p class="menu-label" v-if="$can.includes('can-tag')">Tags</p>
          <ul class="menu-list" v-if="$can.includes('can-tag')">
            <li>
              <router-link :to="{ name: 'user.tags' }">My Tags
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
            <li v-if="$can.includes('read-account-tags')">
              <router-link :to="{ name: 'user.account.tags' }">Account Tags
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
          </ul>

          <p class="menu-label">Exports</p>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'user.exports' }">View Exports
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight/>
                </div>
              </router-link>
            </li>
          </ul>
        </aside>
        <main class="column is-four-fifths is-paddingless">
          <div class="container no-hero">
            <transition
              name="custom-classes-transition"
              enter-active-class="animated fadeIn faster"
            >
              <router-view></router-view>
            </transition>
          </div>
        </main>
      </div>
    </section>
  </div>
</template>

<script>
import { IconChevronRight } from '@/Icons/IconBase'
export default {
  name: 'User',
  components: {
    IconChevronRight
  }
}
</script>
