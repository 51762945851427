import axios from 'axios'

export default {

  getMasterOwners(accountId) {
    return axios({
      url: 'accounts/' + accountId + '/master-rights'
    })
  },

  getMasterOwnersForAudioFile(audioFileId) {
    return axios({
      url: 'audio-files/' + audioFileId + '/master-rights'
    })
  },

  storeMasterOwner(audioFileId, ownerType, ownerId, percentage) {
    return axios({
      method: 'post',
      url: 'audio-files/' + audioFileId + '/master-rights',
      data: {
        owner_type: ownerType,
        owner_id: ownerId,
        percentage: percentage
      }
    })
  },

  updateMasterOwner(id, percentage) {
    return axios({
      method: 'put',
      url: 'master-rights/' + id,
      data: {
        percentage: percentage
      }
    })
  },

  deleteMasterOwner(id) {
    return axios({
      method: 'delete',
      url: 'master-rights/' + id
    })
  }

}
