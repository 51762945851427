<template>
  <div class="activity-viewer">
    <progress
      v-if="isLoading || !isInit"
      class="progress is-small is-primary component-progress-bar"
      max="100"
    ></progress>
    <div v-if="!selectedActivity" @click="load" class="is-pulled-right refresh-button">
      <IconRefresh/>
    </div>
    <transition enter-active-class="animated fadeIn faster">
      <div v-if="!selectedActivity">
        <article class="media" v-for="activity in activities" :key="activity.id">
          <div class="media-content">
            <div class="content">
              <small v-if="activity.subject_type !== 'Wrios\\Models\\Pitch'">{{activity.subject_type | phpClassName}}</small>
              <br>
              <small>{{activity.created_at | momentDateTime}}</small>
              <br>
              <div
                class="details-button"
                @click="open(activity)"
              >{{activity.changes | snakeToTitle}} - {{activity.description | capitalize}} by {{activity.causer | orBlank('full_name')}}</div>
            </div>
          </div>
        </article>

        <div class="level activity-pagination">
          <div class="level-left">
            <div class="level-item">
              <a v-if="currentPage > 1" @click="previousPage">
                <IconChevronLeft/>Back
              </a>
            </div>
          </div>
          <div class="level-right">
            <div class="level-right">
              <a v-if="currentPage < totalPages" @click="nextPage">
                More
                <IconChevronRight/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn faster">
      <div v-if="selectedActivity">
        <article class="media activity-details">
          <div class="media-content">
            <div class="content">
              <p>
                <label class="label">Activity Details</label>
                <small>{{selectedActivity.created_at | momentDateTime}}</small>
                <br>
                {{selectedActivity.description | capitalize }} by {{selectedActivity.causer | orBlank('full_name')}}
              </p>
              <div
                v-for="(value, key) in selectedActivity.properties.attributes"
                :key="key"
                class="activity-attribute"
              >
                <div v-if="oldAttributes && attributeHasChanged(key)">
                  <small v-if="!key.includes('date')">
                    <span class="has-text-weight-semibold">{{key | snakeToTitle }}</span>
                    <br>Changed from: <br>"<span class="is-italic">{{ oldAttributes | orBlank(key) }}</span>"
                    <br>To: <br>"<span class="is-italic">{{value | orBlank()}}</span>"
                  </small>
                  <small v-else>
                    <span class="has-text-weight-semibold">{{key | snakeToTitle }}</span>
                    <br>Changed from: <br>"<span class="is-italic">{{ oldAttributes[key] | momentDate | orBlank(key) }}</span>"
                    <br>To: <br>"<span class="is-italic">{{value | momentDate |orBlank()}}</span>"
                  </small>
                </div>
                <div v-if="!oldAttributes">
                  <small v-if="!key.includes('date')">{{key | snakeToTitle }}: {{value | orBlank()}}</small>
                  <small v-else>{{key | snakeToTitle}}: {{value | momentDate | orBlank()}}</small>
                </div>
              </div>
            </div>
          </div>
        </article>
        <div class="level activity-pagination">
          <div class="level-left">
            <div class="level-item">
              <a @click="close">
                <IconChevronLeft/>Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import ActivitiesApi from '@/services/api/Activities'
import { IconChevronLeft, IconChevronRight, IconRefresh } from '@/Icons/IconBase'
import moment from 'moment'

export default {
  name: 'ActivityViewer',
  props: ['subjectId', 'subjectType'],
  components: {
    IconChevronLeft,
    IconChevronRight,
    IconRefresh
  },

  data() {
    return {
      activities: [],
      isLoading: false,
      isInit: false,
      perPage: 15,
      currentPage: 1,
      totalPages: 0,
      selectedActivity: null
    }
  },

  created() {
    this.load()
  },

  computed: {
    attributes() {
      if (this.selectedActivity) {
        console.log(this.selectedActivity.properties.attributes)
        return this.selectedActivity.properties.attributes
      }

      return null
    },

    oldAttributes() {
      if (this.selectedActivity) {
        console.log('old', this.selectedActivity.properties.old)
        return this.selectedActivity.properties.old
      }

      return null
    }
  },

  watch: {
    subjectId: function () {
      this.load()
    },

    subjectType: function () {
      this.load()
    },

    currentPage: function () {
      this.load()
    },

    perPage: function () {
      this.load()
    }
  },

  methods: {
    open(activity) {
      this.selectedActivity = activity
    },

    close() {
      this.selectedActivity = null
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage = this.currentPage + 1
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1
      }
    },

    setPerPage(value) {
      this.perPage = value
    },

    datesAreEqual(firstDate, secondDate) {
      return moment(firstDate, 'YYYY-MM-DD hh:mm:ss').isSame(secondDate, 'day')
    },

    attributeHasChanged(attributeKey) {
      if (this.attributes && this.oldAttributes) {
        if (attributeKey === 'date') {
          return !this.datesAreEqual(this.attributes[attributeKey], this.oldAttributes[attributeKey])
        }
        return this.attributes[attributeKey] !== this.oldAttributes[attributeKey]
      }
    },

    load() {
      if (!this.subjectId) {
        return false
      }

      this.isLoading = true
      let self = this

      ActivitiesApi.getActivities(this.subjectType, this.subjectId, this.perPage, this.currentPage)
        .then(resp => {
          self.activities = resp.data.data
          self.totalPages = resp.data.meta.last_page

          self.isLoading = false
          self.isInit = true
        })
        .catch(err => {
          self.isLoading = false
          self.$store.commit('error', 'Could not load activity data.')
          console.log(err)
        })
    }
  }
}
</script>
