import {
  DATEPICKER_OPEN
} from '@/store/actions'

export default {
  watch: {
    datepickerData: function (newValue, oldValue) {
      if (newValue.key) {
        let splitKey = newValue.key.split('.')
        if (splitKey.length > 1) {
          this[splitKey[0]][splitKey[1]] = newValue.data
        } else {
          this[newValue.key] = newValue.data
        }

        this.$forceUpdate()
      }
    }
  },

  computed: {
    datepickerData() {
      return this.$store.getters.getDatepickerData
    }
  },

  methods: {
    openDatePicker: function (key, value, title, type) {
      this.$store.dispatch(DATEPICKER_OPEN, {
        data: value,
        title: title,
        key: key,
        type: type || 'range'
      })
    }
  }
}
