<template>
  <aside class="column is-2 has-background-light hero menu">
    <p class="menu-label">User Profile</p>
    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'settings.index' }"
          @click="selectedSection = 1"
          :class="{'is-active' : selectedSection === 1}"
        >
          Profile
          <div class="menu-icon is-pulled-right">
            <IconChevronRight/>
          </div>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'settings.index' }"
          @click="selectedSection = 2"
          :class="{'is-active' : selectedSection === 2}"
        >
          Notifications
          <div class="menu-icon is-pulled-right">
            <IconChevronRight/>
          </div>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'settings.index' }"
          @click="selectedSection = 3"
          :class="{'is-active' : selectedSection === 3}"
        >
          Password
          <div class="menu-icon is-pulled-right">
            <IconChevronRight/>
          </div>
        </router-link>
      </li>
    </ul>
    <p class="menu-label">Tags</p>
    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'tags.index' }"
          @click="selectedSection = 4"
          :class="{'is-active' : selectedSection === 4}"
        >
          Manage Tags
          <div class="menu-icon is-pulled-right">
            <IconChevronRight/>
          </div>
        </router-link>
      </li>
    </ul>
    <p class="menu-label">Exports</p>
    <ul class="menu-list">
      <li>
        <a>
          View Exports
          <div class="menu-icon is-pulled-right">
            <IconChevronRight/>
          </div>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>

import { IconChevronRight } from '@/Icons/IconBase'

export default {
  name: 'AsideSettings',
  data() {
    return {
      selectedSection: 1
    }
  },

  components: {
    IconChevronRight
  }
}
</script>
