import Vue from 'vue'
import axios from 'axios'

import {
  QUEUED_AUDIO_FILE_USER_ALL,
  QUEUED_AUDIO_FILE_SESSION_ALL,
  QUEUED_AUDIO_FILE_USER_CREATE,
  QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST,
  QUEUED_AUDIO_FILE_SESSION_CREATE,
  QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PLAYLIST,
  QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PITCH_GROUP,
  QUEUED_AUDIO_FILE_USER_READ,
  QUEUED_AUDIO_FILE_SESSION_READ,
  QUEUED_AUDIO_FILE_USER_UPDATE,
  QUEUED_AUDIO_FILE_SESSION_UPDATE,
  QUEUED_AUDIO_FILE_USER_DELETE,
  QUEUED_AUDIO_FILE_SESSION_DELETE
} from '../actions'

import {
  QUEUED_AUDIO_FILE_LOADING,
  QUEUED_AUDIO_FILE_ERROR,
  QUEUED_AUDIO_FILE_SUCCESS,
  QUEUED_AUDIO_FILE_SET_ALL
} from '../mutations'

export default {
  state: {
    status: '',
    queuedAudioFiles: []
  },

  getters: {
    getQueuedAudioFiles: state => state.queuedAudioFiles
  },

  mutations: {
    [QUEUED_AUDIO_FILE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [QUEUED_AUDIO_FILE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [QUEUED_AUDIO_FILE_ERROR]: (state) => {
      state.status = 'error'
    },
    [QUEUED_AUDIO_FILE_SET_ALL]: (state, data) => {
      Vue.set(state, 'queuedAudioFiles', data)
    }
  },

  actions: {
    [QUEUED_AUDIO_FILE_USER_ALL]: ({
      commit
    }) => {
      return new Promise((resolve, reject) => {
        commit(QUEUED_AUDIO_FILE_LOADING)
        axios({
          url: 'users/me/user-queued-audio-files'
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SET_ALL, resp.data.data)
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_SESSION_ALL]: ({
      commit
    }, sessionId) => {
      return new Promise((resolve, reject) => {
        commit(QUEUED_AUDIO_FILE_LOADING)
        axios({
          url: 'session-queued-audio-files',
          params: {
            session_id: sessionId
          }
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SET_ALL, resp.data.data)
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_USER_READ]: ({
      commit
    }, id) => {
      return new Promise((resolve, reject) => {
        commit(QUEUED_AUDIO_FILE_LOADING)
        axios({
          url: 'user-queued-audio-files/' + id
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_SESSION_READ]: ({
      commit
    }, id) => {
      return new Promise((resolve, reject) => {
        commit(QUEUED_AUDIO_FILE_LOADING)
        axios({
          url: 'session-queued-audio-files/' + id
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_USER_CREATE]: ({
      commit,
      dispatch
    }, data) => {
      commit(QUEUED_AUDIO_FILE_LOADING)

      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: 'users/me/user-queued-audio-files',
          data: data
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            dispatch(QUEUED_AUDIO_FILE_USER_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST]: ({
      commit,
      dispatch
    }, data) => {
      commit(QUEUED_AUDIO_FILE_LOADING)

      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: 'users/me/user-queued-audio-files/playlist',
          data: data
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            dispatch(QUEUED_AUDIO_FILE_USER_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_SESSION_CREATE]: ({
      commit,
      dispatch
    }, data) => {
      commit(QUEUED_AUDIO_FILE_LOADING)

      axios({
        method: 'post',
        url: 'session-queued-audio-files',
        data: data
      })
        .then(resp => {
          commit(QUEUED_AUDIO_FILE_SUCCESS)
          dispatch(QUEUED_AUDIO_FILE_SESSION_ALL)
        })
        .catch(err => {
          console.log(err)
          commit(QUEUED_AUDIO_FILE_ERROR)
        })
    },

    [QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PLAYLIST]: ({
      commit,
      dispatch
    }, data) => {
      commit(QUEUED_AUDIO_FILE_LOADING)
      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: 'session-queued-audio-files/playlist',
          data: data
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            dispatch(QUEUED_AUDIO_FILE_SESSION_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PITCH_GROUP]: ({
      commit,
      dispatch
    }, data) => {
      commit(QUEUED_AUDIO_FILE_LOADING)

      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: 'session-queued-audio-files/pitch-group',
          data: data
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            dispatch(QUEUED_AUDIO_FILE_SESSION_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_USER_UPDATE]: ({
      commit
    }, { id, data }) => {
      return new Promise((resolve, reject) => {
        commit(QUEUED_AUDIO_FILE_LOADING)

        axios({
          method: 'put',
          url: 'user-queued-audio-files/' + id,
          data: data
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_SESSION_UPDATE]: ({
      commit
    }, { id, data }) => {
      return new Promise((resolve, reject) => {
        commit(QUEUED_AUDIO_FILE_LOADING)

        axios({
          method: 'put',
          url: 'session-queued-audio-files/' + id,
          data: data
        })
          .then(resp => {
            commit(QUEUED_AUDIO_FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(QUEUED_AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [QUEUED_AUDIO_FILE_USER_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(QUEUED_AUDIO_FILE_LOADING)

      axios({
        method: 'delete',
        url: 'user-queued-audio-files/' + id
      })
        .then(resp => {
          dispatch(QUEUED_AUDIO_FILE_USER_ALL)
        })
        .catch(resp => {
          commit(QUEUED_AUDIO_FILE_ERROR)
        })
    },

    [QUEUED_AUDIO_FILE_SESSION_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(QUEUED_AUDIO_FILE_LOADING)

      axios({
        method: 'delete',
        url: 'session-queued-audio-files/' + id
      })
        .then(resp => {
          dispatch(QUEUED_AUDIO_FILE_SESSION_ALL)
        })
        .catch(resp => {
          commit(QUEUED_AUDIO_FILE_ERROR)
        })
    }
  }
}
