<template>
  <div>
    <div class="field m-b-15">
      <label for="name" class="label">Name</label>
      <input
        id="name"
        type="text"
        class="input"
        placeholder="Name"
        v-model="recordLabel.name"
      >
    </div>
    <div class="field m-b-15">
      <label for="address_city" class="label">City</label>
      <input
        id="address_city"
        type="text"
        class="input"
        placeholder="Nashville"
        v-model="recordLabel.address_city"
      >
    </div>
    <div class="field m-b-15">
      <label for="address_state" class="label">State</label>
      <input
        id="address_state"
        type="text"
        class="input"
        placeholder="TN"
        v-model="recordLabel.address_state"
      >
    </div>
    <div class="field m-b-15">
      <label for="website" class="label">Website</label>
      <input
        id="website"
        type="text"
        class="input"
        placeholder="https://www.wrios.com"
        v-model="recordLabel.website"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordLabelForm',
  model: {
    prop: 'recordLabel',
    event: 'change'
  },
  props: {
    recordLabel: Object
  }
}
</script>
