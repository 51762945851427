<template>
  <thead class="is-size-7">
    <tr>
      <!-- <th class="has-text-grey-light">
        <input
          class="is-checkradio"
          id="selectAllCheckbox"
          type="checkbox"
          name="selectAllCheckbox"
          @change="$emit('select-all', $event)"
          :model="selectAll"
        >
        <label for="selectAllCheckbox"></label>
      </th> -->
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('date')">Pitch Date
        <column-sort-indicator name="date" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('song_title')">Song Title
        <column-sort-indicator name="song_title" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('pitch_to_contact')">Pitched To
        <column-sort-indicator name="pitch_to_contact" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('pitch_artist_names')">Pitched For
        <column-sort-indicator name="pitch_artist_names" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('pitch_by_user')">Pitched By
        <column-sort-indicator name="pitch_by_user" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('status')">Status
        <column-sort-indicator name="status" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('account_tags')">Tags
        <column-sort-indicator name="account_tags" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light" colspan="2"></th>
    </tr>
  </thead>
</template>

<script>

export default {
  name: 'TablePitchesHead',

  props: {
    sortColumn: String,
    sortOrder: String,
    selectAll: Boolean
  },

  data() {
    return {
      localSortColumn: this.sortColumn,
      localSortOrder: this.sortOrder
    }
  },

  methods: {

    updateSortColumn: function (column) {
      let newOrder = null

      let newColumn = null

      if (column !== this.localSortColumn || this.localSortOrder === null) {
        newOrder = 'asc'
        newColumn = column
      } else if (this.localSortOrder === 'asc') {
        newOrder = 'desc'
        newColumn = column
      }

      this.localSortOrder = newOrder
      this.localSortColumn = newColumn

      this.$emit('sort-change', newColumn, newOrder)
    }
  }
}
</script>
