<template>
  <a @click="playPlaylist" class="table-icon tooltip" data-tooltip="Play Playlist">
    <IconPlay/>
  </a>
</template>

<script>
import { IconPlay } from '@/Icons/IconBase'
import { AUDIO_PLAYER_LOAD, QUEUED_AUDIO_FILE_USER_ALL, QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST, QUEUED_AUDIO_FILE_USER_UPDATE } from '@/store/actions'
import moment from 'moment'

export default {
  name: 'PlayPlaylistButton',
  props: ['playlistId'],
  components: {
    IconPlay
  },
  methods: {
    playPlaylist() {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST, {
        playlist_id: this.playlistId,
        overwrite: true
      })
        .then(function () {
          self.playQueuedAudioFiles()
        })
        .catch(function (err) {
          self.$store.commit('error', `Could not play playlist.`)
          console.log(err)
        })
    },

    playQueuedAudioFiles() {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
        .then(function (data) {
          if (data && data.length) {
            self.$store.dispatch(AUDIO_PLAYER_LOAD, { id: data[0].audio_file_id })
            self.$store.dispatch(QUEUED_AUDIO_FILE_USER_UPDATE, {
              id: data[0].id,
              data: {
                played_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
              }
            }).then(function () {
              self.$store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
            })
          }
        }, function (err) {
          self.$store.commit('error', `Could not play playlist.`)
          console.log(err)
        })
    }
  }
}
</script>
