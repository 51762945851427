<template>
  <transition
    enter-active-class="animated fadeInDown faster"
    leave-active-class="animated fadeOutUp faster"
  >
    <div class="message-display" v-if="message">
      <div
        class="notification"
        v-if="message"
        v-bind:class="notificationClass"
        v-click-outside="clear"
      >
        <button @click="clear" class="delete"></button>
        <span v-html="message"></span>
      </div>
    </div>
    <div class="message-display" v-if="exportMessage">
      <div
        class="notification"
        v-if="exportMessage"
        v-bind:class="notificationClass"
        v-click-outside="clear"
      >
        <button @click="clear" class="delete"></button>
        <span v-html="exportMessage"></span><span class="message-link m-l-5" @click="goToExports">Click here to go to the 'Exports' page and view the status.</span>
      </div>
    </div>
    <div class="message-display" v-if="queueMessage">
      <div
        class="notification"
        v-if="queueMessage"
        v-bind:class="notificationClass"
        v-click-outside="clear"
      >
        <button @click="clear" class="delete"></button>
        <span v-html="queueMessage"></span><span class="message-link m-l-5" @click="goToQueue">Click here to go to your queue.</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MessageDisplay',

  computed: {

    notificationClass() {
      if (this.error) {
        return 'is-danger'
      }

      if (this.alert) {
        return 'is-primary'
      }

      if (this.success) {
        return 'is-success'
      }

      if (this.export) {
        return 'is-success'
      }

      if (this.queue) {
        return 'is-success'
      }

      return ''
    },

    message() {
      if (this.error) {
        return this.error
      }

      if (this.alert) {
        return this.alert
      }

      if (this.success) {
        return this.success
      }

      return ''
    },
    exportMessage() {
      if (this.export) {
        return this.export
      }

      return ''
    },
    queueMessage() {
      if (this.queue) {
        return this.queue
      }

      return ''
    },

    alert() {
      return this.$store.getters.getAlertMessage
    },

    error() {
      return this.$store.getters.getErrorMessage
    },

    success() {
      return this.$store.getters.getSuccessMessage
    },
    export() {
      return this.$store.getters.getExportMessage
    },
    queue() {
      return this.$store.getters.getQueueMessage
    }
  },

  methods: {
    clear() {
      this.$store.commit('clear')
    },

    goToExports() {
      this.clear()
      this.$router.push({ name: 'user.exports' })
    },

    goToQueue() {
      this.clear()
      this.$router.push({ name: 'audio-player-queue' })
    }
  }
}
</script>
