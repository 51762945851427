<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="modal" class="modal is-active" v-if="isModal">
      <div class="modal-background" @click="close"></div>
      <div class="modal-content card resource-modal-content">
        <div class="card-content">
          <h2 class="title">{{ title }}</h2>
          <form
            class="form"
            @submit.prevent="$emit('save-record', formData)"
            enctype="multipart/form-data"
          >
            <AudioFileForm
              v-if="type === 'audioFile'"
              v-model="localModel"
              :isSaving="isSaving"
              v-on:file-change="filesChange"
            ></AudioFileForm>

            <PlaylistFileForm
              v-if="type === 'playlist'"
              v-model="localModel"
              :isSaving="isSaving"
              v-on:file-change="filesChange"
              v-on:input-change="inputChange"
            ></PlaylistFileForm>

            <div class="level m-t-30 is-block-mobile">
              <div class="level-left is-block-mobile">
                <div class="level-item is-inline-block-mobile">
                  <button type="submit" class="button is-primary">Save</button>
                </div>
                <div class="level-item is-inline-block-mobile">
                  <a href="#" class="button is-primary is-inverted" @click="close">Cancel</a>
                </div>
              </div>
              <div class="level-right is-block-mobile">
                <div class="level-item is-inline-block-mobile" v-if="message">
                  <p class="is-italic has-text-grey-light">{{ message }}</p>
                </div>
                <div class="level-item is-inline-block-mobile" v-if="canDelete">
                  <delete-button v-on:delete-confirmed="$emit('delete-record', localModel)"></delete-button>
                </div>
              </div>
            </div>
            <progress v-if="isSaving" class="progress is-small is-primary" max="100"></progress>
          </form>
        </div>
        <button class="modal-close is-large" @click="close"></button>
      </div>
    </div>
  </transition>
</template>

<script>

import AudioFileForm from '@/components/Forms/AudioFileForm'
import PlaylistFileForm from '@/components/Forms/PlaylistFileForm'

export default {
  name: 'FileFormModal',

  components: {
    AudioFileForm,
    PlaylistFileForm
  },

  props: {
    model: Object,
    title: String,
    type: String,
    canDelete: Boolean,
    isModal: Boolean,
    message: String,
    isSaving: Boolean
  },

  data() {
    return {
      localModel: {},
      formData: {}
    }
  },

  created() {
    this.localModel = Object.assign({}, this.$props.model)
    this.formData = new FormData()
  },

  watch: {
    model: function (newValue, oldValue) {
      this.formData = new FormData()
      this.localModel = Object.assign({}, this.$props.model)
    }
  },

  methods: {
    close() {
      this.$emit('clear-modal')
    },

    inputChange(name, value) {
      this.formData.append(name, value)
    },

    filesChange(event) {
      let fieldName = event.target.name
      let fileList = event.target.files

      if (!fileList.length) return

      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          this.formData.append(fieldName, fileList[x], fileList[x].name)
          this.localModel[fieldName] = fileList[x].name
        })
    }
  }
}
</script>
