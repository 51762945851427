<template>
  <thead class="is-size-7">
    <tr>
      <th class="has-text-grey-light"></th>
      <th class="has-text-grey-light">Title</th>
      <th class="has-text-grey-light">Audio File</th>
      <th class="has-text-grey-light">Songwriters</th>
      <th class="has-text-grey-light"></th>
    </tr>
  </thead>
</template>

<script>

export default {
  name: 'TablePlaylistHead'
}
</script>
