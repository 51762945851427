import Vue from 'vue'

import {
  ARTIST_LOADING,
  ARTIST_SUCCESS,
  ARTIST_ERROR,
  ARTIST_SET,
  ARTIST_SET_ALL,
  ARTIST_CURRENT_PAGE_SET,
  ARTIST_PER_PAGE_SET,
  ARTIST_TOTAL_PAGES_SET,
  ARTIST_SEARCH_VALUE_SET,
  CLEAR_STORE
} from '../mutations'

export default {
  state: {
    status: '',
    artists: [],
    artist: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: '',
    url: {
      base: 'artists',
      parentResource: 'account'
    }
  },

  mutations: {
    [ARTIST_LOADING]: (state) => {
      state.status = 'loading'
    },
    [ARTIST_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [ARTIST_ERROR]: (state) => {
      state.status = 'error'
    },
    [ARTIST_SET]: (state, resp) => {
      Vue.set(state, 'artist', resp)
    },
    [ARTIST_SET_ALL]: (state, resp) => {
      Vue.set(state, 'artists', resp)
    },
    [ARTIST_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [ARTIST_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [ARTIST_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [ARTIST_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'artist', {})
      Vue.set(state, 'artists', [])
    }
  }
}
