import axios from 'axios'

export default {

  getSongs(accountId, songTitle) {
    if (this.$can.includes('read-songwriter-songs') && !this.$can.includes('read-songs')) {
      return axios({
        url: 'accounts/' + accountId + '/search/songwriter-songs',
        params: { scope: 'songwriter', title: songTitle }
      })
    } else {
      return axios({
        url: 'accounts/' + accountId + '/search/songs',
        params: { scope: 'all', title: songTitle }
      })
    }
  }
}
