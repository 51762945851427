import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

import {
  AUDIO_FILE_ALL_FOR_SONG,
  AUDIO_FILE_CREATE,
  AUDIO_FILE_READ,
  AUDIO_FILE_UPDATE,
  AUDIO_FILE_DELETE,
  AUDIO_FILE_DOWNLOAD,
  AUDIO_FILE_ZIP_DOWNLOAD
} from '../actions'

import {
  AUDIO_FILE_LOADING,
  AUDIO_FILE_SUCCESS,
  AUDIO_FILE_ERROR,
  AUDIO_FILE_DELETED,
  AUDIO_FILE_SET,
  AUDIO_FILES_SET,
  CLEAR_STORE
} from '../mutations'

export default {
  state: {
    status: '',
    audioFiles: [],
    audioFile: {}
  },

  getters: {
    getAudioFile: state => state.audioFile
  },

  mutations: {
    [AUDIO_FILE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [AUDIO_FILE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [AUDIO_FILE_ERROR]: (state) => {
      state.status = 'error'
    },
    [AUDIO_FILE_DELETED]: (state, id) => {
      let index = state.audioFiles.findIndex(audioFile => audioFile.id === id)
      state.audioFiles.splice(index, 1)
    },
    [AUDIO_FILE_SET]: (state, resp) => {
      Vue.set(state, 'audioFile', resp)
    },
    [AUDIO_FILES_SET]: (state, resp) => {
      Vue.set(state, 'audioFiles', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'audioFile', {})
      Vue.set(state, 'audioFiles', [])
    }
  },

  actions: {
    [AUDIO_FILE_ALL_FOR_SONG]: ({
      commit
    }, songId) => {
      commit(AUDIO_FILE_LOADING)

      axios({
        url: 'songs/' + songId + '/audio-files'
      })
        .then(resp => {
          commit(AUDIO_FILE_SUCCESS)
          commit(AUDIO_FILES_SET, resp.data.data)
        })
        .catch(resp => {
          commit(AUDIO_FILE_ERROR)
        })
    },

    [AUDIO_FILE_READ]: ({
      commit
    }, id) => {
      commit(AUDIO_FILE_LOADING)
      commit(AUDIO_FILE_SET, {})

      return new Promise((resolve, reject) => {
        axios({
          url: 'audio-files/' + id
        })
          .then(resp => {
            commit(AUDIO_FILE_SUCCESS)
            commit(AUDIO_FILE_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [AUDIO_FILE_CREATE]: ({
      commit,
      dispatch
    }, {
      songId,
      data
    }) => {
      commit(AUDIO_FILE_LOADING)
      commit(AUDIO_FILE_SET, {})

      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: 'songs/' + songId + '/audio-files',
          data: data
        })
          .then(resp => {
            commit(AUDIO_FILE_SUCCESS)
            commit(AUDIO_FILE_SET, resp.data.data)
            dispatch(AUDIO_FILE_ALL_FOR_SONG, songId)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [AUDIO_FILE_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(AUDIO_FILE_LOADING)
      commit(AUDIO_FILE_SET, {})

      return new Promise((resolve, reject) => {
        let input = {}

        if (data.beats_per_minute) {
          input.beats_per_minute = data.beats_per_minute
        }

        if (data.allow_manual_master_rights_percentages) {
          input.allow_manual_master_rights_percentages = data.allow_manual_master_rights_percentages
        }

        if (data.type) {
          input.audio_file_type_id = data.type.id
        }

        axios({
          method: 'put',
          url: 'audio-files/' + id,
          data: input
        })
          .then(resp => {
            commit(AUDIO_FILE_SUCCESS)
            commit(AUDIO_FILE_SET, resp.data.data)
            dispatch(AUDIO_FILE_ALL_FOR_SONG, resp.data.data.song.id)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [AUDIO_FILE_DELETE]: ({
      commit
    }, id) => {
      commit(AUDIO_FILE_LOADING)

      return new Promise(function (resolve, reject) {
        axios({
          method: 'delete',
          url: 'audio-files/' + id
        })
          .then(resp => {
            commit(AUDIO_FILE_SET, {})
            commit(AUDIO_FILE_DELETED, id)
            commit(AUDIO_FILE_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [AUDIO_FILE_DOWNLOAD]: ({
      commit
    }, id) => {
      commit(AUDIO_FILE_LOADING)
      return new Promise(function (resolve, reject) {
        axios({
          url: 'audio-files/' + id + '/download',
          method: 'GET',
          responseType: 'blob'
        })
          .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', resp.headers.filename)
            document.body.appendChild(link)
            link.click()
            commit(AUDIO_FILE_SUCCESS)
            resolve(resp.data)
          })
          .catch(err => {
            commit(AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    },

    [AUDIO_FILE_ZIP_DOWNLOAD]: ({
      commit
    }, {
      title,
      audioFileIds
    }) => {
      return new Promise(function (resolve, reject) {
        axios({
          url: 'audio-files/download/zip',
          params: {
            title: title,
            audio_file_ids: audioFileIds
          },
          paramsSerializer: function (params) {
            return Qs.stringify(params, {
              arrayFormat: 'brackets'
            })
          },
          method: 'GET',
          responseType: 'blob'
        })
          .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', resp.headers.filename)
            document.body.appendChild(link)
            link.click()
            commit(AUDIO_FILE_SUCCESS)
            resolve(resp.data)
          })
          .catch(err => {
            commit(AUDIO_FILE_ERROR)
            reject(err)
          })
      })
    }
  }
}
