import Vue from 'vue'
import axios from 'axios'

import {
  RELEASE_ALL_FOR_SONG,
  RELEASE_CREATE,
  RELEASE_UPDATE,
  RELEASE_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  RELEASE_LOADING,
  RELEASE_SUCCESS,
  RELEASE_ERROR,
  RELEASE_SET,
  RELEASES_SET
} from '../mutations'

export default {
  state: {
    status: '',
    releases: [],
    release: {}
  },

  getters: {},

  mutations: {
    [RELEASE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [RELEASE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [RELEASE_ERROR]: (state) => {
      state.status = 'error'
    },
    [RELEASE_SET]: (state, resp) => {
      Vue.set(state, 'release', resp)
    },
    [RELEASES_SET]: (state, resp) => {
      Vue.set(state, 'releases', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'release', {})
      Vue.set(state, 'releases', [])
    }
  },

  actions: {
    [RELEASE_ALL_FOR_SONG]: ({
      commit
    }, songId) => {
      commit(RELEASE_LOADING)

      axios({
        url: 'songs/' + songId + '/releases'
      })
        .then(resp => {
          commit(RELEASE_SUCCESS)
          commit(RELEASES_SET, resp.data.data)
        })
        .catch(resp => {
          commit(RELEASE_ERROR)
        })
    },

    [RELEASE_CREATE]: ({
      commit,
      dispatch
    }, {
      cutId,
      data,
      songId
    }) => {
      return new Promise(function (resolve, reject) {
        commit(RELEASE_LOADING)
        commit(RELEASE_SET, {})

        axios({
          method: 'post',
          url: 'cuts/' + cutId + '/releases',
          data: data
        })
          .then(resp => {
            commit(RELEASE_SUCCESS)
            commit(RELEASE_SET, resp.data.data)
            dispatch(RELEASE_ALL_FOR_SONG, songId)
            resolve(resp.data.data)
          })
          .catch(resp => {
            commit(RELEASE_ERROR)
            reject(resp)
          })
      })
    },

    [RELEASE_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data,
      songId
    }) => {
      commit(RELEASE_LOADING)
      commit(RELEASE_SET, {})
      axios({
        method: 'put',
        url: 'releases/' + id,
        data: data
      })
        .then(resp => {
          commit(RELEASE_SUCCESS)
          commit(RELEASE_SET, resp.data.data)
          dispatch(RELEASE_ALL_FOR_SONG, songId)
        })
        .catch(resp => {
          commit(RELEASE_ERROR)
        })
    },

    [RELEASE_DELETE]: ({
      commit,
      dispatch
    }, {
      id,
      songId
    }) => {
      return new Promise(function (resolve, reject) {
        commit(RELEASE_LOADING)

        axios({
          method: 'delete',
          url: 'releases/' + id
        })
          .then(resp => {
            commit(RELEASE_SET, {})
            dispatch(RELEASE_ALL_FOR_SONG, songId)
            resolve(resp)
          })
          .catch(resp => {
            commit(RELEASE_ERROR)
            reject(resp)
          })
      })
    }
  }
}
