import axios from 'axios'

export default {

  getCompanies(accountId) {
    return axios({
      url: 'accounts/' + accountId + '/publishing-companies'
    })
  }

}
