import {
  MESSAGE_LOADING,
  MESSAGE_READY,
  MESSAGE_ALERT_SET,
  MESSAGE_ERROR_SET,
  MESSAGE_SUCCESS_SET,
  MESSAGE_EXPORT_SET,
  MESSAGE_QUEUE_SET,
  MESSAGE_MODAL_MESSAGE_SET,
  MESSAGE_CLEAR
} from '../mutations'

export default {
  state: {
    alertMessage: null,
    errorMessage: null,
    successMessage: null,
    exportMessage: null,
    queueMessage: null,
    modalMessage: null,
    status: ''
  },

  getters: {
    getAlertMessage: state => state.alertMessage,
    getErrorMessage: state => state.errorMessage,
    getSuccessMessage: state => state.successMessage,
    getExportMessage: state => state.exportMessage,
    getQueueMessage: state => state.queueMessage,
    getModalMessage: state => state.modalMessage
  },

  mutations: {
    [MESSAGE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [MESSAGE_READY]: (state) => {
      state.status = 'ready'
    },
    [MESSAGE_ALERT_SET]: (state, value) => {
      state.alertMessage = value
    },
    [MESSAGE_ERROR_SET]: (state, value) => {
      state.errorMessage = value
    },
    [MESSAGE_SUCCESS_SET]: (state, value) => {
      state.successMessage = value
    },
    [MESSAGE_EXPORT_SET]: (state, value) => {
      state.exportMessage = value
    },
    [MESSAGE_QUEUE_SET]: (state, value) => {
      state.queueMessage = value
    },
    [MESSAGE_MODAL_MESSAGE_SET]: (state, value) => {
      state.modalMessage = value
    },
    [MESSAGE_CLEAR]: (state) => {
      state.alertMessage = null
      state.errorMessage = null
      state.successMessage = null
      state.exportMessage = null
      state.queueMessage = null
    }
  }
}
