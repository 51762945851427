<template>
  <div>
    <div class="field m-b-15">
      <label for="album_title" class="label">Album Title</label>
      <input id="album_title" type="text" class="input" placeholder="Album Title" v-model="album.title">
    </div>
    <div class="field m-b-15">
      <label for="upc_number" class="label">UPC Number</label>
      <input
        id="upc_number"
        type="text"
        class="input"
        placeholder="UPC Number"
        v-model="album.upc_number"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlbumForm',
  model: {
    prop: 'album',
    event: 'change'
  },
  props: {
    album: Object
  }
}
</script>
