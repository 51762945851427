import Vue from 'vue'
import axios from 'axios'

import {
  PUBLISHING_COMPANY_ALL,
  PUBLISHING_COMPANY_ALL_FOR_FORM,
  PUBLISHING_COMPANY_CREATE,
  PUBLISHING_COMPANY_READ,
  PUBLISHING_COMPANY_UPDATE,
  PUBLISHING_COMPANY_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  PUBLISHING_COMPANY_LOADING,
  PUBLISHING_COMPANY_SUCCESS,
  PUBLISHING_COMPANY_ERROR,
  PUBLISHING_COMPANY_SET,
  PUBLISHING_COMPANIES_SET,
  PUBLISHING_COMPANY_CURRENT_PAGE_SET,
  PUBLISHING_COMPANY_PER_PAGE_SET,
  PUBLISHING_COMPANY_TOTAL_PAGES_SET,
  PUBLISHING_COMPANY_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    publishingCompanies: [],
    publishingCompany: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [PUBLISHING_COMPANY_LOADING]: (state) => {
      state.status = 'loading'
    },
    [PUBLISHING_COMPANY_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [PUBLISHING_COMPANY_ERROR]: (state) => {
      state.status = 'error'
    },
    [PUBLISHING_COMPANY_SET]: (state, resp) => {
      Vue.set(state, 'publishingCompany', resp)
    },
    [PUBLISHING_COMPANIES_SET]: (state, resp) => {
      Vue.set(state, 'publishingCompanies', resp)
    },
    [PUBLISHING_COMPANY_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [PUBLISHING_COMPANY_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [PUBLISHING_COMPANY_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [PUBLISHING_COMPANY_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'publishingCompany', {})
      Vue.set(state, 'publishingCompanies', [])
    }
  },

  actions: {
    [PUBLISHING_COMPANY_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(PUBLISHING_COMPANY_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(PUBLISHING_COMPANY_ERROR)
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage
      }

      if (state.searchValue && state.searchValue.length) {
        params.search = state.searchValue
      }

      axios({
        url: 'accounts/' + accountId + '/search/publishing-companies',
        params: params
      })
        .then(resp => {
          commit(PUBLISHING_COMPANY_SUCCESS)
          commit(PUBLISHING_COMPANIES_SET, resp.data.data)
          commit(PUBLISHING_COMPANY_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(PUBLISHING_COMPANY_ERROR)
        })
    },

    [PUBLISHING_COMPANY_ALL_FOR_FORM]: ({
      commit,
      getters
    }) => {
      commit(PUBLISHING_COMPANY_LOADING)
      return new Promise((resolve, reject) => {
        let accountId = getters.getActiveAccountId

        if (!accountId) {
          commit(PUBLISHING_COMPANY_ERROR)
          return false
        }

        axios({
          url: 'accounts/' + accountId + '/publishing-companies'
        })
          .then(resp => {
            commit(PUBLISHING_COMPANY_SUCCESS)
            commit(PUBLISHING_COMPANIES_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PUBLISHING_COMPANY_ERROR)
            reject(err)
          })
      })
    },

    [PUBLISHING_COMPANY_READ]: ({
      commit
    }, id) => {
      commit(PUBLISHING_COMPANY_LOADING)
      commit(PUBLISHING_COMPANY_SET, {})
      axios({
        url: 'publishing-companies/' + id
      })
        .then(resp => {
          commit(PUBLISHING_COMPANY_SUCCESS)
          commit(PUBLISHING_COMPANY_SET, resp.data.data)
        })
        .catch(resp => {
          commit(PUBLISHING_COMPANY_ERROR)
        })
    },

    [PUBLISHING_COMPANY_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(PUBLISHING_COMPANY_LOADING)
        commit(PUBLISHING_COMPANY_SET, {})

        let accountId = getters.getActiveAccountId

        if (!accountId) {
          commit(PUBLISHING_COMPANY_ERROR)
          reject(new Error('No account found'))
          return false
        }

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/publishing-companies',
          data: data
        })
          .then(resp => {
            commit(PUBLISHING_COMPANY_SUCCESS)
            commit(PUBLISHING_COMPANY_SET, resp.data.data)
            dispatch(PUBLISHING_COMPANY_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PUBLISHING_COMPANY_ERROR)
            reject(err)
          })
      })
    },

    [PUBLISHING_COMPANY_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(PUBLISHING_COMPANY_LOADING)
      axios({
        method: 'put',
        url: 'publishing-companies/' + id,
        data: data
      })
        .then(resp => {
          commit(PUBLISHING_COMPANY_SUCCESS)
          commit(PUBLISHING_COMPANY_SET, resp.data.data)
          dispatch(PUBLISHING_COMPANY_ALL)
        })
        .catch(resp => {
          commit(PUBLISHING_COMPANY_ERROR)
        })
    },

    [PUBLISHING_COMPANY_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(PUBLISHING_COMPANY_LOADING)

      axios({
        method: 'delete',
        url: 'publishing-companies/' + id
      })
        .then(resp => {
          commit(PUBLISHING_COMPANY_SET, {})
          dispatch(PUBLISHING_COMPANY_ALL)
        })
        .catch(resp => {
          commit(PUBLISHING_COMPANY_ERROR)
        })
    }
  }
}
