<template>
  <div>
    <transition
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
    >
      <div id="modal" class="modal is-active" v-if="isModal">
        <div class="modal-background" @click="close"></div>

        <div id="audio-file-event-viewer-content" class="modal-content card resource-modal-content">
          <div class="card-content">
            <h3 class="title is-pulled-left">Audio Playback Analysis</h3>
            <div class="is-clearfix"></div>
            <h5>{{ audioFile.file_name }}</h5>
            <div v-if="chartData">
              <p>
                <small>Number of plays and amount of time listened to by external users.</small>
              </p>
              <line-chart :chart-data="chartData" :options="options"></line-chart>
            </div>
            <div v-else>No playback events recorded for this audio file.</div>
          </div>
          <button class="modal-close is-large" @click="close"></button>
        </div>
      </div>
    </transition>

    <a @click="open" class="has-text-grey-light">
      <IconGraph/>
    </a>
  </div>
</template>

<script>
import LineChart from './LineChart'
import AudioFileEventsApi from '@/services/api/AudioFileEvents'
import { IconGraph } from '@/Icons/IconBase'

export default {
  name: 'AudioFileEventViewer',
  props: {
    audioFile: Object
  },
  components: {
    LineChart,
    IconGraph
  },
  data() {
    return {
      chartData: null,
      isModal: false,
      options: {
        legend: {
          display: false
        },
        elements: {
          point: {
            pointStyle: 'line'
          },
          line: {
            tension: 0.2
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: '# of Plays'
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: 'Audio Timeline'
            }
          }]
        }
      }
    }
  },

  created() {
    this.load()
  },

  watch: {
    audioFile: function (newAudioFile) {
      if (newAudioFile) {
        this.load()
      }
    }
  },

  methods: {
    open() {
      this.isModal = true
    },

    close() {
      this.isModal = false
    },

    load() {
      if (!this.audioFile) { return }

      let self = this

      AudioFileEventsApi.getEvents(this.audioFile.id)
        .then(resp => {
          self.updateChartData(resp.data.data.playback_data, resp.data.data.playback_evaluation_interval, resp.data.data.audio_file_duration)
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateChartData(playbackData, evaluationInterval, duration) {
      if (!evaluationInterval || !duration || !playbackData) { return }

      let intervalCount = Math.ceil(duration / evaluationInterval)

      let dataset = []
      let labels = []

      for (let index = 0; index < intervalCount; index++) {
        let seconds = index * evaluationInterval
        let minutes = Math.floor(seconds / 60)
        seconds = this.pad(seconds % 60, 2)
        let label = minutes + ':' + seconds
        labels.push(label)
        let key = '_' + index
        let datapoint = playbackData[key] ? playbackData[key] : 0
        dataset.push(datapoint)
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Plays',
            backgroundColor: '#58c1ee',
            data: dataset
          }
        ]
      }
    },

    pad(num, size) {
      var s = num + ''
      while (s.length < size) s = '0' + s
      return s
    }
  }
}
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
