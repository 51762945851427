<template>
  <div class="form">
    <div class="field m-b-15">
      <button
        class="button is-small is-primary is-pulled-right"
        @click.prevent="deselect"
      >Deselect All</button>
      <button
        class="button is-small is-primary is-pulled-right m-r-10"
        @click.prevent="selectAll"
      >Select All</button>
    </div>
    <div class="field m-b-15">
      <div class="control" v-for="(column, index) in immutableColumns.columns" :key="column">
        <label class="label">
          <input
            type="checkbox"
            class="checkbox m-r-10"
            v-model="localColumns.columns"
            :value="column"
            @click="updateCols(column, index)"
          >
          {{column | snakeToTitle}}
        </label>
      </div>
    </div>
    <progress v-if="isExporting" class="progress is-small is-primary" max="100"></progress>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ExportForm',
  model: {
    prop: 'columns',
    event: 'change'
  },
  props: {
    columns: Object,
    isExporting: Boolean
  },

  data() {
    return {
      localColumns: {
        columns: []
      },
      immutableColumns: {},
      emptyColumns: {
        columns: []
      }
    }
  },

  created: function () {
    this.localColumns = _.cloneDeep(this.$props.columns)
    this.immutableColumns = _.cloneDeep(this.$props.columns)
  },
  watch: {
    localColumns: function () {
      this.$props.columns.columns = _.cloneDeep(this.localColumns.columns)
    },
    deep: true
  },

  methods: {
    deselect() {
      this.localColumns = _.cloneDeep(this.emptyColumns)
      this.$props.columns.columns = _.cloneDeep(this.localColumns.columns)
    },
    selectAll() {
      this.localColumns = _.cloneDeep(this.emptyColumns)
      this.localColumns = _.cloneDeep(this.immutableColumns)
      this.$props.columns.columns = _.cloneDeep(this.localColumns.columns)
    },
    updateCols(col, index) {
      if (this.localColumns.columns.includes(col) || this.$props.columns.columns.includes(col)) {
        _.pull(this.localColumns.columns, col)
      } else {
        this.localColumns.columns.push(col)
      }
      this.$props.columns.columns = _.cloneDeep(this.localColumns.columns)
    }
  }
}
</script>
