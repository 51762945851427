<template>
  <div class="level-item">
    <div v-if="localSong.song_rating != null" class="song-rating">
      <div @click="updateSongRating(3)" class="has-text-primary star-three is-pulled-right">
        <IconStar v-if="localSong.song_rating.value < 3"/>
        <IconStarFilled v-if="localSong.song_rating.value >= 3"/>
      </div>
      <div @click="updateSongRating(2)" class="has-text-primary star-two star-three-children">
        <IconStar v-if="localSong.song_rating.value < 2"/>
        <IconStarFilled v-if="localSong.song_rating.value >= 2"/>
      </div>
      <div
        @click="updateSongRating(1)"
        class="has-text-primary star-one star-two-children star-three-children is-pulled-left"
      >
        <IconStar v-if="localSong.song_rating.value < 1"/>
        <IconStarFilled v-if="localSong.song_rating.value >= 1"/>
      </div>
    </div>
    <div v-else class="song-rating">
      <div @click="createSongRating(song,3)" class="has-text-primary star-three is-pulled-right">
        <IconStar/>
      </div>
      <div @click="createSongRating(song,2)" class="has-text-primary star-two star-three-children">
        <IconStar/>
      </div>
      <div
        @click="createSongRating(song,1)"
        class="has-text-primary star-one star-two-children star-three-children is-pulled-left"
      >
        <IconStar/>
      </div>
    </div>
  </div>
</template>

<script>
import SongRatingsApi from '@/services/api/SongRatings'
import _ from 'lodash'
import { IconStar, IconStarFilled } from '@/Icons/IconBase'

export default {
  name: 'SongRating',
  components: {
    IconStar,
    IconStarFilled
  },
  props: {
    song: Object
  },
  data() {
    return {
      localSong: {}
    }
  },

  created: function () {
    this.localSong = _.cloneDeep(this.$props.song)
  },
  watch: {
    song: function () {
      this.localSong = _.cloneDeep(this.$props.song)
    }
  },
  methods: {
    createSongRating(song, value) {
      this.localSong.song_rating = { value: value }

      SongRatingsApi.storeRating(song.id, value)
        .then(resp => {
          this.localSong.song_rating = resp.data.data
        })
        .catch(err => {
          alert(err)
        })
    },
    updateSongRating(value) {
      if (this.localSong.song_rating.value === value) {
        this.localSong.song_rating.value = 0
        value = 0
      } else {
        this.localSong.song_rating.value = value
      }
      SongRatingsApi.updateRating(this.localSong.song_rating.id, value)
        .then(resp => {
          this.localSong.song_rating = resp.data.data
        })
        .catch(err => {
          alert(err)
        })
    }
  }
}

</script>
