import axios from 'axios'

export default {

  getPitchesForSong(songId) {
    return axios({
      url: 'songs/' + songId + '/pitches'
    })
  },

  updateAudioFileDownloadedAt(pitchId) {
    return axios({
      method: 'put',
      url: 'pitches/' + pitchId + '/audio-file-downloaded',
      data: {
        audio_file_downloaded_at: true
      }
    })
  }

}
