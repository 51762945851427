<template>
  <div>
    <table class="table is-responsive is-fullwidth is-hoverable" v-if="cuts.length > 0">
      <thead>
        <tr>
          <th>Cut Date</th>
          <th>Artists</th>
          <th>Producers</th>
        </tr>
      </thead>
      <tbody>
        <tr class="is-clickable" v-for="cut in cuts" :key="cut.id" @click="$emit('select-cut-to-release', cut)">
          <td>{{ cut.cut_date | momentDate }}</td>
          <td>
            {{ cut.artists | arrayString('name') }}
            <span
              v-if="cut.featured_artists.length"
            >feat. {{ cut.featured_artists | arrayString('name') }}</span>
          </td>
          <td>{{ cut.producer_contacts | arrayString('full_name') | orBlank() }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      This song has no cuts to release.
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReleaseInitForm',
  model: {
    prop: 'release',
    event: 'change'
  },
  props: {
    release: Object
  },
  computed: {
    cuts() {
      return this.$store.state.cut.cuts
    }
  }
}
</script>
