<template>
  <div class="form">
      <div class="field m-b-15">
      <label class="label">Allow Audio Download?</label>
        <div class="control">
            <label class="radio tooltip-span-label">
              <input type="radio" class="m-r-5" name="allowAudio" v-model="playlist.allowAudioDownload" value="1" required>
              Yes
            </label>
            <label class="radio tooltip-span-label">
              <input type="radio" class="m-r-5" name="allowAudio" v-model="playlist.allowAudioDownload" value="0">
              No
            </label>
        </div>
    </div>
    </div>
</template>

<script>

import {
  CONTACT_ALL_FOR_FORM
} from '@/store/actions'

export default {
  name: 'CompLinkForm',
  model: {
    prop: 'playlist',
    event: 'change'
  },
  props: {
    playlist: Object,
    isSaving: Boolean
  },
  created: function () {
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
  },
  computed: {
    contacts() {
      return this.$store.state.contact.contacts
    }
  }
}
</script>
