import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

import {
  SONG_ALL,
  SONGWRITER_SONG_ALL,
  SONG_CREATE,
  SONG_READ,
  SONG_UPDATE,
  SONG_DELETE,
  SONG_SET_DEFAULT_AUDIO_FILE
} from '../actions'

import {
  CLEAR_STORE,
  SONG_LOADING,
  SONG_SUCCESS,
  SONG_ERROR,
  SONG_DELETED,
  SONG_UPDATED,
  SONG_SET,
  SONG_SET_ALL,
  SONG_CURRENT_PAGE_SET,
  SONG_PER_PAGE_SET,
  SONG_TOTAL_PAGES_SET,
  SONG_SORT_SET,
  SONG_SEARCH_QUERY_SET,
  SONG_SEARCH_SCOPE_SET,
  SONG_REMOVE_QUERY_ARRAY_VALUE
} from '../mutations'

export default {
  state: {
    status: '',
    songs: [],
    song: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    sortColumn: null,
    sortOrder: null,
    searchScope: 'all',
    searchQuery: {
      title: null,
      songwriters: null,
      master_owners: null,
      artists: null,
      record_labels: null,
      publishing_companies: null,
      record_label_contacts: null,
      lyrics: null,
      notes: null,
      date_turned_in: null,
      date_written: null,
      date_modified: null,
      created_at: null,
      session_date: null,
      status: null,
      releases: null,
      activity: null,
      with_lyrics: null,
      without_lyrics: null,
      with_audio: null,
      without_audio: null,
      without_pub_info: null,
      without_date_written: null,
      song_rating: null,
      userTag: null,
      accountTag: null,
      tempoTag: null,
      vocalTag: null,
      audioTag: null,
      genreTag: null,
      emotionTag: null
    }
  },

  getters: {
    getSongs: state => state.songs,
    isSongsLoaded: state => !!state.songs,
    getSong: state => state.song,
    isSongLoaded: state => !!state.song,
    getSongSearchQuery: state => state.searchQuery
  },

  mutations: {
    [SONG_LOADING]: (state) => {
      state.status = 'loading'
    },
    [SONG_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [SONG_ERROR]: (state) => {
      state.status = 'error'
    },
    [SONG_DELETED]: (state, id) => {
      let index = state.songs.findIndex(song => parseInt(song.id) === parseInt(id))
      state.songs.splice(index, 1)
    },
    [SONG_UPDATED]: (state, data) => {
      if (state.songs.length) {
        let index = state.songs.findIndex(song => parseInt(song.id) === parseInt(data.id))
        state.songs[index].title = data.title
        state.songs[index].date_written = data.date_written
        state.songs[index].session_date = data.session_date
        state.songs[index].date_turned_in = data.date_turned_in
        state.songs[index].date_modified = data.date_modified
        state.songs[index].active_hold_count = data.active_hold_count
        state.songs[index].cut_count = data.cut_count
        state.songs[index].release_count = data.release_count
        state.songs[index].sync_count = data.sync_count
        state.songs[index].artists = data.artists
      }

      if (state.song && parseInt(state.song.id) === parseInt(data.id)) {
        state.song.title = data.title
        state.song.date_written = data.date_written
        state.song.session_date = data.session_date
        state.song.date_turned_in = data.date_turned_in
        state.song.date_modified = data.date_modified
        state.song.active_hold_count = data.active_hold_count
        state.song.cut_count = data.cut_count
        state.song.release_count = data.release_count
        state.song.sync_count = data.sync_count
        state.song.artists = data.artists
      }
    },
    [SONG_SET]: (state, resp) => {
      Vue.set(state, 'song', resp)
    },
    [SONG_SET_ALL]: (state, resp) => {
      Vue.set(state, 'songs', resp)
    },
    [SONG_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [SONG_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [SONG_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [SONG_SORT_SET]: (state, {
      column,
      order
    }) => {
      state.sortColumn = column
      state.sortOrder = order
    },
    [SONG_SEARCH_QUERY_SET]: (state, query) => {
      state.searchQuery = query
    },
    [SONG_SEARCH_SCOPE_SET]: (state, value) => {
      state.searchScope = value
    },
    [SONG_REMOVE_QUERY_ARRAY_VALUE]: (state, { field, index }) => {
      state.searchQuery[field].splice(index, 1)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'song', {})
      Vue.set(state, 'songs', [])
    }
  },

  actions: {
    [SONG_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(SONG_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(SONG_ERROR)
        console.log('no account for song all')
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage,
        sort_column: state.sortColumn,
        sort_order: state.sortOrder,
        scope: state.searchScope
      }

      params = Object.assign(params, state.searchQuery)

      if (params.accountTag !== null || params.userTag !== null || params.tempoTag !== null ||
        params.vocalTag !== null || params.audioTag !== null || params.genreTag !== null || params.emotionTag !== null) {
        // create new array of all tags if any tags exist
        let tagsArray = [
          ...params.accountTag || [], ...params.userTag || [], ...params.tempoTag || [],
          ...params.vocalTag || [], ...params.audioTag || [], ...params.genreTag || [], ...params.emotionTag || []
        ]
        params.tag_ids = tagsArray.map(function (tag) {
          return tag.id
        })
        params.accountTag = params.userTag = params.tempoTag = params.vocalTag = params.audioTag = params.genreTag = params.emotionTag = null
      }

      if (params.releases !== null) {
        params.releases = params.releases.map(function (release) {
          return release.value
        })
      }

      if (params.activity !== null) {
        params.activity = params.activity.map(function (activity) {
          return activity.value
        })
      }

      if (params.song_rating !== null) {
        params.song_rating = params.song_rating.value
      }

      if (params.songwriters !== null) {
        params.songwriter_ids = params.songwriters.map(function (songwriter) {
          return songwriter.id
        })

        params.songwriters = null
      }

      if (params.record_labels !== null) {
        params.record_label_ids = params.record_labels.map(function (recordLabel) {
          return recordLabel.id
        })

        params.record_labels = null
      }

      if (params.record_label_contacts !== null) {
        params.record_label_contact_ids = params.record_label_contacts.map(function (recordLabelContact) {
          return recordLabelContact.id
        })

        params.record_label_contacts = null
      }

      if (params.master_owners !== null) {
        params.master_owner_id_and_types = params.master_owners.map(function (masterOwner) {
          return masterOwner.id_and_type
        })

        params.master_owners = null
      }

      if (params.publishing_companies !== null) {
        params.publishing_company_ids = params.publishing_companies.map(function (publishingCompany) {
          return publishingCompany.id
        })

        params.publishing_companies = null
      }

      if (params.artists !== null) {
        params.artist_ids = params.artists.map(function (artist) {
          return artist.id
        })

        params.artists = null
      }

      for (var propName in params) {
        if (params[propName] === null || params[propName] === undefined) {
          delete params[propName]
        }
      }

      axios({
        url: 'accounts/' + accountId + '/search/songs',
        params: params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets'
          })
        }
      })
        .then(resp => {
          commit(SONG_SUCCESS)
          commit(SONG_SET_ALL, resp.data.data)
          commit(SONG_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(SONG_ERROR)
        })
    },

    [SONGWRITER_SONG_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(SONG_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(SONG_ERROR)
        console.log('no account for song all')
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage,
        sort_column: state.sortColumn,
        sort_order: state.sortOrder,
        scope: state.searchScope
      }

      params = Object.assign(params, state.searchQuery)

      if (params.accountTag !== null || params.userTag !== null || params.tempoTag !== null ||
        params.vocalTag !== null || params.audioTag !== null || params.genreTag !== null || params.emotionTag !== null) {
        // create new array of all tags if any tags exist
        let tagsArray = [
          ...params.accountTag || [], ...params.userTag || [], ...params.tempoTag || [],
          ...params.vocalTag || [], ...params.audioTag || [], ...params.genreTag || [], ...params.emotionTag || []
        ]
        params.tag_ids = tagsArray.map(function (tag) {
          return tag.id
        })
        params.accountTag = params.userTag = params.tempoTag = params.vocalTag = params.audioTag = params.genreTag = params.emotionTag = null
      }

      if (params.releases !== null) {
        params.releases = params.releases.map(function (release) {
          return release.value
        })
      }

      if (params.activity !== null) {
        params.activity = params.activity.map(function (activity) {
          return activity.value
        })
      }

      if (params.song_rating !== null) {
        params.song_rating = params.song_rating.value
      }

      if (params.songwriters !== null) {
        params.songwriter_ids = params.songwriters.map(function (songwriter) {
          return songwriter.id
        })

        params.songwriters = null
      }

      if (params.record_labels !== null) {
        params.record_label_ids = params.record_labels.map(function (recordLabel) {
          return recordLabel.id
        })

        params.record_labels = null
      }

      if (params.record_label_contacts !== null) {
        params.record_label_contact_ids = params.record_label_contacts.map(function (recordLabelContact) {
          return recordLabelContact.id
        })

        params.record_label_contacts = null
      }

      if (params.master_owners !== null) {
        params.master_owner_id_and_types = params.master_owners.map(function (masterOwner) {
          return masterOwner.id_and_type
        })

        params.master_owners = null
      }

      if (params.publishing_companies !== null) {
        params.publishing_company_ids = params.publishing_companies.map(function (publishingCompany) {
          return publishingCompany.id
        })

        params.publishing_companies = null
      }

      if (params.artists !== null) {
        params.artist_ids = params.artists.map(function (artist) {
          return artist.id
        })

        params.artists = null
      }

      for (var propName in params) {
        if (params[propName] === null || params[propName] === undefined) {
          delete params[propName]
        }
      }

      axios({
        url: 'accounts/' + accountId + '/search/songwriter-songs',
        params: params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets'
          })
        }
      })
        .then(resp => {
          commit(SONG_SUCCESS)
          commit(SONG_SET_ALL, resp.data.data)
          commit(SONG_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(SONG_ERROR)
        })
    },

    [SONG_READ]: ({
      commit
    }, id) => {
      commit(SONG_LOADING)
      commit(SONG_SET, {})
      axios({
        url: 'songs/' + id
      })
        .then(resp => {
          commit(SONG_SUCCESS)
          commit(SONG_SET, resp.data.data)
        })
        .catch(resp => {
          commit(SONG_ERROR)
        })
    },

    [SONG_CREATE]: ({
      commit,
      getters
    }, data) => {
      return new Promise(function (resolve, reject) {
        commit(SONG_LOADING)
        commit(SONG_SET, {})

        let accountId = getters.getActiveAccountId

        if (!accountId) {
          commit(SONG_ERROR)
          reject(new Error('no account specified'))
          return false
        }

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/songs',
          data: data
        })
          .then(resp => {
            commit(SONG_SUCCESS)
            commit(SONG_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(SONG_ERROR)
            reject(err)
          })
      })
    },

    [SONG_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      return new Promise(function (resolve, reject) {
        commit(SONG_LOADING)

        axios({
          method: 'put',
          url: 'songs/' + id,
          data: data
        })
          .then(resp => {
            commit(SONG_SUCCESS)
            commit(SONG_SET, resp.data.data)
            resolve(resp.data.data)
            commit(SONG_UPDATED, resp.data.data)
          })
          .catch(err => {
            commit(SONG_ERROR)
            reject(err)
          })
      })
    },

    [SONG_SET_DEFAULT_AUDIO_FILE]: ({
      commit
    }, {
      id,
      audioFileId
    }) => {
      commit(SONG_LOADING)
      return new Promise(function (resolve, reject) {
        axios({
          method: 'put',
          url: 'songs/' + id + '/audio-files/' + audioFileId,
          data: { default: true }
        })
          .then(resp => {
            commit(SONG_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(SONG_ERROR)
            reject(err)
          })
      })
    },

    [SONG_DELETE]: ({
      commit
    }, id) => {
      return new Promise(function (resolve, reject) {
        commit(SONG_LOADING)

        axios({
          method: 'delete',
          url: 'songs/' + id
        })
          .then(resp => {
            commit(SONG_SUCCESS)
            commit(SONG_DELETED, id)
            commit(SONG_SET, {})
            resolve(resp)
          })
          .catch(err => {
            commit(SONG_ERROR)
            reject(err)
          })
      })
    }
  }
}
