import axios from 'axios'

import {
  IMAGE_CREATE,
  IMAGE_UPDATE,
  IMAGE_DELETE
} from '../actions'

import {
  IMAGE_LOADING,
  IMAGE_SUCCESS,
  IMAGE_ERROR
} from '../mutations'

export default {
  state: {
    status: ''
  },

  mutations: {
    [IMAGE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [IMAGE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [IMAGE_ERROR]: (state) => {
      state.status = 'error'
    }
  },

  actions: {
    [IMAGE_CREATE]: ({
      commit
    }, formData) => {
      return new Promise((resolve, reject) => {
        commit(IMAGE_LOADING)

        axios({
          method: 'post',
          url: 'images',
          data: formData
        })
          .then(resp => {
            commit(IMAGE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(IMAGE_ERROR)
            reject(err)
          })
      })
    },

    [IMAGE_UPDATE]: ({
      commit
    }, {
      id,
      formData
    }) => {
      return new Promise((resolve, reject) => {
        commit(IMAGE_LOADING)

        formData.append('_method', 'PUT')

        axios({
          method: 'post',
          url: 'images/' + id,
          data: formData
        })
          .then(resp => {
            commit(IMAGE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(IMAGE_ERROR)
            reject(err)
          })
      })
    },

    [IMAGE_DELETE]: ({
      commit
    }, id) => {
      return new Promise((resolve, reject) => {
        commit(IMAGE_LOADING)

        axios({
          method: 'delete',
          url: 'images/' + id
        })
          .then(resp => {
            resolve()
          })
          .catch(err => {
            commit(IMAGE_ERROR)
            reject(err)
          })
      })
    }
  }
}
