import Vue from 'vue'
import axios from 'axios'

import {
  NOTIFICATION_ALL,
  NOTIFICATION_UPDATE
} from '../actions'

import {
  CLEAR_STORE,
  NOTIFICATION_LOADING,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  NOTIFICATION_SET,
  NOTIFICATIONS_SET,
  NOTIFICATION_PER_PAGE_SET,
  NOTIFICATION_CURRENT_PAGE_SET,
  NOTIFICATION_TOTAL_PAGES_SET
} from '../mutations'

export default {
  state: {
    status: '',
    notifications: [],
    notification: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0
  },

  mutations: {
    [NOTIFICATION_LOADING]: (state) => {
      state.status = 'loading'
    },
    [NOTIFICATION_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [NOTIFICATION_ERROR]: (state) => {
      state.status = 'error'
    },
    [NOTIFICATION_SET]: (state, resp) => {
      Vue.set(state, 'notification', resp)
    },
    [NOTIFICATIONS_SET]: (state, resp) => {
      Vue.set(state, 'notifications', resp)
    },
    [NOTIFICATION_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [NOTIFICATION_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [NOTIFICATION_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'notification', {})
      Vue.set(state, 'notifications', [])
    }
  },

  actions: {
    [NOTIFICATION_ALL]: ({
      commit,
      state
    }, params) => {
      commit(NOTIFICATION_LOADING)

      params.page = state.currentPage
      params.per_page = state.perPage

      axios({
        url: 'notifications',
        params: params
      })
        .then(resp => {
          commit(NOTIFICATION_SUCCESS)
          commit(NOTIFICATIONS_SET, resp.data.data)
          commit(NOTIFICATION_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(err => {
          console.log(err)
          commit(NOTIFICATION_ERROR)
        })
    },

    [NOTIFICATION_UPDATE]: ({
      commit,
      dispatch
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(NOTIFICATION_LOADING)

        axios({
          method: 'put',
          url: 'notifications',
          data: data
        })
          .then(resp => {
            commit(NOTIFICATION_SUCCESS)
            dispatch(NOTIFICATION_ALL, {})
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(NOTIFICATION_ERROR)
            reject(err)
          })
      })
    }
  }
}
