export default function (obj, property) {
  if (obj) {
    if (typeof (obj) === 'number') {
      return obj
    }

    if (typeof (obj) === 'string' && obj.length) {
      return obj
    }

    if (typeof (obj) === 'object' && obj[property]) {
      return obj[property]
    }
  }

  return '...'
}
