<template>
  <div v-if="$can.includes('read-record-labels')">
    <index-header
      title="Record Labels"
      :newButtonText="$can.includes('create-record-labels') ? 'Add Record Label' : null"
      v-on:click-new-button="create"
    ></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Labels"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <!-- <th class="has-text-grey-light">Contact</th> -->
          <!-- <th class="has-text-grey-light">Location</th> -->
          <th class="has-text-grey-light">Website</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="is-clickable"
          v-for="recordLabel in recordLabels"
          :key="recordLabel.id"
        >
          <td
            @click="edit(recordLabel)"
            class="has-text-dark has-text-weight-bold table-title"
          >{{recordLabel.name}}</td>
          <!-- <td v-if="recordLabel.contact.id">
            <router-link
              :to="{ name: 'contacts.show', params: { id: recordLabel.contact.id, propped: recordLabel.contact } }"
            >{{recordLabel.contact.full_name}}</router-link>
          </td> -->
          <!-- <td v-else @click="edit(recordLabel)">...</td> -->
          <!-- <td
            @click="edit(recordLabel)"
          >{{recordLabel.address_city && recordLabel.address_state ? recordLabel.address_city + ', ' + recordLabel.address_state : '...'}}</td> -->
          <td v-if="recordLabel.website" data-th="Website:">
            <a v-bind:href="recordLabel.website" target="_blank">{{recordLabel.website}}</a>
          </td>
          <td v-else @click="edit(recordLabel)">...</td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
    <form-modal
      type="recordLabel"
      :title="formTitle"
      :model="selectedRecordLabel"
      message="You can add more details later."
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { RECORD_LABEL_ALL, RECORD_LABEL_CREATE, RECORD_LABEL_DELETE } from '@/store/actions'
import { RECORD_LABEL_CURRENT_PAGE_SET, RECORD_LABEL_PER_PAGE_SET, RECORD_LABEL_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'RecordLabelIndex',
  mixins: [uiHelper],

  data() {
    return {
      currentPageMutation: RECORD_LABEL_CURRENT_PAGE_SET,
      perPageMutation: RECORD_LABEL_PER_PAGE_SET,
      searchValueMutation: RECORD_LABEL_SEARCH_VALUE_SET,
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      selectedRecordLabel: {}
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.recordLabel
    },

    recordLabels() {
      return this.$store.state.recordLabel.recordLabels
    }
  },

  methods: {
    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(RECORD_LABEL_ALL)
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New Record Label'
    },

    edit(recordLabel) {
      this.$router.push({ name: 'record-labels.show', params: { id: recordLabel.id, propped: recordLabel } })
    },

    save(data) {
      let self = this
      this.$store.dispatch(RECORD_LABEL_CREATE, data)
        .then(function (recordLabel) {
          self.clearModal()
        }, function (err) {
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    destroy(recordLabel) {
      this.$store.dispatch(RECORD_LABEL_DELETE, recordLabel.id)
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedRecordLabel = {}
    }
  }
}
</script>
