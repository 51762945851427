<template>
  <div class="aside-inner">
    <section class="m-b-30">
     <div class="field">
     </div>
   </section>
   <section class="m-b-30 single-aside-details--data is-hidden-mobile">
     <fieldset disabled>
       <div class="field">
         <label class="label">Pitch Date</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="pitch.date | moment-date">
         </div>
       </div>
       <div class="field" v-if="pitch.playlist">
         <label class="label">Playlist</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="pitch.playlist | orBlank('title')">
         </div>
       </div>
     </fieldset>
   </section>
   <section class="m-b-30 single-aside-details--tags is-hidden-mobile">
     <label class="label">Tags</label>
     <tag-control taggableType="pitch" :taggableId="pitch.id" />
   </section>
  </div>
</template>

<script>
export default {
  name: 'AsidePitch',
  props: {
    pitch: Object
  }

}
</script>
