<template>
  <div>
    <a @click="open">
      <IconEdit/>
    </a>
    <form-modal
      type="publishingCompanyEdit"
      title="Edit Publishing Company"
      :model="publishingCompany"
      :isModal="isModal"
      :canDelete="$can.includes('delete-publishing-companies')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import { PUBLISHING_COMPANY_UPDATE, PUBLISHING_COMPANY_DELETE } from '@/store/actions'
import { IconEdit } from '@/Icons/IconBase'

export default {
  name: 'PublishingCompanyEditButton',
  props: {
    openOnSave: Boolean,
    publishingCompany: Object
  },
  components: {
    IconEdit
  },

  data() {
    return {
      isModal: false
    }
  },

  methods: {
    open() {
      this.isModal = true
    },

    save(input) {
      let data = Object.assign(input, {
        contact_id: null
      })

      if (input.contact) {
        data = Object.assign(input, {
          contact_id: input.contact.id
        })
      }

      let self = this
      this.$store.dispatch(PUBLISHING_COMPANY_UPDATE, {
        id: this.publishingCompany.id,
        data: data
      }).then(function (resp) {
        self.clearModal()
      }, function (err) {
        alert(err)
      })
    },

    destroy(songwriter) {
      let self = this
      this.$store.dispatch(PUBLISHING_COMPANY_DELETE, songwriter.id)
        .then(function () {
          self.$router.push({ name: 'publishing-companies.index' })
        }, function (err) {
          alert(err)
        })
      this.clearModal()
    },

    clearModal() {
      this.isModal = false
    }
  }
}
</script>
