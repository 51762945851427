<template>
  <div>
    <button
      v-if="!isConfirming"
      type="button"
      class="button is-danger"
      @click.prevent="confirm"
      v-bind:disabled="disabled"
    >Delete</button>
    <button
      v-if="isConfirming"
      type="button"
      class="button is-danger m-r-10"
      @click.prevent="$emit('delete-confirmed')"
    >Are you sure?</button>
    <button
      v-if="isConfirming"
      type="button"
      class="button is-primary"
      @click.prevent="cancel"
    >Nevermind</button>
  </div>
</template>

<script>

export default {
  name: 'DeleteButton',
  props: ['disabled'],
  data() {
    return {
      'isConfirming': false
    }
  },

  computed: {
    isDisabled: function () {
      return this.disabled
    }
  },

  methods: {
    confirm() {
      this.isConfirming = true
      this.$emit('is-confirming')
    },
    cancel() {
      this.isConfirming = false
      this.$emit('is-confirming')
    }
  }
}
</script>
