<template>
  <div>
    <div class="field m-b-15">
      <label for="first_name" class="label">First Name</label>
      <input
        id="first_name"
        type="text"
        class="input"
        placeholder="First Name"
        v-model="songwriter.first_name"
      >
    </div>
    <div class="field m-b-15">
      <label for="last_name" class="label">Last Name</label>
      <input
        id="last_name"
        type="text"
        class="input"
        placeholder="Last Name"
        v-model="songwriter.last_name"
      >
    </div>

    <div class="field m-b-15">
      <label for="email" class="label">Email</label>
      <input
        id="email"
        type="text"
        class="input"
        placeholder="songwriter@wrios.com"
        v-model="songwriter.email"
      >
    </div>
    <div class="field m-b-15">
      <label for="phone" class="label">Phone</label>
      <input
        id="phone"
        type="text"
        class="input"
        placeholder="(615)555-1234"
        v-model="songwriter.phone"
      >
    </div>
    <!-- <div class="field m-b-15">
      <label for="website" class="label">Website</label>
      <input
        id="website"
        type="text"
        class="input"
        placeholder="https://www.wrios.com"
        v-model="songwriter.website"
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'SongwriterForm',
  model: {
    prop: 'songwriter',
    event: 'change'
  },
  props: {
    songwriter: Object
  }
}
</script>
