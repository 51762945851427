<template>
  <div>
    <div class="form-section">
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="field">
            <label for="first_name" class="label">First Name</label>
            <input
              id="first_name"
              type="text"
              class="input"
              placeholder="First Name"
              v-model="songwriter.first_name"
            >
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label for="last_name" class="label">Last Name</label>
            <input
              id="last_name"
              type="text"
              class="input"
              placeholder="Last Name"
              v-model="songwriter.last_name"
            >
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label for="pro" class="label">PRO</label>
            <multiselect
            v-model="songwriter.pro"
            :options="pros"
            track-by="name"
            label="name"
            :show-labels="false"
            :close-on-select="true"
          ></multiselect>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
              <label for="current_publishing_company" class="label">Current Publishing Company</label>
              <div class="control">
                <multiselect
                  v-model="songwriter.current_publishing_company"
                  :options="publishingCompanies"
                  track-by="id"
                  label="name"
                  :close-on-select="true"
                  :allow-empty="true"
                ></multiselect>
              </div>
        </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="email" class="label">Email</label>
            <input
              id="email"
              type="text"
              class="input"
              placeholder="songwriter@wrios.com"
              v-model="songwriter.email"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="phone" class="label">Phone Number</label>
            <input
              id="phone"
              type="text"
              class="input"
              placeholder="(615)555-1234"
              v-model="songwriter.phone"
            >
          </div>
        </div>
        <!-- <div class="column is-6">
          <div class="field">
            <label for="website" class="label">Website</label>
            <input
              id="website"
              type="text"
              class="input"
              placeholder="https://wrios.com"
              v-model="songwriter.website"
            >
          </div>
        </div> -->
        <div class="column is-12">
          <div class="field">
            <label for="user" class="label">WRIOS User</label>
            <div class="control">
              <multiselect
                v-model="songwriter.user"
                :options="users"
                track-by="id"
                label="full_name"
                :close-on-select="true"
                :allow-empty="true"
              ></multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="form-section p-t-30">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label for="address_line1" class="label">Address Line 1</label>
            <input
              id="address_line1"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="songwriter.address_line1"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_line2" class="label">Address Line 2</label>
            <input
              id="address_line2"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="songwriter.address_line2"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_city" class="label">City</label>
            <input
              id="address_city"
              type="text"
              class="input"
              placeholder="Nashville"
              v-model="songwriter.address_city"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_state" class="label">State</label>
            <input
              id="address_state"
              type="text"
              class="input"
              placeholder="TN"
              v-model="songwriter.address_state"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_zip" class="label">Zip Code</label>
            <input
              id="address_zip"
              type="text"
              class="input"
              placeholder="37203"
              v-model="songwriter.address_zip"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_country" class="label">Country</label>
            <input
              id="address_country"
              type="text"
              class="input"
              placeholder="USA"
              v-model="songwriter.address_country"
            >
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import UsersApi from '@/services/api/Users'
import PublishingCompaniesApi from '@/services/api/PublishingCompanies'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SongwriterEditForm',
  components: {
    Multiselect
  },
  model: {
    prop: 'songwriter',
    event: 'change'
  },
  props: {
    songwriter: Object
  },

  data() {
    return {
      users: [],
      publishingCompanies: [],
      pros: [
        { 'name': 'ASCAP', 'value': 'ASCAP' },
        { 'name': 'BMI', 'value': 'BMI' },
        { 'name': 'SESAC', 'value': 'SESAC' },
        { 'name': 'GMR', 'value': 'GMR' },
        { 'name': 'ACEMLA', 'value': 'ACEMLA' },
        { 'name': 'SOCAN', 'value': 'SOCAN' },
        { 'name': 'CMRRA', 'value': 'CMRRA' },
        { 'name': 'SACM', 'value': 'SACM' },
        { 'name': 'PRS', 'value': 'PRS' },
        { 'name': 'APRA', 'value': 'APRA' }
      ]
    }
  },

  created() {
    let accountId = this.$store.getters.getActiveAccountId
    let self = this

    UsersApi.getUsers(accountId)
      .then(resp => {
        self.users = resp.data.data
      })
      .catch(err => {
        self.$store.commit('error', 'Could not load user data.')
        console.log(err)
      })

    PublishingCompaniesApi.getCompanies(accountId)
      .then(resp => {
        self.publishingCompanies = resp.data.data
      })
      .catch(err => {
        self.$store.commit('error', 'Could not load publishing company data.')
        console.log(err)
      })
  }
}
</script>
