<template>
  <div v-if="$can.includes('read-publishing-companies')">
    <index-header
      title="Publishing Companies"
      :newButtonText="$can.includes('create-publishing-companies') ? 'Add Publishing Company' : null"
      v-on:click-new-button="create"
    ></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Companies"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <!-- <th class="has-text-grey-light">Contact</th> -->
          <!-- <th class="has-text-grey-light">Location</th> -->
          <!-- <th class="has-text-grey-light">Website</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          class="is-clickable"
          v-for="publishingCompany in publishingCompanies"
          :key="publishingCompany.id"
        >
          <td
            @click="edit(publishingCompany)"
            class="has-text-dark has-text-weight-bold table-title"
          >{{publishingCompany.name}}</td>
          <!-- <td v-if="publishingCompany.contact">
            <router-link
              :to="{ name: 'contacts.show', params: { id: publishingCompany.contact.id, propped: publishingCompany.contact } }"
            >{{publishingCompany.contact.full_name}}</router-link>
          </td>
          <td v-else @click="edit(publishingCompany)">...</td> -->
          <!-- <td
            @click="edit(publishingCompany)"
          >{{publishingCompany.address_city && publishingCompany.address_state ? publishingCompany.address_city + ', ' + publishingCompany.address_state : '...'}}</td>
          <td v-if="publishingCompany.website">
            <a v-bind:href="publishingCompany.website" target="_blank">{{publishingCompany.website}}</a>
          </td>
          <td v-else @click="edit(publishingCompany)">...</td> -->
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
    <form-modal
      type="publishingCompany"
      :title="formTitle"
      :model="selectedPublishingCompany"
      message="You can add more details later."
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'

import { PUBLISHING_COMPANY_ALL, PUBLISHING_COMPANY_CREATE, PUBLISHING_COMPANY_DELETE } from '@/store/actions'
import { PUBLISHING_COMPANY_CURRENT_PAGE_SET, PUBLISHING_COMPANY_PER_PAGE_SET, PUBLISHING_COMPANY_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'PublishingCompanyIndex',
  mixins: [uiHelper],

  data() {
    return {
      currentPageMutation: PUBLISHING_COMPANY_CURRENT_PAGE_SET,
      perPageMutation: PUBLISHING_COMPANY_PER_PAGE_SET,
      searchValueMutation: PUBLISHING_COMPANY_SEARCH_VALUE_SET,
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      selectedPublishingCompany: {}
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.publishingCompany
    },

    publishingCompanies() {
      return this.$store.state.publishingCompany.publishingCompanies
    }
  },

  methods: {
    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(PUBLISHING_COMPANY_ALL)
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New Publishing Company'
    },

    edit(publishingCompany) {
      this.$router.push({ name: 'publishing-companies.show', params: { id: publishingCompany.id, propped: publishingCompany } })
    },

    save(data) {
      let self = this
      this.$store.dispatch(PUBLISHING_COMPANY_CREATE, data)
        .then(function (publishingCompany) {
          // self.edit(publishingCompany)
          self.clearModal()
        }, function (err) {
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    destroy(publishingCompany) {
      this.$store.dispatch(PUBLISHING_COMPANY_DELETE, publishingCompany.id)
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedPublishingCompany = {}
    }
  }
}
</script>
