<template>
  <div>
    <h2>Audio ({{ audioFileCount }})</h2>
    <section class="single-files single-info m-b-60">
      <div class="level is-mobile">
        <div class="level-left">
          <h3>Files</h3>
        </div>
        <div class="level-right">
          <a v-if="$can.includes('edit song audio') || canBeEditedBySongwriterRole"  class="is-primary" @click="create">
            <IconPlus/>
          </a>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-one-third-widescreen" v-for="audioFile in audioFiles" :key="audioFile.id">
          <div class="card single-file">
            <div class="card-header">
              <div class="card-header-icon">
                <play-button v-if="audioFile.id" :audioFileId="audioFile.id"></play-button>
              </div>
              <div v-if="$can.includes('edit song audio') || canBeEditedBySongwriterRole" class="card-header-right" style="padding-top:3px;">
                <div class="card-header-icon">
                  <a
                    v-if="audioFile.default"
                    class="tooltip is-tooltip-right is-primary"
                    data-tooltip="Default Audio File"
                  >
                    <IconStar/>
                  </a>
                  <a
                    v-else
                    class="tooltip is-tooltip-right has-text-grey-light"
                    data-tooltip="Set As Default Audio File"
                    @click="setAsDefault(audioFile)"
                  >
                    <IconStar/>
                  </a>
                </div>
                <div class="card-header-icon" v-if="$can.includes('read-master-rights')">
                  <a
                    :class="{'has-text-grey-light' : !audioFile.master_rights.length}"
                    class="tooltip is-tooltip-right"
                    data-tooltip="Master Owners"
                    @click="masterOwnerModal(audioFile)"
                  >
                    <IconUsers v-if="!audioFile.master_rights.length"/>
                    <IconMasterOwners v-else  />
                  </a>
                </div>
                <div class="card-header-icon">
                  <AudioFileEventViewer :audioFile="audioFile" class="tooltip is-tooltip-left" data-tooltip="Playback Analysis"/>
                </div>

                <div class="card-header-icon" v-if="$can.includes('download-song-audio') || canBeEditedBySongwriterRole">
                  <a
                    class="tooltip is-tooltip-left has-text-grey-light"
                    data-tooltip="Download"
                    @click="downloadAudio(audioFile)"
                  >
                    <IconDownload/>
                  </a>
                </div>
                <div v-if="$can.includes('edit song audio') || canBeEditedBySongwriterRole"  class="card-header-icon">
                  <a class="has-text-grey-light" @click="edit(audioFile)">
                    <IconEdit/>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-content has-text-left">
              <h4>{{ audioFile.file_name }}</h4>
            </div>
            <div class="card-footer">
              <div class="card-footer-item has-text-left">
                {{ audioFile.type | orBlank('name') }}
              </div>
              <div class="card-footer-item has-text-right">
                {{ audioFile.beats_per_minute ? audioFile.beats_per_minute : '---' }} BPM
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <file-form-modal
      type="audioFile"
      :title="formTitle"
      message="You can add more details on the next screen."
      :model="selectedAudioFile"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      :isSaving="isSaving"
    ></file-form-modal>

    <form-modal
      type="audioFile"
      :title="formTitle"
      :model="selectedAudioFile"
      :isModal="isEditModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>

    <master-owner-modal
      :model="selectedAudioFile"
      :isModal="isMasterOwnerModal"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></master-owner-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconPlus, IconDownload, IconEdit, IconUsers, IconMasterOwners, IconStar } from '@/Icons/IconBase'

import { AUDIO_FILES_SET } from '@/store/mutations'
import { AUDIO_FILE_ALL_FOR_SONG, AUDIO_FILE_UPDATE, AUDIO_FILE_CREATE, AUDIO_FILE_DELETE, AUDIO_FILE_DOWNLOAD, SONG_SET_DEFAULT_AUDIO_FILE } from '@/store/actions'
import AudioFileEventViewer from '@/components/AudioFileEventViewer'
import MasterOwnerModal from '@/components/Modals/MasterOwnerModal'

export default {
  name: 'AudioFilesSong',
  mixins: [uiHelper],
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      isMasterOwnerModal: false,
      selectedAudioFile: {},
      isSaving: false
    }
  },

  props: ['song', 'canBeEditedBySongwriterRole'],

  components: {
    IconPlus,
    IconDownload,
    IconEdit,
    IconUsers,
    IconStar,
    IconMasterOwners,
    AudioFileEventViewer,
    MasterOwnerModal
  },

  destroyed: function () {
    this.$store.commit(AUDIO_FILES_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.audioFile
    },

    audioFiles() {
      return this.$store.state.audioFile.audioFiles
    },

    audioFileCount() {
      return this.audioFiles.length ? this.audioFiles.length : 0
    },

    defaultAudioFile() {
      return this.audioFiles.filter(audioFile => audioFile.default === true)
    }
  },

  methods: {
    load() {
      if (this.song.id) {
        this.$store.dispatch(AUDIO_FILE_ALL_FOR_SONG, this.song.id)
      }
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formType = 'audioFile'
      this.formTitle = 'Upload Audio File'
    },

    edit(audioFile) {
      this.formCanDelete = true
      this.formTitle = audioFile.file_name
      this.isEditModal = !this.isEditModal
      this.selectedAudioFile = audioFile
    },

    save(formData) {
      let self = this
      self.isSaving = true
      if (this.selectedAudioFile.id) {
        this.$store.dispatch(AUDIO_FILE_UPDATE, {
          id: this.selectedAudioFile.id,
          data: formData
        })
        self.clearModal()
      } else {
        let data = {
          songId: this.song.id,
          data: formData
        }
        this.$store.dispatch(AUDIO_FILE_CREATE, data)
          .then(function (data) {
            self.isSaving = false
            self.clearModal()
            self.edit(data)
          }, function (err) {
            self.isSaving = false
            self.clearModal()
            self.$store.commit('error', err)
          })
      }
    },

    destroy(audioFile) {
      let self = this
      self.isSaving = true
      this.$store.dispatch(AUDIO_FILE_DELETE, audioFile.id)
        .then(function (data) {
          self.load()
          self.isSaving = false
          self.clearModal()
        }, function (err) {
          self.isSaving = false
          self.$store.commit('error', err)
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.isEditModal = false
      this.isMasterOwnerModal = false
      this.selectedAudioFile = {}
      this.isSaving = false
    },

    masterOwnerModal(audioFile) {
      this.isMasterOwnerModal = !this.isMasterOwnerModal
      this.selectedAudioFile = audioFile
    },

    downloadAudio(audioFile) {
      let self = this
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, audioFile.id)
        .then(function (resp) {
        }).catch(function (err) {
          self.$store.commit('error', err)
        })
    },

    setAsDefault(audioFile) {
      this.isSaving = true
      let self = this

      this.$store.dispatch(SONG_SET_DEFAULT_AUDIO_FILE, {
        id: this.song.id,
        audioFileId: audioFile.id
      })
        .then(function () {
          self.isSaving = false
          self.load()
          self.$store.commit('success', 'Default audio file updated.')
        }, function (err) {
          self.isSaving = false
          if (err.response) {
            self.$store.commit('error', err.response.data.message)
          } else {
            self.$store.commit('error', 'Default audio file could not be updated.')
          }
        })
    }
  }
}
</script>
