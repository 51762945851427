<template>
  <div v-if="activeSearch" class="search-filter-controls">
    <div class="columns">
      <div class="column is-three-fifths">
        <div class="tags">
          <span v-if="searchQuery.song_title" class="tag is-primary">
            <b>Title:</b>
            &nbsp;{{searchQuery.song_title}}
            <button @click="removeFilter('song_title')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.date" class="tag is-primary">
            <b>Pitch Date:</b>
            &nbsp;{{moment(searchQuery.date.start).format('MM/DD/YYYY')}} to
            {{moment(searchQuery.date.end).format('MM/DD/YYYY')}}
            <button @click="removeFilter('date')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.created_at" class="tag is-primary">
            <b>Date Created:</b>
            &nbsp;{{moment(searchQuery.created_at.start).format('MM/DD/YYYY')}} to
            {{moment(searchQuery.created_at.end).format('MM/DD/YYYY')}}
            <button @click="removeFilter('created_at')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.pitch_by_user" class="tag is-primary">
            <b>Pitched By:</b>
            &nbsp;{{searchQuery.pitch_by_user.full_name}}
            <button @click="removeFilter('pitch_by_user')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.pitch_to_contact" class="tag is-primary">
            <b>Pitching To:</b>
            &nbsp;{{searchQuery.pitch_to_contact.full_name}}
            <button @click="removeFilter('pitch_to_contact')" class="delete is-small"></button>
          </span>

          <span v-for="(pitch_artist, index) in searchQuery.pitch_artists" :key="pitch_artist.id"
            class="tag is-primary">
            <b>Pitch For:</b>
            &nbsp;{{pitch_artist.name}}
            <button @click="removeArrayFilter('pitch_artists', index)" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.notes" class="tag is-primary">
            <b>Notes:</b>
            &nbsp;{{searchQuery.notes}}
            <button @click="removeFilter('notes')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.description" class="tag is-primary">
            <b>Description:</b>
            &nbsp;{{searchQuery.description}}
            <button @click="removeFilter('description')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.status" class="tag is-primary">
            <b>Type:</b>
            &nbsp;{{searchQuery.status}}
            <button @click="removeFilter('status', index)" class="delete is-small"></button>
          </span>
          <span v-for="(userTag, index) in searchQuery.userTag" :key="userTag.id" class="tag is-primary">
            <b>User Tag:</b>
            &nbsp;{{userTag.value}}
            <button @click="removeArrayFilter('userTag', index)" class="delete is-small"></button>
          </span>
          <span v-for="(accountTag, index) in searchQuery.accountTag" :key="accountTag.id" class="tag is-primary">
            <b>Account Tag:</b>
            &nbsp;{{accountTag.value}}
            <button @click="removeArrayFilter('accountTag', index)" class="delete is-small"></button>
          </span>
        </div>
      </div>

      <div v-if="!Object.values(this.$data).every(o => o === null)" class="column">
        <div class="is-pulled-right">
          Filters being applied to
          <strong>all pitches</strong>.
          <span class="tag is-small is-primary m-l-5">
            <b>Clear All</b>
            <button @click="clearQuery()" class="delete is-small"></button>
          </span>
        </div>
        <div class="is-clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PITCH_REMOVE_QUERY_ARRAY_VALUE
} from '@/store/mutations'

export default {
  name: 'SearchFilterControlPitches',

  props: {
    searchQuery: Object
  },

  data() {
    return {
      song_title: null,
      date: null,
      created_at: null,
      pitch_by_user: null,
      pitch_to_contact: null,
      pitch_artists: null,
      notes: null,
      status: null,
      userTag: null,
      accountTag: null
    }
  },

  created: function () {
    this.updateLocalValues(this.searchQuery)
  },

  computed: {
    query() {
      return {
        song_title: this.song_title,
        date: this.date,
        created_at: this.created_at,
        pitch_by_user: this.pitch_by_user,
        pitch_to_contact: this.pitch_to_contact,
        pitch_artists: this.pitch_artists,
        notes: this.notes,
        status: this.status,
        userTag: this.userTag,
        accountTag: this.accountTag
      }
    },

    activeSearch() {
      return !Object.values(this.searchQuery).every(function (value) {
        return value === null
      })
    }
  },

  watch: {
    searchQuery: function (newValue) {
      this.updateLocalValues(this.searchQuery)
    },

    query: function (newValue) {
      Object.keys(newValue).forEach(function (key) {
        if (newValue[key] === '') {
          newValue[key] = null
        }
      })

      this.updateSearchQuery(newValue)
    }
  },

  methods: {
    clearQuery: function () {
      Object.assign(this.$data, this.$options.data())
    },

    removeFilter: function (filter, secondFilter = null) {
      this.$data[filter] = null
      if (secondFilter) {
        this.$data[secondFilter] = null
      }
    },

    removeArrayFilter: function (field, index) {
      this.$store.commit(PITCH_REMOVE_QUERY_ARRAY_VALUE, {
        field,
        index
      })
    },

    updateSearchQuery: function (query) {
      this.$emit('update-query', query)
    },

    updateLocalValues: function (obj) {
      this.song_title = obj.song_title
      this.date = obj.date
      this.created_at = obj.created_at
      this.pitch_by_user = obj.pitch_by_user
      this.pitch_to_contact = obj.pitch_to_contact
      this.pitch_artists = obj.pitch_artists
      this.notes = obj.notes
      this.status = obj.status
      this.userTag = obj.userTag
      this.accountTag = obj.accountTag
    }
  }
}
</script>
