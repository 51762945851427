<template>
  <div class="aside-inner">
    <!-- <section class="m-b-30">
     <div class="field">
       <div class="select">
         <select>
           <option selected>Version 1.0</option>
           <option>Version 2.0</option>
         </select>
       </div>
     </div>
   </section> -->
   <section class="m-b-30 single-aside-details--data is-hidden-mobile">
     <fieldset disabled>
       <div class="field">
         <label class="label">Created</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="song.date_written | momentDate">
         </div>
       </div>
       <div class="field">
         <label class="label">Turned In</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="song.date_turned_in | momentDate">
         </div>
       </div>
       <div class="field">
         <label class="label">Session Date</label>
         <div class="control">
           <input class="input" type="text" v-bind:value="song.session_date | momentDate">
         </div>
       </div>
     </fieldset>
   </section>
   <section class="m-b-30 single-aside-details--tags">
     <label class="label">Tags</label>
     <tag-control taggableType="song" :taggableId="song.id" />
   </section>
  </div>
</template>

<script>
export default {
  name: 'AsideSong',
  props: {
    song: Object
  }

}
</script>
