<template>
  <section class="page-header is-paddingless is-marginless">
    <div class="level page-actions">
      <div class="level-left">
        <div class="level-item">
          <h1>{{ playlist.title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <play-playlist-button :playlistId="playlist.id"></play-playlist-button>
        </div>
        <div class="level-item">
          <a
            @click="openPitchMeetingView"
            class="table-icon tooltip"
            data-tooltip="Pitch Meeting View"
          >
            <IconRadio/>
          </a>
        </div>
        <div class="level-item" v-if="$can.includes('download-song-audio')">
          <a
          class="is-primary tooltip"
          data-tooltip="Download Playlist"
          @click="downloadZip(playlist)"
          :class="{'is-disabled' : isDownloading}"
          >
            <IconDownload/>
          </a>
        </div>
        <div class="level-item">
          <a
            class="is-primary tooltip"
            data-tooltip="Edit Playlist"
            @click="$emit('edit-playlist')"
            v-if="$can.includes('update-playlists')"
          >
            <IconEdit/>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { IconEdit, IconDownload, IconRadio } from '@/Icons/IconBase'
import PlayPlaylistButton from '@/components/PlayPlaylistButton'

import { AUDIO_FILE_ZIP_DOWNLOAD } from '@/store/actions'
import { PLAYLIST_LOADING, PLAYLIST_SUCCESS } from '@/store/mutations'

export default {
  name: 'PageHeaderPlaylist',
  components: {
    IconEdit,
    IconDownload,
    IconRadio,
    PlayPlaylistButton
  },
  props: {
    playlist: Object
  },
  computed: {
    isDownloading() {
      return this.$store.state.playlist.status === 'loading'
    }
  },
  methods: {
    openPitchMeetingView() {
      this.$router.push({ name: 'playlists.pitch-meeting.show', params: { id: this.playlist.id, propped: this.playlist } })
    },
    downloadZip(playlist) {
      if (!this.isDownloading) {
        let self = this
        this.$store.commit(PLAYLIST_LOADING)
        this.$store.dispatch(AUDIO_FILE_ZIP_DOWNLOAD, {
          title: playlist.title,
          audioFileIds: playlist.audio_files.map(audio => audio.id)
        })
          .then(function (resp) {
            self.$store.commit(PLAYLIST_SUCCESS)
          }).catch(function (err) {
            self.$store.commit(PLAYLIST_SUCCESS)
            self.$store.commit('error', err)
          })
      }
    }
  }
}
</script>
