<template>
  <div>
    <div class="form-section">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label for="name" class="label">Name</label>
            <input
              id="name"
              type="text"
              class="input"
              placeholder="Company Name"
              v-model="publishingCompany.name"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="Phone Number" class="label">Phone Number</label>
            <input
              id="Phone Number"
              type="text"
              class="input"
              placeholder="(615)555-1234"
              v-model="publishingCompany.phone"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Contact</label>
            <div class="control">
              <multiselect
                v-model="publishingCompany.contact"
                :options="contacts"
                track-by="id"
                label="full_name"
                :close-on-select="true"
                :allow-empty="true"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="website" class="label">Website</label>
            <input
              id="website"
              type="text"
              class="input"
              placeholder="https://www.wrios.com"
              v-model="publishingCompany.website"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="form-section p-t-30">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label for="address_line1" class="label">Address Line 1</label>
            <input
              id="address_line1"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="publishingCompany.address_line1"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_line2" class="label">Address Line 2</label>
            <input
              id="address_line2"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="publishingCompany.address_line2"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_city" class="label">City</label>
            <input
              id="address_city"
              type="text"
              class="input"
              placeholder="Nashville"
              v-model="publishingCompany.address_city"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_state" class="label">State</label>
            <input
              id="address_state"
              type="text"
              class="input"
              placeholder="TN"
              v-model="publishingCompany.address_state"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_zip" class="label">Zip Code</label>
            <input
              id="address_zip"
              type="text"
              class="input"
              placeholder="37203"
              v-model="publishingCompany.address_zip"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_country" class="label">Country</label>
            <input
              id="address_country"
              type="text"
              class="input"
              placeholder="USA"
              v-model="publishingCompany.address_country"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
  name: 'PublishingCompanyEditForm',
  model: {
    prop: 'publishingCompany',
    event: 'change'
  },
  props: {
    publishingCompany: Object
  },

  components: {
    Multiselect
  },

  computed: {
    contacts() {
      return this.$store.state.contact.contacts
    }
  }
}
</script>
