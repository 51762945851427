<template>
<transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="confirm-modal" class="modal is-active" v-if="message">
      <div class="modal-background" @click="reject"></div>

      <div class="modal-content card">
        <div class="card-content">
            <h4 class="title">Are you sure?</h4>
            <div class="level m-t-30">
              <div class="level-item has-text-centered" style="flex-shrink: 1 !important;">
                <span v-html="message"></span>
              </div>
            </div>
            <div class="level is-mobile">
              <div class="level-item">
                <button class="button is-danger" @click="confirm">Confirm</button>
              </div>
              <div class="level-item">
                <a href="#" class="button is-primary" @click="reject">Nevermind</a>
              </div>
            </div>
        </div>
        <button class="modal-close is-large" @click="reject"></button>
      </div>
    </div>
  </transition>

</template>

<script>

import { CONFIRM_RESPONSE_SET, CONFIRM_MESSAGE_SET } from '@/store/mutations'

export default {
  name: 'ConfirmDisplay',

  computed: {
    message() {
      return this.$store.getters.getConfirmMessage
    }
  },

  methods: {
    reject() {
      this.$store.commit(CONFIRM_RESPONSE_SET, false)
      this.$store.commit(CONFIRM_MESSAGE_SET, null)
    },

    confirm() {
      this.$store.commit(CONFIRM_RESPONSE_SET, true)
      this.$store.commit(CONFIRM_MESSAGE_SET, null)
    }
  }
}
</script>
