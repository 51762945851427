<template>
  <ul class="menu-list">
    <li v-if="$can.includes('read-albums')">
      <router-link :to="{ name: 'albums.index' }">
        Albums
        <div class="menu-icon is-pulled-right">
          <IconChevronRight/>
        </div>
      </router-link>
    </li>
    <li v-if="$can.includes('read-projects')">
      <router-link :to="{ name: 'projects.index' }">
        Projects
        <div class="menu-icon is-pulled-right">
          <IconChevronRight/>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>

import { IconChevronRight } from '@/Icons/IconBase'

export default {
  name: 'AsideResource',
  components: {
    IconChevronRight
  }
}
</script>
