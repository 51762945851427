<template>
    <div class="home-page wrap">
      <transition name="custom-classes-transition" enter-active-class="animated fadeIn faster">
        <router-view></router-view>
      </transition>
      <PublicFooter />
    </div>
</template>

<script>
import PublicFooter from './PublicFooter'

export default {
  name: 'PublicLayout',
  components: {
    PublicFooter
  }
}
</script>
