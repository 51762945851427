<template>
  <div>
    <span
      v-for="tag in tags"
      v-bind:key="tag.id"
      class="tag"
      v-bind:class="{ 'is-success': tag.scope === 'user',  'is-primary': tag.scope === 'account', 'is-warning': tag.scope === null }"
    >
      {{tag.value}}
      <button
        v-if="$can.includes('can-tag')"
        @click="untag(tag)"
        class="delete is-small"
      ></button>
    </span>

    <div v-if="$can.includes('can-tag')" class="field">
      <div class="control">
        <multiselect
          placeholder="General Tags"
          track-by="id"
          label="value"
          :options="systemTags"
          :selectLabel="null"
          :close-on-select="true"
          :resetAfter="true"
          @select="tag"
        ></multiselect>
      </div>
    </div>

    <div v-if="$can.includes('can-tag') && $can.includes('read-account-tags')" class="field">
      <div class="control">
        <multiselect
          placeholder="Account Tags"
          track-by="id"
          label="value"
          :options="accountTags"
          :selectLabel="null"
          :taggable="true"
          :close-on-select="true"
          :resetAfter="true"
          @tag="createAccountTag"
          @select="tag"
        ></multiselect>
      </div>
    </div>

    <div v-if="$can.includes('can-tag')" class="field">
      <div class="control">
        <multiselect
          placeholder="User Tags"
          track-by="id"
          label="value"
          :options="userTags"
          :selectLabel="null"
          :taggable="true"
          :close-on-select="true"
          :resetAfter="true"
          @tag="createUserTag"
          @select="tag"
        ></multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import TagsApi from '@/services/api/Tags'
import Multiselect from 'vue-multiselect'
import { TAG_ALL_FOR_TAGGABLE, USER_TAG_ALL, ACCOUNT_TAG_ALL, TAGGED_ITEM_CREATE, TAGGED_ITEM_DELETE_BY_TAGGABLE, USER_TAG_CREATE, ACCOUNT_TAG_CREATE } from '@/store/actions'

export default {
  name: 'TagControl',
  props: ['taggableId', 'taggableType'],
  components: {
    Multiselect
  },

  data() {
    return {
      tags: [],
      systemTags: []
    }
  },

  created() {
    this.load()
    this.$store.dispatch(USER_TAG_ALL)
    this.$store.dispatch(ACCOUNT_TAG_ALL)

    TagsApi.getTags()
      .then(resp => {
        this.systemTags = resp.data.data
      })
      .catch(resp => {
        this.$store.commit('error', 'Could not load system tags')
      })
  },

  computed: {
    userTags() {
      return this.$store.state.userTag.userTags
    },
    accountTags() {
      return this.$store.state.accountTag.accountTags
    }
  },

  watch: {
    taggableId: function () {
      this.load()
    },

    taggableType: function () {
      this.load()
    }
  },

  methods: {
    load() {
      if (this.taggableId && this.taggableType) {
        let self = this
        this.$store.dispatch(TAG_ALL_FOR_TAGGABLE, {
          taggableType: this.taggableType,
          taggableId: this.taggableId
        }).then(function (data) {
          if (!self.$root.$can.includes('read-account-tags')) {
            self.tags = data.filter(tag => tag.scope !== 'account')
          } else {
            self.tags = data
          }
        }, function (err) {
          alert(err)
        })
      }
    },

    tag(tag) {
      let self = this
      this.$store.dispatch(TAGGED_ITEM_CREATE, {
        tag_id: tag.id,
        taggable_id: this.taggableId,
        taggable_type: this.taggableType
      }).then(function (data) {
        self.load()
      }, function (err) {
        alert(err)
      })

      this.taggedUserTags = []
      this.taggedAccountTags = []
    },

    untag(tag) {
      let self = this
      this.$store.dispatch(TAGGED_ITEM_DELETE_BY_TAGGABLE, {
        data: {
          tag_id: tag.id,
          taggable_id: this.taggableId,
          taggable_type: this.taggableType
        }
      }).then(function (data) {
        self.load()
      }, function (err) {
        alert(err)
      })
    },

    createUserTag(data) {
      let self = this
      this.$store.dispatch(USER_TAG_CREATE, {
        scope: 'user',
        value: data
      }).then(function (data) {
        self.tag(data)
      }, function (err) {
        alert(err)
      })
    },

    createAccountTag(data) {
      let self = this
      this.$store.dispatch(ACCOUNT_TAG_CREATE, {
        scope: 'account',
        value: data
      }).then(function (data) {
        self.tag(data)
      }, function (err) {
        alert(err)
      })
    }
  }
}
</script>
