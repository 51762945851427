<template>
<!-- <div v-if="$can.includes('read-holds')"> -->
  <div>
    <section class="single-info">
      <div class="level is-mobile">
        <div class="level-left">
          <h3 class="m-b-30">Holds</h3>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="create"  v-if="$can.includes('update-songs')">
            <IconPlus/>
          </a>
        </div>
      </div>
      <div class="level" v-if="!holds.length">
        <p>
          No holds to display.
          <a @click="create" v-if="$can.includes('update-songs')">Add a hold here.</a>
        </p>
      </div>
      <div class="level" v-else>
        <table class="table is-fullwidth is-responsive is-hoverable">
          <thead>
            <tr>
              <th>Hold Date</th>
              <th>Hold Release</th>
              <th>Artist</th>
              <th>Backup Artist</th>
              <th>Contact</th>
              <th>Pitched By User</th>
              <th>Pitched By Contact</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="hold in holds"
              :key="hold.id"
              @click="edit(hold)"
              :class="{'is-clickable' : $can.includes('update-songs'), 'is-prohibited' : !$can.includes('update-songs') }"
            >
              <td>{{ hold.hold_date | momentDate }}</td>
              <td>{{ hold.hold_release_date | momentDate }}</td>
              <td>{{ hold.artist ? hold.artist.name : '...' }}</td>
              <td>{{ hold.backup_artist ? hold.backup_artist.name : '...' }}</td>
              <td>{{ hold.hold_contact ? hold.hold_contact.full_name : '...' }}</td>
              <td>{{ hold.pitch_by_user ? hold.pitch_by_user.full_name : '...' }}</td>
              <td>{{ hold.pitch_by_contact ? hold.pitch_by_contact.full_name : '...' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <form-modal
      type="hold"
      :title="formTitle"
      :model="selectedHold"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconPlus } from '@/Icons/IconBase'

import { HOLDS_SET } from '@/store/mutations'
import { HOLD_ALL_FOR_SONG, HOLD_UPDATE, HOLD_CREATE, HOLD_DELETE, SONG_UPDATE } from '@/store/actions'

export default {
  name: 'HoldsSong',
  mixins: [uiHelper],
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      selectedHold: {},
      isSaving: false
    }
  },

  props: ['songId'],

  components: {
    IconPlus
  },

  destroyed: function () {
    this.$store.commit(HOLDS_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.hold
    },

    holds() {
      return this.$store.state.hold.holds
    }
  },

  methods: {
    load() {
      if (this.songId) {
        this.$store.dispatch(HOLD_ALL_FOR_SONG, this.songId)
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Hold'
      this.isModal = !this.isModal
    },

    edit(hold) {
      if (this.$can.includes('update-songs')) {
        this.formCanDelete = true
        this.formTitle = 'Update Hold'
        this.selectedHold = hold
        this.isModal = !this.isModal
      }
    },

    save(input) {
      if (input.artist) {
        let data = {
          artist_id: input.artist.id,
          backup_artist_id: input.backup_artist ? input.backup_artist.id : null,
          hold_contact_id: input.hold_contact ? input.hold_contact.id : null,
          pitch_by_user_id: input.pitch_by_user ? input.pitch_by_user.id : null,
          pitch_by_contact_id: input.pitch_by_contact ? input.pitch_by_contact.id : null,
          hold_date: input.hold_date,
          hold_release_date: input.hold_release_date
        }

        if (this.selectedHold.id) {
          this.$store.dispatch(HOLD_UPDATE, {
            id: this.selectedHold.id,
            data: data,
            songId: this.songId
          })
        } else {
          this.$store.dispatch(HOLD_CREATE, {
            songId: this.songId,
            data: data
          })
        }
        this.$emit('child-updated')
        this.clearModal()
      } else {
        alert('A primary artist is required. Please select or create one.')
      }
    },

    destroy(hold) {
      let self = this
      this.$store.dispatch(HOLD_DELETE, { id: hold.id, songId: this.songId })
        .then(function () {
          self.$store.dispatch(SONG_UPDATE, {
            id: self.songId,
            data: null
          })
        })
        .then(function () {
          self.clearModal()
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedHold = {}
    }
  }
}
</script>
