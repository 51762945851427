import Vue from 'vue'
import axios from 'axios'

import {
  ACCOUNT_READ_ACTIVE_ACCOUNT,
  ACCOUNT_UPDATE_ACTIVE_ACCOUNT
} from '../actions'

import {
  ACCOUNT_LOADING,
  ACCOUNT_ERROR,
  ACCOUNT_SUCCESS,
  ACCOUNT_SET_ACTIVE_ACCOUNT,
  ACCOUNT_SET
} from '../mutations'

export default {
  state: {
    status: '',
    account: {},
    activeAccount: {},
    url: {
      base: 'accounts',
      parentResource: null
    }
  },

  getters: {
    getActiveAccount: state => state.activeAccount,
    getActiveAccountId: state => state.activeAccount.id ? state.activeAccount.id : localStorage.getItem('active-account-id'),
    isActiveAccountLoaded: state => !!state.activeAccount.id
  },

  mutations: {
    [ACCOUNT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [ACCOUNT_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [ACCOUNT_ERROR]: (state) => {
      state.status = 'error'
    },
    [ACCOUNT_SET_ACTIVE_ACCOUNT]: (state, data) => {
      state.activeAccount = data
    },
    [ACCOUNT_SET]: (state, resp) => {
      Vue.set(state, 'account', resp)
    }
  },

  actions: {
    [ACCOUNT_READ_ACTIVE_ACCOUNT]: ({
      commit
    }, id) => {
      return new Promise((resolve, reject) => {
        commit(ACCOUNT_LOADING)
        axios({
          url: 'accounts/' + id
        })
          .then(resp => {
            commit(ACCOUNT_SUCCESS)
            commit(ACCOUNT_SET_ACTIVE_ACCOUNT, resp.data.data)
            resolve(resp)
          })
          .catch(err => {
            commit(ACCOUNT_ERROR)
            reject(err)
          })
      })
    },

    [ACCOUNT_UPDATE_ACTIVE_ACCOUNT]: ({
      commit,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        if (!getters.isActiveAccountLoaded) {
          commit(ACCOUNT_ERROR)
          return false
        }

        commit(ACCOUNT_LOADING)

        axios({
          method: 'put',
          url: 'accounts/' + getters.getActiveAccountId,
          data: data
        })
          .then(resp => {
            commit(ACCOUNT_SET_ACTIVE_ACCOUNT, resp.data.data)
            commit(ACCOUNT_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(ACCOUNT_ERROR)
            reject(err)
          })
      })
    }
  }
}
