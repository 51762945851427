<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/Default/DefaultLayout'
import PublicLayout from './layouts/Public/PublicLayout'
import { USER_SELF } from '@/store/actions'

export default {
  components: {
    DefaultLayout,
    PublicLayout
  },
  beforeCreate: function () {
    if (!this.$store.getters.isProfileLoaded && localStorage.getItem('user-token')) {
      return this.$store.dispatch(USER_SELF)
    }
  },
  data() {
    // this is needed to select the layout on initial page load
    const layout = this.$route.meta?.publicLayout ? 'PublicLayout' : 'DefaultLayout'
    return {
      layout
    }
  },
  watch: {
    $route(to) {
      // this is needed for selecting the layout after a transition
      if (to.meta.publicLayout) {
        this.layout = 'PublicLayout'
      } else {
        this.layout = 'DefaultLayout'
      }
    }
  }
}
</script>
