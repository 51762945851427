<template>
  <div>
      <div class="columns is-multiline">
        <div class="column is-12">
        <div class="field m-b-15">
          <label class="label">Type</label>
          <div class="control">
            <multiselect
              v-model="contact.type"
              :options="contactTypes"
              track-by="id"
              label="name"
              :close-on-select="true"
            ></multiselect>
          </div>
        </div>
        </div>
      <div class="column is-half">
        <div class="field m-b-15">
          <label for="first_name" class="label">First Name</label>
          <input
            id="first_name"
            type="text"
            class="input"
            placeholder="First Name"
            v-model="contact.first_name"
            tabindex="1"
            required
          >
        </div>
        <div class="field m-b-15">
          <label for="email" class="label">Email</label>
          <input
            id="email"
            type="text"
            class="input"
            placeholder="contact@wrios.com"
            v-model="contact.email"
            tabindex="3"
          >
        </div>
        <div class="field m-b-15">
          <label for="address_city" class="label">City</label>
          <input
            id="address_city"
            type="text"
            class="input"
            placeholder="Nashville"
            v-model="contact.address_city"
            tabindex="5"
          >
        </div>
      </div>
      <div class="column is-half">
        <div class="field m-b-15">
          <label for="last_name" class="label">Last Name</label>
          <input
            id="last_name"
            type="text"
            class="input"
            placeholder="Last Name"
            v-model="contact.last_name"
            tabindex="2"
            required
          >
        </div>
        <div class="field m-b-15">
          <label for="mobile_phone" class="label">Mobile Phone</label>
          <input
            id="mobile_phone"
            type="text"
            class="input"
            placeholder="(615)555-1234"
            v-model="contact.mobile_phone"
            tabindex="4"
          >
        </div>
        <div class="field m-b-15">
          <label for="address_state" class="label">State</label>
          <input
            id="address_state"
            type="text"
            class="input"
            placeholder="TN"
            v-model="contact.address_state"
            tabindex="6"
          >
        </div>
      </div>
    </div>
    <div class="field m-b-15">
      <label for="company_name" class="label">Company Name</label>
      <input
        id="company_name"
        type="text"
        class="input"
        placeholder="Company Name"
        v-model="contact.company_name"
        tabindex="7"
      >
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ContactTypesApi from '@/services/api/ContactTypes'

export default {
  name: 'ContactForm',
  model: {
    prop: 'contact',
    event: 'change'
  },
  components: {
    Multiselect
  },
  props: {
    contact: Object
  },
  data() {
    return {
      contactTypes: []
    }
  },
  created: function () {
    ContactTypesApi.getTypes()
      .then(resp => {
        this.contactTypes = resp.data.data
      })
      .catch(resp => {
        this.$store.commit('error', 'Could not load contact types')
      })
  }
}
</script>
