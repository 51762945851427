import {
  AUDIO_FILE_SELECT_LOADING,
  AUDIO_FILE_SELECT_READY,
  AUDIO_FILE_SELECT_SET,
  AUDIO_FILE_SELECT_RESPONSE_SET,
  AUDIO_FILE_SELECT_CLEAR
} from '../mutations'

export default {
  state: {
    response: null,
    files: null,
    status: ''
  },

  getters: {
    getAudioFileSelectFiles: state => state.files,
    getAudioFileSelectResponse: state => state.response
  },

  mutations: {
    [AUDIO_FILE_SELECT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [AUDIO_FILE_SELECT_READY]: (state) => {
      state.status = 'ready'
    },
    [AUDIO_FILE_SELECT_SET]: (state, value) => {
      state.files = value
    },
    [AUDIO_FILE_SELECT_RESPONSE_SET]: (state, value) => {
      state.response = value
    },
    [AUDIO_FILE_SELECT_CLEAR]: (state) => {
      state.files = null
      state.response = null
    }
  }
}
