<template class="HeaderMain">
  <header class="hero">
    <progress v-if="isLoading" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <nav class="navbar nav is-mobile has-shadow ordered-mobile" role="navigation" aria-label="main navigation">

      <div class="navbar-brand fl-1">
        <router-link class="navbar-item" :to="{ name: 'songs.index' }">
          <img alt="Wrios logo" src="@/assets/logo-wrios.svg">
        </router-link>
      </div>

      <div id="mainNavbar" class="navbar-menu" v-if="isProfileLoaded" :class="{'is-hidden' : mobileNav !== 1}">
        <div @click="mobileNavUpdate" class="navbar-start">
            <router-link @click="mobileNavUpdate" class="navbar-item is-tab" :to="{ name: 'songs.index' }">
              <div class="navbar-link"><IconMusic class="nav-icon songs"/>Songs</div>
            </router-link>

            <router-link  class="navbar-item is-tab" :to="{ name: 'playlists.index' }">
              <div class="navbar-link"><IconPlaylist class="nav-icon playlists"/>Playlists</div>
            </router-link>

            <router-link
              class="navbar-item is-tab"
              :to="{ name: 'pitches.index' }"
              v-if="$can.includes('read-pitches')"
            >
              <div class="navbar-link"><IconRadio class="nav-icon pitches"/>Pitches</div>
            </router-link>

            <!-- v-if="$can.includes('read-industry')" -->
            <router-link  class="navbar-item is-tab" to="/industry">
              <div class="navbar-link"><IconUsers class="nav-icon industry"/>Industry</div>
            </router-link>
        </div>
      </div>

      <div class="navbar-end fl-2" v-if="isProfileLoaded">
        <div @click="notificationNavUpdate" v-if="isProfileLoaded">
          <NotificationControl></NotificationControl>
        </div>

        <div class="navbar-item has-dropdown is-hoverable navbar-settings" v-if="isProfileLoaded">
          <div @click="accountNavUpdate">
            <span class="navbar-link">
              <IconUser class="nav-icon" />
              <span class="user-desktop">{{ currentUser.full_name }}</span>
            </span>
          </div>
        </div>

        <a
          v-if="isProfileLoaded"
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="true"
          data-target="mainNavbar"
          @click="mobileNavUpdate"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>

        <div @click="accountNavUpdate" class="navbar-dropdown is-right" :class="{'is-hidden' : accountNav !== 1}">
          <router-link class="navbar-item" :to="{ name: 'user.profile' }">User Profile</router-link>
          <router-link class="navbar-item" :to="{ name: 'user.password' }">Change Password</router-link>
          <router-link class="navbar-item" :to="{ name: 'user.notifications' }">Notifications</router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'user.account.profile' }"
            v-if="$can.includes('read-accounts')"
          >Account Profile</router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'user.account.tags' }"
            v-if="$can.includes('can-tag') && $can.includes('read-account-tags')"
          >Account Tags</router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'user.account.audio-file-types' }"
            v-if="$can.includes('read-audio-file-types')"
          >Audio File Types</router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'user.tags' }"
            v-if="$can.includes('can-tag')"
          >My Tags</router-link>
          <router-link class="navbar-item" :to="{ name: 'user.exports' }">Exports</router-link>
          <hr class="navbar-divider">
          <router-link class="navbar-item" :to="{ name: 'audio-player-queue' }">Play Queue</router-link>
          <hr class="navbar-divider">
          <a class="navbar-item" v-on:click="logout">Log Out</a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

import { AUTH_LOGOUT } from '@/store/actions'
import { IconMusic, IconPlaylist, IconRadio, IconUser, IconUsers } from '@/Icons/IconBase'
import NotificationControl from '@/components/NotificationControl'

export default {
  name: 'HeaderMain',
  components: {
    IconPlaylist,
    IconMusic,
    IconRadio,
    IconUser,
    IconUsers,
    NotificationControl
  },
  created: function () {
    let self = this

    // close account nav when clicking outside of element
    window.addEventListener('click', function (e) {
      if (!self.$el.contains(e.target)) {
        self.accountNav = 0
      }
    })
  },
  computed: {
    isLoading() {
      return this.$store.state.account.status === 'loading' ||
        this.$store.state.accountTag.status === 'loading' ||
        this.$store.state.album.status === 'loading' ||
        this.$store.state.artist.status === 'loading' ||
        this.$store.state.audioFile.status === 'loading' ||
        this.$store.state.audioFileType.status === 'loading' ||
        this.$store.state.audioPlayer.status === 'loading' ||
        this.$store.state.auth.status === 'loading' ||
        this.$store.state.base.status === 'loading' ||
        this.$store.state.contact.status === 'loading' ||
        this.$store.state.contract.status === 'loading' ||
        this.$store.state.contractShare.status === 'loading' ||
        this.$store.state.cut.status === 'loading' ||
        this.$store.state.email.status === 'loading' ||
        this.$store.state.hold.status === 'loading' ||
        this.$store.state.image.status === 'loading' ||
        this.$store.state.message.status === 'loading' ||
        this.$store.state.notification.status === 'loading' ||
        this.$store.state.pitch.status === 'loading' ||
        this.$store.state.playlist.status === 'loading' ||
        this.$store.state.project.status === 'loading' ||
        this.$store.state.publishingCompany.status === 'loading' ||
        this.$store.state.recordLabel.status === 'loading' ||
        this.$store.state.release.status === 'loading' ||
        this.$store.state.song.status === 'loading' ||
        this.$store.state.songwriter.status === 'loading' ||
        this.$store.state.sync.status === 'loading' ||
        this.$store.state.tag.status === 'loading' ||
        this.$store.state.taggedItem.status === 'loading' ||
        this.$store.state.user.status === 'loading' ||
        this.$store.state.userTag.status === 'loading' ||
        this.$store.state.writingCredit.status === 'loading'
    },

    isProfileLoaded() {
      return this.$store.getters.isProfileLoaded
    },

    currentUser() {
      return this.$store.getters.getProfile
    }
  },

  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push('/login')
        })
    },
    accountNavUpdate: function () {
      this.mobileNav = 0
      this.accountNav !== 1 ? this.accountNav = 1 : this.accountNav = 0
    },
    mobileNavUpdate: function () {
      this.accountNav = 0
      this.mobileNav !== 1 ? this.mobileNav = 1 : this.mobileNav = 0
    },
    notificationNavUpdate: function () {
      this.accountNav = 0
      this.mobileNav = 0
    }
  },

  data() {
    return {
      mobileNav: 0,
      accountNav: 0
    }
  }
}
</script>
