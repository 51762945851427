import axios from 'axios'

export default {

  storeColumnPreference(viewName, columnTitle, active) {
    return axios({
      method: 'post',
      url: 'column-preferences',
      data: {
        view_name: viewName,
        column_title: columnTitle,
        active: active
      }
    })
  }

}
