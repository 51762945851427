<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <div class="field m-b-15">
          <label for="name" class="label">Contract Name</label>
          <input
            id="name"
            type="text"
            class="input"
            placeholder="Contract Name"
            v-model="contract.name"
          >
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6">
        <div class="field m-b-15">
          <label for="start_date" class="label">Start Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('contract.start_date', contract.start_date, 'Contract Start Date', 'single')">
              <span v-if="contract.start_date">
                {{ contract.start_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field m-b-15">
          <label for="end_date" class="label">End Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('contract.end_date', contract.end_date, 'Contract End Date', 'single')">
              <span v-if="contract.end_date">
                {{ contract.end_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datepicker from '@/mixins/datepicker'

export default {
  name: 'ContractForm',
  mixins: [datepicker],
  model: {
    prop: 'contract',
    event: 'change'
  },
  props: {
    contract: Object
  }
}
</script>
