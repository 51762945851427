<template>
  <div>
    <div class="field m-b-15">
      <label for="artist_title" class="label">Artist Name</label>
      <input
        id="artist_title"
        type="text"
        class="input"
        placeholder="Artist Name"
        v-model="artist.name"
      >
    </div>
    <div class="field m-b-15">
      <label class="label">Contact</label>
      <div class="control">
        <multiselect
          v-model="artist.contact"
          :options="contacts"
          track-by="id"
          label="full_name"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
      <div class="field m-b-15">
        <label for="email" class="label">Email</label>
        <input
          id="email"
          type="text"
          class="input"
          placeholder="contact@wrios.com"
          v-model="artist.email"
        >
      </div>
      <div class="field m-b-15">
        <label for="mobile_phone" class="label">Mobile Phone</label>
        <input
          id="mobile_phone"
          type="text"
          class="input"
          placeholder="(615)555-1234"
          v-model="artist.mobile_phone"
        >
      </div>
      <div class="field m-b-15">
        <label for="website" class="label">Website</label>
        <input
          id="website"
          type="url"
          class="input"
          placeholder="http://www.wrios.com"
          v-model="artist.website"
        >
      </div>
      <div class="form-section p-t-30">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label for="address_line1" class="label">Address Line 1</label>
            <input
              id="address_line1"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="artist.address_line1"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_line2" class="label">Address Line 2</label>
            <input
              id="address_line2"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="artist.address_line2"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_city" class="label">City</label>
            <input
              id="address_city"
              type="text"
              class="input"
              placeholder="Nashville"
              v-model="artist.address_city"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_state" class="label">State</label>
            <input
              id="address_state"
              type="text"
              class="input"
              placeholder="TN"
              v-model="artist.address_state"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_zip" class="label">Zip Code</label>
            <input
              id="address_zip"
              type="text"
              class="input"
              placeholder="37203"
              v-model="artist.address_zip"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_country" class="label">Country</label>
            <input
              id="address_country"
              type="text"
              class="input"
              placeholder="USA"
              v-model="artist.address_country"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

import { CONTACT_ALL_FOR_FORM } from '@/store/actions'

export default {
  name: 'ArtistForm',
  components: {
    Multiselect
  },
  model: {
    prop: 'artist',
    event: 'change'
  },
  props: {
    artist: Object
  },
  created: function () {
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
  },
  computed: {
    contacts() {
      return this.$store.state.contact.contacts
    }
  }
}
</script>
