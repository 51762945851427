<template>
  <div v-if="$can.includes('read-notes')">
    <section class="single-info">
      <div class="level is-marginless is-mobile">
        <div class="level-left">
          <h3 class="m-b-30">Notes</h3>
        </div>
        <div class="level-right">
          <a @click.prevent="create" class="is-primary" v-if="$can.includes('create-notes')">
            <IconPlus/>
          </a>
        </div>
      </div>
      <transition enter-active-class="animated fadeIn faster">
        <div v-if="isCreating">
          <form class="form" @submit="save($event)">
            <div class="field m-b-15">
              <textarea-autosize
                v-model="noteInput"
                rows="1"
                class="textarea"
                placeholder="Enter note here..."
                v-on:keyup.enter.exact.native="save($event)"
                v-bind:disabled="isLoading"
              ></textarea-autosize>
            </div>
            <div class="field m-b-15">
              <button type="submit" class="button is-primary is-small">Save</button>
              <button @click.prevent="clearForms" class="button is-danger is-small m-l-5">Cancel</button>
            </div>
          </form>
        </div>
      </transition>
      <progress
        v-if="isLoading || !isInit"
        class="progress is-small is-primary component-progress-bar"
        max="100"
      ></progress>
      <transition enter-active-class="animated fadeIn faster">
        <div v-if="isInit">
          <div class="level" v-if="!notes || !notes.length">
            <p>
              No notes to display.
              <a
                @click.prevent="create"
                v-if="$can.includes('create-notes')"
              >Add a note here.</a>
            </p>
          </div>
          <div v-else>
            <div v-for="note in notes" :key="note.id">
              <article class="media note-article is-hoverable">
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>{{ note.created_by.full_name }}</strong>
                      <small class="m-l-10">{{ note.created_at | momentDateTime }}</small>
                      <small class="m-l-10" v-if="isEdited(note)">(Edited)</small>
                    </p>
                    <transition enter-active-class="animated fadeIn faster">
                      <div v-if="editingNote && editingNote.id === note.id">
                        <form class="form" @submit="update($event, note)">
                          <div class="field m-b-15">
                            <textarea-autosize
                              v-model="note.content"
                              rows="1"
                              class="textarea"
                              placeholder="Enter note here..."
                              v-on:keyup.enter.exact.native="update($event, note)"
                              v-bind:disabled="isLoading"
                            ></textarea-autosize>
                          </div>
                          <div class="field m-b-15">
                            <button type="submit" class="button is-primary is-small">Save</button>
                            <button
                              @click.prevent="clearForms"
                              class="button is-danger is-small m-l-5"
                            >Cancel</button>
                          </div>
                        </form>
                      </div>
                      <div v-else>
                        <p class="note-content">{{ note.content }}</p>
                      </div>
                    </transition>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a
                        class="level-item note-edit-button"
                        v-if="editable(note) && !(editingNote && editingNote.id === note.id) && $can.includes('update-notes')"
                        @click="edit(note)"
                      >
                        <IconEdit/>
                      </a>
                    </div>
                  </nav>
                </div>
                <div class="media-right">
                  <button
                    v-if="editable(note) && !(deletingNote && deletingNote.id === note.id) && $can.includes('delete-notes')"
                    @click.prevent="confirmDelete(note)"
                    class="delete"
                  ></button>
                  <transition enter-active-class="animated fadeIn faster">
                    <div
                      class="delete-confirmation"
                      v-if="deletingNote && deletingNote.id === note.id"
                    >
                      <p>Delete this note?</p>
                      <a @click="destroy" class="button is-danger is-small">Confirm</a>
                      <a @click="cancelDelete" class="button is-primary is-small m-l-10">Nevermind</a>
                    </div>
                  </transition>
                </div>
              </article>
            </div>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import NotesApi from '@/services/api/Notes'
import { IconPlus, IconEdit } from '@/Icons/IconBase'

export default {
  name: 'NoteControl',
  props: ['notableId', 'notableType'],
  components: {
    IconPlus,
    IconEdit
  },

  data() {
    return {
      notes: [],
      isCreating: false,
      noteInput: '',
      editingNote: null,
      isLoading: false,
      isInit: false,
      deletingNote: null
    }
  },

  created() {
    this.load()
  },

  watch: {
    notableId: function () {
      this.load()
    },

    notableType: function () {
      this.load()
    }
  },

  methods: {
    create() {
      this.clearForms()
      this.noteInput = ''
      this.isCreating = true
    },

    edit(note) {
      this.clearForms()
      this.editingNote = note
    },

    clearForms() {
      this.noteInput = ''
      this.editingNote = null
      this.deletingNote = null
      this.isCreating = false
      this.isLoading = false
    },

    editable(note) {
      return note.created_by.id === this.$store.getters.getProfile.id ||
        note.created_by.id === this.$store.getters.getActiveAccount.system_user_id
    },

    isEdited(note) {
      return note.created_at !== note.updated_at
    },

    load() {
      if (!this.notableId) {
        return false
      }

      this.isLoading = true
      let self = this

      NotesApi.getNotes(this.notableType, this.notableId)
        .then(resp => {
          self.notes = resp.data.data
          self.isLoading = false
          self.isInit = true
        })
        .catch(err => {
          self.$store.commit('error', 'Could not load notes data.')
          console.log(err)
        })
    },

    save($event) {
      $event.preventDefault()

      if (this.editingNote) {
        return this.update()
      }

      if (!this.noteInput || this.noteInput.length < 1) {
        this.clearForms()
        return
      }

      this.isLoading = true
      let self = this

      NotesApi.storeNote(this.notableType, this.notableId, this.noteInput)
        .then(resp => {
          self.notes.unshift(resp.data.data)
          self.clearForms()
        })
        .catch(err => {
          self.clearForms()
          alert(err)
        })
    },

    update($event, note) {
      $event.preventDefault()
      this.isLoading = true
      let self = this

      NotesApi.updateNote(note.id, note.content)
        .then(resp => {
          self.clearForms()
          if (self.notes.length) {
            let data = resp.data.data
            let index = self.notes.findIndex(note => note.id === data.id)
            self.notes[index].content = data.content
            self.notes[index].updated_at = data.updated_at
          }
        })
        .catch(err => {
          self.clearForms()
          alert(err)
        })
    },

    confirmDelete(note) {
      this.clearForms()
      this.deletingNote = note
    },

    cancelDelete() {
      this.deletingNote = null
    },

    destroy() {
      this.isLoading = true
      let id = this.deletingNote.id
      let self = this

      NotesApi.deleteNote(id)
        .then(resp => {
          if (self.notes.length) {
            let index = self.notes.findIndex(note => note.id === id)
            self.notes.splice(index, 1)
          }
          self.clearForms()
        })
        .catch(err => {
          self.clearForms()
          alert(err)
        })
    }
  }
}
</script>
