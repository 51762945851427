<template>
  <div class="form">
    <div class="field m-b-15">
      <label class="label">Pitched By</label>
      <div class="control">
        <multiselect v-model="pitch.pitch_by_user" :options="users" track-by="id" label="full_name"
          :value="pitch.pitch_by_user" :close-on-select="true"></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <div class="level m-b-0">
        <div class="level-left">
          <label class="label">Pitching To</label>
        </div>
        <div v-if="!isEditing" class="level-right">
          <div>
            <a class="is-primary tooltip is-tooltip-left" data-tooltip="Create Contact" @click="addContact"
              v-if="$can.includes('create-contacts')">
              <IconPlus style="height: 20px; width: 20px;" />
            </a>
          </div>
        </div>
      </div>
      <div class="control" :class="{'not-allowed' : isEditing}">
        <multiselect v-model="pitch.pitch_to_contact" :options="contacts" track-by="id" label="full_name"
          :close-on-select="true" :disabled="isEditing"></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Pitching For</label>
      <div class="control">
        <multiselect v-model="pitch.pitch_artists" :multiple="true" :taggable="true" @tag="addArtist"
          tag-placeholder="Artist not found. Press enter to create" :options="artists" track-by="id" label="name"
          :close-on-select="true"></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Pitch Date</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('pitch.date', pitch.date, 'Pitch Date', 'single')">
          <span v-if="pitch.date">
            {{ pitch.date | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>

    <div v-if="!pitch.status && $can.includes('send song pitch')" class="field m-b-15">
      <label class="label">Send Now?</label>
      <div class="control">
        <input class="m-r-5" type="radio" name="sendNow" v-model="sendNow" value="yes" @click="validateContactEmail(); sending();" required>
        <span class="tooltip is-tooltip-right tooltip-span-label"
          data-tooltip="Select 'Yes' if you would like to send the pitch">Yes</span>
        <input class="m-l-5 m-r-5" type="radio" name="sendNow" v-model="sendNow" @click="sending" value="no">
        <span class="tooltip is-tooltip-right tooltip-span-label"
          data-tooltip="Select 'NO' if you have already pitched">No</span>
      </div>
    </div>
    <div v-if="!$can.includes('send song pitch') || sendNow === 'no' || pitch.status" >
      <div class="field m-b-15">
        <label class="label">Status</label>
        <div class="control">
          <multiselect v-model="pitch.status" :options="['Sent', 'Pass', 'Took Copy', 'Request Hold', 'Cut']"
            :value="pitch.status" :close-on-select="true"></multiselect>
        </div>
      </div>
      <div class="field m-b-15" v-if="pitch.status == 'Request Hold'">
        <label for="primary_artist_hold" class="label">Primary Hold Artist</label>
        <input id="primary_artist_hold" type="text" class="input" placeholder="Artist Name"
          v-model="pitch.primary_artist_hold" autocomplete="off" required>
      </div>
      <div class="field m-b-15" v-if="pitch.status == 'Request Hold'">
        <label for="backup_artist_hold" class="label">Backup Hold Artist</label>
        <input id="backup_artist_hold" type="text" class="input" placeholder="Backup Artist Name"
          v-model="pitch.backup_artist_hold" autocomplete="off">
      </div>
    </div>
    <div v-if="sendNow === 'yes'">
      <div class="field m-b-15">
        <label class="label">Subject</label>
        <div class="control">
          <input class="input" v-model="pitch.subject" type="text" placeholder="Enter subject here...">
        </div>
      </div>
      <div class="field m-b-15">
        <label class="label">Message</label>
        <div class="control">
          <textarea class="input" v-model="pitch.message" placeholder="Enter message here..."></textarea>
        </div>
      </div>
      <div class="field m-b-15">
        <label class="label">Allow Audio Download?</label>
        <div class="control">
          <label class="radio">
            <input type="radio" name="allowAudio" v-model="pitch.allowAudioDownload" value="1" required>
            Yes
          </label>
          <label class="radio">
            <input type="radio" name="allowAudio" v-model="pitch.allowAudioDownload" value="0">
            No
          </label>
        </div>
      </div>
    </div>
    <form-modal type="contact" title="Add Contact" :model="contact" :isModal="isModal" v-on:clear-modal="clearModal"
      v-on:save-record="saveContact"></form-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {
  IconPlus
} from '@/Icons/IconBase'
import datepicker from '@/mixins/datepicker'

import {
  ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  USER_ALL,
  CREATE,
  CONTACT_CREATE
} from '@/store/actions'

export default {
  name: 'PitchForm',
  model: {
    prop: 'pitch',
    event: 'change'
  },
  mixins: [datepicker],
  data() {
    return {
      sendNow: '',
      isModal: false,
      contact: {}
      // isSendingNow: true
    }
  },
  components: {
    Multiselect,
    IconPlus
  },
  props: {
    pitch: Object,
    isSaving: Boolean,
    isSendingNow: Boolean
  },
  created: function () {
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    this.$store.dispatch(USER_ALL)
  },
  computed: {
    artists() {
      return this.$store.state.artist.artists
    },
    contacts() {
      return this.$store.state.contact.contacts
    },
    users() {
      return this.$store.state.user.users
    },
    primaryArtistHoldOptions() {
      return (Object.values(this.$store.state.artist.artists).map(artist => artist.name))
    },
    backupArtistHoldOptions() {
      return (Object.values(this.$store.state.artist.artists).map(artist => artist.name))
    },
    isEditing() {
      return !!this.$props.pitch.pitch_artist_names
    }
  },
  methods: {
    addArtist(newArtist) {
      let self = this
      this.$store.dispatch(CREATE, {
        resourceName: 'artist',
        data: {
          name: newArtist
        }
      })
        .then(resp => {
          if (self.pitch.pitch_artists) {
            let index = self.pitch.pitch_artists.length
            self.$set(self.pitch.pitch_artists, index, resp)
          } else {
            self.$set(self.pitch, 'pitch_artists', [])
            self.pitch.pitch_artists.push(resp)
          }
        })
    },
    addContact() {
      this.clearModal()
      this.isModal = !this.isModal
    },
    saveContact(data) {
      let self = this
      this.$store.dispatch(CONTACT_CREATE, data)
        .then(function (contact) {
          self.clearModal()
          self.pitch.pitch_to_contact = contact
        }, function (err) {
          self.clearModal()
          alert(err)
        })
    },
    validateContactEmail() {
      let self = this
      if (self.pitch.pitch_to_contact && self.pitch.pitch_to_contact.email == null) {
        alert('This pitch will NOT be sent. You have selected to send this to a contact with no email address. Please change your selection or update the contact information')
      }
    },
    clearModal() {
      this.contact = {}
      this.isModal = false
    },
    sending() {
      this.$emit('is-not-sending')
    }
  }
}
</script>
