<template>
  <div>
    <index-header title="Tags" :newButtonText="$can.includes('create-account-tags') ? 'New Account Tag' : null" v-on:click-new-button="create"></index-header>
    <div class="columns">
      <div class="column is-4">
        <h3>Account Tags</h3>
        <p class="is-italic has-text-grey-light m-b-10">Click a tag to view a list of tagged items.</p>
        <p></p>
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth">
              <tbody>
                <tr v-if="isCreating">
                  <td colspan="2">
                    <form @submit.prevent="store" class="form">
                      <div class="field has-addons">
                        <div class="control">
                          <input
                            type="text"
                            class="input"
                            placeholder="Name your tag..."
                            v-model="newTag.value"
                          >
                        </div>
                        <div class="control">
                          <button type="submit" class="button is-primary">SAVE</button>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
                <tr v-for="tag in localTags" :key="tag.id">
                  <td class="is-narrow" v-if="selectedTag.id !== tag.id">
                    <div
                      class="dropdown"
                      @click="dropdown(tag)"
                      v-bind:class="{ 'is-active': openMenu === tag.id }"
                    >
                      <div v-if="$can.includes('update-account-tags') || $can.includes('delete-account-tags')" class="dropdown-trigger">
                        <button class="button table-icon">
                          <span class="icon">
                            <IconMoreHorizontal/>
                          </span>
                        </button>
                      </div>
                      <div class="dropdown-menu" id="dropdown-menu">
                        <div class="dropdown-content">
                          <a v-if="$can.includes('update-account-tags')" @click.prevent="edit(tag)" class="dropdown-item">Edit Tag</a>
                          <a v-if="$can.includes('delete-account-tags')" @click.prevent="destroy(tag)" class="dropdown-item">Delete Tag</a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-if="selectedTag.id !== tag.id">
                    <span>
                      <router-link
                        :to="{name: 'user.account.tags.show', params: {id: tag.id, tag: tag}}"
                      >{{ tag.value }}</router-link>
                    </span>
                  </td>
                  <td colspan="2" v-if="selectedTag.id === tag.id">
                    <form @submit.prevent="update" class="form">
                      <div class="field has-addons">
                        <div class="control">
                          <input
                            type="text"
                            class="input"
                            placeholder="Name your tag..."
                            @input="selectedTag.value = $event.target.value"
                            :value="tag.value"
                          >
                        </div>
                        <div class="control">
                          <button type="submit" class="button is-primary">SAVE</button>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="column is-8">
        <transition name="custom-classes-transition" enter-active-class="animated fadeIn faster">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { IconMoreHorizontal } from '@/Icons/IconBase'
import { ACCOUNT_TAG_ALL, ACCOUNT_TAG_CREATE, ACCOUNT_TAG_UPDATE, ACCOUNT_TAG_DELETE } from '@/store/actions'

export default {
  name: 'Tags',

  data() {
    return {
      isCreating: false,
      selectedTag: {},
      localTags: {},
      newTag: {
        'scope': 'account',
        'value': ''
      },
      openMenu: null
    }
  },

  components: {
    IconMoreHorizontal
  },

  created: function () {
    this.$store.dispatch(ACCOUNT_TAG_ALL)
    this.localTags = Object.assign({}, this.accountTags)
  },

  computed: {
    accountTags() {
      return this.$store.state.accountTag.accountTags
    }
  },

  watch: {
    accountTags: function (newValue, oldValue) {
      this.localTags = newValue
    }
  },

  methods: {
    dropdown: function (tag) {
      if (this.openMenu === tag.id) {
        this.openMenu = null
      } else {
        this.openMenu = tag.id
      }
    },

    create: function () {
      this.selectedTag = {}
      this.newTag.value = ''
      this.isCreating = true
    },

    store: function () {
      let self = this
      if (this.newTag.value) {
        let duplicate = this.localTags.find(tag => tag.value === this.newTag.value)
        if (duplicate) {
          this.$store.dispatch('confirm', `The tag "${duplicate.value}" already exists. Do you want to create a duplicate tag?`).then(resp => {
            if (resp === true) {
              this.$store.dispatch(ACCOUNT_TAG_CREATE, this.newTag)
                .then(function (data) {
                  self.selectedTag = {}
                  self.isCreating = false
                }, function (err) {
                  self.$store.commit('error', err)
                })
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.$store.dispatch(ACCOUNT_TAG_CREATE, this.newTag)
            .then(function (data) {
              self.selectedTag = {}
              self.isCreating = false
            }, function (err) {
              self.$store.commit('error', err)
            })
        }
      } else {
        this.$store.commit('error', 'Please enter a value for the Tag.')
      }
    },

    edit: function (tag) {
      this.isCreating = false
      if (tag.id === this.selectedTag.id) {
        this.selectedTag = {}
      } else {
        this.selectedTag = Object.assign({}, tag)
      }
    },

    update: function () {
      if (this.selectedTag.value) {
        let duplicate = this.localTags.find(tag => tag.value === this.selectedTag.value)
        if (duplicate) {
          this.$store.dispatch('confirm', `The tag "${duplicate.value}" already exists. Do you want to create a duplicate tag?`).then(resp => {
            if (resp === true) {
              this.$store.dispatch(ACCOUNT_TAG_UPDATE, { id: this.selectedTag.id, data: this.selectedTag })
              let index = this.localTags.findIndex(tag => tag.id === this.selectedTag.id)
              this.localTags[index] = this.selectedTag
              this.selectedTag = {}
            } else {
              return null
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.$store.dispatch(ACCOUNT_TAG_UPDATE, { id: this.selectedTag.id, data: this.selectedTag })
          let index = this.localTags.findIndex(tag => tag.id === this.selectedTag.id)
          this.localTags[index] = this.selectedTag
          this.selectedTag = {}
        }
      } else {
        this.$store.commit('error', 'Please enter a value for the Tag.')
      }
    },

    destroy(tag) {
      this.$store.dispatch(ACCOUNT_TAG_DELETE, tag.id)
    }
  }
}
</script>
