<template>
  <a class="play-button" @click.prevent="playQueuedAudioFile">
    <IconPlay/>
  </a>
</template>

<script>
import { IconPlay } from '@/Icons/IconBase'
import { QUEUED_AUDIO_FILE_USER_ALL, AUDIO_PLAYER_LOAD, QUEUED_AUDIO_FILE_USER_UPDATE, QUEUED_AUDIO_FILE_USER_READ } from '@/store/actions'
import moment from 'moment'

export default {
  name: 'QueuePlayButton',
  props: ['queuedAudioFileId'],
  components: {
    IconPlay
  },
  methods: {
    playQueuedAudioFile() {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_USER_READ, this.queuedAudioFileId)
        .then(function (data) {
          if (data) {
            self.$store.dispatch(AUDIO_PLAYER_LOAD, { id: data.audio_file_id })
            self.$store.dispatch(QUEUED_AUDIO_FILE_USER_UPDATE, {
              id: data.id,
              data: {
                played_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
              }
            }).then(function () {
              self.$store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
            })
          }
        }, function (err) {
          self.$store.commit('error', 'Could not load audio file.')
          console.log(err)
        })
    }
  }
}
</script>
