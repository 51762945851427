<template>
  <tr class="is-clickable draggable-song" :id="uid">
    <td v-if="song.default_audio_file_id" class="table-icon-play is-narrow is-hidden-mobile">
      <draggable-song-handle
       :title="song.title"
       :uid="uid" :id="song.id"
       :active_hold_count="song.active_hold_count"
       :cut_count="song.cut_count"
       :audio_files="JSON.stringify(song.audio_files)"
       ></draggable-song-handle>
      <index-play-button :audioFileId="song.default_audio_file_id"></index-play-button>
    </td>
    <td  v-else class="table-icon-play is-narrow is-hidden-mobile">
      <a class="index-play-button is-disabled is-hidden-hover">
        <IconPlayStrikeThrough/>
      </a>
    </td>
    <!-- <td class="is-hidden-mobile">
      <div class="field">
        <input
          class="is-checkradio"
          v-bind:id="song.id"
          type="checkbox"
          name="selectedPlaylist"
          @change="toggleSelect"
          v-model="isSelected"
        >
        <label v-bind:for="song.id"></label>
      </div>
    </td> -->
    <td v-if="searchScope !== 'submitted'" class="table-status has-text-centered">
      <div
        class="dropdown is-left is-hidden-mobile fullwidth"
        id="dropdown"
        @click="activityIsOpen = !activityIsOpen"
        :class="{ 'is-active': activityIsOpen }"
        v-click-outside="closeActivity"
      >
        <div
          class="dropdown-trigger fullwidth activity-button"
          @mouseover="activityHover = true"
          @mouseleave="activityHover = false"
          v-bind:class="{ 'activity-active' : activityHover || activityIsOpen }"
        >
          <div class="fullwidth">
            <span class="fullwidth">
              <span v-if="song.active_hold_count" class="dot has-background-danger"></span>
              <span v-if="song.cut_count" class="dot has-background-primary"></span>
              <span v-if="song.release_count" class="dot has-background-success"></span>
              <span v-if="song.sync_count" class="dot has-background-warning"></span>
              <span class="has-text-grey-light is-size-7" v-if="!song.active_hold_count && !song.cut_count && !song.sync_count && !song.release_count">...</span>
            </span>
          </div>
        </div>
        <div class="dropdown-menu" id="dropdown-menu">
          <div class="dropdown-content">
            <a
              v-if="$can.includes('update-songs')"
              class="dropdown-item"
              @click="$emit('click-add-hold', song)"
            ><span class="dot has-background-danger m-r-1"></span> Add Hold</a>
            <a
              v-if="$can.includes('update-songs')"
              class="dropdown-item"
              @click="$emit('click-add-cut', song)"
            ><span class="dot has-background-primary m-r-1"></span> Add Cut</a>
            <a
              v-if="$can.includes('update-songs') && song.cut_count"
              class="dropdown-item"
              @click="$emit('click-add-release', song)"
            ><span class="dot has-background-success m-r-1"></span> Add Release</a>
            <a
              v-if="$can.includes('update-songs')"
              class="dropdown-item"
              @click="$emit('click-add-sync', song)"
            ><span class="dot has-background-warning m-r-1"></span> Add Sync</a>
          </div>
        </div>
      </div>
    </td>
    <td v-else @click="openSong" class="table-status is-hidden-mobile has-text-centered">
      <span v-if="song.submission_status === 'approved' " class="dot has-background-success"></span>
      <span v-else-if="song.submission_status === 'pending' " class="dot has-background-warning"></span>
      <span v-else-if="song.submission_status === 'denied' " class="dot has-background-danger"></span>
    </td>
    <td
      class="has-text-dark has-text-weight-bold table-block table-title"
      @click="openSong"
    >{{song.title}}</td>
    <td @click="openSong" v-if="columnIsActive(viewName, 'date_written')" class="table-block" data-th="Date Written:">{{ song.date_written | momentDate }}</td>
    <td @click="openSong" v-if="columnIsActive(viewName, 'date_modified')" class="is-hidden-mobile" data-th="Date Modified:">{{ song.date_modified | momentDate }}</td>
    <td @click="openSong" v-if="columnIsActive(viewName, 'song_rating') && !song.song_rating" class="is-hidden-mobile song-rating" data-th="Song Rating:"></td>
    <td @click="openSong" v-if="columnIsActive(viewName, 'song_rating') && song.song_rating == 1" class="is-hidden-mobile song-rating" data-th="Song Rating:">
      <IconStarFilledSongIndex />
    </td>
    <td @click="openSong" v-if="columnIsActive(viewName, 'song_rating') && song.song_rating == 2" class="is-hidden-mobile song-rating" data-th="Song Rating:">
      <IconStarFilledSongIndex />
      <IconStarFilledSongIndex />
      </td>
    <td @click="openSong" v-if="columnIsActive(viewName, 'song_rating') && song.song_rating == 3" class="is-hidden-mobile song-rating" data-th="Song Rating:">
      <IconStarFilledSongIndex />
      <IconStarFilledSongIndex />
      <IconStarFilledSongIndex />
      </td>
    <td @click="openSong" class="table-block" data-th="Songwriter(s):">{{song.songwriters}}</td>
    <td @click="openSong" class="is-hidden-mobile" data-th="Artist(s):">{{song.artists}}</td>
    <td class="is-narrow is-hidden-mobile">
      <a v-if="song.default_audio_file_id" @click="enqueueAudioFile(song.default_audio_file_id)" class="table-icon tooltip m-r-10" data-tooltip="Add to Queue">
        <IconPlus/>
      </a>
      <a v-if="!song.default_audio_file_id" class="table-icon m-r-10 is-disabled">
        <IconPlus/>
      </a>
      <a
        v-if="$can.includes('update-songs')"
        class="table-icon tooltip"
        data-tooltip="Quick Edit"
        @click="$emit('click-edit-button', song)"
      >
        <IconEdit/>
      </a>
    </td>
    <td class="is-narrow is-hidden-mobile">
      <div
        class="dropdown is-right"
        id="dropdown"
        :class="{ 'is-active': isOpen }"
        v-click-outside="close"
      >
        <div class="dropdown-trigger">
          <button class="button table-icon" @click="isOpen = !isOpen">
            <span class="icon">
              <IconMoreHorizontal/>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu">
          <div class="dropdown-content">
            <router-link
              class="dropdown-item"
              :to="{ name: 'songs.show', params: { id: song.id, propped: song }}"
            >Show Song</router-link>
            <a
              v-if="$can.includes('update-songs')"
              class="dropdown-item"
              @click="$emit('click-edit-button', song)"
            >Edit Song</a>
            <a
              v-if="$can.includes('delete-songs')"
              class="dropdown-item"
              @click="$emit('click-delete-dropdown', song)"
            >Delete Song</a>
            <a
              @click="$emit('click-download-lyrics-button', song)"
              class="dropdown-item"
            >Download Lyrics</a>
            <a
              v-if="$can.includes('download-song-audio')"
              @click="$emit('click-download-audio-button', song)"
              class="dropdown-item"
            >Download Audio</a>
            <hr v-if="song.default_audio_file_id" class="dropdown-divider">
            <!-- <a v-if="song.default_audio_file_id" class="dropdown-item">Add to Playlist</a> -->
            <a
              v-if="song.default_audio_file_id"
              @click="enqueueAudioFile(song.default_audio_file_id)"
              class="dropdown-item"
            >Add to Queue</a>
            <hr class="dropdown-divider">
            <a
              v-if="$can.includes('create-pitches')"
              @click="$emit('click-new-pitch-button', song)"
              class="dropdown-item"
            >Create Pitch</a>
            <a
              v-if="$can.includes('send song pitch')"
              @click="$emit('click-send-link-button', song)"
              class="dropdown-item"
            >Send Link</a>
            <a
              v-if="$can.includes('send song pitch')"
              @click="$emit('click-generate-pitch-link-button', song)"
              class="dropdown-item"
            >Generate Pitch Link</a>
          </div>
        </div>
      </div>
    </td>
    <td v-if="searchScope !== 'submitted'">
      <span class="song-mobile-activity">
        <span v-if="song.active_hold_count" class="dot has-background-danger m-r-1"></span>
        <span v-if="song.cut_count" class="dot has-background-primary m-r-1"></span>
        <span v-if="song.sync_count" class="dot has-background-warning m-r-1"></span>
        <span v-if="song.release_count" class="dot has-background-success"></span>
        <span v-if="!song.active_hold_count && !song.cut_count && !song.sync_count && !song.release_count"></span>
      </span>
    </td>
  </tr>
</template>

<script>
import { IconPlus, IconEdit, IconStarFilledSongIndex, IconMoreHorizontal, IconPlayStrikeThrough } from '@/Icons/IconBase'
import DraggableSongHandle from '@/components/DraggableSongHandle'
import columnPreferences from '@/mixins/columnPreferences'

export default {
  name: 'TableSongsRow',
  mixins: [columnPreferences],
  props: {
    song: Object,
    selectAll: Boolean,
    viewName: String
  },
  components: {
    IconPlus,
    IconEdit,
    IconStarFilledSongIndex,
    IconMoreHorizontal,
    IconPlayStrikeThrough,
    DraggableSongHandle
  },
  data() {
    return {
      isOpen: false,
      activityIsOpen: false,
      activityHover: false,
      isSelected: false,
      uid: null
    }
  },
  mounted() {
    this.uid = this._uid
  },
  watch: {
    selectAll: function (newValue) {
      this.isSelected = newValue
    },
    song: function () {
      this.uid = this._uid
    }
  },
  methods: {
    toggleSelect() {
      this.isSelected = !this.isSelected
    },

    close() {
      this.isOpen = false
    },

    closeActivity() {
      this.activityIsOpen = false
    },

    openSong() {
      this.$router.push({ name: 'songs.show', params: { id: this.song.id, propped: this.song } })
    },

    enqueueAudioFile(audioFileId) {
      this.$emit('click-add-to-queue', audioFileId)
      this.close()
    }
  },
  computed: {
    searchScope() {
      return this.$store.state.song.searchScope
    },
    audioFiles() {
      return JSON.stringify(this.$props.song.audio_files)
    }
  }
}
</script>
