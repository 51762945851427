<template>
  <div>
    <index-header title="Account Profile" :newButtonText="$can.includes('create-audio-file-types') ? 'New Audio File Type' : null" v-on:click-new-button="create"></index-header>
    <div class="columns">
      <div class="column is-4">
        <h3>Audio File Types</h3>
        <p></p>
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth">
              <tbody>
                <tr v-if="isCreating">
                  <td colspan="2">
                    <form @submit.prevent="store" class="form">
                      <div class="field has-addons">
                        <div class="control">
                          <input
                            type="text"
                            class="input"
                            placeholder="Name your audio type..."
                            v-model="newAudioFileType.name"
                          >
                        </div>
                        <div class="control">
                          <button type="submit" class="button is-primary">SAVE</button>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
                <tr v-for="type in localAudioFileTypes" :key="type.id">
                  <td class="is-narrow" v-if="selectedAudioFileType.id !== type.id">
                    <div
                      class="dropdown"
                      @click="dropdown(type)"
                      v-bind:class="{ 'is-active': openMenu === type.id }"
                    >
                      <div v-if="$can.includes('update-audio-file-types') || $can.includes('delete-audio-file-types')" class="dropdown-trigger">
                        <button class="button table-icon">
                          <span class="icon">
                            <IconMoreHorizontal/>
                          </span>
                        </button>
                      </div>
                      <div class="dropdown-menu" id="dropdown-menu">
                        <div class="dropdown-content">
                          <a v-if="$can.includes('update-audio-file-types')" @click.prevent="edit(type)" class="dropdown-item">Edit Audio File Type</a>
                          <a v-if="$can.includes('delete-audio-file-types')" @click.prevent="destroy(type)" class="dropdown-item">Delete Audio File Type</a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-if="selectedAudioFileType.id !== type.id">
                    <span>
                        {{ type.name }}
                    </span>
                  </td>
                  <td colspan="2" v-if="selectedAudioFileType.id === type.id">
                    <form @submit.prevent="update" class="form">
                      <div class="field has-addons">
                        <div class="control">
                          <input
                            type="text"
                            class="input"
                            placeholder="Name your audio type..."
                            @input="selectedAudioFileType.name = $event.target.value"
                            :value="type.name"
                          >
                        </div>
                        <div class="control">
                          <button type="submit" class="button is-primary">SAVE</button>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="column is-8">
        <transition name="custom-classes-transition" enter-active-class="animated fadeIn faster">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { IconMoreHorizontal } from '@/Icons/IconBase'
import { AUDIO_FILE_TYPE_ALL, AUDIO_FILE_TYPE_CREATE, AUDIO_FILE_TYPE_UPDATE, AUDIO_FILE_TYPE_DELETE } from '@/store/actions'

export default {
  name: 'AudioFileType',

  data() {
    return {
      isCreating: false,
      selectedAudioFileType: {},
      localAudioFileTypes: {},
      newAudioFileType: {
        'name': ''
      },
      openMenu: null
    }
  },

  components: {
    IconMoreHorizontal
  },

  created: function () {
    this.$store.dispatch(AUDIO_FILE_TYPE_ALL)
    this.localAudioFileTypes = Object.assign({}, this.audioFileTypes)
  },

  computed: {
    audioFileTypes() {
      return this.$store.state.audioFileType.audioFileTypes
    }
  },

  watch: {
    audioFileTypes: function (newValue, oldValue) {
      this.localAudioFileTypes = newValue
    }
  },

  methods: {
    dropdown: function (type) {
      if (this.openMenu === type.id) {
        this.openMenu = null
      } else {
        this.openMenu = type.id
      }
    },

    create: function () {
      this.selectedAudioFileType = {}
      this.newAudioFileType.name = ''
      this.isCreating = true
    },

    store: function () {
      let self = this
      if (this.newAudioFileType.name) {
        let duplicate = this.localAudioFileTypes.find(type => type.name === this.newAudioFileType.name)
        if (duplicate) {
          this.$store.dispatch('confirm', `The audio file type "${duplicate.name}" already exists. Do you want to create a duplicate?`).then(resp => {
            if (resp === true) {
              this.$store.dispatch(AUDIO_FILE_TYPE_CREATE, this.newAudioFileType)
                .then(function (data) {
                  self.selectedAudioFileType = {}
                  self.isCreating = false
                }, function (err) {
                  self.$store.commit('error', err)
                })
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.$store.dispatch(AUDIO_FILE_TYPE_CREATE, this.newAudioFileType)
            .then(function (data) {
              self.selectedAudioFileType = {}
              self.isCreating = false
            }, function (err) {
              self.$store.commit('error', err)
            })
        }
      } else {
        this.$store.commit('error', 'Please enter a name for the Audio File Type.')
      }
    },

    edit: function (type) {
      this.isCreating = false
      if (type.id === this.selectedAudioFileType.id) {
        this.selectedAudioFileType = {}
      } else {
        this.selectedAudioFileType = Object.assign({}, type)
      }
    },

    update: function () {
      if (this.selectedAudioFileType.name) {
        let duplicate = this.localAudioFileTypes.find(type => type.name === this.selectedAudioFileType.name)
        if (duplicate) {
          this.$store.dispatch('confirm', `The audio file type "${duplicate.name}" already exists. Do you want to create a duplicate?`).then(resp => {
            if (resp === true) {
              this.$store.dispatch(AUDIO_FILE_TYPE_UPDATE, { id: this.selectedAudioFileType.id, data: this.selectedAudioFileType })
              let index = this.localAudioFileTypes.findIndex(type => type.id === this.selectedAudioFileType.id)
              this.localAudioFileTypes[index] = this.selectedAudioFileType
              this.selectedAudioFileType = {}
            } else {
              return null
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.$store.dispatch(AUDIO_FILE_TYPE_UPDATE, { id: this.selectedAudioFileType.id, data: this.selectedAudioFileType })
          let index = this.localAudioFileTypes.findIndex(type => type.id === this.selectedAudioFileType.id)
          this.localAudioFileTypes[index] = this.selectedAudioFileType
          this.selectedAudioFileType = {}
        }
      } else {
        this.$store.commit('error', 'Please enter a value for the Audio File Type.')
      }
    },

    destroy(type) {
      let self = this
      this.$store.dispatch('confirm', `Do you really want to delete "${type.name}" from your accounts audio file types?`).then(resp => {
        if (resp === true) {
          self.$store.dispatch(AUDIO_FILE_TYPE_DELETE, type.id)
        } else {
          return null
        }
      })
    }
  }
}
</script>
