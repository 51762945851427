import Vue from 'vue'

import {
  CLEAR_STORE,
  ALBUM_LOADING,
  ALBUM_SUCCESS,
  ALBUM_ERROR,
  ALBUM_SET,
  ALBUM_SET_ALL,
  ALBUM_CURRENT_PAGE_SET,
  ALBUM_PER_PAGE_SET,
  ALBUM_TOTAL_PAGES_SET,
  ALBUM_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    albums: [],
    album: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: '',
    url: {
      base: 'albums',
      parentResource: 'account'
    }
  },

  mutations: {
    [ALBUM_LOADING]: (state) => {
      state.status = 'loading'
    },
    [ALBUM_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [ALBUM_ERROR]: (state) => {
      state.status = 'error'
    },
    [ALBUM_SET]: (state, resp) => {
      Vue.set(state, 'album', resp)
    },
    [ALBUM_SET_ALL]: (state, resp) => {
      Vue.set(state, 'albums', resp)
    },
    [ALBUM_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [ALBUM_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [ALBUM_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [ALBUM_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'album', {})
      Vue.set(state, 'albums', [])
    }
  }
}
