<template>
  <div class="table-actions level m-b-15">
    <div class="level-left">
      <div class="level-item">
        <slot></slot>
      </div>
    </div>
    <div class="level-right is-hidden-mobile">
      <div class="level-item">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="localPerPage">
                    <option>15</option>
                    <option>25</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(manageableColumns).length" class="level-item">
        <div class="control has-icons-left">
          <div class="select">
            <select v-model="selected" @change="columnOptionSelected($event)">
              <option value="0">Column Options</option>
              <option v-for="(column, index) in manageableColumns" :key="index" :value="index">
                <span v-if="columnIsActive(viewName, index)">Hide</span>
                <span v-else>Show</span>
                '{{column.display_name}}'
              </option>
            </select>
          </div>
          <span class="icon is-left">
            <IconLayout/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconLayout } from '@/Icons/IconBase'
import columnPreferences from '@/mixins/columnPreferences'

export default {
  name: 'TableActions',
  mixins: [columnPreferences],

  components: {
    IconLayout
  },

  props: {
    perPage: Number,
    viewName: String
  },

  data() {
    return {
      localPerPage: 15,
      selected: 0
    }
  },

  computed: {
    manageableColumns: function () {
      return this.defaultPreferences[this.viewName] ? this.defaultPreferences[this.viewName] : []
    }
  },

  created: function () {
    this.localPerPage = this.perPage
  },

  watch: {
    perPage: function (newValue, oldValue) {
      this.localPerPage = newValue
    },

    localPerPage: function (newValue) {
      this.updatePerPage(newValue)
    }
  },

  methods: {
    updatePerPage: function (value) {
      this.$emit('per-page-change', value)
    },

    columnOptionSelected() {
      let self = this
      let currentStatus = this.columnIsActive(this.viewName, this.selected)
      this.setColumnPreference(this.viewName, this.selected, !currentStatus)
      self.selected = 0
    }
  }
}
</script>
