<template>
  <div class="form">
    <div class="field m-b-15">
      <div class="level m-b-0">
        <div class="level-left">
          <label class="label">Pitch To</label>
        </div>
        <div class="level-right">
          <div>
            <a class="is-primary tooltip is-tooltip-left" data-tooltip="Create Contact" @click="addContact"
              v-if="$can.includes('create-contacts')">
              <IconPlus style="height: 20px; width: 20px;" />
            </a>
          </div>
        </div>
      </div>
      <div class="control">
        <multiselect v-model="playlist.pitch_to_contact" :options="contacts" track-by="id" label="name"
          :close-on-select="true"></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Pitch For</label>
      <div class="control">
        <multiselect v-model="playlist.pitch_artists" :multiple="true" :taggable="true" @tag="addArtist"
          tag-placeholder="Artist not found. Press enter to create" :options="artists" track-by="id" label="name"
          :close-on-select="true"></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Pitch Date</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('playlist.date', playlist.date, 'Pitch Date', 'single')">
          <span v-if="playlist.date">
            {{ playlist.date | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Which Songs?</label>
      <div class="control" v-for="song in songs" :key="song.id">
        <label class="label">
          <input type="checkbox" class="checkbox m-r-10" v-model="playlist.songs" :value="song" checked>
          {{song.title}}
        </label>
      </div>
    </div>
    <form-modal type="contact" title="Add Contact" :model="contact" :isModal="isModal" v-on:clear-modal="clearModal"
      v-on:save-record="saveContact"></form-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {
  IconPlus
} from '@/Icons/IconBase'
import datepicker from '@/mixins/datepicker'

import {
  ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  CREATE,
  CONTACT_CREATE
} from '@/store/actions'

export default {
  name: 'SendToPitchlogForm',
  model: {
    prop: 'playlist',
    event: 'change'
  },
  mixins: [datepicker],
  data() {
    return {
      songs: Object.assign({}, this.$props.playlist.songs),
      isModal: false,
      contact: {}
    }
  },
  components: {
    Multiselect,
    IconPlus
  },
  props: {
    playlist: Object,
    isSaving: Boolean
  },
  created: function () {
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
  },
  computed: {
    artists() {
      return this.$store.state.artist.artists
    },
    contacts() {
      return this.$store.state.contact.contacts
    }
  },
  mounted() {
    let selectedArtists = this.artists.filter(artist => this.playlist.pitch_for_artist_ids.indexOf(artist.id) !== -1)
    this.$set(this.playlist, 'pitch_artists', selectedArtists)
    let selectedContact = this.contacts.find(contact => contact.id === this.playlist.pitch_to_contact_id)
    this.$set(this.playlist, 'pitch_to_contact', selectedContact)
  },
  methods: {
    addArtist(newArtist) {
      let self = this
      this.$store.dispatch(CREATE, {
        resourceName: 'artist',
        data: {
          name: newArtist
        }
      })
        .then(resp => {
          if (self.playlist.pitch_artists) {
            let index = self.playlist.pitch_artists.length
            self.$set(self.playlist.pitch_artists, index, resp)
          } else {
            self.$set(self.playlist, 'pitch_artists', [])
            self.playlist.pitch_artists.push(resp)
          }
        })
    },
    addContact() {
      this.clearModal()
      this.isModal = !this.isModal
    },
    saveContact(data) {
      let self = this
      this.$store.dispatch(CONTACT_CREATE, data)
        .then(function (contact) {
          self.clearModal()
          self.playlist.pitch_to_contact = contact
        }, function (err) {
          self.clearModal()
          alert(err)
        })
    },

    clearModal() {
      this.contact = {}
      this.isModal = false
    }
  }
}
</script>
