import Vue from 'vue'
import axios from 'axios'

import {
  CONTRACT_SHARE_ALL_FOR_CONTRACT,
  CONTRACT_SHARE_CREATE,
  CONTRACT_SHARE_UPDATE,
  CONTRACT_SHARE_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  CONTRACT_SHARE_LOADING,
  CONTRACT_SHARE_SUCCESS,
  CONTRACT_SHARE_ERROR,
  CONTRACT_SHARE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    contractShare: {}
  },

  mutations: {
    [CONTRACT_SHARE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [CONTRACT_SHARE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [CONTRACT_SHARE_ERROR]: (state) => {
      state.status = 'error'
    },
    [CONTRACT_SHARE_SET]: (state, resp) => {
      Vue.set(state, 'contractShare', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'contractShare', {})
    }
  },

  actions: {
    [CONTRACT_SHARE_ALL_FOR_CONTRACT]: ({
      commit
    }, {
      contractId,
      type
    }) => {
      return new Promise((resolve, reject) => {
        commit(CONTRACT_SHARE_LOADING)

        let params = {}

        if (type) {
          params.type = type
        }

        axios({
          url: 'contracts/' + contractId + '/contract-shares',
          params: params
        })
          .then(resp => {
            commit(CONTRACT_SHARE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(CONTRACT_SHARE_ERROR)
            reject(err)
          })
      })
    },

    [CONTRACT_SHARE_CREATE]: ({
      commit
    }, {
      contractId,
      data
    }) => {
      return new Promise((resolve, reject) => {
        commit(CONTRACT_SHARE_LOADING)

        if (data.shareable) {
          data.shareable_id = data.shareable.id
        }
        if (data.administrator) {
          data.administrator_id = data.administrator.id
        }

        axios({
          method: 'post',
          url: 'contracts/' + contractId + '/contract-shares',
          data: data
        })
          .then(resp => {
            commit(CONTRACT_SHARE_SUCCESS)
            commit(CONTRACT_SHARE_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(CONTRACT_SHARE_ERROR)
            reject(err)
          })
      })
    },

    [CONTRACT_SHARE_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      return new Promise((resolve, reject) => {
        commit(CONTRACT_SHARE_LOADING)

        if (data.shareable) {
          data.shareable_id = data.shareable.id
        }
        if (data.administrator) {
          data.administrator_id = data.administrator.id
        }

        axios({
          method: 'put',
          url: 'contract-shares/' + id,
          data: data
        })
          .then(resp => {
            commit(CONTRACT_SHARE_SUCCESS)
            commit(CONTRACT_SHARE_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(CONTRACT_SHARE_ERROR)
            reject(err)
          })
      })
    },

    [CONTRACT_SHARE_DELETE]: ({
      commit
    }, {
      id
    }) => {
      return new Promise((resolve, reject) => {
        commit(CONTRACT_SHARE_LOADING)

        axios({
          method: 'delete',
          url: 'contract-shares/' + id
        })
          .then(resp => {
            commit(CONTRACT_SHARE_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(CONTRACT_SHARE_ERROR)
            reject(err)
          })
      })
    }

  }
}
