import axios from 'axios'

export default {

  getContracts(songwriterId, date = null) {
    let params = {}

    if (date) {
      params = {
        date: date
      }
    }

    return axios({
      url: 'songwriters/' + songwriterId + '/contracts',
      params: params
    })
  }

}
