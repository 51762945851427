<template>
  <div>
    <section class="index-header p-b-15 m-b-15">
      <div class="level index-actions">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title m-t-0">{{ songwriter.full_name }}</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <SongwriterEditButton
              :songwriter="songwriter"
              v-if="$can.includes('update-songwriters')"
            />
          </div>
          <div class="level-item">
            <a
              class="back-button has-text-grey-light tooltip is-tooltip-left"
              data-tooltip="Back to Songwriters"
              @click="close"
            >
              <IconArrowLeft/>
            </a>
          </div>
        </div>
      </div>
    </section>
    <div class="content single">
      <section class="section is-paddingless">
        <div class="columns is-marginless">
          <aside class="column is-3">
            <div class="aside-inner">
              <section class="m-b-30 single-aside-details--data">
                <fieldset disabled>
                  <div class="field">
                    <label class="label">Current Publishing Co.</label>
                    <p>{{ songwriter.current_publishing_company | orBlank('name') }}</p>
                  </div>
                  <div class="field">
                    <label class="label">PRO</label>
                    <p>{{ songwriter.pro | orBlank('name') }}</p>
                  </div>
                  <div class="field">
                    <label class="label">Email</label>
                    <p>{{ songwriter | orBlank('email') }}</p>
                  </div>
                  <div class="field">
                    <label class="label">Phone</label>
                    <p>{{ songwriter | orBlank('phone') }}</p>
                  </div>
                  <!-- <div class="field">
                    <label class="label">Website</label>
                    <p>
                      <a
                        v-if="songwriter.website"
                        v-bind:href="songwriter.website"
                        target="_blank"
                      >{{ songwriter.website }}</a>
                      <span v-else>...</span>
                    </p>
                  </div> -->
                  <!-- <div class="field">
                    <label class="label">Location</label>
                    <p>
                      <span v-if="songwriter.address_line1">
                        {{ songwriter.address_line1 }}
                        <br>
                      </span>
                      <span v-if="songwriter.address_line2">
                        {{ songwriter.address_line2 }}
                        <br>
                      </span>
                      <span v-if="songwriter.address_city">{{ songwriter.address_city }},</span>
                      <span v-if="songwriter.address_state">{{ songwriter.address_state }}</span>
                      <span class="m-l-5" v-if="songwriter.address_zip">{{ songwriter.address_zip }}</span>
                      <br>
                      {{ songwriter.address_country }}
                    </p>
                  </div> -->
                </fieldset>
              </section>
              <section class="m-b-30 single-aside-details--tags">
                <label class="label">Tags</label>
                <tag-control taggableType="songwriter" :taggableId="songwriter.id"/>
              </section>
            </div>
          </aside>
          <div class="column is-9 is-paddingless">
            <SongwriterContracts v-if="songwriter.id" :songwriterId="songwriter.id"></SongwriterContracts>
            <note-control :notableId="songwriter.id" notableType="songwriter"></note-control>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { SONGWRITER_READ } from '@/store/actions'
import store from '@/store'

import { IconArrowLeft } from '@/Icons/IconBase'
import SongwriterContracts from '@/components/SongwriterContracts'
import SongwriterEditButton from '@/components/SongwriterEditButton'

export default {
  name: 'SongwriterShow',
  components: {
    SongwriterContracts,
    SongwriterEditButton,
    IconArrowLeft
  },
  props: {
    propped: Object
  },

  data() {
    return {
      songwriter: null
    }
  },

  created() {
    this.songwriter = Object.assign({}, this.model)
  },

  computed: {
    model: function () {
      if (this.$store.state.songwriter.songwriter.id) return Object.assign({}, this.$store.state.songwriter.songwriter)
      if (this.propped) return Object.assign({}, this.propped)

      return {}
    }
  },

  watch: {
    model: function (newValue, oldValue) {
      this.songwriter = Object.assign({}, newValue)
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(SONGWRITER_READ, to.params.id)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(SONGWRITER_READ, to.params.id)
    next()
  },

  methods: {
    close() {
      this.$router.push({ name: 'songwriters.index' })
    }
  }
}
</script>
