<template>
  <div v-if="activeSearch" class="search-filter-controls">
    <div class="columns">
      <div class="column is-three-fifths">
        <div class="tags">
          <span v-if="searchQuery.title" class="tag is-primary">
            <b>Title:</b>
            &nbsp;{{searchQuery.title}}
            <button
              @click="removeFilter('title')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.creation_date" class="tag is-primary">
            <b>Date Created:</b>
            &nbsp;{{moment(searchQuery.creation_date.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.creation_date.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('creation_date')"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(song, index) in searchQuery.song_titles"
            :key="index"
            class="tag is-primary"
          >
            <b>Song Title:</b>
            &nbsp;{{song.title}}
            <button
              @click="removeArrayFilter('song_titles', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-if="searchQuery.pitch_to_contact"
            class="tag is-primary"
          >
            <b>Pitching To:</b>
            &nbsp;{{searchQuery.pitch_to_contact.full_name}}
            <button
              @click="removeFilter('pitch_to_contact')"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(pitch_for_artist, index) in searchQuery.pitch_artists"
            :key="pitch_for_artist.id"
            class="tag is-primary"
          >
            <b>Pitching For:</b>
            &nbsp;{{pitch_for_artist.name}}
            <button
              @click="removeArrayFilter('pitch_artists', index)"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.notes" class="tag is-primary">
            <b>Notes:</b>
            &nbsp;{{searchQuery.notes}}
            <button
              @click="removeFilter('notes')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.description" class="tag is-primary">
            <b>Description:</b>
            &nbsp;{{searchQuery.description}}
            <button
              @click="removeFilter('description')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.type" class="tag is-primary">
            <b>Type:</b>
            &nbsp;{{searchQuery.type}}
            <button
              @click="removeFilter('type', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(userTag, index) in searchQuery.userTag"
            :key="userTag.id"
            class="tag is-primary"
          >
            <b>User Tag:</b>
            &nbsp;{{userTag.value}}
            <button
              @click="removeArrayFilter('userTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(accountTag, index) in searchQuery.accountTag"
            :key="accountTag.id"
            class="tag is-primary"
          >
            <b>Account Tag:</b>
            &nbsp;{{accountTag.value}}
            <button
              @click="removeArrayFilter('accountTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(tempoTag, index) in searchQuery.tempoTag"
            :key="tempoTag.id"
            class="tag is-primary"
          >
            <b>Tempo:</b>
            &nbsp;{{tempoTag.value}}
            <button
              @click="removeArrayFilter('tempoTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(vocalTag, index) in searchQuery.vocalTag"
            :key="vocalTag.id"
            class="tag is-primary"
          >
            <b>Vocal:</b>
            &nbsp;{{vocalTag.value}}
            <button
              @click="removeArrayFilter('vocalTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(audioTag, index) in searchQuery.audioTag"
            :key="audioTag.id"
            class="tag is-primary"
          >
            <b>Audio:</b>
            &nbsp;{{audioTag.value}}
            <button
              @click="removeArrayFilter('audioTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(genreTag, index) in searchQuery.genreTag"
            :key="genreTag.id"
            class="tag is-primary"
          >
            <b>Genre:</b>
            &nbsp;{{genreTag.value}}
            <button
              @click="removeArrayFilter('genreTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(emotionTag, index) in searchQuery.emotionTag"
            :key="emotionTag.id"
            class="tag is-primary"
          >
            <b>Emotion:</b>
            &nbsp;{{emotionTag.value}}
            <button
              @click="removeArrayFilter('emotionTag', index)"
              class="delete is-small"
            ></button>
          </span>
        </div>
      </div>
      <div v-if="!Object.values(this.$data).every(o => o === null)" class="column">
        <div class="is-pulled-right">
          Filters being applied to
          <strong>all playlists</strong>.
          <span class="tag is-small is-primary m-l-5">
            <b>Clear All</b>
            <button @click="clearQuery()" class="delete is-small"></button>
          </span>
        </div>
        <div class="is-clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { PLAYLIST_REMOVE_QUERY_ARRAY_VALUE } from '@/store/mutations'

export default {
  name: 'SearchFilterControlPlaylists',

  props: {
    searchQuery: Object
  },

  data() {
    return {
      title: null,
      pitch_to_contact: null,
      pitch_artists: null,
      notes: null,
      description: null,
      song_titles: null,
      type: null,
      creation_date: null,
      userTag: null,
      accountTag: null,
      tempoTag: null,
      vocalTag: null,
      audioTag: null,
      genreTag: null,
      emotionTag: null
    }
  },

  created: function () {
    this.updateLocalValues(this.searchQuery)
  },

  computed: {
    query() {
      return {
        title: this.title,
        pitch_to_contact: this.pitch_to_contact,
        pitch_artists: this.pitch_artists,
        notes: this.notes,
        description: this.description,
        song_titles: this.song_titles,
        type: this.type,
        creation_date: this.creation_date,
        userTag: this.userTag,
        accountTag: this.accountTag,
        tempoTag: this.tempoTag,
        vocalTag: this.vocalTag,
        audioTag: this.audioTag,
        genreTag: this.genreTag,
        emotionTag: this.emotionTag
      }
    },

    activeSearch() {
      return !Object.values(this.searchQuery).every(function (value) { return value === null })
    }
  },

  watch: {
    searchQuery: function (newValue) {
      this.updateLocalValues(this.searchQuery)
    },

    query: function (newValue) {
      Object.keys(newValue).forEach(function (key) {
        if (newValue[key] === '') {
          newValue[key] = null
        }
      })

      this.updateSearchQuery(newValue)
    }
  },

  methods: {
    clearQuery: function () {
      Object.assign(this.$data, this.$options.data())
    },

    removeFilter: function (filter, secondFilter = null) {
      this.$data[filter] = null
      if (secondFilter) {
        this.$data[secondFilter] = null
      }
    },

    removeArrayFilter: function (field, index) {
      this.$store.commit(PLAYLIST_REMOVE_QUERY_ARRAY_VALUE, { field, index })
    },

    updateSearchQuery: function (query) {
      this.$emit('update-query', query)
    },

    updateLocalValues: function (obj) {
      this.title = obj.title
      this.pitch_to_contact = obj.pitch_to_contact
      this.pitch_artists = obj.pitch_artists
      this.notes = obj.notes
      this.description = obj.description
      this.song_titles = obj.song_titles
      this.type = obj.type
      this.creation_date = obj.creation_date
      this.userTag = obj.userTag
      this.accountTag = obj.accountTag
      this.tempoTag = obj.tempoTag
      this.vocalTag = obj.vocalTag
      this.audioTag = obj.audioTag
      this.genreTag = obj.genreTag
      this.emotionTag = obj.emotionTag
    }
  }
}
</script>
