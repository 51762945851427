<template>
  <div class="is-hidden-mobile">
    <a class="button is-primary is-inverted" @click.prevent="initiateExport">Export</a>
    <form-modal
      type="export"
      title="Select Columns To Export"
      :model="columnData"
      :isModal="isModal"
      :isExporting="isExporting"
      v-on:clear-modal="clearModal"
      v-on:save-record="createExport"
    ></form-modal>
  </div>
</template>

<script>
import { EXPORT_ALL_COLUMNS, EXPORT_CREATE } from '@/store/actions'

export default {
  name: 'ExportButton',

  props: ['type', 'search', 'scope'],

  data() {
    return {
      columnData: {},
      isModal: false,
      isExporting: false
    }
  },

  methods: {
    initiateExport() {
      let self = this
      this.$store.dispatch(EXPORT_ALL_COLUMNS, this.type)
        .then(function (data) {
          self.openModal(data)
        }, function (err) {
          self.$store.commit('error', 'Unable to export records of this type.')
          console.log(err)
        })
    },

    openModal(data) {
      this.columnData = data
      this.isModal = true
    },

    createExport(data) {
      this.isExporting = true
      data.search = this.search
      data.scope = this.scope
      data.type = this.type
      let self = this
      this.$store.dispatch(EXPORT_CREATE, data)
        .then(function () {
          self.isExporting = false
          self.clearModal()
          self.$store.commit('export', `Your export is in process.`)
        }, function (err) {
          self.isExporting = false
          self.clearModal()
          if (err.response) {
            self.$store.commit('error', err.response.data.message)
          } else {
            self.$store.commit('error', 'Export could not be processed.')
          }
        })
    },

    clearModal() {
      this.isModal = false
      this.columns = []
    }
  }
}
</script>
