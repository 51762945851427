<template>
  <span>
    <span class="table-sort-icon" v-if="column === name && order === 'asc'">
      <IconChevronUpSmall/>
    </span>
    <span class="table-sort-icon" v-if="column === name && order === 'desc'">
      <IconChevronDownSmall/>
    </span>
  </span>
</template>

<script>
import { IconChevronDownSmall, IconChevronUpSmall } from '@/Icons/IconBase'

export default {
  name: 'ColumnSortIndicator',
  props: ['name', 'column', 'order'],
  components: {
    IconChevronDownSmall,
    IconChevronUpSmall
  }
}
</script>
