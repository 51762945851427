<template>
  <div>
    <!-- <div class="field m-b-15">
      <label for="file_name" class="label">File Name (style this to change name only, not extension)</label>
      <input id="file_name" type="text" class="input" v-model="audioFile.file_name">
    </div>-->
    <div class="field m-b-15">
      <label for="type" class="label">Type</label>
        <div class="control">
          <multiselect
            v-model="audioFile.type"
            :taggable="true"
            @tag="addType"
            tag-placeholder="Type not found. Press enter to create"
            :options="audioFileTypes"
            track-by="id"
            label="name"
            :close-on-select="true"
          ></multiselect>
        </div>
    </div>
    <div class="field m-b-15">
      <label for="beats_per_minute" class="label">Beats Per Minute</label>
      <input id="beats_per_minute" type="number" class="input" v-model="audioFile.beats_per_minute" autocomplete="off">
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { AUDIO_FILE_TYPE_ALL, AUDIO_FILE_TYPE_CREATE } from '@/store/actions'

export default {
  name: 'AudioFileForm',
  components: {
    Multiselect
  },
  model: {
    prop: 'audioFile',
    event: 'change'
  },
  props: {
    audioFile: Object
  },
  created: function () {
    this.$store.dispatch(AUDIO_FILE_TYPE_ALL)
  },
  computed: {
    audioFileTypes() {
      return this.$store.state.audioFileType.audioFileTypes
    }
  },
  methods: {
    addType(newType) {
      let self = this
      this.$store.dispatch(AUDIO_FILE_TYPE_CREATE, { name: newType })
        .then(resp => {
          self.audioFile.type = resp
        })
    }
  }
}
</script>
