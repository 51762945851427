import moment from 'moment'

export default function (value) {
  if (!value) return '...'
  if (typeof value === 'object' && value.date.includes('00:00:00')) {
    return moment(value.date.split(' ')[0]).format('M/D/YYYY')
  } else {
    return moment(value, 'YYYY-MM-DD hh:mm:ss').format('M/D/YYYY')
  }
}
