import axios from 'axios'

export default {

  getSongwriters(accountId) {
    return axios({
      url: 'accounts/' + accountId + '/songwriters'
    })
  }

}
