<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#56C0EE"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <polygon points="5 3 19 12 5 21 5 3"></polygon>
    <line x1="20" y1="5" x2="1" y2="18"></line>

  </svg>
</template>

<script>
export default {
  name: 'IconPlay'
}
</script>
