import axios from 'axios'

export default {

  getEvents(audioFileId) {
    return axios({
      url: 'audio-files/' + audioFileId + '/audio-file-events'
    })
  },

  storeEvent(audioFileId, type, elapsedTime, sessionId, duration, contactId, pitchId) {
    axios({
      url: 'audio-files/' + audioFileId + '/audio-file-events',
      method: 'post',
      data: {
        type: type,
        elapsed_time: elapsedTime,
        session_id: sessionId,
        duration: duration,
        contact_id: contactId,
        pitch_id: pitchId
      }
    })
  }
}
