<template>
  <div>
    <section>
      <div class="level">
        <div class="level-left">
          <p class="has-text-weight-bold">Publisher Shares</p>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="create">Add Publisher Share</a>
        </div>
      </div>
      <div class="level" v-if="!contractShares.length">
        <p>
          No publisher shares.
          <a class="m-l-5" @click="create">Add a publisher share here.</a>
        </p>
      </div>
      <div class="level" v-else>
        <table class="table is-fullwidth is-responsive is-hoverable">
          <thead>
            <tr>
              <th>Shareholder</th>
              <th v-if="$can.includes('read-contract-publishing-split-percentage')">Split</th>
              <th>Administrator</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="is-clickable"
              v-for="contractShare in contractShares"
              :key="contractShare.id"
              @click="edit(contractShare)"
            >
              <td>{{ contractShare.shareable | orBlank('name') }}</td>
              <td v-if="$can.includes('read-contract-publishing-split-percentage')">{{ contractShare.percentage }}%</td>
              <td>{{ contractShare.administrator | orBlank('name') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <form-modal
      type="contractPublisherShare"
      :title="formTitle"
      :model="selectedContractShare"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import { CONTRACT_SHARE_ALL_FOR_CONTRACT, CONTRACT_SHARE_UPDATE, CONTRACT_SHARE_CREATE, CONTRACT_SHARE_DELETE } from '@/store/actions'

export default {
  name: 'ContractPublisherShares',

  props: ['contractId'],

  data() {
    return {
      shareType: 'publisher',
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      selectedContractShare: {},
      contractShares: []
    }
  },

  created() {
    this.load()
  },

  methods: {
    load() {
      if (this.contractId) {
        let self = this
        this.$store.dispatch(CONTRACT_SHARE_ALL_FOR_CONTRACT, {
          contractId: this.contractId,
          type: this.shareType
        }).then(function (data) {
          self.contractShares = data
        }, function (err) {
          alert(err)
        })
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Publisher Share'
      this.isModal = !this.isModal
    },

    edit(contractShare) {
      this.formCanDelete = true
      this.formTitle = 'Update Publisher Share'
      this.selectedContractShare = contractShare
      this.isModal = !this.isModal
    },

    save(input) {
      if (this.selectedContractShare.id) {
        let self = this
        this.$store.dispatch(CONTRACT_SHARE_UPDATE, {
          id: this.selectedContractShare.id,
          data: input
        }).then(function (data) {
          self.load()
        }, function (err) {
          self.$store.commit('error', err.response.data)
        })
      } else {
        let data = Object.assign(input, {
          shareable_type: 'publishing_company',
          type: this.shareType
        })
        let self = this
        this.$store.dispatch(CONTRACT_SHARE_CREATE, {
          contractId: this.contractId,
          data: data
        }).then(function (data) {
          self.load()
        }, function (err) {
          self.$store.commit('error', err.response.data)
        })
      }
      this.clearModal()
    },

    destroy(contractShare) {
      let self = this
      this.$store.dispatch(CONTRACT_SHARE_DELETE, { id: contractShare.id })
        .then(function () {
          self.load()
        }, function (err) {
          alert(err)
        })
      self.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedContractShare = {}
    }
  }
}
</script>
