<template>
<!-- <div v-if="$can.includes('read-cuts')"> -->
  <div>
    <section class="single-info">
      <div class="level is-mobile">
        <div class="level-left">
          <h3 class="m-b-30">Cuts</h3>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="create" v-if="$can.includes('update-songs')">
            <IconPlus/>
          </a>
        </div>
      </div>
      <div class="level" v-if="!cuts.length">
        <p>
          No cuts to display.
          <a @click="create" v-if="$can.includes('update-songs')">Add a cut here.</a>
        </p>
      </div>
      <div class="level" v-else>
        <table class="table is-responsive is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Date</th>
              <th>Artists</th>
              <th>Producers</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="cut in cuts"
              :key="cut.id"
              @click="edit(cut)"
              :class="{'is-clickable' : $can.includes('update-songs'), 'is-prohibited' : !$can.includes('update-songs') }"
            >
              <td>{{ cut.cut_date | momentDate }}</td>
              <td>
                {{ cut.artists | arrayString('name') | orBlank() }}
                <span v-if="cut.featured_artists.length"> feat. {{ cut.featured_artists | arrayString('name') | orBlank() }}</span>
              </td>
              <td>
                {{ cut.producer_contacts | arrayString('full_name') | orBlank() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <form-modal
      type="cut"
      :title="formTitle"
      :model="selectedCut"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconPlus } from '@/Icons/IconBase'

import { CUTS_SET } from '@/store/mutations'
import { CUT_ALL_FOR_SONG, CUT_UPDATE, CUT_CREATE, CUT_DELETE, RELEASE_ALL_FOR_SONG, SONG_UPDATE } from '@/store/actions'

export default {
  name: 'CutsSong',
  mixins: [uiHelper],
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      selectedCut: {},
      isSaving: false
    }
  },

  props: ['songId'],

  components: {
    IconPlus
  },

  destroyed: function () {
    this.$store.commit(CUTS_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.cut
    },

    cuts() {
      return this.$store.state.cut.cuts
    }
  },

  methods: {
    load() {
      if (this.songId) {
        this.$store.dispatch(CUT_ALL_FOR_SONG, this.songId)
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Cut'
      this.isModal = !this.isModal
    },

    edit(cut) {
      if (this.$can.includes('update-songs')) {
        this.formCanDelete = true
        this.formTitle = 'Update Cut'
        this.selectedCut = cut
        this.isModal = !this.isModal
      }
    },

    save(input) {
      let data = {
        artist_ids: input.artists ? input.artists.map(function (artist) { return artist.id }) : [],
        featured_artist_ids: input.featured_artists ? input.featured_artists.map(function (artist) { return artist.id }) : [],
        producer_contact_ids: input.producer_contacts ? input.producer_contacts.map(function (contact) { return contact.id }) : [],
        pitch_id: input.pitch ? input.pitch.id : null,
        cut_date: input.cut_date
      }

      if (this.selectedCut.id) {
        this.$store.dispatch(CUT_UPDATE, {
          id: this.selectedCut.id,
          data: data,
          songId: this.songId
        })
      } else {
        this.$store.dispatch(CUT_CREATE, {
          songId: this.songId,
          data: data
        })
      }
      this.$emit('child-updated')
      this.clearModal()
    },

    destroy(cut) {
      let self = this
      this.$store.dispatch(CUT_DELETE, { id: cut.id, songId: this.songId })
        .then(resp => {
          self.$store.dispatch(RELEASE_ALL_FOR_SONG, self.songId)
          self.$store.dispatch(SONG_UPDATE, {
            id: self.songId,
            data: null
          })
            .then(function () {
              self.clearModal()
            })
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedCut = {}
    }
  }
}
</script>
