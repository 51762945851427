<template>
  <div id="web-player">
    <section class="section web-player" :class="{ 'is-active': howl }">
      <div class="level ordered-mobile">
        <a class="close-button" @click.prevent="close">
          <IconX/>
        </a>
        <div class="level-item web-player--controls fl-4">
          <a class="button is-small is-rounded is-dark rewind" @click="rewind(10)">
            <IconRewind/>
          </a>
          <a
            class="button is-medium is-rounded is-dark pause"
            v-if="isPlaying"
            @click="playPause()"
          >
            <IconPause/>
          </a>
          <a class="button is-medium is-rounded is-dark play" v-else @click="playPause()">
            <IconPlay/>
          </a>
          <a class="button is-small is-rounded is-dark stop" @click="stop()">
            <IconSquare/>
          </a>
          <a class="button is-small is-rounded is-dark fast-forward" @click="fastForward(10)">
            <IconFastForward/>
          </a>
          <a
            v-if="nextAudioFile"
            class="button is-small is-rounded is-dark skip-forward"
            @click="next()"
          >
            <IconSkipForward/>
          </a>
          <div v-else class="button is-small is-rounded is-dark skip-forward-disabled">
            <IconSkipForward/>
          </div>

          <router-link
            :to="{ name: 'audio-player-queue' }"
            class="button is-small is-rounded is-dark play-queue"
            v-if="this.$store.getters.getActiveQueue === 'user'"
          >
            <IconList/>
          </router-link>
        </div>
        <div class="level-item has-text-left song-name fl-1">
          <p class="song-title">{{ currentAudioFile.song ? currentAudioFile.song.title : '' }}</p>
          <span class="m-l-5 song-filename">
            <small>({{ currentAudioFile.file_name }})</small>
          </span>
        </div>
        <div class="level-item web-player--progress fl-2">
          <div class="level">
            <div class="level-item m-r-10">
              <span class="time start-time is-marginless">{{ elapsedTime }}</span>
            </div>
            <div class="level-item position-click" @click="positionClick($event)">
              <progress
                class="progress is-small is-primary"
                v-bind:value="progress"
                max="100"
              >{{ progress }}%</progress>
            </div>
            <div class="level-item m-l-10">
              <span class="time end-time">{{ totalTime }}</span>
            </div>
          </div>
        </div>
        <div class="level-item web-player--volume fl-3">
          <div class="level">
            <div class="level-item m-r-10 volume-down" @click="setVolume(0)">
              <IconVolume/>
            </div>
            <div class="level-item position-click" @click="volumeClick($event)">
              <progress
                class="progress is-small is-white"
                v-bind:value="volumePercentage"
                max="1"
              >{{ volumePercentage }}%</progress>
            </div>
            <div class="level-item m-l-15 volume-up" @click="setVolume(1)">
              <IconVolume2/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { IconPlay, IconRewind, IconFastForward, IconSquare, IconVolume, IconVolume2, IconX, IconSkipForward, IconList, IconPause } from '@/Icons/IconBase'
import { Howl } from 'howler'
import { QUEUED_AUDIO_FILE_USER_ALL, QUEUED_AUDIO_FILE_USER_UPDATE, QUEUED_AUDIO_FILE_SESSION_ALL, QUEUED_AUDIO_FILE_SESSION_UPDATE, AUDIO_PLAYER_LOAD, NOTIFY_PITCHED_SONG_LISTENED_TO } from '@/store/actions'
import { AUDIO_PLAYER_CLEAR_STORE } from '@/store/mutations'
import AudioFileEventsApi from '@/services/api/AudioFileEvents'
import moment from 'moment'

export default {
  name: 'AudioPlayer',

  components: {
    IconPlay,
    IconRewind,
    IconFastForward,
    IconSkipForward,
    IconSquare,
    IconVolume,
    IconVolume2,
    IconX,
    IconList,
    IconPause
  },

  props: ['contactId', 'pitchId'],

  data() {
    return {
      howl: null,
      duration: 0,
      progress: 0,
      elapsed: 0,
      isMuted: false,
      volume: 1,
      playbackSessionId: null,
      playingEventInterval: 5,
      lastPlayingEventTime: null,
      latestPlayStartTime: null,
      isPlaying: false,
      nextAudioFile: null
    }
  },

  computed: {
    currentAudioFile: function () {
      return this.$store.state.audioPlayer.audioFile
    },

    totalTime: function () {
      let duration = this.duration
      let minutes = Math.floor(duration / 60)
      let seconds = Math.floor(duration - minutes * 60)
      return minutes + ':' + (new Array(2 + 1).join('0') + seconds).slice(-2)
    },

    elapsedTime: function () {
      let elapsed = this.elapsed
      let minutes = Math.floor(elapsed / 60)
      let seconds = Math.floor(elapsed - minutes * 60)
      return minutes + ':' + (new Array(2 + 1).join('0') + seconds).slice(-2)
    },

    volumePercentage: function () {
      if (this.isMuted) {
        return 0
      }
      return this.volume
    },

    queuedAudioFiles: function () {
      return this.$store.getters.getQueuedAudioFiles
    }
  },

  watch: {
    queuedAudioFiles: function (newValue) {
      if (newValue.length) {
        this.nextAudioFile = newValue[0]
      } else {
        this.nextAudioFile = null
      }
    },
    currentAudioFile: function (newValue) {
      this.unload()

      if (!newValue.url) {
        return
      }

      this.playbackSessionId = this.$store.getters.getSessionId
      this.lastPlayingEventTime = null

      let self = this

      this.howl = new Howl({
        src: this.currentAudioFile.url,
        volume: this.volume,
        onload: (id) => {
          console.log('onload fired')
          self.setDuration()

          if (self.volume === null) {
            self.setVolume(0.25)
          }

          self.play()
          self.updateProgress()
        },
        onloaderror: () => {
          console.log('onloaderror fired')
        },
        onplay: (id) => {
          console.log('onplay fired')
        },
        onplayerror: (id) => {
          console.log('onplayerror fired')
        },
        onpause: (id) => {
          console.log('onpause fired')
        },
        onstop: (id) => {
          self.lastPlayingEventTime = null
          console.log('onstop fired')
        },
        onend: (id) => {
          console.log('onend fired')
          self.next()
          self.isPlaying = false
          self.recordEvent('end', self.elapsed)
        },
        onmute: (id) => {
          console.log('onmute fired')
        },
        onvolume: (id) => {
          console.log('onvolume fired')
        },
        onrate: (id) => {
          console.log('onrate fired')
        },
        onseek: (id) => {
          console.log('onseek fired')
        },
        onfade: (id) => {
          console.log('onfade fired')
        },
        onunlock: (id) => {
          console.log('onunlock fired')
        }
      })
    }
  },

  methods: {
    close() {
      if (this.howl) {
        this.howl.fade(this.volume, 0, 250)
        this.$store.commit(AUDIO_PLAYER_CLEAR_STORE)
      }

      let self = this
      setTimeout(function () {
        self.unload()
        setTimeout(function () {
          self.duration = 0
          self.elapsed = 0
          self.progress = 0
        }, 500)
      }, 250)
    },

    unload() {
      if (this.howl) {
        this.howl.unload()
        this.howl = null
      }
    },

    updateProgress() {
      if (this.howl && this.howl.state() === 'loaded') {
        if (this.duration === 0) {
          this.progress = 0
        }
        this.progress = (this.howl.seek() / this.duration) * 100
        this.elapsed = this.howl.seek()

        if (this.howl.playing()) {
          this.executePlayingEvent(this.elapsed)
          requestAnimationFrame(this.updateProgress)
        }
      }
    },

    setDuration() {
      this.duration = this.howl.duration()
    },

    setVolume(value) {
      this.volume = value
      this.howl.volume(this.volume)
    },

    play() {
      this.recordEvent('play', this.elapsed)
      this.howl.play()
      this.latestPlayStartTime = this.howl.seek()
      this.updateProgress()
      this.isPlaying = true
    },

    seek(position) {
      this.howl.seek(position)
      this.latestPlayStartTime = this.howl.seek()
    },

    playPause() {
      if (this.howl.playing()) {
        this.recordEvent('pause', this.elapsed)
        this.isPlaying = false
        return this.howl.pause()
      }

      return this.play()
    },

    stop() {
      this.recordEvent('stop', this.elapsed)
      this.howl.stop()
      this.isPlaying = false
    },

    rewind(secs) {
      let currPosition = this.howl.seek()
      let newPosition = currPosition - parseInt(secs) > 0 ? currPosition - parseInt(secs) : 0
      this.recordEvent('rewind', newPosition)
      this.seek(newPosition)
    },

    fastForward(secs) {
      let currPosition = this.howl.seek()
      let newPosition = currPosition + parseInt(secs)
      let duration = this.howl.duration()
      if (newPosition < duration) {
        this.recordEvent('fast-forward', newPosition)
        this.seek(newPosition)
      } else {
        this.stop()
      }
    },

    positionClick(event) {
      let width = event.target.offsetWidth
      let clickPosition = event.offsetX
      let positionPercentage = clickPosition / width
      let duration = this.howl.duration()
      let newPosition = parseInt(duration * positionPercentage)

      if (newPosition < duration) {
        this.recordEvent('seek', newPosition)
        this.seek(newPosition)
      } else {
        this.stop()
      }
    },

    mute() {
      this.howl.mute(!this.isMuted)
      this.isMuted = !this.isMuted
    },

    volumeClick(event) {
      let width = event.target.offsetWidth
      let clickPosition = event.offsetX
      let volume = clickPosition / width
      console.log('Setting volume to ' + volume)

      this.setVolume(volume)
    },

    executePlayingEvent(currentTime) {
      let currentPlayDuration = currentTime - this.latestPlayStartTime

      // Don't record playing event if playing for less than 3 seconds
      if (currentPlayDuration < 3) {
        return
      }

      if (this.lastPlayingEventTime === null || currentTime - this.lastPlayingEventTime > this.playingEventInterval) {
        this.lastPlayingEventTime = currentTime
        this.recordEvent('playing', currentTime)
      }
    },

    recordEvent(type, currentTime) {
      const contactId = this.contactId ? this.contactId : null
      let pitchId = this.currentAudioFile.pitch_id ? this.currentAudioFile.pitch_id : null
      AudioFileEventsApi.storeEvent(this.currentAudioFile.id, type, currentTime, this.playbackSessionId, this.duration, contactId, pitchId)
    },

    next() {
      if (this.$store.getters.getActiveQueue === 'session') {
        this.incrementSessionQueue()
      }

      if (this.$store.getters.getActiveQueue === 'user') {
        this.incrementUserQueue()
      }
    },

    incrementSessionQueue() {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_ALL, this.$store.getters.getSessionId)
        .then(function (data) {
          if (data.length) {
            self.recordEvent('next', self.elapsed)
            self.$store.dispatch(AUDIO_PLAYER_LOAD, { id: data[0].audio_file_id, pitchId: data[0].pitch_id })
            if (data[0].pitch_id) {
              self.$store.dispatch(NOTIFY_PITCHED_SONG_LISTENED_TO, { song: data[0].audio_file.song.id, pitch: data[0].pitch_id })
            }
            self.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_UPDATE, {
              id: data[0].id,
              data: {
                played_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
              }
            }).then(function () {
              self.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_ALL, self.$store.getters.getSessionId)
            })
          }
        }, function (err) {
          self.$store.commit('error', 'Could not load audio player queue.')
          console.log(err)
        })
    },

    incrementUserQueue() {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
        .then(function (data) {
          if (data.length) {
            self.recordEvent('next', self.elapsed)
            self.$store.dispatch(AUDIO_PLAYER_LOAD, { id: data[0].audio_file_id })
            self.$store.dispatch(QUEUED_AUDIO_FILE_USER_UPDATE, {
              id: data[0].id,
              data: {
                played_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
              }
            }).then(function () {
              self.$store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
            })
          }
        }, function (err) {
          self.$store.commit('error', 'Could not load audio player queue.')
          console.log(err)
        })
    }
  }
}
</script>
