import Vue from 'vue'
import axios from 'axios'

import {
  ACCOUNT_TAG_ALL,
  ACCOUNT_TAG_CREATE,
  ACCOUNT_TAG_READ,
  ACCOUNT_TAG_UPDATE,
  ACCOUNT_TAG_DELETE
} from '../actions'

import {
  ACCOUNT_TAG_ADDED,
  ACCOUNT_TAG_LOADING,
  ACCOUNT_TAG_SUCCESS,
  ACCOUNT_TAG_ERROR,
  ACCOUNT_TAG_SET,
  ACCOUNT_TAGS_SET,
  ACCOUNT_TAG_CURRENT_PAGE_SET,
  ACCOUNT_TAG_PER_PAGE_SET,
  ACCOUNT_TAG_TOTAL_PAGES_SET,
  ACCOUNT_TAG_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    accountTags: [],
    accountTag: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [ACCOUNT_TAG_ADDED]: (state, tag) => {
      state.accountTags.unshift(tag)
    },
    [ACCOUNT_TAG_LOADING]: (state) => {
      state.status = 'loading'
    },
    [ACCOUNT_TAG_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [ACCOUNT_TAG_ERROR]: (state) => {
      state.status = 'error'
    },
    [ACCOUNT_TAG_SET]: (state, resp) => {
      Vue.set(state, 'accountTag', resp)
    },
    [ACCOUNT_TAGS_SET]: (state, resp) => {
      Vue.set(state, 'accountTags', resp)
    },
    [ACCOUNT_TAG_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [ACCOUNT_TAG_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [ACCOUNT_TAG_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [ACCOUNT_TAG_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    }
  },

  actions: {
    [ACCOUNT_TAG_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(ACCOUNT_TAG_LOADING)

      let accountId = getters.getActiveAccountId
      if (!accountId) {
        console.log('no account!')
        commit(ACCOUNT_TAG_ERROR)
        return
      }

      // let params = {
      //     page: state.currentPage,
      //     per_page: state.perPage
      // }

      // if (state.searchValue && state.searchValue.length) {
      //     params.search = state.searchValue
      // }

      axios({
        url: 'accounts/' + accountId + '/tags'
      })
        .then(resp => {
          commit(ACCOUNT_TAG_SUCCESS)
          commit(ACCOUNT_TAGS_SET, resp.data.data)
          commit(ACCOUNT_TAG_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(ACCOUNT_TAG_ERROR)
        })
    },

    [ACCOUNT_TAG_READ]: ({
      commit
    }, id) => {
      commit(ACCOUNT_TAG_LOADING)
      commit(ACCOUNT_TAG_SET, {})
      axios({
        url: 'tags/' + id
      })
        .then(resp => {
          commit(ACCOUNT_TAG_SUCCESS)
          commit(ACCOUNT_TAG_SET, resp.data.data)
        })
        .catch(resp => {
          commit(ACCOUNT_TAG_ERROR)
        })
    },

    [ACCOUNT_TAG_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(ACCOUNT_TAG_LOADING)
        commit(ACCOUNT_TAG_SET, {})

        let accountId = getters.getActiveAccountId

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/tags',
          data: data
        })
          .then(resp => {
            commit(ACCOUNT_TAG_SUCCESS)
            commit(ACCOUNT_TAG_ADDED, resp.data.data)
            commit(ACCOUNT_TAG_SET, resp.data.data)
            dispatch(ACCOUNT_TAG_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(ACCOUNT_TAG_ERROR)
            reject(err)
          })
      })
    },

    [ACCOUNT_TAG_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(ACCOUNT_TAG_LOADING)
      commit(ACCOUNT_TAG_SET, {})
      axios({
        method: 'put',
        url: 'tags/' + id,
        data: data
      })
        .then(resp => {
          commit(ACCOUNT_TAG_SUCCESS)
          commit(ACCOUNT_TAG_SET, resp.data.data)
          dispatch(ACCOUNT_TAG_ALL)
        })
        .catch(resp => {
          commit(ACCOUNT_TAG_ERROR)
        })
    },

    [ACCOUNT_TAG_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(ACCOUNT_TAG_LOADING)

      axios({
        method: 'delete',
        url: 'tags/' + id
      })
        .then(resp => {
          commit(ACCOUNT_TAG_SET, {})
          dispatch(ACCOUNT_TAG_ALL)
        })
        .catch(resp => {
          commit(ACCOUNT_TAG_ERROR)
        })
    }
  }
}
