<template>
  <section class="page-header is-paddingless is-marginless">
    <div class="level page-actions">
      <div class="level-left">
        <div class="level-item">
          <h1>{{ title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div v-if="importButtonText" class="level-item">
          <a class="button is-primary is-inverted" @click="$emit('click-import-button')">Import</a>
        </div>
        <div v-if="exportType" class="level-item">
          <export-button :type="exportType" :search="exportSearch" :scope="exportScope"></export-button>
        </div>
        <div class="level-item">
          <a
            v-if="newButtonText"
            id="modal"
            class="button is-primary"
            v-on:click="$emit('click-new-button')"
          >{{ newButtonText }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AdvancedSearchPageHeader',
  props: {
    title: String,
    newButtonText: String,
    importButtonText: String,
    exportScope: String,
    exportSearch: [String, Array, Object],
    exportType: String
  }
}
</script>
