<template>
  <div>
    <div class="field m-b-15">
      <div class="level m-b-0">
        <div class="level-left">
          <label class="label">Publishing Company</label>
        </div>
        <div class="level-right">
          <div>
            <a
              class="is-primary tooltip is-tooltip-left"
              data-tooltip="Create Publishing Company"
              @click="addPublishingCompany"
              v-if="$can.includes('create-publishing-companies')"
            >
              <IconPlus style="height: 20px; width: 20px;"/>
            </a>
          </div>
        </div>
      </div>
      <div class="control">
        <multiselect
          v-model="contractShare.shareable"
          :options="publishingCompanies"
          track-by="id"
          label="name"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label for="percentage" class="label">Split</label>
      <input
        id="percentage"
        type="number"
        max="100"
        class="input"
        v-model="contractShare.percentage"
        step="0.01"
      >
    </div>
    <div class="field m-b-15">
      <label for="songwriter_id" class="label">Administrator</label>
      <div class="control">
        <multiselect
          v-model="contractShare.administrator"
          :options="publishingCompanies"
          track-by="id"
          label="name"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
      <form-modal
      type="publishingCompany"
      title="Add Publishing Company"
      :model="publishingCompany"
      :isModal="isModal"
      :isSaving="isSaving"
      v-on:clear-modal="clearModal"
      v-on:save-record="savePublishingCompany"
    ></form-modal>
  </div>
</template>

<script>

import PublishingCompaniesApi from '@/services/api/PublishingCompanies'
import Multiselect from 'vue-multiselect'
import { PUBLISHING_COMPANY_CREATE } from '@/store/actions'
import { IconPlus } from '@/Icons/IconBase'

export default {
  name: 'ContractPublisherShareForm',
  components: {
    Multiselect,
    IconPlus
  },
  model: {
    prop: 'contractShare',
    event: 'change'
  },
  props: {
    contractShare: Object
  },

  data() {
    return {
      isModal: false,
      isSaving: false,
      publishingCompanies: [],
      publishingCompany: {}
    }
  },

  created() {
    this.getPublishingCompanies()
  },
  methods: {
    addPublishingCompany() {
      this.clearModal()
      this.isModal = !this.isModal
    },
    savePublishingCompany(data) {
      let self = this
      this.isSaving = true
      this.$store.dispatch(PUBLISHING_COMPANY_CREATE, data)
        .then(resp => {
          self.isSaving = false
          self.clearModal()
          self.$set(self.contractShare, 'shareable', resp)
          self.getPublishingCompanies()
        }, function (err) {
          self.isSaving = false
          self.clearModal()
          alert(err)
        })
    },
    clearModal() {
      this.project = {}
      this.isModal = false
    },
    getPublishingCompanies() {
      let self = this

      PublishingCompaniesApi.getCompanies(this.$store.getters.getActiveAccountId)
        .then(resp => {
          self.publishingCompanies = resp.data.data
        })
        .catch(err => {
          self.$store.commit('error', 'Could not load publishing company data.')
          console.log(err)
        })
    }
  }
}
</script>
