<template>
  <div v-if="activeSearch" class="search-filter-controls">
    <div class="columns">
      <div class="column is-three-fifths">
        <div class="tags">
          <span v-if="searchQuery.title" class="tag is-primary">
            <b>Title:</b>
            &nbsp;{{searchQuery.title}}
            <button
              @click="removeFilter('title')"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(songwriter, index) in searchQuery.songwriters"
            :key="index"
            class="tag is-primary"
          >
            <b>Songwriter:</b>
            &nbsp;{{songwriter.name}}
            <button
              @click="removeArrayFilter('songwriters', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(master_owner, index) in searchQuery.master_owners"
            :key="index"
            class="tag is-primary"
          >
            <b>Master Owner:</b>
            &nbsp;{{master_owner.name}}
            <button
              @click="removeArrayFilter('master_owners', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(record_label, index) in searchQuery.record_labels"
            :key="index"
            class="tag is-primary"
          >
            <b>Label:</b>
            &nbsp;{{record_label.name}}
            <button
              @click="removeArrayFilter('record_labels', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(artist, index) in searchQuery.artists"
            :key="index"
            class="tag is-primary"
          >
            <b>Artist:</b>
            &nbsp;{{artist.name}}
            <button
              @click="removeArrayFilter('artists', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(publishing_company, index) in searchQuery.publishing_companies"
            :key="index"
            class="tag is-primary"
            >
            <b>Publisher:</b>
            &nbsp;{{publishing_company.name}}
            <button
              @click="removeArrayFilter('publishing_companies', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(record_label_contact, index) in searchQuery.record_label_contacts"
            :key="index"
            class="tag is-primary"
          >
            <b>Label Contact:</b>
            &nbsp;{{record_label_contact.name}}
            <button
              @click="removeArrayFilter('record_label_contacts', index)"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.lyrics" class="tag is-primary">
            <b>Lyrics:</b>
            &nbsp;{{searchQuery.lyrics}}
            <button
              @click="removeFilter('lyrics')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.notes" class="tag is-primary">
            <b>Notes:</b>
            &nbsp;{{searchQuery.notes}}
            <button
              @click="removeFilter('notes')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.date_modified" class="tag is-primary">
            <b>Date Modified:</b>
            &nbsp;{{moment(searchQuery.date_modified.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.date_modified.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('date_modified')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.cut_date" class="tag is-primary">
            <b>Cut Date Added:</b>
            &nbsp;{{moment(searchQuery.cut_date.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.cut_date.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('cut_date')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.hold_date" class="tag is-primary">
            <b>Hold Date Added:</b>
            &nbsp;{{moment(searchQuery.hold_date.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.hold_date.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('hold_date')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.date_turned_in" class="tag is-primary">
            <b>Date Turned In:</b>
            &nbsp;{{moment(searchQuery.date_turned_in.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.date_turned_in.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('date_turned_in')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.date_written" class="tag is-primary">
            <b>Date Written:</b>
            &nbsp;{{moment(searchQuery.date_written.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.date_written.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('date_written')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.created_at" class="tag is-primary">
            <b>Date Added:</b>
            &nbsp;{{moment(searchQuery.created_at.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.created_at.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('created_at')"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.session_date" class="tag is-primary">
            <b>Session Date:</b>
            &nbsp;{{moment(searchQuery.session_date.start).format('MM/DD/YYYY')}} to {{moment(searchQuery.session_date.end).format('MM/DD/YYYY')}}
            <button
              @click="removeFilter('session_date')"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(release, index) in searchQuery.releases"
            :key="index"
            class="tag is-primary"
          >
            {{release.label}}
            <button
              @click="removeArrayFilter('releases', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(activity, index) in searchQuery.activity"
            :key="index"
            class="tag is-primary"
          >
            {{activity.label}}
            <button
              @click="removeArrayFilter('activity', index)"
              class="delete is-small"
            ></button>
          </span>
          <span v-if="searchQuery.song_rating" class="tag is-primary">
            <b>Song Rating:</b>
            &nbsp;{{searchQuery.song_rating.label}}
            <button @click="removeFilter('song_rating')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.with_lyrics" class="tag is-primary">
            <b>With Lyrics</b>
            <button @click="removeFilter('with_lyrics')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.without_lyrics" class="tag is-primary">
            <b>Without Lyrics</b>
            <button @click="removeFilter('without_lyrics')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.with_audio" class="tag is-primary">
            <b>With Audio</b>
            <button @click="removeFilter('with_audio')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.without_audio" class="tag is-primary">
            <b>Without Audio</b>
            <button @click="removeFilter('without_audio')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.without_pub_info" class="tag is-primary">
            <b>Without Publishing Info</b>
            <button @click="removeFilter('without_pub_info')" class="delete is-small"></button>
          </span>
          <span v-if="searchQuery.without_date_written" class="tag is-primary">
            <b>Without Date of Creation</b>
            <button @click="removeFilter('without_date_written')" class="delete is-small"></button>
          </span>
          <span
            v-for="(userTag, index) in searchQuery.userTag"
            :key="index"
            class="tag is-primary"
          >
            <b>User Tag:</b>
            &nbsp;{{userTag.value}}
            <button
              @click="removeArrayFilter('userTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(accountTag, index) in searchQuery.accountTag"
            :key="index"
            class="tag is-primary"
          >
            <b>Account Tag:</b>
            &nbsp;{{accountTag.value}}
            <button
              @click="removeArrayFilter('accountTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(tempoTag, index) in searchQuery.tempoTag"
            :key="index"
            class="tag is-primary"
          >
            <b>Tempo:</b>
            &nbsp;{{tempoTag.value}}
            <button
              @click="removeArrayFilter('tempoTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(vocalTag, index) in searchQuery.vocalTag"
            :key="index"
            class="tag is-primary"
          >
            <b>Vocal:</b>
            &nbsp;{{vocalTag.value}}
            <button
              @click="removeArrayFilter('vocalTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(audioTag, index) in searchQuery.audioTag"
            :key="index"
            class="tag is-primary"
          >
            <b>Audio:</b>
            &nbsp;{{audioTag.value}}
            <button
              @click="removeArrayFilter('audioTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(genreTag, index) in searchQuery.genreTag"
            :key="index"
            class="tag is-primary"
          >
            <b>Genre:</b>
            &nbsp;{{genreTag.value}}
            <button
              @click="removeArrayFilter('genreTag', index)"
              class="delete is-small"
            ></button>
          </span>
          <span
            v-for="(emotionTag, index) in searchQuery.emotionTag"
            :key="index"
            class="tag is-primary"
          >
            <b>Emotion:</b>
            &nbsp;{{emotionTag.value}}
            <button
              @click="removeArrayFilter('emotionTag', index)"
              class="delete is-small"
            ></button>
          </span>
        </div>
      </div>

      <div v-if="!Object.values(this.$data).every(o => o === null)" class="column">
        <div class="is-pulled-right">
          Filters being applied to
          <strong>all songs</strong>.
          <span class="tag is-small is-primary m-l-5">
            <b>Clear All</b>
            <button @click="clearQuery()" class="delete is-small"></button>
          </span>
        </div>
        <div class="is-clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { SONG_REMOVE_QUERY_ARRAY_VALUE } from '@/store/mutations'

export default {
  name: 'SearchFilterControlSongs',

  props: {
    searchQuery: Object
  },

  data() {
    return {
      title: null,
      songwriters: null,
      master_owners: null,
      artists: null,
      record_labels: null,
      publishing_companies: null,
      record_label_contacts: null,
      lyrics: null,
      notes: null,
      date_turned_in: null,
      date_written: null,
      date_modified: null,
      cut_date: null,
      hold_date: null,
      created_at: null,
      session_date: null,
      releases: null,
      activity: null,
      with_lyrics: null,
      without_lyrics: null,
      with_audio: null,
      without_audio: null,
      without_pub_info: null,
      without_date_written: null,
      song_rating: null,
      userTag: null,
      accountTag: null,
      tempoTag: null,
      vocalTag: null,
      audioTag: null,
      genreTag: null,
      emotionTag: null
    }
  },

  created: function () {
    this.updateLocalValues(this.searchQuery)
  },

  computed: {
    query() {
      return {
        title: this.title,
        songwriters: this.songwriters,
        master_owners: this.master_owners,
        artists: this.artists,
        record_labels: this.record_labels,
        publishing_companies: this.publishing_companies,
        record_label_contacts: this.record_label_contacts,
        lyrics: this.lyrics,
        notes: this.notes,
        date_turned_in: this.date_turned_in,
        date_written: this.date_written,
        date_modified: this.date_modified,
        cut_date: this.cut_date,
        hold_date: this.hold_date,
        created_at: this.created_at,
        session_date: this.session_date,
        releases: this.releases,
        activity: this.activity,
        with_lyrics: this.with_lyrics,
        without_lyrics: this.without_lyrics,
        with_audio: this.with_audio,
        without_audio: this.without_audio,
        without_pub_info: this.without_pub_info,
        without_date_written: this.without_date_written,
        song_rating: this.song_rating,
        userTag: this.userTag,
        accountTag: this.accountTag,
        tempoTag: this.tempoTag,
        vocalTag: this.vocalTag,
        audioTag: this.audioTag,
        genreTag: this.genreTag,
        emotionTag: this.emotionTag
      }
    },

    activeSearch() {
      return !Object.values(this.searchQuery).every(function (value) { return value === null })
    }
  },

  watch: {
    searchQuery: function (newValue) {
      this.updateLocalValues(this.searchQuery)
    },

    query: function (newValue) {
      Object.keys(newValue).forEach(function (key) {
        if (newValue[key] === '') {
          newValue[key] = null
        }
      })

      this.updateSearchQuery(newValue)
    }
  },

  methods: {

    clearQuery: function () {
      Object.assign(this.$data, this.$options.data())
    },

    removeFilter: function (filter, secondFilter = null) {
      this.$data[filter] = null
      if (secondFilter) {
        this.$data[secondFilter] = null
      }
    },

    removeArrayFilter: function (field, index) {
      this.$store.commit(SONG_REMOVE_QUERY_ARRAY_VALUE, { field, index })
    },

    updateSearchQuery: function (query) {
      this.$emit('update-query', query)
    },

    updateLocalValues: function (obj) {
      this.title = obj.title
      this.songwriters = obj.songwriters
      this.master_owners = obj.master_owners
      this.artists = obj.artists
      this.record_labels = obj.record_labels
      this.publishing_companies = obj.publishing_companies
      this.record_label_contacts = obj.record_label_contacts
      this.lyrics = obj.lyrics
      this.notes = obj.notes
      this.date_turned_in = obj.date_turned_in
      this.date_written = obj.date_written
      this.date_modified = obj.date_modified
      this.cut_date = obj.cut_date
      this.hold_date = obj.hold_date
      this.created_at = obj.created_at
      this.session_date = obj.session_date
      this.releases = obj.releases
      this.activity = obj.activity
      this.with_lyrics = obj.with_lyrics
      this.without_lyrics = obj.without_lyrics
      this.with_audio = obj.with_audio
      this.without_audio = obj.without_audio
      this.without_pub_info = obj.without_pub_info
      this.without_date_written = obj.without_date_written
      this.song_rating = obj.song_rating
      this.userTag = obj.userTag
      this.accountTag = obj.accountTag
      this.tempoTag = obj.tempoTag
      this.vocalTag = obj.vocalTag
      this.audioTag = obj.audioTag
      this.genreTag = obj.genreTag
      this.emotionTag = obj.emotionTag
    }
  }
}
</script>
