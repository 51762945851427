<template>
<transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="audio-file-select-modal" class="modal is-active" v-if="files">
      <div class="modal-background" @click="cancel"></div>

      <div class="modal-content card">
        <div class="card-content has-text-centered">
            <h4 class="title">Which audio file you would like to add?</h4>
            <div class="level">
              <div class="level-item">
                <table class="table is-responsive is-fullwidth is-hoverable">
                  <tbody>
                    <tr
                      v-for="file in files"
                      :key="file.id"
                      @click="add(file)"
                      class="is-clickable"
                    >
                      <td>{{ file.audio_file_name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="level">
              <div class="level-item">
                <a href="#" class="button is-primary is-inverted" @click="cancel">Cancel</a>
              </div>
            </div>
        </div>
      </div>

      <button class="modal-close is-large" @click="cancel"></button>
    </div>
  </transition>

</template>

<script>
import { AUDIO_FILE_SELECT_RESPONSE_SET, AUDIO_FILE_SELECT_SET } from '@/store/mutations'
export default {
  name: 'AudioFileSelect',

  computed: {
    files() {
      return this.$store.getters.getAudioFileSelectFiles
    }
  },
  methods: {
    cancel() {
      this.$store.commit(AUDIO_FILE_SELECT_RESPONSE_SET, false)
      this.$store.commit(AUDIO_FILE_SELECT_SET, null)
    },
    add(file) {
      console.log(file)
      this.$store.commit(AUDIO_FILE_SELECT_RESPONSE_SET, { message: true, audioFileId: file.id, songId: file.song_id })
      this.$store.commit(AUDIO_FILE_SELECT_SET, null)
    }
  }
}
</script>
