<template>
  <div class="content playlists">
    <progress v-if="isDownloading" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <advanced-search-index-header title="Playlists" newButtonText="New Playlist" exportType="playlist"
      :exportScope="searchScope" :exportSearch="searchQuery" v-on:click-new-button="create">
    </advanced-search-index-header>
    <section class="section is-paddingless">
      <div class="columns is-marginless ordered-mobile">
        <aside class="column is-3 has-background-light hero fl-2">
          <AsidePlaylists :searchQuery="searchQuery" v-on:update-query="handleQueryUpdate" />
        </aside>
        <main class="column is-paddingless fl-1">
          <SearchFilterControlPlaylists :searchQuery="searchQuery" v-on:update-query="handleQueryUpdate" />
          <div class="container p-b-90">
            <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
              <TabsPlaylists :scope="searchScope" v-on:set-search-scope="setSearchScope" />
            </table-actions>
            <table class="table is-hoverable is-fullwidth is-responsive">
              <TablePlaylistsHead :sortColumn="sortColumn" :sortOrder="sortOrder" :selectAll="selectAll"
                v-on:sort-change="updateSort" v-on:select-all="handleSelectAll" />
              <tbody>
                <TablePlaylistsRow v-for="playlist in playlists" :key="playlist.id" :playlist="playlist"
                  :selectAll="selectAll" v-on:click-edit-button="edit"
                  v-on:click-remove-playlist-button="removePlaylist" v-on:click-new-comp-email-button="sendComp"
                  v-on:click-new-playlist-pitch-button="sendPlaylistPitch" v-on:click-download-button="downloadZip"
                  v-on:click-duplicate-button="duplicatePlaylist" v-on:click-send-to-pitchlog-button="sendToPitchlog"
                  v-on:click-generate-playlist-pitch-link-button="generatePlaylistPitchLink"
                  v-on:click-generate-playlist-comp-link-button="generatePlaylistCompLink"
                  v-on:click-create-cd-labels-button="createCdLabels" v-on:add-to-queue="enqueuePlaylist">
                </TablePlaylistsRow>
              </tbody>
            </table>
            <pagination :currentPage="currentPage" :totalPages="totalPages" v-on:current-page-change="setCurrentPage">
            </pagination>
          </div>
        </main>
      </div>
    </section>
    <form-modal :type="formType" :title="formTitle" :message="modalMessage" :model="selectedPlaylist" :isModal="isModal"
      :canDelete="formCanDelete" v-on:clear-modal="clearModal" v-on:save-record="save" :isSaving="isSaving"
      v-on:delete-record="destroy"></form-modal>
  </div>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'

import AsidePlaylists from '@/components/Playlists/AsidePlaylists'
import TablePlaylistsHead from '@/components/Playlists/TablePlaylistsHead'
import TablePlaylistsRow from '@/components/Playlists/TablePlaylistsRow'
import TabsPlaylists from '@/components/Playlists/TabsPlaylists'
import SearchFilterControlPlaylists from '@/components/Playlists/SearchFilterControlPlaylists'
import PlaylistsApi from '@/services/api/Playlists'

import {
  ALL_FOR_FORM,
  PLAYLIST_ALL,
  PLAYLIST_READ,
  PLAYLIST_CREATE,
  PLAYLIST_DELETE,
  PLAYLIST_UPDATE,
  USER_TAG_ALL,
  ACCOUNT_TAG_ALL,
  CONTACT_ALL_FOR_FORM,
  EMAIL_CREATE,
  AUDIO_FILE_ZIP_DOWNLOAD,
  PLAYLIST_DUPLICATE,
  PLAYLIST_CD_LABEL_CREATE,
  PITCH_CREATE,
  QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST
} from '@/store/actions'

import {
  PLAYLIST_CURRENT_PAGE_SET,
  PLAYLIST_PER_PAGE_SET,
  PLAYLIST_SORT_SET,
  PLAYLIST_SEARCH_QUERY_SET,
  PLAYLIST_SEARCH_SCOPE_SET
} from '@/store/mutations'

export default {
  name: 'PlaylistIndex',
  mixins: [uiHelper],

  data() {
    return {
      currentPageMutation: PLAYLIST_CURRENT_PAGE_SET,
      perPageMutation: PLAYLIST_PER_PAGE_SET,
      sortMutation: PLAYLIST_SORT_SET,
      searchQueryMutation: PLAYLIST_SEARCH_QUERY_SET,
      searchScopeMutation: PLAYLIST_SEARCH_SCOPE_SET,
      formTitle: 'Create Playlist',
      formType: null,
      formCanDelete: false,
      isModal: false,
      selectedPlaylist: {},
      modalMessage: null,
      isSaving: false,
      selectAll: false,
      isDownloading: false
    }
  },
  components: {
    AsidePlaylists,
    TablePlaylistsHead,
    TablePlaylistsRow,
    TabsPlaylists,
    SearchFilterControlPlaylists
  },

  created: function () {
    this.$store.dispatch(USER_TAG_ALL)
    this.$store.dispatch(ACCOUNT_TAG_ALL)
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    this.$store.dispatch(PLAYLIST_ALL)
  },

  computed: {
    resourceState() {
      return this.$store.state.playlist
    },

    currentPage() {
      return this.$store.state.playlist.currentPage
    },

    totalPages() {
      return this.$store.state.playlist.totalPages
    },

    searchQuery() {
      return this.$store.getters.getPlaylistSearchQuery
    },

    playlists() {
      return this.$store.state.playlist.playlists
    },

    searchScope() {
      return this.$store.state.playlist.searchScope
    }
  },
  beforeCreate: function () {
    document.body.className = 'playlists'
  },

  methods: {
    handleQueryUpdate(query) {
      this.setSearchQuery(query, this)
    },

    handleSelectAll(event) {
      this.selectAll = event.target.checked
    },

    load() {
      this.selectAll = false
      this.$store.dispatch(PLAYLIST_ALL)
    },

    create() {
      if (this.$can.includes('create-playlists')) {
        this.clearModal()
        this.formTitle = 'New Playlist'
        this.formType = 'playlist'
        this.isModal = !this.isModal
      } else {
        this.$store.commit('error', 'You are not permitted to perform this action')
      }
    },

    edit(playlist) {
      PlaylistsApi.getPlaylist(playlist.id)
        .then(resp => {
          this.clearModal()
          this.formType = 'playlist'
          this.formTitle = 'Edit Playlist'
          this.formCanDelete = true
          this.isModal = !this.isModal
          this.selectedPlaylist = resp.data.data
          this.$store.dispatch(ALL_FOR_FORM, 'artist')
          this.$store.dispatch(CONTACT_ALL_FOR_FORM)
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('error', 'Could not load playlist data.')
        })
    },

    sendComp(playlist) {
      this.clearModal()
      this.formTitle = `New Comp - ${playlist.title}`
      this.formType = 'compEmail'
      this.modalMessage = 'You can add more details on the next screen.'
      this.selectedPlaylist = playlist
      this.isModal = true
    },

    sendPlaylistPitch(playlist) {
      this.clearModal()
      this.formTitle = `New Pitch - ${playlist.title}`
      this.formType = 'playlistPitch'
      this.selectedPlaylist = playlist
      this.isModal = true
    },

    sendToPitchlog(playlist) {
      this.clearModal()
      this.formTitle = `Send To Pitchlog`
      this.formType = 'sendToPitchlog'
      this.selectedPlaylist = playlist
      this.isModal = true
    },

    generatePlaylistPitchLink(playlist) {
      this.clearModal()
      this.formTitle = `New Pitch Link For - ${playlist.title}`
      this.formType = 'playlistPitchLink'
      this.selectedPlaylist = playlist
      this.isModal = true
    },

    generatePlaylistCompLink(playlist) {
      this.clearModal()
      this.formTitle = `New Comp Link For - ${playlist.title}`
      this.formType = 'compLink'
      this.modalMessage = 'You can add more details on the next screen.'
      this.selectedPlaylist = playlist
      this.isModal = true
    },

    createCdLabels(playlist) {
      this.clearModal()
      this.formTitle = `Create CD Label For - ${playlist.title}`
      this.formType = 'playlistCdLabel'
      this.selectedPlaylist = playlist
      this.isModal = true
    },

    saveCompEmail(data) {
      let self = this
      return this.$store.dispatch(EMAIL_CREATE, {
        type: 'playlist',
        id: data.id,
        allowAudioDownload: data.allowAudioDownload,
        linkOnly: false,
        subject: data.subject,
        body: data.message,
        contactIds: data.pitch_to_contact.length ? (Object.values(data.pitch_to_contact).map(contact => contact.id)) : [data.pitch_to_contact.id],
        pitchForArtists: []
      })
        .then(function (data) {
          self.isSaving = false
          self.clearModal()
          self.$store.commit('success', `Playlist sent successfully!`)
        })
    },

    saveCompLink(data) {
      let self = this
      return this.$store.dispatch(EMAIL_CREATE, {
        type: 'playlist',
        id: data.id,
        allowAudioDownload: data.allowAudioDownload,
        linkOnly: true,
        subject: '',
        body: '',
        contactIds: [],
        pitchForArtists: []
      })
        .then(function (data) {
          self.isSaving = false
          self.clearModal()
          let emailRecord = data[0]
          self.$store.commit('modalMessage', `<div class="message-title">Link to the Playlist</div><div class="message-url">${emailRecord.link}</div>`)
        })
    },

    savePlaylist(data) {
      let self = this

      if (this.selectedPlaylist.id) {
        let updateData = {
          title: data.title,
          creation_date: data.creation_date,
          pitch_to_contact_id: data.pitch_to_contact ? data.pitch_to_contact.id : null,
          shared: data.shared ? data.shared : false,
          comps: data.comps ? data.comps : false,
          artist_ids: data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(
            function (artist) {
              return artist.id
            }) : data.pitch_artists ? [data.pitch_artists.id] : []
        }

        return this.$store.dispatch(PLAYLIST_UPDATE, {
          id: data.id,
          data: updateData
        })
          .then(function (response) {
            self.isSaving = false
            self.clearModal()
            // there were some inconsistencies with the updates propagating
            // forcing update of component, loading the playlists,
            // running search to ensure updates display on index
            self.$forceUpdate()
            self.load()
            self.handleQueryUpdate(self.searchQuery)
          })
      }

      this.$store.dispatch(PLAYLIST_CREATE, data)
        .then(function (data) {
          self.isSaving = false
          self.clearModal()
        }, function (err) {
          self.isSaving = false
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    savePlaylistCdLabel(data) {
      let self = this
      return this.$store.dispatch(PLAYLIST_CD_LABEL_CREATE, data)
        .then(function (data) {
          self.isSaving = false
          self.clearModal()
        })
    },

    savePlaylistPitch(data) {
      let self = this
      this.$store.dispatch(EMAIL_CREATE, {
        type: 'pitch_group',
        id: data.id,
        allowAudioDownload: data.allowAudioDownload,
        linkOnly: false,
        subject: data.subject,
        body: data.message,
        contactIds: [data.pitch_to_contact.id],
        pitchForArtists: data.pitch_artists,
        playlistId: data.id,
        force: data.force
      })
        .then(function (data) {
          self.isSaving = false
          self.clearModal()
          self.$router.push({
            name: 'pitches.index'
          })
          self.$store.commit('success', `Playlist sent successfully!`)
        }, function (err) {
          if (err.response.data.errors && err.response.data.errors['duplicate_pitch'] !== undefined) {
            self.isModal = false
            self.$store.dispatch('confirm',
              `Similar pitches were previously created for this playlist.  Do you want to create new pitches?<br><br><strong>Previously-Created Playlist Link</strong><br><span class="message-url">${err.response.data.errors['link']}</span>`
            ).then(resp => {
              if (resp === true) {
                data.force = true
                self.savePlaylistPitch(data)
              } else {
                self.clearModal()
              }
            }).catch(err => {
              self.clearModal()
              console.log(err)
            })
          } else {
            self.$store.commit('alert', `Playlist pitch could not be created.`)
          }
        })
    },

    savePlaylistPitchLink(data) {
      let self = this
      return this.$store.dispatch(EMAIL_CREATE, {
        type: 'pitch_group',
        id: data.id,
        allowAudioDownload: data.allowAudioDownload,
        linkOnly: true,
        subject: data.subject,
        body: data.message,
        contactIds: [data.pitch_to_contact.id],
        pitchForArtists: data.pitch_artists,
        playlistId: data.id,
        force: data.force
      })
        .then(function (emails) {
          self.isSaving = false
          self.clearModal()
          let emailRecord = emails[0]
          self.$store.commit('modalMessage', `<div class="message-title">Link to the Playlist</div><div class="message-url">${emailRecord.link}</div>`)
        }, function (err) {
          if (err.response.data.errors && err.response.data.errors['duplicate_pitch'] !== undefined) {
            self.isModal = false
            self.$store.dispatch('confirm',
              `Similar pitches and a pitch link were previously created for this playlist.  Do you want to create new pitches and generate a new link?<br><br><strong>Previously-Generated Link</strong><br><span class="message-url">${err.response.data.errors['link']}</span>`
            ).then(resp => {
              if (resp === true) {
                data.force = true
                self.savePlaylistPitchLink(data)
              } else {
                self.clearModal()
              }
            }).catch(err => {
              self.clearModal()
              console.log(err)
            })
          } else {
            self.$store.commit('alert', `Playlist pitch link could not be created.`)
          }
        })
    },

    saveSendToPitchLog(data) {
      let self = this
      const pitches = []
      data.pitchlog = true
      data.songs.forEach(song => {
        pitches.push(this.$store.dispatch(PITCH_CREATE, {
          songId: song.id,
          data: data
        }))
      })

      return Promise.all(pitches)
        .then(resp => {
          self.isSaving = false
          self.clearModal()
          self.$router.push({
            name: 'pitches.index'
          })
        })
    },

    save(data) {
      this.isSaving = true

      if (this.formType === 'compEmail') {
        return this.saveCompEmail(data)
      }

      if (this.formType === 'compLink') {
        return this.saveCompLink(data)
      }

      if (this.formType === 'playlist') {
        return this.savePlaylist(data)
      }

      if (this.formType === 'playlistCdLabel') {
        return this.savePlaylistCdLabel(data)
      }

      if (this.formType === 'playlistPitch') {
        return this.savePlaylistPitch(data)
      }

      if (this.formType === 'playlistPitchLink') {
        return this.savePlaylistPitchLink(data)
      }

      if (this.formType === 'sendToPitchlog') {
        return this.saveSendToPitchLog(data)
      }

      this.clearModal()
    },

    destroy(playlist) {
      let self = this
      this.isSaving = true
      this.$store.dispatch(PLAYLIST_DELETE, playlist.id)
        .then(function (resp) {
          self.isSaving = false
          self.clearModal()
          self.$store.commit('success', `Playlist - "${playlist.title}" deleted`)
        }, function (resp) {
          self.$store.commit('error', 'Unable to delete playlist.')
        })
    },

    clearModal() {
      this.isModal = false
      this.formCanDelete = false
      this.formTitle = ''
      this.selectedPlaylist = {}
      this.modalMessage = null
    },

    downloadZip(playlist) {
      let self = this
      this.isDownloading = true
      this.$store.dispatch(PLAYLIST_READ, playlist.id)
        .then(function (data) {
          if (!data.audio_files.length) {
            self.$store.commit('alert', 'The selected playlist has no songs in it.')
            self.isDownloading = false
            return
          }
          self.$store.dispatch(AUDIO_FILE_ZIP_DOWNLOAD, {
            title: data.title,
            audioFileIds: data.audio_files.map(audioFile => audioFile.id)
          })
            .then(function (resp) {
              self.isDownloading = false
            }).catch(function (err) {
              self.isDownloading = false
              self.$store.commit('error', err)
            })
        }, function (err) {
          self.isDownloading = false
          self.$store.commit('error', 'Could not load playlist.')
          console.log(err)
        })
    },

    duplicatePlaylist(playlist) {
      let self = this
      this.isSaving = true
      this.$store.dispatch(PLAYLIST_DUPLICATE, playlist.id)
        .then(function (data) {
          self.isSaving = false
        }, function (err) {
          self.isSaving = false
          alert(err)
        })
    },

    enqueuePlaylist(playlist) {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_USER_CREATE_BY_PLAYLIST, {
        playlist_id: playlist.id,
        overwrite: false
      })
        .then(function (data) {
          self.$store.commit('queue', `${playlist.title} added to your queue! `)
        })
        .catch(function (err) {
          self.$store.commit('error', `could not add to queue ${err}`)
        })
    },

    removePlaylist(playlist) {
      let self = this
      let playlistTitle = playlist.title
      self.$store.dispatch('confirm', `Are you sure you want to remove "${playlistTitle}"?`).then(resp => {
        if (resp === true) {
          this.$store.dispatch(PLAYLIST_DELETE, playlist.id)
            .then(function (resp) {
              self.$store.commit('success', `"${playlistTitle}" removed successfully.`)
            }, function (resp) {
              self.$store.commit('error', `Unable to remove "${playlistTitle}".`)
            })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
