import axios from 'axios'

export default {

  getTypes() {
    return axios({
      url: 'contact-types'
    })
  }

}
