import Vue from 'vue'
import axios from 'axios'

import {
  USER_TAG_ALL,
  USER_TAG_CREATE,
  USER_TAG_READ,
  USER_TAG_UPDATE,
  USER_TAG_DELETE
} from '../actions'

import {
  USER_TAG_ADDED,
  USER_TAG_LOADING,
  USER_TAG_SUCCESS,
  USER_TAG_ERROR,
  USER_TAG_SET,
  USER_TAGS_SET,
  USER_TAG_CURRENT_PAGE_SET,
  USER_TAG_PER_PAGE_SET,
  USER_TAG_TOTAL_PAGES_SET,
  USER_TAG_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    userTags: [],
    userTag: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [USER_TAG_ADDED]: (state, tag) => {
      state.userTags.unshift(tag)
    },
    [USER_TAG_LOADING]: (state) => {
      state.status = 'loading'
    },
    [USER_TAG_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [USER_TAG_ERROR]: (state) => {
      state.status = 'error'
    },
    [USER_TAG_SET]: (state, resp) => {
      Vue.set(state, 'userTag', resp)
    },
    [USER_TAGS_SET]: (state, resp) => {
      Vue.set(state, 'userTags', resp)
    },
    [USER_TAG_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [USER_TAG_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [USER_TAG_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [USER_TAG_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    }
  },

  actions: {
    [USER_TAG_ALL]: ({
      commit,
      getters
    }) => {
      commit(USER_TAG_LOADING)

      let user = getters.getProfile

      if (!user.id) {
        commit(USER_TAG_ERROR)
        return false
      }

      axios({
        url: 'users/' + user.id + '/tags'
      })
        .then(resp => {
          commit(USER_TAG_SUCCESS)
          commit(USER_TAGS_SET, resp.data.data)
          // commit(USER_TAG_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(USER_TAG_ERROR)
        })
    },

    [USER_TAG_READ]: ({
      commit
    }, id) => {
      commit(USER_TAG_LOADING)
      commit(USER_TAG_SET, {})
      axios({
        url: 'tags/' + id
      })
        .then(resp => {
          commit(USER_TAG_SUCCESS)
          commit(USER_TAG_SET, resp.data.data)
        })
        .catch(resp => {
          commit(USER_TAG_ERROR)
        })
    },

    [USER_TAG_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(USER_TAG_LOADING)
        commit(USER_TAG_SET, {})

        let user = getters.getProfile

        axios({
          method: 'post',
          url: 'users/' + user.id + '/tags',
          data: data
        })
          .then(resp => {
            commit(USER_TAG_SUCCESS)
            commit(USER_TAG_ADDED, resp.data.data)
            commit(USER_TAG_SET, resp.data.data)
            dispatch(USER_TAG_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(USER_TAG_ERROR)
            reject(err)
          })
      })
    },

    [USER_TAG_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(USER_TAG_LOADING)
      commit(USER_TAG_SET, {})
      axios({
        method: 'put',
        url: 'tags/' + id,
        data: data
      })
        .then(resp => {
          commit(USER_TAG_SUCCESS)
          commit(USER_TAG_SET, resp.data.data)
          dispatch(USER_TAG_ALL)
        })
        .catch(resp => {
          commit(USER_TAG_ERROR)
        })
    },

    [USER_TAG_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(USER_TAG_LOADING)

      axios({
        method: 'delete',
        url: 'tags/' + id
      })
        .then(resp => {
          commit(USER_TAG_SET, {})
          dispatch(USER_TAG_ALL)
        })
        .catch(resp => {
          commit(USER_TAG_ERROR)
        })
    }
  }
}
