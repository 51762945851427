<template>
  <section class="page-header is-paddingless is-marginless">
    <div class="level page-actions">
      <div class="level-left">
        <div class="level-item m-r-30">
          <h1>{{ pitch.song_title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item" v-if="$can.includes('update-pitches')">
          <a class="is-primary" @click="$emit('edit-pitch')">
            <IconEdit />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  IconEdit
} from '@/Icons/IconBase'

export default {
  name: 'PageHeaderPitch',
  components: {
    IconEdit
  },
  props: {
    pitch: Object
  }
}
</script>
