<template>
  <div class="content single">
    <progress v-if="gettingPitchGroup" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <section class="section page-header">
      <div class="level page-actions">
        <div class="level-left">
            <div class="level-item m-r-30">
              <h1>{{ pitchGroup.playlist_title }}</h1>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
              <a @click="back" class="button is-primary is-inverted back">
                <IconArrowLeft/>Back to Playlist
              </a>
            </div>
        </div>
      </div>
      <div class="columns is-marginless">
        <aside class="column is-2 hero">
          <AsidePitchGroup :pitchGroup="pitchGroup" :email="email"></AsidePitchGroup>
        </aside>
        <main class="column is-paddingless">
          <div class="container">
            <section class="single-info">
              <table class="table is-hoverable is-fullwidth">
                <thead class="single-info">
                  <tr>
                    <th class="has-text-grey-light">Song</th>
                    <th class="has-text-grey-light">Pitched To</th>
                    <th class="has-text-grey-light">Pitched By</th>
                    <th class="has-text-grey-light">Pitched For</th>
                    <th class="has-text-grey-light">Status</th>
                    <th class="has-text-grey-light">Primary Artist Hold</th>
                    <th class="has-text-grey-light">Backup Artist Hold</th>
                  </tr>
                </thead>
                <tbody
                    v-for="pitch in pitches"
                    :key="pitch.id"
                    :pitch="pitch">
                  <tr class="is-clickable">
                    <td @click="openPitch(pitch)">{{ pitch | orBlank('song_title') }}</td>
                    <td @click="openPitch(pitch)">{{ pitch.pitch_to_contact | orBlank('full_name') }}</td>
                    <td @click="openPitch(pitch)">{{ pitch.pitch_by_user | orBlank('full_name') }}</td>
                    <td @click="openPitch(pitch)">{{ pitch.pitch_artist_names | orBlank('name') }}</td>
                    <td @click="openPitch(pitch)">{{ pitch | orBlank('status') }}</td>
                    <td @click="openPitch(pitch)">{{ pitch | orBlank('primary_artist_hold') }}</td>
                    <td @click="openPitch(pitch)">{{ pitch | orBlank('backup_artist_hold') }}</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <!-- <note-control :notableId="pitch.id" notableType="pitch"></note-control> -->
          </div>
        </main>
        <!-- <aside class="hero column is-3 has-background-light">
          <div class="aside-inner">
            <TabsPitch :pitch="pitch"/>
          </div>
        </aside> -->
      </div>
    </section>
    <!-- <form-modal
      type="pitch"
      :title="formTitle"
      :model="pitch"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal> -->
  </div>
</template>

<script>
import PitchGroupApi from '@/services/api/PitchGroups'
import AsidePitchGroup from '@/components/PitchGroup/AsidePitchGroup'
import { IconArrowLeft } from '@/Icons/IconBase'

export default {
  name: 'PitchGroupShow',
  //   props: ['email'],
  components: {
    AsidePitchGroup,
    IconArrowLeft
  },

  data() {
    return {
      gettingPitchGroup: false,
      pitchGroup: {},
      pitches: []
    }
  },

  props: {
    email: Object
  },

  created() {
    let self = this
    this.gettingPitchGroup = true
    PitchGroupApi.getPitchGroup(this.$route.params.id)
      .then(resp => {
        self.pitchGroup = resp.data.data
        self.pitches = resp.data.data.pitches
        self.gettingPitchGroup = false
      })
      .catch(err => {
        self.$store.commit('error', 'Could not load pitch group.')
        console.log(err)
      })
  },
  methods: {
    back() {
      this.$router.push({ name: 'playlists.show', params: { id: this.pitchGroup.playlist_id } })
    },
    openPitch(pitch) {
      this.$router.push({ name: 'pitches.show', params: { id: pitch.id } })
    }
  }
}
</script>
