import Vue from 'vue'
import axios from 'axios'

import {
  AUDIO_PLAYER_LOAD,
  NOTIFY_SONG_LISTENED_TO,
  NOTIFY_PITCHED_SONG_LISTENED_TO
} from '../actions'

import {
  AUDIO_PLAYER_LOADING,
  AUDIO_PLAYER_SUCCESS,
  AUDIO_PLAYER_ERROR,
  AUDIO_PLAYER_SET_AUDIO_FILE,
  AUDIO_PLAYER_ACTIVATE_SESSION_QUEUE,
  AUDIO_PLAYER_ACTIVATE_USER_QUEUE,
  AUDIO_PLAYER_CLEAR_STORE,
  CLEAR_STORE
} from '../mutations'

const SESSION_QUEUE = 'session'
const USER_QUEUE = 'user'

export default {
  state: {
    status: '',
    audioFile: {},
    activeQueue: USER_QUEUE
  },

  getters: {
    getActiveQueue: state => state.activeQueue
  },

  mutations: {
    [AUDIO_PLAYER_LOADING]: (state) => {
      state.status = 'loading'
    },
    [AUDIO_PLAYER_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [AUDIO_PLAYER_ERROR]: (state) => {
      state.status = 'error'
    },
    [AUDIO_PLAYER_SET_AUDIO_FILE]: (state, resp) => {
      Vue.set(state, 'audioFile', resp)
    },
    [AUDIO_PLAYER_ACTIVATE_SESSION_QUEUE]: (state) => {
      Vue.set(state, 'activeQueue', SESSION_QUEUE)
    },
    [AUDIO_PLAYER_ACTIVATE_USER_QUEUE]: (state) => {
      Vue.set(state, 'activeQueue', USER_QUEUE)
    },
    [AUDIO_PLAYER_CLEAR_STORE]: (state) => {
      Vue.set(state, 'audioFile', {})
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'audioFile', {})
    }
  },

  actions: {
    [AUDIO_PLAYER_LOAD]: ({
      commit
    }, { id, pitchId }) => {
      commit(AUDIO_PLAYER_LOADING)
      axios({
        url: 'audio-files/' + id + '/listen'
      })
        .then(resp => {
          commit(AUDIO_PLAYER_SUCCESS)
          if (pitchId !== null) {
            resp.data.data['pitch_id'] = pitchId
          }
          commit(AUDIO_PLAYER_SET_AUDIO_FILE, resp.data.data)
        })
        .catch(resp => {
          commit(AUDIO_PLAYER_ERROR)
        })
    },
    [NOTIFY_SONG_LISTENED_TO]: (a = {}, { song, contact }) => {
      let params = {}

      params.song_id = song.id
      params.contact_id = contact

      axios({
        method: 'post',
        url: 'notifications',
        params: params
      })
        .then(resp => {
          console.log(resp)
        })
    },
    [NOTIFY_PITCHED_SONG_LISTENED_TO]: (a = {}, { song, pitch }) => {
      let params = {}

      params.song_id = song
      params.pitch_id = pitch

      axios({
        method: 'post',
        url: 'notifications',
        params: params
      })
        .then(resp => {
          console.log(resp)
        })
    }
  }
}
