import axios from 'axios'

export default {

  getNotes(type, id) {
    return axios({
      url: 'notes',
      params: {
        notable_type: type,
        notable_id: id
      }
    })
  },

  storeNote(type, id, content) {
    return axios({
      method: 'post',
      url: 'notes',
      data: {
        notable_type: type,
        notable_id: id,
        content: content
      }
    })
  },

  updateNote(id, content) {
    return axios({
      method: 'put',
      url: 'notes/' + id,
      data: {
        content: content
      }
    })
  },

  deleteNote(id) {
    return axios({
      method: 'delete',
      url: 'notes/' + id
    })
  }

}
