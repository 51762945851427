import Vue from 'vue'
import axios from 'axios'

import {
  RECORD_LABEL_ALL,
  RECORD_LABEL_ALL_FOR_FORM,
  RECORD_LABEL_CREATE,
  RECORD_LABEL_READ,
  RECORD_LABEL_UPDATE,
  RECORD_LABEL_DELETE
} from '../actions'

import {
  RECORD_LABEL_LOADING,
  RECORD_LABEL_SUCCESS,
  RECORD_LABEL_ERROR,
  RECORD_LABEL_SET,
  RECORD_LABELS_SET,
  RECORD_LABEL_CURRENT_PAGE_SET,
  RECORD_LABEL_PER_PAGE_SET,
  RECORD_LABEL_TOTAL_PAGES_SET,
  RECORD_LABEL_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    recordLabels: [],
    recordLabel: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [RECORD_LABEL_LOADING]: (state) => {
      state.status = 'loading'
    },
    [RECORD_LABEL_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [RECORD_LABEL_ERROR]: (state) => {
      state.status = 'error'
    },
    [RECORD_LABEL_SET]: (state, resp) => {
      Vue.set(state, 'recordLabel', resp)
    },
    [RECORD_LABELS_SET]: (state, resp) => {
      Vue.set(state, 'recordLabels', resp)
    },
    [RECORD_LABEL_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [RECORD_LABEL_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [RECORD_LABEL_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [RECORD_LABEL_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    }
  },

  actions: {
    [RECORD_LABEL_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(RECORD_LABEL_LOADING)

      let accountId = getters.getActiveAccountId

      let params = {
        page: state.currentPage,
        per_page: state.perPage
      }

      if (state.searchValue && state.searchValue.length) {
        params.search = state.searchValue
      }

      axios({
        url: 'accounts/' + accountId + '/search/record-labels',
        params: params
      })
        .then(resp => {
          commit(RECORD_LABEL_SUCCESS)
          commit(RECORD_LABELS_SET, resp.data.data)
          commit(RECORD_LABEL_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(RECORD_LABEL_ERROR)
        })
    },
    [RECORD_LABEL_ALL_FOR_FORM]: ({
      commit,
      state,
      getters
    }) => {
      commit(RECORD_LABEL_LOADING)

      return new Promise((resolve, reject) => {
        let accountId = getters.getActiveAccountId

        axios({
          url: 'accounts/' + accountId + '/record-labels'
        })
          .then(resp => {
            commit(RECORD_LABEL_SUCCESS)
            commit(RECORD_LABELS_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(RECORD_LABEL_ERROR)
            reject(err)
          })
      })
    },

    [RECORD_LABEL_READ]: ({
      commit
    }, id) => {
      commit(RECORD_LABEL_LOADING)
      commit(RECORD_LABEL_SET, {})
      axios({
        url: 'record-labels/' + id
      })
        .then(resp => {
          commit(RECORD_LABEL_SUCCESS)
          commit(RECORD_LABEL_SET, resp.data.data)
        })
        .catch(resp => {
          commit(RECORD_LABEL_ERROR)
        })
    },

    [RECORD_LABEL_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      return new Promise(function (resolve, reject) {
        commit(RECORD_LABEL_LOADING)
        commit(RECORD_LABEL_SET, {})

        let accountId = getters.getActiveAccountId

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/record-labels',
          data: data
        })
          .then(resp => {
            commit(RECORD_LABEL_SUCCESS)
            commit(RECORD_LABEL_SET, resp.data.data)
            dispatch(RECORD_LABEL_ALL)
            resolve(resp.data.data)
          })
          .catch(resp => {
            commit(RECORD_LABEL_ERROR)
          })
      })
    },

    [RECORD_LABEL_UPDATE]: ({
      commit,
      dispatch
    }, { id, data }) => {
      commit(RECORD_LABEL_LOADING)
      commit(RECORD_LABEL_SET, {})
      axios({
        method: 'put',
        url: 'record-labels/' + id,
        data: data
      })
        .then(resp => {
          commit(RECORD_LABEL_SUCCESS)
          commit(RECORD_LABEL_SET, resp.data.data)
          dispatch(RECORD_LABEL_ALL)
        })
        .catch(resp => {
          commit(RECORD_LABEL_ERROR)
        })
    },

    [RECORD_LABEL_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(RECORD_LABEL_LOADING)

      axios({
        method: 'delete',
        url: 'record-labels/' + id
      })
        .then(resp => {
          commit(RECORD_LABEL_SET, {})
          dispatch(RECORD_LABEL_ALL)
        })
        .catch(resp => {
          commit(RECORD_LABEL_ERROR)
        })
    }
  }
}
