<template>
  <div v-if="tag">
    <h3>
      Tagged Items for
      <span class="is-italic has-text-weight-light">"{{tag.value}}"</span>
    </h3>
    <div v-if="isInitialized">
      <div v-if="taggedItems.length">
        <div v-for="taggedItem in taggedItems" :key="taggedItem.id">
          <router-link
            v-if="routeToTaggedItem(taggedItem)"
            :to="routeToTaggedItem(taggedItem)"
          >{{ taggedItemDisplayName(taggedItem) }}</router-link>
          <span v-else>{{ taggedItem.taggable_type_simple}} ID: {{ taggedItem.taggable_id }}</span>
        </div>
      </div>
      <div v-else>
        <p class="is-italic">Nothing is tagged with this tag.</p>
      </div>
    </div>
  </div>
</template>

<script>

import { TAGGED_ITEM_ALL_FOR_TAG } from '@/store/actions'

export default {
  name: 'TagShow',
  props: {
    tag: Object
  },

  data() {
    return {
      taggedItems: [],
      isInitialized: false
    }
  },

  created() {
    this.load()
  },

  watch: {
    tag: function () {
      this.taggedItems = []
      this.isInitialized = false
      this.load()
    }
  },

  methods: {
    load() {
      if (this.tag) {
        let self = this
        this.$store.dispatch(TAGGED_ITEM_ALL_FOR_TAG, this.tag).then(function (data) {
          self.taggedItems = data
          self.isInitialized = true
        }, function (err) {
          console.log(err)
          self.$store.commit('error', 'Unable to load tagged items.')
        })
      }
    },

    taggedItemDisplayName(taggedItem) {
      switch (taggedItem.taggable_type_simple) {
        case 'album':
          return 'Album: ' + taggedItem.name
        case 'contact':
          return 'Contact: ' + taggedItem.name
        case 'pitch':
          return 'Pitch: ' + taggedItem.name
        case 'playlist':
          return 'Playlist: ' + taggedItem.name
        case 'publishingcompany':
          return 'Publishing Company: ' + taggedItem.name
        case 'songwriter':
          return 'Songwriter: ' + taggedItem.name
        case 'song':
          return 'Song: ' + taggedItem.name
        case 'recordlabel':
          return 'Record Label: ' + taggedItem.name
        default:
          return null
      }
    },

    routeToTaggedItem(taggedItem) {
      switch (taggedItem.taggable_type_simple) {
        case 'album':
          return { name: 'albums.index' }
        case 'contact':
          return { name: 'contacts.show', params: { id: taggedItem.taggable_id } }
        case 'pitch':
          return { name: 'pitches.show', params: { id: taggedItem.taggable_id } }
        case 'playlist':
          return { name: 'playlists.show', params: { id: taggedItem.taggable_id } }
        case 'publishingcompany':
          return { name: 'publishing-companies.show', params: { id: taggedItem.taggable_id } }
        case 'songwriter':
          return { name: 'songwriters.show', params: { id: taggedItem.taggable_id } }
        case 'song':
          return { name: 'songs.show', params: { id: taggedItem.taggable_id } }
        case 'recordlabel':
          return { name: 'record-labels.show', params: { id: taggedItem.taggable_id } }
        default:
          return null
      }
    }
  }
}
</script>
