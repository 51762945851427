<template>
  <div v-if="$can.includes('read-songwriters')">
    <section class="index-header p-b-15 m-b-15">
      <div class="level index-actions">
        <div class="level-left">
          <div class="level-item">
            <h1>Songwriters</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
          <div class="level-item">
            <export-button type="songwriter" :search="searchValue"></export-button>
          </div>
            <SongwriterCreateButton v-if="$can.includes('create-songwriters')"/>
          </div>
        </div>
      </div>
    </section>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Songwriters"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <th class="has-text-grey-light">Current Publishing Co.</th>
          <!-- <th class="has-text-grey-light">Phone</th> -->
          <!-- <th class="has-text-grey-light">Email</th> -->
          <!-- <th class="has-text-grey-light">Website</th> -->
        </tr>
      </thead>
      <tbody>
        <tr class="is-clickable" v-for="songwriter in songwriters" :key="songwriter.id">
          <td
            @click="show(songwriter)"
            class="has-text-dark has-text-weight-bold table-title"
          >{{songwriter.full_name}}</td>
          <td @click="show(songwriter)" data-th="Current Publishing Company:">{{songwriter.current_publishing_company | orBlank('name')}}</td>
          <!-- <td @click="show(songwriter)">{{songwriter.phone ? songwriter.phone : '...'}}</td> -->
          <!-- <td @click="show(songwriter)">{{songwriter.email ? songwriter.email : '...'}}</td> -->
          <!-- <td>
            <a
              v-if="songwriter.website"
              v-bind:href="songwriter.website"
              target="_blank"
            >{{songwriter.website}}</a>
          </td> -->
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import SongwriterCreateButton from '@/components/SongwriterCreateButton'

import { SONGWRITER_ALL } from '@/store/actions'
import { SONGWRITER_CURRENT_PAGE_SET, SONGWRITER_PER_PAGE_SET, SONGWRITER_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'SongwriterIndex',
  mixins: [uiHelper],
  components: {
    SongwriterCreateButton
  },

  data() {
    return {
      currentPageMutation: SONGWRITER_CURRENT_PAGE_SET,
      perPageMutation: SONGWRITER_PER_PAGE_SET,
      searchValueMutation: SONGWRITER_SEARCH_VALUE_SET
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.songwriter
    },

    songwriters() {
      return this.$store.state.songwriter.songwriters
    }
  },

  methods: {
    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(SONGWRITER_ALL)
    },

    show(songwriter) {
      this.$router.push({ name: 'songwriters.show', params: { id: songwriter.id, propped: songwriter } })
    }
  }
}
</script>
