import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

import {
  PITCH_ALL,
  PITCH_ALL_FOR_SONG,
  PITCH_CREATE,
  PITCH_READ,
  PITCH_UPDATE,
  PITCH_DELETE,
  PITCH_EMAIL_UPDATE
} from '../actions'

import {
  CLEAR_STORE,
  PITCH_LOADING,
  PITCH_SUCCESS,
  PITCH_DELETED,
  PITCH_ERROR,
  PITCH_UPDATED,
  PITCH_SET,
  PITCHES_SET,
  PITCH_CURRENT_PAGE_SET,
  PITCH_PER_PAGE_SET,
  PITCH_TOTAL_PAGES_SET,
  PITCH_SORT_SET,
  PITCH_SEARCH_QUERY_SET,
  PITCH_SEARCH_SCOPE_SET,
  PITCH_REMOVE_QUERY_ARRAY_VALUE
} from '../mutations'

export default {
  state: {
    status: '',
    pitches: {},
    pitch: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    sortColumn: null,
    sortOrder: null,
    searchScope: 'all',
    searchQuery: {
      song_title: null,
      date: null,
      created_at: null,
      pitch_by_user: null,
      pitch_to_contact: null,
      pitch_artists: null,
      notes: null,
      status: null,
      userTag: null,
      accountTag: null
    }
  },

  getters: {
    getPitches: state => state.pitches,
    isPitchesLoaded: state => !!state.pitches,
    getPitch: state => state.pitch,
    isPitchLoaded: state => !!state.pitch,
    getPitchSearchQuery: state => state.searchQuery
  },

  mutations: {
    [PITCH_LOADING]: (state) => {
      state.status = 'loading'
    },
    [PITCH_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [PITCH_DELETED]: (state, id) => {
      let index = state.pitches.findIndex(pitch => parseInt(pitch.id) === parseInt(id))
      state.pitches.splice(index, 1)
    },
    [PITCH_UPDATED]: (state, data) => {
      if (state.pitches.length) {
        let index = state.pitches.findIndex(pitch => parseInt(pitch.id) === parseInt(data.id))
        state.pitches[index].title = data.title
        state.pitches[index].date = data.date
      }

      if (state.pitch && parseInt(state.pitch.id) === parseInt(data.id)) {
        state.pitches.title = data.title
        state.pitches.date = data.date
      }
    },
    [PITCH_ERROR]: (state) => {
      state.status = 'error'
    },
    [PITCH_SET]: (state, resp) => {
      Vue.set(state, 'pitch', resp)
    },
    [PITCHES_SET]: (state, resp) => {
      Vue.set(state, 'pitches', resp)
    },
    [PITCH_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [PITCH_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [PITCH_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [PITCH_SORT_SET]: (state, {
      column,
      order
    }) => {
      state.sortColumn = column
      state.sortOrder = order
    },
    [PITCH_SEARCH_QUERY_SET]: (state, query) => {
      state.searchQuery = query
    },
    [PITCH_SEARCH_SCOPE_SET]: (state, value) => {
      state.searchScope = value
    },
    [PITCH_REMOVE_QUERY_ARRAY_VALUE]: (state, {
      field,
      index
    }) => {
      state.searchQuery[field].splice(index, 1)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'pitch', {})
      Vue.set(state, 'pitches', {})
    }
  },

  actions: {
    [PITCH_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(PITCH_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(PITCH_ERROR)
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage,
        sort_column: state.sortColumn,
        sort_order: state.sortOrder,
        scope: state.searchScope
      }

      params = Object.assign(params, state.searchQuery)

      if (params.accountTag || params.userTag) {
        // create new array of all tags if any tags exist
        let tagsArray = [...params.accountTag || [], ...params.userTag || []]

        params.tag_ids = tagsArray.map(function (tag) {
          return tag.id
        })

        params.accountTag = params.userTag = null
      }

      if (params.pitch_by_user !== null) {
        params.pitch_by_user_ids = [params.pitch_by_user.id]
        params.pitch_by_user = null
      }

      if (params.pitch_to_contact !== null) {
        params.pitch_to_contact_ids = [params.pitch_to_contact.id]
        params.pitch_to_contact = null
      }

      if (params.pitch_artists !== null) {
        params.pitch_artist_ids = params.pitch_artists.map(function (artist) {
          return artist.id
        })

        params.pitch_artists = null
      }

      for (var propName in params) {
        if (params[propName] === null || params[propName] === undefined) {
          delete params[propName]
        }
      }

      axios({
        url: 'accounts/' + accountId + '/search/pitches',
        params: params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets'
          })
        }
      })
        .then(resp => {
          commit(PITCH_SUCCESS)
          commit(PITCHES_SET, resp.data.data)
          commit(PITCH_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(PITCH_ERROR)
        })
    },

    [PITCH_ALL_FOR_SONG]: ({
      commit
    }, songId) => {
      commit(PITCH_LOADING)

      axios({
        url: 'songs/' + songId + '/pitches'
      })
        .then(resp => {
          commit(PITCH_SUCCESS)
          commit(PITCHES_SET, resp.data.data)
        })
        .catch(resp => {
          commit(PITCH_ERROR)
        })
    },

    [PITCH_READ]: ({
      commit
    }, id) => {
      commit(PITCH_LOADING)
      commit(PITCH_SET, {})
      axios({
        url: 'pitches/' + id
      })
        .then(resp => {
          commit(PITCH_SUCCESS)
          commit(PITCH_SET, resp.data.data)
        })
        .catch(resp => {
          commit(PITCH_ERROR)
        })
    },

    [PITCH_CREATE]: ({
      commit
    }, {
      songId,
      data
    }) => {
      let formattedData = {
        pitch_by_user_id: data.pitch_by_user ? data.pitch_by_user.id : null,
        pitch_to_contact_id: data.pitch_to_contact ? data.pitch_to_contact.id : null,
        primary_artist_hold: data.primary_artist_hold ? data.primary_artist_hold : null,
        backup_artist_hold: data.backup_artist_hold ? data.backup_artist_hold : null,
        status: data.status ? data.status : 'Sent',
        allow_audio_download: data.allowAudioDownload ? data.allowAudioDownload === '1' : false,
        force: !!data.force
      }

      if (data.pitchlog) {
        formattedData.artist_ids = data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (artist) { return artist.id }) : data.pitch_artists.id ? [data.pitch_artists.id] : []
      } else {
        formattedData.artist_ids = data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (artist) { return artist.id }) : data.pitch_artists && data.pitch_artists.id ? [data.pitch_artists.id] : []
      }

      if (data.date) {
        formattedData.date = data.date
      }

      if (data.note) {
        formattedData.note = data.note
      }

      return new Promise(function (resolve, reject) {
        commit(PITCH_LOADING)
        commit(PITCH_SET, {})

        axios({
          method: 'post',
          url: 'songs/' + songId + '/pitches',
          data: formattedData
        })
          .then(resp => {
            commit(PITCH_SUCCESS)
            commit(PITCH_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PITCH_ERROR)
            reject(err)
          })
      })
    },

    [PITCH_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      let formattedData = {
        title: data.title,
        date: data.date,
        pitch_by_user_id: data.pitch_by_user ? data.pitch_by_user.id : null,
        pitch_for_artist_id: data.pitch_for_artist ? data.pitch_for_artist.id : null,
        pitch_to_contact_id: data.pitch_to_contact ? data.pitch_to_contact.id : null,
        primary_artist_hold: data.primary_artist_hold,
        backup_artist_hold: data.backup_artist_hold,
        status: data.status
      }

      if (data.pitchlog) {
        formattedData.artist_ids = data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (artist) { return artist.id }) : data.pitch_artists.id ? [data.pitch_artists.id] : []
      } else {
        formattedData.artist_ids = data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (artist) { return artist.id }) : data.pitch_artists && data.pitch_artists.id ? [data.pitch_artists.id] : []
      }

      return new Promise(function (resolve, reject) {
        commit(PITCH_LOADING)

        axios({
          method: 'put',
          url: 'pitches/' + id,
          data: formattedData
        })
          .then(resp => {
            commit(PITCH_SUCCESS)
            commit(PITCH_UPDATED, resp.data.data)
            commit(PITCH_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PITCH_ERROR)
            reject(err)
          })
      })
    },
    [PITCH_EMAIL_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      let formattedData = {
        title: data.title,
        date: data.date,
        pitch_by_user_id: data.pitch_by_user ? data.pitch_by_user.id : null,
        pitch_for_artist_id: data.pitch_for_artist ? data.pitch_for_artist.id : null,
        pitch_to_contact_id: data.pitch_to_contact ? data.pitch_to_contact.id : null,
        primary_artist_hold: data.primary_artist_hold,
        backup_artist_hold: data.backup_artist_hold,
        status: data.status,
        email_id: data.emailId ? data.emailId : null
      }
      return new Promise(function (resolve, reject) {
        commit(PITCH_LOADING)

        axios({
          method: 'put',
          url: 'pitches/' + id + '/emails',
          data: formattedData
        })
          .then(resp => {
            commit(PITCH_SUCCESS)
            commit(PITCH_UPDATED, resp.data.data)
            commit(PITCH_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PITCH_ERROR)
            reject(err)
          })
      })
    },

    [PITCH_DELETE]: ({
      commit
    }, id) => {
      return new Promise(function (resolve, reject) {
        commit(PITCH_LOADING)

        axios({
          method: 'delete',
          url: 'pitches/' + id
        })
          .then(resp => {
            commit(PITCH_SUCCESS)
            commit(PITCH_DELETED, id)
            commit(PITCH_SET, {})
            resolve()
          })
          .catch(err => {
            commit(PITCH_ERROR)
            reject(err)
          })
      })
    }
  }
}
