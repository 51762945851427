import {
  CONFIRM_LOADING,
  CONFIRM_READY,
  CONFIRM_MESSAGE_SET,
  CONFIRM_RESPONSE_SET,
  CONFIRM_CLEAR
} from '../mutations'

export default {
  state: {
    message: null,
    response: null,
    status: ''
  },

  getters: {
    getConfirmMessage: state => state.message,
    getConfirmResponse: state => state.response
  },

  mutations: {
    [CONFIRM_LOADING]: (state) => {
      state.status = 'loading'
    },
    [CONFIRM_READY]: (state) => {
      state.status = 'ready'
    },
    [CONFIRM_MESSAGE_SET]: (state, value) => {
      state.message = value
    },
    [CONFIRM_RESPONSE_SET]: (state, value) => {
      state.response = value
    },
    [CONFIRM_CLEAR]: (state) => {
      state.message = null
      state.response = null
    }
  }
}
