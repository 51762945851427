<template>
  <div class="column is-paddingless is-full-width">
    <transition enter-active-class="animated fadeIn faster">
      <div v-if="isCreating">
        <form class="form" @submit="save($event)">
          <div class="field m-b-15">
            <textarea-autosize
              v-model="lyricsInput"
              rows="10"
              class="textarea"
              placeholder="Enter Lyrics here..."
              v-on:keyup.enter.exact.native="save($event)"
              v-bind:disabled="isLoading"
            ></textarea-autosize>
          </div>
          <div class="field m-b-15">
            <button type="submit" class="button is-primary is-small">Save</button>
            <button @click.prevent="clearForms" class="button is-danger is-small m-l-5">Cancel</button>
          </div>
        </form>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn faster">
      <div v-if="!isCreating">
        <div class="level is-mobile m-b-5">
          <div class="level-left file-editor">
            <a
              style="border: none !important;"
              class="song-lyrics-edit-button level-item"
              v-if="(!editingLyrics && song.lyrics_text && ($can.includes('edit song lyrics') || canBeEditedBySongwriterRole))"
              @click="edit(song)"
            >
              <IconEdit/>
            </a>
          </div>
          <div class="level-right">
            <div class="level-item" v-if="song.lyrics && ($can.includes('edit song lyrics') || canBeEditedBySongwriterRole)">
              <div
                class="file-editor download-lyrics tooltip p-r-5"
                data-tooltip="Download Lyrics"
                @click="downloadLyrics(song)"
              >
                <a>
                  <IconDownload/>
                </a>
              </div>
            </div>
            <file-editor
              title="Upload Lyrics"
              :file="song.lyrics"
              parentType="song"
              :parentId="song.id"
              class="level-item"
              v-on:file-updated="reload"
            ></file-editor>
          </div>
        </div>
        <div class="level has-text-centered" v-if="!song.lyrics_text">
          <p>
            No Lyrics to display.
            <a
              v-if="$can.includes('edit song lyrics') || canBeEditedBySongwriterRole"
              style="border: none !important;"
              @click.prevent="create"
            >Add searchable lyrics here.</a>
          </p>
        </div>
        <div v-else>
          <div class="media song-lyrics is-hoverable is-paddingless">
            <div class="media-content">
              <div class="content">
                <transition enter-active-class="animated fadeIn faster">
                  <div v-if="editingLyrics">
                    <form class="form" @submit="update($event, song)">
                      <div class="field m-b-15">
                        <textarea-autosize
                          v-model="song.lyrics_text"
                          rows="10"
                          class="textarea"
                          placeholder="Enter lyrics here..."
                          v-bind:disabled="isLoading"
                        ></textarea-autosize>
                      </div>
                      <div class="field m-b-15">
                        <button type="submit" class="button is-primary is-small">Save</button>
                        <button
                          @click.prevent="clearForms"
                          class="button is-danger is-small m-l-5"
                        >Cancel</button>
                      </div>
                    </form>
                  </div>
                  <div v-else>
                    <p class="song-lyrics-content">{{ song.lyrics_text }}</p>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { IconEdit, IconDownload } from '@/Icons/IconBase'

import {
  SONG_READ,
  SONG_UPDATE,
  FILE_DOWNLOAD
} from '@/store/actions'

export default {
  name: 'SongLyrics',
  props: ['song', 'canBeEditedBySongwriterRole'],
  components: {
    IconEdit,
    IconDownload
  },

  data() {
    return {
      isCreating: false,
      lyricsInput: '',
      editingLyrics: null,
      isLoading: false,
      deletingLyrics: null
    }
  },

  created() {
    this.load()
  },

  methods: {
    create() {
      this.clearForms()
      this.lyricsInput = ''
      this.isCreating = true
    },

    edit(song) {
      this.clearForms()
      this.editingLyrics = song
    },

    clearForms() {
      this.lyricsInput = ''
      this.editingLyrics = null
      this.deletingLyrics = null
      this.isCreating = false
      this.isLoading = false
    },

    load() {
      this.isLoading = true
    },

    save($event) {
      $event.preventDefault()

      if (this.editingLyrics) {
        return this.update()
      }

      if (!this.lyricsInput || this.lyricsInput.length < 1) {
        this.clearForms()
        return
      }

      this.isLoading = true
      let self = this

      this.$store.dispatch(SONG_UPDATE, {
        id: this.song.id,
        data: { lyrics_text: this.lyricsInput }
      })
        .then(resp => {
          console.log('resp ', resp)
          self.clearForms()
        })
        .catch(err => {
          console.log('err ', err)
          self.clearForms()
          alert(err)
        })
    },

    update($event, song) {
      $event.preventDefault()
      this.isLoading = true
      let self = this

      this.$store.dispatch(SONG_UPDATE, {
        id: song.id,
        data: { lyrics_text: song.lyrics_text }
      })
        .then(resp => {
          self.clearForms()
        })
        .catch(err => {
          self.clearForms()
          alert(err)
        })
    },

    confirmDelete(song) {
      this.clearForms()
      this.deletingLyrics = song
    },

    cancelDelete() {
      this.deletingLyrics = null
    },

    destroy() {
      this.isLoading = true
      let id = this.deletingLyrics.id
      let self = this

      this.$store.dispatch(SONG_UPDATE, {
        id: id,
        data: { lyrics_text: null }
      })
        .then(resp => {
          self.clearForms()
        })
        .catch(err => {
          self.clearForms()
          alert(err)
        })
    },
    downloadLyrics(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(FILE_DOWNLOAD, song.lyrics.id)
        .then(function (resp) {
          self.isResponding = false
        }).catch(function (err) {
          alert(err)
        })
    },

    reload() {
      this.$store.dispatch(SONG_READ, this.$props.song.id)
    }
  }
}
</script>
