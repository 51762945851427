<template>
  <div class="content single">
    <PageHeaderSong
      :song="song"
      :canBeEditedBySongwriterRole="canBeEditedBySongwriterRole"
      v-on:edit-song="edit"
      v-on:file-updated="reload"
      v-on:click-download-audio-button="downloadAudio"
      v-on:click-submit-song-button="submitSong"
      v-on:click-resubmit-song-button="resubmitSong"
      v-on:click-approve-song-submission-button="approveSongSubmission"
      v-on:click-deny-song-submission-button="denySongSubmission"
    ></PageHeaderSong>
    <section class="section is-paddingless">
      <div class="columns is-marginless ordered-mobile">
        <aside class="column is-2 hero fl-2 is-block-mobile">
          <AsideSong :song="song"></AsideSong>
        </aside>
        <main class="column is-7 is-paddingless fl-1 is-block-mobile">
          <div class="container">
            <transition enter-active-class="animated fadeIn fast">
              <div v-if="song.id">
                <AudioFilesSong :canBeEditedBySongwriterRole="canBeEditedBySongwriterRole" :song="song"></AudioFilesSong>
                   <section class="m-b-30 single-aside-details--data is-hidden-desktop">
                    <fieldset disabled>
                      <div class="field">
                        <label class="label">Created</label>
                        <div class="control">
                          <input class="input" type="text" v-bind:value="song.date_written | momentDate">
                        </div>
                      </div>
                      <div class="field">
                        <label class="label">Turned In</label>
                        <div class="control">
                          <input class="input" type="text" v-bind:value="song.date_turned_in | momentDate">
                        </div>
                      </div>
                      <div class="field">
                        <label class="label">Session Date</label>
                        <div class="control">
                          <input class="input" type="text" v-bind:value="song.session_date | momentDate">
                        </div>
                      </div>
                    </fieldset>
                  </section>
                <WritingCreditsSong
                  :songId="song.id"
                  :allowManualPercentages="song.allow_manual_writing_credit_percentages"
                  :canBeEditedBySongwriterRole="canBeEditedBySongwriterRole"
                  :song="song"
                ></WritingCreditsSong>
                <HoldsSong :songId="song.id" v-on:child-updated="reload"></HoldsSong>
                <CutsSong :songId="song.id" v-on:child-updated="reload"></CutsSong>
                <ReleasesSong :songId="song.id" v-on:child-updated="reload"></ReleasesSong>
                <SyncsSong :songId="song.id" v-on:child-updated="reload"></SyncsSong>
                <note-control :notableId="song.id" notableType="song"></note-control>
                <PitchesSong :songId="song.id"></PitchesSong>
              </div>
            </transition>
          </div>
        </main>
        <aside class="hero column is-3 has-background-light aside-right fl-3 is-block-mobile">
          <div class="aside-inner">
            <TabsSong :canBeEditedBySongwriterRole="canBeEditedBySongwriterRole" :song="song"/>
          </div>
        </aside>
      </div>
    </section>
    <form-modal
      type="song"
      :title="formTitle"
      :model="song"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
  <!-- /home -->
</template>

<script>
import AsideSong from '@/components/Song/AsideSong'
import AudioFilesSong from '@/components/Song/AudioFilesSong'
import CutsSong from '@/components/Song/CutsSong'
import HoldsSong from '@/components/Song/HoldsSong'
import PageHeaderSong from '@/components/Song/PageHeaderSong'
import ReleasesSong from '@/components/Song/ReleasesSong'
import store from '@/store'
import SyncsSong from '@/components/Song/SyncsSong'
import PitchesSong from '@/components/Song/PitchesSong'
import TabsSong from '@/components/Song/TabsSong'
import WritingCreditsSong from '@/components/Song/WritingCreditsSong'

import {
  SONG_READ,
  SONG_DELETE,
  SONG_UPDATE,
  AUDIO_FILE_DOWNLOAD,
  SONG_SUBMISSION_CREATE,
  SONG_SUBMISSION_UPDATE
} from '@/store/actions'

import {
  SONG_SET
} from '@/store/mutations'

export default {
  name: 'Song',
  components: {
    AsideSong,
    AudioFilesSong,
    CutsSong,
    HoldsSong,
    PageHeaderSong,
    ReleasesSong,
    SyncsSong,
    PitchesSong,
    TabsSong,
    WritingCreditsSong
  },

  props: {
    propped: Object
  },

  data() {
    return {
      formTitle: 'Edit Song',
      formCanDelete: false,
      isModal: false
    }
  },

  computed: {
    song() {
      if (this.$store.getters.getSong.id) return this.$store.getters.getSong
      if (this.propped) return this.propped

      return {
        title: ''
      }
    },

    cuts() {
      return this.$store.state.cut.cuts
    },

    syncs() {
      return this.$store.state.sync.syncs
    },

    isLoading() {
      return this.$store.state.song.status === 'loading' ||
        this.$store.state.audioFile.status === 'loading' ||
        this.$store.state.cut.status === 'loading' ||
        this.$store.state.hold.status === 'loading' ||
        this.$store.state.sync.status === 'loading' ||
        this.$store.state.writingCredit.status === 'loading'
    },
    canBeEditedBySongwriterRole() {
      let currentUser = Object.assign({}, this.$store.getters.getProfile)
      if (currentUser.role === 'songwriter' && this.song.can_be_updated_by_songwriter_role && this.song.created_by_user_id === currentUser.id) {
        return true
      }
      return false
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(SONG_READ, to.params.id)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(SONG_READ, to.params.id)
    next()
  },

  beforeCreate: function () {
    document.body.className = 'single'
  },

  destroyed: function () {
    this.$store.commit(SONG_SET, {})
  },

  methods: {
    destroy(song) {
      let self = this
      this.$store.dispatch(SONG_DELETE, song.id)
        .then(function (resp) {
          self.$router.push({ name: 'songs.index' })
        }, function (resp) {
          self.$store.commit('error', 'Unable to delete song.')
        })
    },

    edit() {
      this.clearModal()
      this.formTitle = 'Edit Song'
      this.formCanDelete = true
      this.isModal = !this.isModal
    },

    save(data) {
      let updateData = {
        id: data.id,
        title: data.title,
        date_written: data.date_written,
        date_turned_in: data.date_turned_in,
        session_date: data.session_date,
        allow_manual_writing_credit_percentages: data.allow_manual_writing_credit_percentages ? data.allow_manual_writing_credit_percentages : false,
        account_song: data.account_song ? data.account_song : false
      }

      this.$store.dispatch(SONG_UPDATE, {
        id: updateData.id,
        data: updateData
      })
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
    },
    reload() {
      let self = this
      setTimeout(function () {
        self.$store.dispatch(SONG_READ, self.song.id)
      }, 500)
    },
    downloadAudio(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, song.default_audio_file_id)
        .then(function (resp) {
          self.isResponding = false
        }).catch(function (err) {
          alert(err)
        })
    },
    submitSong(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(SONG_SUBMISSION_CREATE, { song_id: song.id })
        .then(function (resp) {
          self.isResponding = false
          self.$store.dispatch(SONG_READ, song.id)
          self.$store.commit('success', 'Song submitted.')
        }).catch(function (err) {
          console.log(err)
          self.$store.commit('error', 'Song submission failed.')
        })
    },
    resubmitSong(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(SONG_SUBMISSION_UPDATE, {
        id: song.submission_id,
        data: {
          status: 'pending',
          song_id: song.id
        }
      })
        .then(function (resp) {
          self.isResponding = false
          self.$store.dispatch(SONG_READ, song.id)
          self.$store.commit('success', 'Song submitted.')
        }).catch(function (err) {
          console.log(err)
          self.$store.commit('error', 'Song submission failed.')
        })
    },
    approveSongSubmission(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(SONG_SUBMISSION_UPDATE, {
        id: song.submission_id,
        data: {
          status: 'approved',
          song_id: song.id
        }
      })
        .then(function (resp) {
          self.isResponding = false
          self.$store.dispatch(SONG_UPDATE, {
            id: song.id,
            data: {
              title: song.title,
              date_written: song.date_written,
              session_date: song.session_date,
              date_turned_in: song.date_turned_in,
              account_song: true
            }
          })
            .then(function () {
              self.$store.dispatch(SONG_READ, song.id)
              self.$store.commit('success', 'Song approved.')
            })
        }).catch(function (err) {
          console.log(err)
          self.$store.commit('error', 'Song approval failed.')
        })
    },
    denySongSubmission(song) {
      let self = this
      this.isResponding = true
      this.$store.dispatch(SONG_SUBMISSION_UPDATE, {
        id: song.submission_id,
        data: {
          status: 'denied',
          song_id: song.id
        }
      })
        .then(function (resp) {
          self.isResponding = false
          self.$store.dispatch(SONG_READ, song.id)
          self.$store.commit('success', 'Song denied.')
        }).catch(function (err) {
          console.log(err)
          self.$store.commit('error', 'Song denial failed.')
        })
    }
  }
}
</script>
