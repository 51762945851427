<template>
  <a class="play-button" @click.prevent="playAudioFile">
    <IconPlay/>
  </a>
</template>

<script>
import { IconPlay } from '@/Icons/IconBase'
import { AUDIO_PLAYER_LOAD } from '@/store/actions'

export default {
  name: 'PlayButton',
  props: ['audioFileId', 'pitchId'],
  components: {
    IconPlay
  },
  methods: {
    playAudioFile() {
      this.$store.dispatch(AUDIO_PLAYER_LOAD, { id: this.audioFileId, pitchId: this.pitchId })
    }
  }
}
</script>
