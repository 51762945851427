
<template>
    <section class="single-info is-hidden-mobile" v-if="$can.includes('read-pitches')">
      <div class="level">
        <div class="level-left">
          <h3 class="m-b-30">Pitches</h3>
        </div>
        <div class="level-right" v-if="pitches.length">
          <a class="is-primary" @click="showPitches !== 1 ? showPitches = 1 : showPitches = 0">
            <IconChevronDown v-if="showPitches === 0"/>
            <IconChevronUp v-if="showPitches === 1"/>
          </a>
        </div>
      </div>
        <div class="level" v-if="showPitches === 0 && pitches.length">
          <div class="level-left">
          <p>There {{pitchStatement}} for this song.</p>
          </div>
        </div>
        <div class="level" v-if="showPitches === 0 && !pitches.length">
          <div class="level-left">
          <p>No pitches to display.</p>
          </div>
        </div>
        <table class="table is-hoverable is-fullwidth" v-if="showPitches === 1 && pitches.length">
          <thead class="is-size-7 is-sortable-column">
            <tr>
            <th class="has-text-grey-light">Pitch Date</th>
            <th class="has-text-grey-light">Pitched To</th>
            <th class="has-text-grey-light">Pitched For</th>
            <th class="has-text-grey-light">Pitched By</th>
            <th class="has-text-grey-light">Status</th>
            </tr>
          </thead>
          <tbody
            v-for="pitch in pitches"
            :key="pitch.id"
            :pitch="pitch">
            <tr class="is-clickable">
              <td @click="openPitch(pitch)">{{ pitch.date | moment-date }}</td>
              <td @click="openPitch(pitch)">{{ pitch.pitch_to_contact | orBlank('full_name')}}</td>
              <td @click="openPitch(pitch)">{{ pitch.pitch_artist_names | orBlank('name') }}</td>
              <td @click="openPitch(pitch)">{{ pitch.pitch_by_user | orBlank('full_name') }}</td>
              <td @click="openPitch(pitch)">{{pitch.status}}</td>
            </tr>
          </tbody>
        </table>
    </section>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'
import { IconChevronUp, IconChevronDown } from '@/Icons/IconBase'
import { PITCH_ALL_FOR_SONG } from '@/store/actions'

export default {
  name: 'PitchesSong',
  mixins: [uiHelper],
  props: ['songId'],
  components: {
    IconChevronUp,
    IconChevronDown
  },
  data() {
    return {
      showPitches: 0
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.pitch
    },

    pitches() {
      return this.$store.state.pitch.pitches
    },

    pitchStatement() {
      return this.pitches.length === 1 ? 'is 1 pitch' : 'are ' + this.pitches.length + ' pitches'
    }
  },
  methods: {
    load() {
      if (this.songId) {
        this.$store.dispatch(PITCH_ALL_FOR_SONG, this.songId)
      }
    },
    openPitch(pitch) {
      this.$router.push({ name: 'pitches.show', params: { id: pitch.id, propped: pitch } })
    }
  }
}
</script>
