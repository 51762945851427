<template>
  <div class="aside-inner">
    <div class="control has-icons-right">
      <input class="input is-medium" type="search" placeholder="Search Songs" v-model="title">
      <span class="icon is-right">
        <IconSearch />
      </span>
    </div>
    <Tabs class="tabs m-t-20 m-b-15">
      <TabPane label="Advanced Search">
        <section class="p-t-0 p-b-30">
          <div class="form advanced-search">
            <!-- PUBLISHING -->
            <div class="field">
              <label class="label has-collapse">
                <a @click="searchPublishing !== 1 ? searchPublishing = 1 : searchPublishing = 0">Publishing</a>
              </label>
              <div class="control" :class="{'is-hidden' : searchPublishing !== 1}">
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 1 ? openInput = 1 : openInput = 0">
                      <span class="field-sub">Songwriters</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 1}">
                    <multiselect
                      v-model="songwriters"
                      :multiple="true"
                      :options="songwriterFullNames"
                      track-by="id"
                      label="name" :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>

                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 5 ? openInput = 5 : openInput = 0">
                      <span class="field-sub">Publishers</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 5}">
                    <multiselect
                      v-model="publishing_companies"
                      :multiple="true"
                      :options="publishingCompanyNames"
                      track-by="id"
                      label="name"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 8 ? openInput = 8 : openInput = 0">
                      <span class="field-sub">Notes</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 8}">
                    <input class="input" type="text" placeholder="Notes" v-model="notes">
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 7 ? openInput = 7 : openInput = 0">
                      <span class="field-sub">Lyrics</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 7}">
                    <input class="input" type="text" placeholder="Lyrics" v-model="lyrics">
                  </div>
                </div>
              </div>
            </div>

            <!-- DATE FILTERS -->
            <div class="field">
              <label class="label">
                <a @click="searchDate !== 1 ? searchDate = 1 : searchDate = 0">Dates</a>
              </label>
              <div class="control" :class="{'is-hidden' : searchDate !== 1}">
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 24 ? openInput = 24 : openInput = 0">
                      <span class="field-sub">Date Modified</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 24}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('date_modified', date_modified, 'Date Modified')">
                      <span v-if="date_modified">
                        {{ date_modified.start | momentDate }} to {{ date_modified.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 9 ? openInput = 9 : openInput = 0">
                      <span class="field-sub">Date Written</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 9}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('date_written', date_written, 'Date Written')">
                      <span v-if="date_written">
                        {{ date_written.start | momentDate }} to {{ date_written.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 10 ? openInput = 10 : openInput = 0">
                      <span class="field-sub">Date Turned In</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 10}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('date_turned_in', date_turned_in, 'Date Turned In')">
                      <span v-if="date_turned_in">
                        {{ date_turned_in.start | momentDate }} to {{ date_turned_in.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 11 ? openInput = 11 : openInput = 0">
                      <span class="field-sub">Date Added</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 11}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('created_at', created_at, 'Date Added')">
                      <span v-if="created_at">
                        {{ created_at.start | momentDate }} to {{ created_at.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 25 ? openInput = 25 : openInput = 0">
                      <span class="field-sub">Cut Date Added</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 25}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('cut_date', cut_date, 'Cut Date Added')">
                      <span v-if="cut_date">
                        {{ cut_date.start | momentDate }} to {{ cut_date.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 26 ? openInput = 26 : openInput = 0">
                      <span class="field-sub">Hold Date Added</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 26}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('hold_date', hold_date, 'Hold Date Added')">
                      <span v-if="hold_date">
                        {{ hold_date.start | momentDate }} to {{ hold_date.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 12 ? openInput = 12 : openInput = 0">
                      <span class="field-sub">Session Date</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 12}">
                    <button class="button is-fullwidth is-primary is-inverted"
                      @click="openDatePicker('session_date', session_date, 'Session Date')">
                      <span v-if="session_date">
                        {{ session_date.start | momentDate }} to {{ session_date.end | momentDate }}
                      </span>
                      <span v-else>
                        Select Date Range
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- ARTISTS -->
            <div class="field">
              <label class="label">
                <a @click="searchArtists !== 1 ? searchArtists = 1 : searchArtists = 0">Artists</a>
              </label>
              <div class="control" :class="{'is-hidden' : searchArtists !== 1}">
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 3 ? openInput = 3 : openInput = 0">
                      <span class="field-sub">Artists</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 3}">
                    <multiselect
                      v-model="artists"
                      :multiple="true"
                      :options="artistNames"
                      track-by="id"
                      label="name"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 4 ? openInput = 4 : openInput = 0">
                      <span class="field-sub">Record Label</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 4}">
                    <multiselect
                      v-model="record_labels"
                      :multiple="true"
                      :options="recordLabelNames"
                      track-by="id"
                      label="name"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>

                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 6 ? openInput = 6 : openInput = 0">
                      <span class="field-sub">Label Contact</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 6}">
                    <multiselect
                      v-model="record_label_contacts"
                      :multiple="true"
                      :options="recordLabelContactFullNames"
                      track-by="id"
                      label="name"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">
                <a @click="searchTags !== 1 ? searchTags = 1 : searchTags = 0">Tags</a>
              </label>
              <div class="control" :class="{'is-hidden' : searchTags !== 1}">
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 17 ? openInput = 17 : openInput = 0">
                      <span class="field-sub">User Tags</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 17}">
                    <multiselect
                      v-model="userTag"
                      :options="userTags"
                      track-by="id"
                      label="value"
                      :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 18 ? openInput = 18 : openInput = 0">
                      <span class="field-sub">Account Tags</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 18}">
                    <multiselect
                      v-model="accountTag"
                      :options="accountTags"
                      track-by="id"
                      label="value"
                      :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 19 ? openInput = 19 : openInput = 0">
                      <span class="field-sub">Tempo</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 19}">
                    <multiselect
                      v-model="tempoTag"
                      :options="tempoTags"
                      track-by="id"
                      label="value"
                      :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 20 ? openInput = 20 : openInput = 0">
                      <span class="field-sub">Vocal</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 20}">
                    <multiselect
                      v-model="vocalTag"
                      :options="vocalTags"
                      track-by="id" label="value" :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 21 ? openInput = 21 : openInput = 0">
                      <span class="field-sub">Audio</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 21}">
                    <multiselect
                      v-model="audioTag"
                      :options="audioTags"
                      track-by="id"
                      label="value"
                      :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 22 ? openInput = 22 : openInput = 0">
                      <span class="field-sub">Genre</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 22}">
                    <multiselect
                      v-model="genreTag"
                      :options="genreTags"
                      track-by="id"
                      label="value"
                      :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="field">
                  <label class="label">
                    <a @click="openInput !== 23 ? openInput = 23 : openInput = 0">
                      <span class="field-sub">Emotion</span>
                    </a>
                  </label>
                  <div class="control" :class="{'is-hidden' : openInput !== 23}">
                    <multiselect
                      v-model="emotionTag"
                      :options="emotionTags"
                      track-by="id"
                      label="value"
                      :multiple="true"
                      :close-on-select="true">
                      <span slot="noResult">No results found.</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">
                <a @click="openInput !== 2 ? openInput = 2 : openInput = 0">Master Owners</a>
              </label>
              <div class="control" :class="{'is-hidden' : openInput !== 2}">
                <multiselect
                  v-model="master_owners"
                  :options="masterOwnerNames"
                  track-by="id_and_type"
                  label="name"
                  :multiple="true"
                  :close-on-select="true">
                  <span slot="noResult">No results found.</span>
                </multiselect>
              </div>
            </div>
            <div class="field">
              <label class="label">
                <a @click="openInput !== 13 ? openInput = 13 : openInput = 0">Activity</a>
              </label>
              <div class="control" :class="{'is-hidden' : openInput !== 13}">
                <multiselect
                  v-model="activity"
                  :options="activityOptionObjects"
                  :multiple="true"
                  label="label"
                  track-by="value"
                  :close-on-select="true">
                  <span slot="noResult">No results found.</span>
                </multiselect>
              </div>
            </div>
            <div class="field">
              <label class="label">
                <a @click="openInput !== 14 ? openInput = 14 : openInput = 0">Cuts &amp; Singles</a>
              </label>
              <div class="control" :class="{'is-hidden' : openInput !== 14}">
                <multiselect
                  v-model="releases"
                  :multiple="true"
                  :options="releaseOptionObjects"
                  label="label"
                  track-by="value"
                  :close-on-select="true">
                  <span slot="noResult">No results found.</span>
                </multiselect>
              </div>
            </div>
            <div class="field">
              <label class="label">
                <a @click="openInput !== 16 ? openInput = 16 : openInput = 0">Song Rating</a>
              </label>
              <div class="control" :class="{'is-hidden' : openInput !== 16}">
                <multiselect
                  v-model="song_rating"
                  :options="songRatingOptionObjects"
                  label="label"
                  track-by="value"
                  :close-on-select="true">
                </multiselect>
              </div>
            </div>
            <div class="field">
              <label class="label">
                <a @click="openInput !== 15 ? openInput = 15 : openInput = 0">Attachments & Info</a>
              </label>
              <div class="control" :class="{'is-hidden' : openInput !== 15}">
                <div>
                  <label for="withLyrics">
                    <input id="withLyrics" type="checkbox" true-value="true" v-model="with_lyrics"> With Lyrics
                  </label>
                </div>
                <div>
                  <label for="withoutLyrics">
                    <input id="withoutLyrics" type="checkbox" true-value="false" v-model="without_lyrics"> Without
                    Lyrics
                  </label>
                </div>
                <div>
                  <label for="withAudio">
                    <input id="withAudio" type="checkbox" true-value="true" v-model="with_audio"> With Audio
                  </label>
                </div>
                <div>
                  <label for="withoutAudio">
                    <input id="withoutAudio" type="checkbox" true-value="false" v-model="without_audio"> Without Audio
                  </label>
                </div>
                <div>
                  <label for="withoutPublishingInfo">
                    <input id="withoutPublishingInfo" type="checkbox" true-value="true" v-model="without_pub_info">
                    Without Publishing Info
                  </label>
                </div>
                <div>
                  <label for="withoutDateOfCreation">
                    <input id="withoutDateOfCreation" type="checkbox" true-value="true" v-model="without_date_written">
                    Without Date of Creation
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
      </TabPane>
      <TabPane label="Playlists">
        <div class="level m-b-10 songs-playlist-search is-mobile">
          <div class="level-left">
            <div class="control has-icons-right">
              <input class="input is-medium" type="search" placeholder="Search Playlists" v-model="playlistTitleSearch">
              <span class="icon is-right is-medium">
                <IconSearch v-if="playlistTitleSearch === ''" />
                <button v-if="playlistTitleSearch != ''" @click="playlistTitleSearch = ''"
                  class="delete is-medium"></button>
              </span>
            </div>
          </div>
          <div class="level-right">
            <a class="is-primary tooltip is-tooltip-left" data-tooltip="Create Playlist"
              @click="$emit('create-playlist')">
              <IconPlus />
            </a>
          </div>
        </div>
        <ul class="menu-list playlist-list">
          <li v-for="playlist in playlists" :key="playlist.id">
            <droppable-playlist :playlist="playlist"></droppable-playlist>
          </li>
        </ul>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import PlaylistsApi from '@/services/api/Playlists'
import MasterOwnersApi from '@/services/api/MasterOwners'
import TagsApi from '@/services/api/Tags'
import {
  Tabs,
  TabPane
} from 'vue-bulma-tabs'
import Multiselect from 'vue-multiselect'
import {
  IconSearch,
  IconPlus
} from '@/Icons/IconBase'

import {
  DATEPICKER_OPEN
} from '@/store/actions'

import DroppablePlaylist from '@/components/DroppablePlaylist'
import _ from 'lodash'
import Permissions from '@/mixins/permissions'

export default {
  name: 'AsideSongs',
  components: {
    IconSearch,
    Tabs,
    TabPane,
    Multiselect,
    DroppablePlaylist,
    IconPlus
  },

  props: {
    searchQuery: Object
  },

  data() {
    return {
      searchPublishing: 0,
      searchArtists: 0,
      searchTags: 0,
      searchDate: 0,
      searching: false,
      openInput: 0,
      title: null,
      songwriters: null,
      master_owners: null,
      artists: null,
      record_labels: null,
      publishing_companies: null,
      record_label_contacts: null,
      lyrics: null,
      notes: null,
      date_turned_in: null,
      date_written: null,
      date_modified: null,
      cut_date: null,
      hold_date: null,
      created_at: null,
      session_date: null,
      status: null,
      releases: null,
      releaseOptionObjects: [
        {
          label: 'Released Cuts',
          value: 'released_cuts'
        },
        {
          label: 'Unreleased Cuts',
          value: 'unreleased_cuts'
        },
        {
          label: 'Released Singles',
          value: 'released_singles'
        },
        {
          label: 'Unreleased Singles',
          value: 'unreleased_singles'
        }
      ],
      activity: null,
      activityOptionObjects: [
        {
          label: 'Cut',
          value: 'cut'
        },
        {
          label: 'Hold',
          value: 'active_hold'
        },
        {
          label: 'Release',
          value: 'release'
        },
        {
          label: 'Sync',
          value: 'sync'
        }
      ],
      with_lyrics: null,
      without_lyrics: null,
      with_audio: null,
      without_audio: null,
      without_pub_info: null,
      without_date_written: null,
      song_rating: null,
      songRatingOptionObjects: [
        {
          label: 'None',
          value: 'none'
        },
        {
          label: '1 Star',
          value: 1
        },
        {
          label: '2 Stars',
          value: 2
        },
        {
          label: '3 Stars',
          value: 3
        }
      ],
      userTag: null,
      accountTag: null,
      tempoTag: null,
      tempoTags: [],
      vocalTag: null,
      vocalTags: [],
      audioTag: null,
      audioTags: [],
      genreTag: null,
      genreTags: [],
      emotionTag: null,
      emotionTags: [],
      playlists: null,
      masterOwnerNames: [],
      playlistTitleSearch: ''
    }
  },

  created: function () {
    this.updateLocalValues(this.searchQuery)
    this.loadTags()

    if (Permissions.can('read-playlists')) {
      this.loadPlaylists()
    }

    if (Permissions.can('read-master-rights')) {
      this.loadMasterOwners()
    }
  },

  computed: {
    query() {
      return {
        title: this.title,
        songwriters: this.songwriters,
        master_owners: this.master_owners,
        artists: this.artists,
        record_labels: this.record_labels,
        publishing_companies: this.publishing_companies,
        record_label_contacts: this.record_label_contacts,
        lyrics: this.lyrics,
        notes: this.notes,
        date_turned_in: this.date_turned_in,
        date_written: this.date_written,
        date_modified: this.date_modified,
        cut_date: this.cut_date,
        hold_date: this.hold_date,
        created_at: this.created_at,
        session_date: this.session_date,
        status: this.status,
        releases: this.releases,
        activity: this.activity,
        with_lyrics: this.with_lyrics,
        without_lyrics: this.without_lyrics,
        with_audio: this.with_audio,
        without_audio: this.without_audio,
        without_pub_info: this.without_pub_info,
        without_date_written: this.without_date_written,
        song_rating: this.song_rating,
        userTag: this.userTag,
        accountTag: this.accountTag,
        tempoTag: this.tempoTag,
        vocalTag: this.vocalTag,
        audioTag: this.audioTag,
        genreTag: this.genreTag,
        emotionTag: this.emotionTag
      }
    },
    songwriterFullNames() {
      return this.$store.state.songwriter.songwriters.map(function (songwriter) {
        return {
          id: songwriter.id,
          name: songwriter.full_name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    recordLabelNames() {
      return this.$store.state.recordLabel.recordLabels.map(function (recordLabel) {
        return {
          id: recordLabel.id,
          name: recordLabel.name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    userTags() {
      return this.$store.state.userTag.userTags.map(tag => tag).sort((a, b) => (
        a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))
    },
    accountTags() {
      return this.$store.state.accountTag.accountTags.map(tag => tag).sort((a, b) => (
        a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))
    },
    artistNames() {
      return this.$store.state.artist.artists.map(function (artist) {
        return {
          id: artist.id,
          name: artist.name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    publishingCompanyNames() {
      return this.$store.state.publishingCompany.publishingCompanies.map(function (publishingCompany) {
        return {
          id: publishingCompany.id,
          name: publishingCompany.name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    recordLabelContactFullNames() {
      return this.$store.state.contact.contacts.map(function (contact) {
        return {
          id: contact.id,
          name: contact.full_name
        }
      }).sort((a, b) => (
        a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    datepickerData() {
      return this.$store.getters.getDatepickerData
    }
  },

  watch: {
    playlistTitleSearch: _.debounce(function (newValue) {
      this.loadPlaylists(newValue)
    }, 250),

    searchQuery: function (newValue) {
      this.updateLocalValues(this.searchQuery)
    },

    query: function (newValue) {
      Object.keys(newValue).forEach(function (key) {
        if (newValue[key] === '') {
          newValue[key] = null
        }
      })

      this.updateSearchQuery(newValue)
    },

    datepickerData: function (newValue, oldValue) {
      if (newValue.key) {
        this[newValue.key] = newValue.data
      }
    }
  },

  methods: {

    updateSearchQuery: function (query) {
      this.$emit('update-query', query)
    },

    updateLocalValues: function (obj) {
      this.title = obj.title
      this.songwriters = obj.songwriters
      this.master_owners = obj.master_owners
      this.artists = obj.artists
      this.record_labels = obj.record_labels
      this.publishing_companies = obj.publishing_companies
      this.record_label_contacts = obj.record_label_contacts
      this.lyrics = obj.lyrics
      this.notes = obj.notes
      this.date_turned_in = obj.date_turned_in
      this.date_written = obj.date_written
      this.date_modified = obj.date_modified
      this.cut_date = obj.cut_date
      this.hold_date = obj.hold_date
      this.created_at = obj.created_at
      this.session_date = obj.session_date
      this.status = obj.status
      this.releases = obj.releases
      this.activity = obj.activity
      this.with_lyrics = obj.with_lyrics
      this.without_lyrics = obj.without_lyrics
      this.with_audio = obj.with_audio
      this.without_audio = obj.without_audio
      this.without_pub_info = obj.without_pub_info
      this.without_date_written = obj.without_date_written
      this.song_rating = obj.song_rating
      this.userTag = obj.userTag
      this.accountTag = obj.accountTag
      this.tempoTag = obj.tempoTag
      this.vocalTag = obj.vocalTag
      this.audioTag = obj.audioTag
      this.genreTag = obj.genreTag
      this.emotionTag = obj.emotionTag
    },

    loadPlaylists: function (query = null) {
      PlaylistsApi.searchPlaylists(this.$store.getters.getActiveAccountId, 1, 50, query)
        .then(resp => {
          this.playlists = resp.data.data
        })
        .catch(err => {
          this.$store.commit('error', 'Could not load playlist data.')
          console.log(err)
        })
    },

    loadTags: function () {
      let self = this
      TagsApi.getTags()
        .then(resp => {
          let systemTags = resp.data.data
          self.tempoTags = systemTags.filter(tag => tag.scope === 'tempo')
          self.vocalTags = systemTags.filter(tag => tag.scope === 'vocal')
          self.audioTags = systemTags.filter(tag => tag.scope === 'audio')
          self.genreTags = systemTags.filter(tag => tag.scope === 'genre')
          self.emotionTags = systemTags.filter(tag => tag.scope === 'emotion')
        })
        .catch(err => {
          self.$store.commit('error', 'Could not load system tags')
          console.log(err)
        })
    },
    loadMasterOwners: function () {
      MasterOwnersApi.getMasterOwners(this.$store.getters.getActiveAccountId)
        .then(resp => {
          let allMasterOwners = resp.data.data.map(function (masterRightsRecord) {
            return {
              id: masterRightsRecord.owner_id,
              name: masterRightsRecord.owner_name,
              type: masterRightsRecord.owner_type,
              id_and_type: masterRightsRecord.owner_id + '-' + masterRightsRecord.owner_type
            }
          }).sort((a, b) => (
            a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
          this.masterOwnerNames = _.uniqBy(allMasterOwners, 'name')
        })
        .catch(err => {
          this.$store.commit('error', 'Could not load master owners')
          console.log(err)
        })
    },

    openDatePicker: function (key, value, title) {
      this.$store.dispatch(DATEPICKER_OPEN, {
        data: value,
        title: title,
        key: key
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
