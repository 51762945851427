import axios from 'axios'

export default {

  getRoles() {
    return axios({
      url: 'roles'
    })
  }

}
