<template>
  <div v-if="$can.includes('read-projects')">
    <index-header
      title="Projects"
      :newButtonText="$can.includes('create-projects') ? 'Add Project' : null"
      v-on:click-new-button="create"
    ></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Projects"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <th class="has-text-grey-light">Media Type</th>
          <th class="has-text-grey-light">Contact</th>
          <th class="has-text-grey-light">Syncs</th>
          <th class="has-text-grey-light"></th>
        </tr>
      </thead>
      <tbody>
        <tr
        v-for="project in projects"
        :key="project.id"
        :class="{'is-clickable' : $can.includes('update-projects'), 'is-prohibited' : !$can.includes('update-projects') }"
        >
          <td @click="edit(project)" class="has-text-dark has-text-weight-bold table-title">{{project.name}}</td>
          <td @click="edit(project)" data-th="Media Type:">{{project.media_type ? project.media_type : '...'}}</td>
          <td @click="edit(project)" data-th="Contact:">{{project.contact ? project.contact.full_name : '...'}}</td>
          <td @click="edit(project)" data-th="Syncs:">{{ project.sync_count }}</td>
          <td class="is-narrow is-hidden-mobile">
            <a class="table-icon tooltip" data-tooltip="Edit Project" @click="edit(project)">
              <IconEdit/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
    <form-modal
      type="project"
      :title="formTitle"
      :model="selectedProject"
      :message="modalMessage"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconEdit } from '@/Icons/IconBase'

import { PROJECT_ALL, PROJECT_UPDATE, PROJECT_CREATE, PROJECT_DELETE } from '@/store/actions'
import { PROJECT_CURRENT_PAGE_SET, PROJECT_PER_PAGE_SET, PROJECT_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'ProjectIndex',
  mixins: [uiHelper],
  data() {
    return {
      currentPageMutation: PROJECT_CURRENT_PAGE_SET,
      perPageMutation: PROJECT_PER_PAGE_SET,
      searchValueMutation: PROJECT_SEARCH_VALUE_SET,
      formTitle: '',
      formCanDelete: false,
      modalMessage: null,
      isModal: false,
      selectedProject: {}
    }
  },

  components: {
    IconEdit
  },

  computed: {
    resourceState() {
      return this.$store.state.project
    },

    projects() {
      return this.$store.state.project.projects
    }

  },

  methods: {

    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(PROJECT_ALL)
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New Project'
    },

    edit(project) {
      if (this.$can.includes('update-projects')) {
        this.formCanDelete = project.sync_count < 1 && this.$can.includes('delete-projects')
        this.modalMessage = project.sync_count > 0 ? `This Project has syncs and cannot be deleted.` : null
        this.formTitle = 'Edit Project'
        this.isModal = !this.isModal
        this.selectedProject = project
      }
    },

    save(data) {
      if (this.selectedProject.id) {
        this.$store.dispatch(PROJECT_UPDATE, {
          id: this.selectedProject.id,
          data: data
        })
      } else {
        this.$store.dispatch(PROJECT_CREATE, data)
      }

      this.clearModal()
    },

    destroy(project) {
      this.$store.dispatch(PROJECT_DELETE, project.id)
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedProject = {}
      this.modalMessage = null
    }
  }
}
</script>
