<template>
  <div v-if="$can.includes('read-albums')">
    <index-header
    title="Albums"
    :newButtonText="$can.includes('create-albums') ? 'Add Album' : null"
    v-on:click-new-button="create"
    ></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Albums"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Title</th>
          <th class="has-text-grey-light">UPC Number</th>
          <th class="has-text-grey-light"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="album in albums"
          :key="album.id"
          :class="{'is-clickable' : $can.includes('update-albums'), 'is-prohibited' : !$can.includes('update-albums') }"
        >
          <td @click="edit(album)" class="has-text-dark has-text-weight-bold table-title">{{album.title}}</td>
          <td @click="edit(album)" data-th="UPC Number:">{{album.upc_number ? album.upc_number : '...'}}</td>
          <td class="is-narrow is-hidden-mobile">
            <a class="table-icon tooltip" data-tooltip="Edit Album" @click="edit(album)" v-if="$can.includes('update-albums')">
              <IconEdit/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
    <form-modal
      type="album"
      :title="formTitle"
      :model="selectedAlbum"
      :isModal="isModal"
      :canDelete="$can.includes('delete-albums')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconEdit } from '@/Icons/IconBase'

import { ALL, UPDATE, CREATE, DELETE } from '@/store/actions'
import { ALBUM_CURRENT_PAGE_SET, ALBUM_PER_PAGE_SET, ALBUM_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'AlbumIndex',
  mixins: [uiHelper],
  data() {
    return {
      currentPageMutation: ALBUM_CURRENT_PAGE_SET,
      perPageMutation: ALBUM_PER_PAGE_SET,
      searchValueMutation: ALBUM_SEARCH_VALUE_SET,
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      selectedAlbum: {}
    }
  },

  components: {
    IconEdit
  },

  computed: {
    resourceState() {
      return this.$store.state.album
    },

    albums() {
      return this.$store.state.album.albums
    }
  },

  methods: {
    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(ALL, 'album')
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New Album'
    },

    edit(album) {
      if (this.$can.includes('update-albums')) {
        this.formCanDelete = true
        this.formTitle = 'Edit Album'
        this.isModal = !this.isModal
        this.selectedAlbum = album
      }
    },

    save(data) {
      if (this.selectedAlbum.id) {
        this.$store.dispatch(UPDATE, {
          resourceName: 'album',
          id: this.selectedAlbum.id,
          data: data
        })
      } else {
        this.$store.dispatch(CREATE, {
          resourceName: 'album',
          data: data
        })
      }

      this.clearModal()
    },

    destroy(album) {
      this.$store.dispatch(DELETE, {
        resourceName: 'album',
        id: album.id
      })
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedAlbum = {}
    }
  }
}
</script>
