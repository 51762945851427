import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import permissions from '@/mixins/permissions'

import account from './modules/account'
import accountTag from './modules/accountTag'
import audioFileSelect from './modules/audioFileSelect'
import audioFileType from './modules/audioFileType'
import accountUser from './modules/accountUser'
import album from './modules/album'
import artist from './modules/artist'
import auth from './modules/auth'
import audioFile from './modules/audioFile'
import audioPlayer from './modules/audioPlayer'
import base from './modules/base'
import confirm from './modules/confirm'
import contact from './modules/contact'
import contract from './modules/contract'
import contractShare from './modules/contractShare'
import cut from './modules/cut'
import datepicker from './modules/datepicker'
import image from './modules/image'
import email from './modules/email'
import exports from './modules/exports'
import file from './modules/file'
import hold from './modules/hold'
import message from './modules/message'
import notification from './modules/notification'
import optionPeriod from './modules/optionPeriod'
import pitch from './modules/pitch'
import playlist from './modules/playlist'
import project from './modules/project'
import publishingCompany from './modules/publishingCompany'
import queuedAudioFile from './modules/queuedAudioFile'
import recordLabel from './modules/recordLabel'
import release from './modules/release'
import song from './modules/song'
import songSubmission from './modules/songSubmission'
import songwriter from './modules/songwriter'
import sync from './modules/sync'
import tag from './modules/tag'
import taggedItem from './modules/taggedItem'
import user from './modules/user'
import userTag from './modules/userTag'
import writingCredit from './modules/writingCredit'

import {
  AUDIO_FILE_SELECT_CLEAR,
  AUDIO_FILE_SELECT_SET,
  CONFIRM_CLEAR,
  CONFIRM_MESSAGE_SET,
  MESSAGE_ALERT_SET,
  MESSAGE_ERROR_SET,
  MESSAGE_SUCCESS_SET,
  MESSAGE_EXPORT_SET,
  MESSAGE_QUEUE_SET,
  MESSAGE_MODAL_MESSAGE_SET,
  MESSAGE_CLEAR
} from './mutations'

Vue.use(Vuex)
Vue.mixin(permissions)

Vue.prototype.moment = moment

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    account,
    accountTag,
    audioFileSelect,
    audioFileType,
    accountUser,
    album,
    artist,
    auth,
    audioFile,
    audioPlayer,
    base,
    confirm,
    contact,
    contract,
    contractShare,
    cut,
    datepicker,
    image,
    email,
    exports,
    file,
    hold,
    message,
    notification,
    optionPeriod,
    pitch,
    playlist,
    project,
    publishingCompany,
    queuedAudioFile,
    recordLabel,
    release,
    song,
    songSubmission,
    songwriter,
    sync,
    tag,
    taggedItem,
    user,
    userTag,
    writingCredit
  },
  actions: {
    confirm(state, value) {
      let self = this

      return new Promise((resolve, reject) => {
        self.commit(CONFIRM_CLEAR)
        self.commit(CONFIRM_MESSAGE_SET, value)

        function checkResponse() {
          if (state.getters.getConfirmResponse == null) {
            window.setTimeout(checkResponse, 100) /* this checks the flag every 100 milliseconds */
          } else {
            resolve(state.getters.getConfirmResponse)
          }
        }

        checkResponse()
      })
    },
    audioFileSelect(state, value) {
      let self = this

      return new Promise((resolve, reject) => {
        self.commit(AUDIO_FILE_SELECT_CLEAR)
        self.commit(AUDIO_FILE_SELECT_SET, value)

        function checkResponse() {
          if (state.getters.getAudioFileSelectResponse == null) {
            window.setTimeout(checkResponse, 100) /* this checks the flag every 100 milliseconds */
          } else {
            resolve(state.getters.getAudioFileSelectResponse)
          }
        }

        checkResponse()
      })
    }
  },
  mutations: {
    alert(state, value) {
      this.commit(MESSAGE_CLEAR)
      this.commit(MESSAGE_ALERT_SET, value)
    },
    error(state, value) {
      this.commit(MESSAGE_CLEAR)
      this.commit(MESSAGE_ERROR_SET, value)
    },
    success(state, value) {
      this.commit(MESSAGE_CLEAR)
      this.commit(MESSAGE_SUCCESS_SET, value)
    },
    export(state, value) {
      this.commit(MESSAGE_CLEAR)
      this.commit(MESSAGE_EXPORT_SET, value)
    },
    queue(state, value) {
      this.commit(MESSAGE_CLEAR)
      this.commit(MESSAGE_QUEUE_SET, value)
    },
    clear() {
      this.commit(MESSAGE_CLEAR)
    },
    modalMessage(state, value) {
      this.commit(MESSAGE_MODAL_MESSAGE_SET, value)
    }
  },
  strict: debug
})
