import axios from 'axios'

import {
  FILE_CREATE,
  FILE_UPDATE,
  FILE_DELETE,
  FILE_DOWNLOAD
} from '../actions'

import {
  FILE_LOADING,
  FILE_SUCCESS,
  FILE_ERROR
} from '../mutations'

export default {
  state: {
    status: ''
  },

  mutations: {
    [FILE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [FILE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [FILE_ERROR]: (state) => {
      state.status = 'error'
    }
  },

  actions: {
    [FILE_CREATE]: ({
      commit
    }, formData) => {
      return new Promise((resolve, reject) => {
        commit(FILE_LOADING)

        axios({
          method: 'post',
          url: 'files',
          data: formData
        })
          .then(resp => {
            commit(FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(FILE_ERROR)
            reject(err)
          })
      })
    },

    [FILE_UPDATE]: ({
      commit
    }, {
      id,
      formData
    }) => {
      return new Promise((resolve, reject) => {
        commit(FILE_LOADING)

        formData.append('_method', 'PUT')

        axios({
          method: 'post',
          url: 'files/' + id,
          data: formData
        })
          .then(resp => {
            commit(FILE_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(FILE_ERROR)
            reject(err)
          })
      })
    },

    [FILE_DELETE]: ({
      commit
    }, id) => {
      return new Promise((resolve, reject) => {
        commit(FILE_LOADING)

        axios({
          method: 'delete',
          url: 'files/' + id
        })
          .then(resp => {
            resolve()
          })
          .catch(err => {
            commit(FILE_ERROR)
            reject(err)
          })
      })
    },
    [FILE_DOWNLOAD]: ({
      commit
    }, id) => {
      commit(FILE_LOADING)
      return new Promise(function (resolve, reject) {
        axios({
          url: 'files/' + id + '/download',
          method: 'GET',
          responseType: 'blob'
        })
          .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', resp.headers.filename)
            document.body.appendChild(link)
            link.click()
            commit(FILE_SUCCESS)
            resolve(resp.data)
          })
          .catch(err => {
            commit(FILE_ERROR)
            reject(err)
          })
      })
    }
  }
}
