<template>
  <div v-if="$can.includes('read-artists')">
    <index-header
      title="Artists"
      :newButtonText="$can.includes('create-artists') ? 'Add Artist' : null"
      v-on:click-new-button="create"
    >
    </index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Artists"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <th class="has-text-grey-light">Email</th>
          <!-- <th class="has-text-grey-light">Website</th> -->
          <!-- <th class="has-text-grey-light">Contact</th> -->
          <th class="has-text-grey-light"></th>
        </tr>
      </thead>
      <tbody>
        <tr
        v-for="artist in artists"
        :key="artist.id"
        :class="{'is-clickable' : $can.includes('update-artists'), 'is-prohibited' : !$can.includes('update-artists') }"

        >
          <td @click="edit(artist)" class="has-text-dark has-text-weight-bold table-title">{{artist.name}}</td>
          <td @click="edit(artist)" data-th="Email:">{{artist.email ? artist.email : '...'}}</td>
          <!-- <td @click="edit(artist)">{{artist.website ? artist.website : '...'}}</td> -->
          <!-- <td @click="edit(artist)">{{artist.contact ? artist.contact.full_name : '...'}}</td> -->
          <td class="is-narrow is-hidden-mobile">
            <a class="table-icon tooltip" data-tooltip="Edit Artist" @click="edit(artist)" v-if="$can.includes('update-artists')">
              <IconEdit/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>

    <form-modal
      type="artist"
      :title="formTitle"
      :model="selectedArtist"
      :isModal="isModal"
      :canDelete="$can.includes('delete-artists')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconEdit } from '@/Icons/IconBase'

import { ALL, UPDATE, CREATE, DELETE } from '@/store/actions'
import { ARTIST_CURRENT_PAGE_SET, ARTIST_PER_PAGE_SET, ARTIST_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'ArtistIndex',
  mixins: [uiHelper],
  data() {
    return {
      currentPageMutation: ARTIST_CURRENT_PAGE_SET,
      perPageMutation: ARTIST_PER_PAGE_SET,
      searchValueMutation: ARTIST_SEARCH_VALUE_SET,
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      selectedArtist: {}
    }
  },

  components: {
    IconEdit
  },

  computed: {
    resourceState() {
      return this.$store.state.artist
    },

    artists() {
      return this.$store.state.artist.artists
    }

  },

  methods: {

    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(ALL, 'artist')
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New Artist'
    },

    edit(artist) {
      if (this.$can.includes('update-artists')) {
        this.formCanDelete = true
        this.formTitle = 'Edit Artist'
        this.isModal = !this.isModal
        this.selectedArtist = artist
      }
    },

    save(data) {
      if (data.contact) {
        data.contact_id = data.contact.id
      }
      if (this.selectedArtist.id) {
        this.$store.dispatch(UPDATE, {
          resourceName: 'artist',
          id: this.selectedArtist.id,
          data: data
        })
      } else {
        this.$store.dispatch(CREATE, {
          resourceName: 'artist',
          data: data
        })
      }

      this.clearModal()
    },

    destroy(artist) {
      this.$store.dispatch(DELETE, {
        resourceName: 'artist',
        id: artist.id
      })
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedArtist = {}
    }
  }
}
</script>
