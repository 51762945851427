<template>
  <div>
    <index-header title="User Profile"></index-header>
    <div class="columns">
      <div class="column is-7">
        <h3 class="m-b-30">Notification Settings</h3>
        <form class="form" @submit="save" v-if="settings">
          <div class="columns">
            <div class="column is-6" v-if="$can.includes('create-song-submissions')">
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.song_submission_status_changed">
                  Song Submission Status Changed
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.wants_emails">
                  Email Notifications
                </label>
              </div>
            </div>
            <div class="column is-6" v-else>
              <div class="field" v-if="$can.includes('read-song-submissions')">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.song_submitted">
                  New Song Submission
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.song_activity">
                  Song Activity
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.song_viewed">
                  Song Email Viewed
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.song_note_created">
                  Song Notes Updated
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.pitch_viewed">
                  Pitch Viewed
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.pitch_status_changed">
                  Pitch Response
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.song_listened_to">
                  Song Listened To
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.playlist_viewed">
                  Playlist Viewed
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.option_period_expiring">
                  Option Period Expiring
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.pitch_artists_changed">
                  Pitched For Changed
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="settings.wants_emails">
                  Email Notifications
                </label>
              </div>
            </div>
          </div>
          <div class="level m-t-15">
            <div class="level-left">
              <div class="level-item">
                <button type="submit" class="button is-primary">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_SELF_UPDATE } from '@/store/actions'

export default {
  name: 'UserSettings',

  data() {
    return {
      settings: null
    }
  },

  created() {
    this.settings = Object.assign({}, this.$store.getters.getProfile.notification_settings)
  },

  computed: {
    currentUser: function () {
      return Object.assign({}, this.$store.getters.getProfile)
    }
  },

  watch: {
    currentUser: function (newValue) {
      this.settings = Object.assign({}, newValue.notification_settings)
    }
  },

  methods: {
    save($event) {
      $event.preventDefault()
      let self = this
      this.$store.dispatch(USER_SELF_UPDATE, {
        notification_settings: this.settings
      }).then(function (data) {
        self.$store.commit('success', 'Settings updated.')
      }, function (err) {
        self.$store.commit('error', err)
      })
    }
  }
}
</script>
