<template>
  <div class="control has-icons-right">
    <input class="input" type="search" v-bind:placeholder="placeholder" v-model="localSearchValue">
    <span class="icon is-right">
      <IconSearch/>
    </span>
  </div>
</template>

<script>
import { IconSearch } from '@/Icons/IconBase'

export default {
  name: 'SearchInput',

  components: {
    IconSearch
  },

  props: {
    placeholder: String,
    searchValue: String
  },

  data() {
    return {
      localSearchValue: ''
    }
  },

  created: function () {
    this.localSearchValue = this.searchValue
  },

  watch: {
    searchValue: function (newValue) {
      this.localSearchValue = newValue
    },

    localSearchValue: function (newValue) {
      this.updateSearchValue(newValue)
    }
  },

  methods: {
    updateSearchValue: function (value) {
      this.$emit('search-input-change', value)
    }
  }
}
</script>
