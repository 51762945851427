import Vue from 'vue'
import axios from 'axios'

import {
  AUDIO_FILE_TYPE_ALL,
  AUDIO_FILE_TYPE_CREATE,
  AUDIO_FILE_TYPE_READ,
  AUDIO_FILE_TYPE_UPDATE,
  AUDIO_FILE_TYPE_DELETE
} from '../actions'

import {
  AUDIO_FILE_TYPE_ADDED,
  AUDIO_FILE_TYPE_LOADING,
  AUDIO_FILE_TYPE_SUCCESS,
  AUDIO_FILE_TYPE_ERROR,
  AUDIO_FILE_TYPE_SET,
  AUDIO_FILE_TYPES_SET
} from '../mutations'

export default {
  state: {
    status: '',
    audioFileTypes: [],
    audioFileType: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [AUDIO_FILE_TYPE_ADDED]: (state, tag) => {
      state.audioFileTypes.unshift(tag)
    },
    [AUDIO_FILE_TYPE_LOADING]: (state) => {
      state.status = 'loading'
    },
    [AUDIO_FILE_TYPE_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [AUDIO_FILE_TYPE_ERROR]: (state) => {
      state.status = 'error'
    },
    [AUDIO_FILE_TYPE_SET]: (state, resp) => {
      Vue.set(state, 'audioFileType', resp)
    },
    [AUDIO_FILE_TYPES_SET]: (state, resp) => {
      Vue.set(state, 'audioFileTypes', resp)
    }
  },

  actions: {
    [AUDIO_FILE_TYPE_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(AUDIO_FILE_TYPE_LOADING)

      let accountId = getters.getActiveAccountId
      if (!accountId) {
        console.log('no account!')
        commit(AUDIO_FILE_TYPE_ERROR)
        return
      }

      axios({
        url: 'accounts/' + accountId + '/audio-file-types'
      })
        .then(resp => {
          commit(AUDIO_FILE_TYPE_SUCCESS)
          commit(AUDIO_FILE_TYPES_SET, resp.data.data)
        })
        .catch(resp => {
          commit(AUDIO_FILE_TYPE_ERROR)
        })
    },

    [AUDIO_FILE_TYPE_READ]: ({
      commit
    }, id) => {
      commit(AUDIO_FILE_TYPE_LOADING)
      commit(AUDIO_FILE_TYPE_SET, {})
      axios({
        url: 'audio-file-types/' + id
      })
        .then(resp => {
          commit(AUDIO_FILE_TYPE_SUCCESS)
          commit(AUDIO_FILE_TYPE_SET, resp.data.data)
        })
        .catch(resp => {
          commit(AUDIO_FILE_TYPE_ERROR)
        })
    },

    [AUDIO_FILE_TYPE_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(AUDIO_FILE_TYPE_LOADING)
        commit(AUDIO_FILE_TYPE_SET, {})

        let accountId = getters.getActiveAccountId

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/audio-file-types',
          data: data
        })
          .then(resp => {
            commit(AUDIO_FILE_TYPE_SUCCESS)
            commit(AUDIO_FILE_TYPE_ADDED, resp.data.data)
            commit(AUDIO_FILE_TYPE_SET, resp.data.data)
            dispatch(AUDIO_FILE_TYPE_ALL)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(AUDIO_FILE_TYPE_ERROR)
            reject(err)
          })
      })
    },

    [AUDIO_FILE_TYPE_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(AUDIO_FILE_TYPE_LOADING)
      commit(AUDIO_FILE_TYPE_SET, {})
      axios({
        method: 'put',
        url: 'audio-file-types/' + id,
        data: data
      })
        .then(resp => {
          commit(AUDIO_FILE_TYPE_SUCCESS)
          commit(AUDIO_FILE_TYPE_SET, resp.data.data)
          dispatch(AUDIO_FILE_TYPE_ALL)
        })
        .catch(resp => {
          commit(AUDIO_FILE_TYPE_ERROR)
        })
    },

    [AUDIO_FILE_TYPE_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(AUDIO_FILE_TYPE_LOADING)

      axios({
        method: 'delete',
        url: 'audio-file-types/' + id
      })
        .then(resp => {
          commit(AUDIO_FILE_TYPE_SET, {})
          dispatch(AUDIO_FILE_TYPE_ALL)
        })
        .catch(resp => {
          commit(AUDIO_FILE_TYPE_ERROR)
        })
    }
  }
}
