<template>
  <thead class="is-size-7">
    <tr>
      <th class="has-text-grey-light"></th>
      <!-- <th class="has-text-grey-light">
        <input
          class="is-checkradio"
          id="selectAllCheckbox"
          type="checkbox"
          name="selectAllCheckbox"
          @change="$emit('select-all', $event)"
          :model="selectAll"
        >
        <label for="selectAllCheckbox"></label>
      </th> -->
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('title')">Name
        <column-sort-indicator name="title" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('creation_date')">Date Created
        <column-sort-indicator name="creation_date" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('pitch_to_contact_name')">Pitching To
        <column-sort-indicator
          name="pitch_to_contact_name"
          :column="localSortColumn"
          :order="localSortOrder"
        ></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('pitch_for_artist_names')">Pitching For
        <column-sort-indicator
          name="pitch_for_artist_names"
          :column="localSortColumn"
          :order="localSortOrder"
        ></column-sort-indicator>
      </th>
      <th class="has-text-grey-light is-sortable-column" @click="updateSortColumn('type')">Type
        <column-sort-indicator name="type" :column="localSortColumn" :order="localSortOrder"></column-sort-indicator>
      </th>
      <th class="has-text-grey-light" colspan="2"></th>
    </tr>
  </thead>
</template>

<script>

export default {
  name: 'TablePlaylistsHead',

  props: {
    sortColumn: String,
    sortOrder: String,
    selectAll: Boolean
  },

  data() {
    return {
      localSortColumn: this.sortColumn,
      localSortOrder: this.sortOrder
    }
  },

  methods: {

    updateSortColumn: function (column) {
      let newOrder = null

      let newColumn = null

      if (column !== this.localSortColumn || this.localSortOrder === null) {
        newOrder = 'asc'
        newColumn = column
      } else if (this.localSortOrder === 'asc') {
        newOrder = 'desc'
        newColumn = column
      }

      this.localSortOrder = newOrder
      this.localSortColumn = newColumn

      this.$emit('sort-change', newColumn, newOrder)
    }
  }
}
</script>
