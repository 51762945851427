<template>
  <div>
    <div class="field m-b-15">
      <div class="level m-b-0 is-mobile">
        <div class="level-left">
          <label for="songwriter_id" class="label">Songwriter</label>
        </div>
        <div class="level-right">
          <div v-if="!writingCredit.id">
            <a
              class="is-primary tooltip is-tooltip-left"
              data-tooltip="Create Songwriter"
              @click="addSongwriter"
              v-if="$can.includes('create-songwriters')"
            >
              <IconPlus style="height: 20px; width: 20px;"/>
            </a>
          </div>
        </div>
      </div>
      <div class="control">
        <multiselect
          v-model="writingCredit.songwriter"
          @input="loadContracts"
          :options="songwriters"
          track-by="id"
          label="name_and_current_publishing_company"
          :close-on-select="true"
        ></multiselect>
      </div>
    </div>
    <div class="field m-b-15" v-if="writingCredit.songwriter && contractsLoaded">
      <div class="level m-b-0">
        <div class="level-left">
          <label for="songwriter_id" class="label">Songwriter Contract</label>
        </div>
      </div>
      <div class="control" v-if="allowContractSelect">
        <multiselect
          v-model="writingCredit.contract"
          :options="contracts"
          track-by="id"
          :custom-label="contractLabel"
          :close-on-select="true"
          :disabled="!allowContractSelect"
        ></multiselect>
      </div>
      <p
        v-if="writingCredit.songwriter && contractsLoaded && !allowContractSelect"
        class="is-italic has-text-grey-light"
      >No contracts available for the selected songwriter.</p>
    </div>
    <div class="field m-b-15">
      <label for="percentage" class="label">Split</label>
      <input
        v-bind:disabled="!allowPercentageEntry"
        id="percentage"
        type="number"
        max="100"
        class="input"
        v-model="writingCredit.percentage"
        step="0.01"
      >
      <p
        v-if="!allowPercentageEntry"
        class="is-italic has-text-grey-light"
      >Splits calculate automatically for this song.</p>
      <p
        v-if="allowPercentageEntry"
        class="is-italic has-text-grey-light"
      >Manual split values are enabled for this song.</p>
    </div>
    <form-modal
      type="songwriter"
      title="Add Songwriter"
      :model="songwriter"
      message="You can add more details later."
      :isModal="isModal"
      :isSaving="isSaving"
      v-on:clear-modal="clearModal"
      v-on:save-record="saveSongwriter"
    ></form-modal>
  </div>
</template>

<script>

import moment from 'moment'
import ContractsApi from '@/services/api/Contracts'
import { IconPlus } from '@/Icons/IconBase'
import Multiselect from 'vue-multiselect'
import { SONGWRITER_ALL_FOR_FORM, SONGWRITER_CREATE } from '@/store/actions'

export default {
  name: 'WritingCreditForm',
  model: {
    prop: 'writingCredit',
    event: 'change'
  },
  props: {
    writingCredit: Object
  },
  components: {
    IconPlus,
    Multiselect
  },
  data() {
    return {
      isModal: false,
      songwriter: {},
      contracts: {},
      contractsLoaded: false,
      isSaving: false
    }
  },
  created() {
    this.$store.dispatch(SONGWRITER_ALL_FOR_FORM)
    this.loadContracts()
  },
  computed: {
    songwriters() {
      return this.$store.state.songwriter.songwriters
    },

    allowPercentageEntry() {
      return this.writingCredit.allow_manual_writing_credit_percentages
    },

    allowContractSelect() {
      return this.contractsLoaded && this.contracts.length && this.writingCredit.songwriter
    }
  },

  methods: {
    addSongwriter() {
      this.clearModal()
      this.isModal = !this.isModal
    },

    saveSongwriter(data) {
      let self = this
      this.isSaving = true
      this.$store.dispatch(SONGWRITER_CREATE, data)
        .then(function (songwriter) {
          self.clearModal()
          self.isSaving = false
          self.writingCredit.songwriter = songwriter
        }, function (err) {
          self.isSaving = false
          self.clearModal()
          alert(err)
        })
    },

    clearModal() {
      this.songwriter = {}
      this.isModal = false
    },

    loadContracts() {
      if (this.writingCredit.songwriter) {
        let self = this
        ContractsApi.getContracts(this.writingCredit.songwriter.id)
          .then(function (resp) {
            self.contracts = resp.data.data
            self.contractsLoaded = true
          }, function (err) {
            console.log(err)
            self.contractsLoaded = true
            self.$store.commit('error', 'Could not load contracts for songwriter')
          })
      }
    },
    contractLabel(contract) {
      if (contract.name && contract.name.length > 0) {
        return `${contract.name} ${contract.contract_shares_publisher_names ? '(' + contract.contract_shares_publisher_names + ')' : ''}`
      }
      return `${moment(contract.start_date).format('M/D/YYYY')} to ${moment(contract.end_date).format('M/D/YYYY')}
              ${contract.contract_shares_publisher_names ? '(' + contract.contract_shares_publisher_names + ')' : ''}`
    }
  }
}
</script>
