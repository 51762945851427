<template>
  <tr class="is-clickable ordered-mobile">
    <!-- <td class="is-hidden-mobile">
      <div class="field">
        <input
          class="is-checkradio"
          v-bind:id="pitch.id"
          type="checkbox"
          name="selectedPitch"
          @change="toggleSelect"
          v-model="isSelected"
        >
        <label v-bind:for="pitch.id"></label>
      </div>
    </td> -->
    <td @click="openPitch" class="fl-2" data-th="Pitch Date:">{{ pitch.date | moment-date }}</td>
    <td @click="openPitch" class="fl-1 has-text-dark has-text-weight-bold table-title">{{ pitch.song_title }}</td>
    <td @click="openPitch" class="fl-3" data-th="Pitched To:">{{ pitch.pitch_to_contact | orBlank('full_name')}}</td>
    <td @click="openPitch" class="is-hidden-mobile" data-th="Pitched For:">{{ pitch.pitch_artist_names | orBlank('name') }}</td>
    <td @click="openPitch" class="is-hidden-mobile" data-th="Pitched By:">{{ pitch.pitch_by_user | orBlank('full_name') }}</td>
    <td @click="openPitch" class="fl-4" data-th="Status:">{{pitch.status}}</td>
    <!-- need to sort out showing multiple tags, showing only account tags for now -->
    <td @click="openPitch" class="is-hidden-mobile" data-th="Tags:">{{!pitch.account_tags ? '...' : pitch.account_tags.join() | orBlank(0) }}</td>
    <td class="is-narrow is-hidden-mobile">
      <a class="table-icon tooltip" data-tooltip="View Pitch" @click="openPitch">
        <IconEye/>
      </a>
    </td>
    <td class="is-narrow is-hidden-mobile">
      <a
        v-if="$can.includes('update-pitches')"
        class="table-icon tooltip"
        data-tooltip="Edit Pitch"
        @click="$emit('click-edit-button', pitch)"
      >
        <IconEdit/>
      </a>
    </td>
  </tr>
</template>

<script>
import { IconEdit, IconEye } from '@/Icons/IconBase'

export default {
  name: 'TablePitchesRow',
  props: {
    pitch: Object,
    selectAll: Boolean
  },
  components: {
    IconEdit,
    IconEye
  },
  data() {
    return {
      isOpen: false,
      isSelected: false
    }
  },
  watch: {
    selectAll: function (newValue) {
      this.isSelected = newValue
    }
  },
  methods: {
    toggleSelect() {
      this.isSelected = !this.isSelected
    },

    close() {
      this.isOpen = false
    },

    openPitch() {
      this.$router.push({ name: 'pitches.show', params: { id: this.pitch.id, propped: this.pitch } })
    }
  }
}
</script>
