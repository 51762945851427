<template>
    <div>
        <section class="home-hero is-info is-medium has-text-centered">
            <div class="hero-body">
                <div class="container">
                    <object type="image/svg+xml" data="img/wrios_logo.svg" class="logo">
                        Wrios Logo
                    </object>
                    <h1 class="title">
                        Manage Your Music. Your Way.
                    </h1>
                    <h2 class="subtitle">
                        Administrators. Songwriters. Creatives.
                    </h2>
                    <p class="control has-text-centered">
                        <router-link class="button is-medium is-primary" to="/login">Log In</router-link>
                    </p>
                </div>
            </div>
        </section>
        <section class="section is-medium product">
            <div class="container">
                <div class="columns is-8 is-desktop is-centered is-vcentered product-info product-info-1">
                    <div class="column is-narrow">
                        <div class="product-image-wrap">
                            <div class="image product-image image-clip-height">
                                <img src="img/wrios-product_desktop_mobile.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="column is-5 is-offset-1 product-description">
                        <h2 class="title">Submit. Catalog. Pitch.</h2>
                        <p>WRIOS is a comprehensive online tool to simplify workflow for music companies. It makes it
                            easy for songwriters to submit songs, for administrators to catalog, and for executives to
                            pitch. The business stays the same but WRIOS saves you time.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section is-medium has-background-white product">
            <div class="container">
                <div class="columns is-8 is-desktop is-centered is-vcentered product-info product-info-2">
                    <div class="column is-5 product-description">
                        <h2 class="title">Organized to be creative.</h2>
                        <p>Everything you need to organize your songs, all in one place. It allows you to focus on what
                            is most important, being creative.</p>
                    </div>
                    <div class="column is-narrow is-offset-1">
                        <div class="product-image-wrap">
                            <div class="image product-image image-clip-width">
                                <img src="img/wrios-product_creatives.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  mounted() {
    /* eslint-env jquery */
    function showImages(el) {
      var windowHeight = jQuery(window).height()
      $(el).each(function () {
        var thisPos = $(this).offset().top

        var topOfWindow = $(window).scrollTop()
        if (topOfWindow + windowHeight - 200 > thisPos) {
          $(this).addClass('fadeIn')
        }
      })
    }

    // if the image in the window of browser when the page is loaded, show that image
    $(document).ready(function () {
      showImages('.product-info')
    })

    // if the image in the window of browser when scrolling the page, show that image
    $(window).scroll(function () {
      showImages('.product-info')
    })
  }
}
</script>
