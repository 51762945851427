<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="modal" class="modal is-active" v-if="isModal">
      <div class="modal-background" @click="close"></div>

      <div class="modal-content card resource-modal-content">
        <div class="card-content">
          <h2 class="title">{{ title }}</h2>
          <form class="form m-t-20" @submit.prevent="$emit('save-record', localModel)">
            <AccountUserForm v-if="type === 'accountUser'" v-model="localModel"></AccountUserForm>
            <AlbumForm v-if="type === 'album'" v-model="localModel"></AlbumForm>
            <ArtistForm v-if="type === 'artist'" v-model="localModel"></ArtistForm>
            <AudioFileEditForm v-if="type === 'audioFile'" v-model="localModel"></AudioFileEditForm>
            <ContactForm v-if="type === 'contact'" v-model="localModel"></ContactForm>
            <ContactEditForm v-if="type === 'contactEdit'" v-model="localModel"></ContactEditForm>
            <ContractForm v-if="type === 'contract'" v-model="localModel"></ContractForm>
            <ContractOptionPeriodForm v-if="type === 'contractOptionPeriod'" v-model="localModel"></ContractOptionPeriodForm>
            <ContractPublisherShareForm v-if="type === 'contractPublisherShare'" v-model="localModel"></ContractPublisherShareForm>
            <ContractWriterShareForm v-if="type === 'contractWriterShare'" v-model="localModel"></ContractWriterShareForm>
            <PitchForm v-if="type === 'pitch'" v-model="localModel" v-on:is-not-sending="localIsSendingNow !== true ? localIsSendingNow = true : localIsSendingNow = false"></PitchForm>
            <PitchLinkForm v-if="type === 'pitchLink'" v-model="localModel"></PitchLinkForm>
            <CompEmailForm v-if="type === 'compEmail'" v-model="localModel"></CompEmailForm>
            <CompLinkForm v-if="type === 'compLink'" v-model="localModel"></CompLinkForm>
            <CutForm v-if="type === 'cut'" v-model="localModel"></CutForm>
            <ExportForm v-if="type === 'export'" v-model="localModel" :isExporting="isExporting"></ExportForm>
            <HoldRequestForm v-if="type === 'holdRequest'" v-model="localModel"></HoldRequestForm>
            <HoldForm v-if="type === 'hold'" v-model="localModel"></HoldForm>
            <PlaylistForm v-if="type === 'playlist'" v-model="localModel"></PlaylistForm>
            <PlaylistPitchForm v-if="type === 'playlistPitch'" v-model="localModel"></PlaylistPitchForm>
            <PlaylistCdLabelForm v-if="type === 'playlistCdLabel'" v-model="localModel"></PlaylistCdLabelForm>
            <PlaylistPitchLinkForm v-if="type === 'playlistPitchLink'" v-model="localModel"></PlaylistPitchLinkForm>
            <ProjectForm v-if="type === 'project'" v-model="localModel"></ProjectForm>
            <PublishingCompanyForm v-if="type === 'publishingCompany'" v-model="localModel"></PublishingCompanyForm>
            <PublishingCompanyEditForm v-if="type === 'publishingCompanyEdit'" v-model="localModel"></PublishingCompanyEditForm>
            <RecordLabelForm v-if="type === 'recordLabel'" v-model="localModel"></RecordLabelForm>
            <RecordLabelEditForm v-if="type === 'recordLabelEdit'" v-model="localModel"></RecordLabelEditForm>
            <ReleaseForm v-if="type === 'release'" v-model="localModel"></ReleaseForm>
            <ReleaseInitForm
              v-if="type === 'release-init'"
              v-model="localModel"
              v-on:select-cut-to-release="itemSelected"
            ></ReleaseInitForm>
            <SendToPitchlogForm v-if="type === 'sendToPitchlog'" v-model="localModel"></SendToPitchlogForm>
            <SongForm v-if="type === 'song'" v-model="localModel"></SongForm>
            <SongLinkForm v-if="type === 'songLink'" v-model="localModel"></SongLinkForm>
            <SongwriterForm v-if="type === 'songwriter'" v-model="localModel"></SongwriterForm>
            <SongwriterEditForm v-if="type === 'songwriterEdit'" v-model="localModel"></SongwriterEditForm>
            <SyncForm v-if="type === 'sync'" v-model="localModel"></SyncForm>
            <WritingCreditForm v-if="type === 'writingCredit'" v-model="localModel"></WritingCreditForm>

            <progress v-if="isSaving" class="progress is-small is-primary" max="100"></progress>
            <div class="level m-t-30 is-mobile">
              <div class="level-left" v-if="isConfirming !== 1">
                <div class="level-item" v-if="!canDelete">
                  <button v-if="type === 'pitch' && !localIsSendingNow || type === 'songLink' || type === 'compEmail' || type === 'playlistPitch'" type="submit" class="button is-primary" :disabled="isExporting || isSaving">Send</button>
                  <button v-else-if="type === 'pitch' && localIsSendingNow" type="submit" class="button is-primary" :disabled="isExporting || isSaving">Save</button>
                  <button v-else-if="type === 'pitchLink' || type === 'compLink' || type === 'playlistPitchLink'" type="submit" class="button is-primary" :disabled="isExporting || isSaving">Generate Link</button>
                  <button v-else-if="type === 'sendToPitchlog'" type="submit" class="button is-primary" :disabled="isExporting || isSaving">Send To Pitchlog</button>
                  <button v-else-if="type === 'playlistCdLabel'" type="submit" class="button is-primary" :disabled="isExporting || isSaving">Create CD Label</button>
                  <button v-else type="submit" class="button is-primary" :disabled="isExporting || isSaving">Save</button>
                </div>
                <div class="level-item" v-else>
                  <button type="submit" class="button is-primary" :disabled="isExporting || isSaving">Save</button>
                </div>
                <div class="level-item">
                  <a href="#" class="button is-primary is-inverted" @click="close">Cancel</a>
                </div>
              </div>
              <div class="level-right" v-if="type === 'writingCredit' && !canDelete">
                <div class="level-item">
                  <a class="button is-primary is-inverted" @click="$emit('add-another', localModel)">Save and Add Another</a>
                </div>
              </div>
              <div class="level" v-if="message || canDelete">
                <div class="level-item" v-if="message">
                  <p class="is-italic has-text-grey-light">{{ message }}</p>
                </div>
                <div class="level-item" v-if="canDelete">
                  <delete-button v-on:is-confirming="isConfirming !== 1 ? isConfirming = 1 : isConfirming = 0" v-on:delete-confirmed="$emit('delete-record', localModel)"></delete-button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <button class="modal-close is-large" @click="close"></button>
      </div>
    </div>
  </transition>
</template>

<script>

import AccountUserForm from '@/components/Forms/AccountUserForm'
import AlbumForm from '@/components/Forms/AlbumForm'
import ArtistForm from '@/components/Forms/ArtistForm'
import AudioFileEditForm from '@/components/Forms/AudioFileEditForm'
import CompEmailForm from '@/components/Forms/CompEmailForm'
import CompLinkForm from '@/components/Forms/CompLinkForm'
import ContactForm from '@/components/Forms/ContactForm'
import ContactEditForm from '@/components/Forms/ContactEditForm'
import ContractForm from '@/components/Forms/ContractForm'
import ContractOptionPeriodForm from '@/components/Forms/ContractOptionPeriodForm'
import ContractPublisherShareForm from '@/components/Forms/ContractPublisherShareForm'
import ContractWriterShareForm from '@/components/Forms/ContractWriterShareForm'
import CutForm from '@/components/Forms/CutForm'
import ExportForm from '@/components/Forms/ExportForm'
import HoldForm from '@/components/Forms/HoldForm'
import HoldRequestForm from '@/components/Forms/HoldRequestForm'
import PitchForm from '@/components/Forms/PitchForm'
import PitchLinkForm from '@/components/Forms/PitchLinkForm'
import PlaylistForm from '@/components/Forms/PlaylistForm'
import PlaylistPitchForm from '@/components/Forms/PlaylistPitchForm'
import PlaylistCdLabelForm from '@/components/Forms/PlaylistCdLabelForm'
import PlaylistPitchLinkForm from '@/components/Forms/PlaylistPitchLinkForm'
import ProjectForm from '@/components/Forms/ProjectForm'
import PublishingCompanyForm from '@/components/Forms/PublishingCompanyForm'
import PublishingCompanyEditForm from '@/components/Forms/PublishingCompanyEditForm'
import RecordLabelForm from '@/components/Forms/RecordLabelForm'
import RecordLabelEditForm from '@/components/Forms/RecordLabelEditForm'
import ReleaseForm from '@/components/Forms/ReleaseForm'
import ReleaseInitForm from '@/components/Forms/ReleaseInitForm'
import SongForm from '@/components/Forms/SongForm'
import SendToPitchlogForm from '@/components/Forms/SendToPitchlogForm'
import SongLinkForm from '@/components/Forms/SongLinkForm'
import SongwriterForm from '@/components/Forms/SongwriterForm'
import SongwriterEditForm from '@/components/Forms/SongwriterEditForm'
import SyncForm from '@/components/Forms/SyncForm'
import WritingCreditForm from '@/components/Forms/WritingCreditForm'

export default {
  name: 'FormModal',

  components: {
    AccountUserForm,
    AlbumForm,
    ArtistForm,
    AudioFileEditForm,
    CompEmailForm,
    CompLinkForm,
    ContactForm,
    ContactEditForm,
    ContractForm,
    ContractOptionPeriodForm,
    ContractPublisherShareForm,
    ContractWriterShareForm,
    CutForm,
    ExportForm,
    HoldForm,
    HoldRequestForm,
    PitchForm,
    PitchLinkForm,
    PlaylistForm,
    PlaylistPitchForm,
    PlaylistCdLabelForm,
    PlaylistPitchLinkForm,
    ProjectForm,
    PublishingCompanyForm,
    PublishingCompanyEditForm,
    RecordLabelForm,
    RecordLabelEditForm,
    ReleaseForm,
    ReleaseInitForm,
    SendToPitchlogForm,
    SongForm,
    SongLinkForm,
    SongwriterForm,
    SongwriterEditForm,
    SyncForm,
    WritingCreditForm
  },

  props: {
    model: Object || Array,
    title: String,
    type: String,
    canDelete: Boolean,
    isModal: Boolean,
    message: String,
    isExporting: Boolean,
    isSaving: Boolean,
    isSendingNow: Boolean
  },

  data() {
    return {
      localModel: {},
      isConfirming: 0,
      localIsSendingNow: false
    }
  },

  created() {
    this.localModel = Object.assign({}, this.$props.model)
    this.localIsSendingNow = this.$props.isSendingNow
  },

  watch: {
    model: function (newValue, oldValue) {
      this.localModel = Object.assign({}, this.$props.model)
      this.isConfirming = 0
    }
  },

  methods: {
    close() {
      this.$emit('clear-modal')
    },
    itemSelected(item) {
      this.$emit('item-selected', item)
    }
  }
}
</script>
