<template>
  <section class="page-header is-paddingless is-marginless">
    <div class="level page-actions song" style="height: auto">
      <div class="level-left m-b-10">
        <div class="level-item song-title m-r-30">
          <h1>{{ song.title }}</h1>
        </div>
        <div class="level-item level-item-center is-flex-mobile m-r-30">
          <span v-if="song.active_hold_count" class="tag is-danger m-r-5">Hold</span>
          <span v-if="song.cut_count" class="tag is-primary m-r-5">Cut</span>
          <span v-if="song.release_count" class="tag is-success m-r-5">Release</span>
          <span v-if="song.sync_count" class="tag is-warning" style="color: #fff;">Sync</span>
        </div>
        <SongRating v-if="$can.includes('read-songs')"  :song="song" class="level-item-left" />
      </div>
      <div class="level-right">
        <div v-if="$can.includes('update-songs') || canBeEditedBySongwriterRole" class="level-item">
          <a class="is-primary tooltip " data-tooltip="Edit Song" @click="$emit('edit-song')">
            <IconEdit/>
          </a>
        </div>
        <div class="level-item" v-if="$can.includes('download-song-audio')">
          <a v-if="song.default_audio_file_id" class="is-primary tooltip " data-tooltip="Download Audio" @click="$emit('click-download-audio-button', song)">
            <IconDownload/>
          </a>
          <a v-else class="is-primary tooltip disabled"  style="cursor: not-allowed;" data-tooltip="No Audio">
            <IconDownload/>
          </a>
        </div>
        <div v-if="(!isLoading && !song.has_account && canBeEditedBySongwriterRole && $can.includes('create-song-submissions'))" class="level-item">
          <button
            v-if="(!song.submission_status)"
            type="button"
            class="button is-primary"
            @click="$emit('click-submit-song-button', song)"
          >Submit Song</button>

          <!-- TODO: add permissions -->
            <!-- v-else-if="(song.submission_status == 'pending' && !$can.includes('update-song-submissions'))" -->
          <button
            v-else-if="(song.submission_status == 'pending' && !$can.includes('update-song-submissions'))"
            type="button"
            class="button is-danger"
            disabled
          >Submitted</button>
            <!-- v-else-if="(song.submission_status == 'denied' && !$can.includes('update-song-submissions'))" -->
          <button
            v-else-if="(song.submission_status == 'denied' && !song.has_account && canBeEditedBySongwriterRole && $can.includes('create-song-submissions'))"
            type="button"
            class="button is-primary"
            @click="$emit('click-resubmit-song-button', song)"
          >Resubmit Song</button>
        </div>
        <div v-if="(!isLoading && !song.has_account && $can.includes('update-song-submissions') && song.submission_status == 'pending')" class="level-item">
          <button
            type="button"
            class="button is-success m-r-5"
            @click="$emit('click-approve-song-submission-button', song)"
          >Approve</button>
          <button
            type="button"
            class="button is-danger"
            @click="$emit('click-deny-song-submission-button', song)"
          >Deny</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SongRating from '@/components/Song/SongRating'
import { IconEdit, IconDownload } from '@/Icons/IconBase'

export default {
  name: 'PageHeaderSong',
  components: {
    SongRating,
    IconEdit,
    IconDownload
  },
  props: {
    song: Object,
    canBeEditedBySongwriterRole: Boolean
  },
  computed: {
    isLoading() {
      return this.$store.state.song.status === 'loading'
    }
  }
}
</script>
