export default {
  can: function (action) {
    const permissions = localStorage.getItem('permissions')
    return permissions && permissions.includes(action)
  },

  computed: {
    $can() {
      return localStorage.getItem('permissions')
    }
  }
}
