
<template>
    <section class="single-info is-hidden-mobile" v-if="playlist.pitch_group_emails">
      <div class="level">
        <div class="level-left">
          <h3 class="m-b-30">Emails</h3>
        </div>
        <div class="level-right" v-if="playlist.pitch_group_emails.length">
          <a class="is-primary" @click="showEmails !== 1 ? showEmails = 1 : showEmails = 0">
            <IconArrowUp v-if="showEmails === 0"/>
            <IconArrowDown v-if="showEmails === 1"/>
          </a>
        </div>
      </div>
        <div class="level" v-if="showEmails === 0 && playlist.pitch_group_emails.length">
          <div class="level-left">
          <p>There are {{playlist.pitch_group_emails.length}} emails for this playlist.</p>
          </div>
        </div>
        <div class="level" v-if="showEmails === 0 && !playlist.pitch_group_emails.length">
          <div class="level-left">
          <p>No emails to display.</p>
          </div>
        </div>
        <table class="table is-hoverable is-fullwidth" v-if="showEmails === 1 && playlist.pitch_group_emails.length">
          <thead class="is-size-7 is-sortable-column">
            <tr>
            <th class="has-text-grey-light">Sent By</th>
            <th class="has-text-grey-light">Recipient</th>
            <th class="has-text-grey-light">Sent At</th>
            <th class="has-text-grey-light">Status</th>
            <th class="has-text-grey-light">View Count</th>
            </tr>
          </thead>
          <tbody
            v-for="email in playlist.pitch_group_emails"
            :key="email.id"
            :pitch="email">
            <tr class="is-clickable">
              <td @click="openPitchGroupEmail(email)">{{ email.created_by | orBlank('full_name')}}</td>
              <td @click="openPitchGroupEmail(email)">{{ email.contact | orBlank('full_name') }}</td>
              <td @click="openPitchGroupEmail(email)">{{ email.created_at.date | moment-date }}</td>
              <td @click="openPitchGroupEmail(email)">{{ email.status | snakeToTitle }}</td>
              <td @click="openPitchGroupEmail(email)">{{ email.view_count }}</td>
            </tr>
          </tbody>
        </table>
    </section>
</template>

<script>
import { IconArrowUp, IconArrowDown } from '@/Icons/IconBase'

export default {
  name: 'PitchGroupEmailsPlaylist',
  props: ['playlist'],
  components: {
    IconArrowUp,
    IconArrowDown
  },
  data() {
    return {
      showEmails: 0
    }
  },
  methods: {
    openPitchGroupEmail(email) {
      this.$router.push({ name: 'pitch-group.show', params: { id: email.pitch_group_id, email: email } })
    }
  }
}
</script>
