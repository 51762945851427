<template>
  <div>
    <div class="field m-b-15">
      <label for="title-input" class="label">Song Title</label>
      <input id="title-input" type="text" class="input" placeholder="Song Title" v-model="song.title" required
        autocomplete="off" autofocus="true">
    </div>
    <div class="field m-b-15">
      <label for="date-written-input" class="label">Date Written</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('song.date_written', song.date_written, 'Date Written', 'single')">
          <span v-if="song.date_written">
            {{ song.date_written | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>
    <div class="field m-b-15">
      <label for="date-turned-in-input" class="label">Date Turned In</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('song.date_turned_in', song.date_turned_in, 'Date Turned In', 'single')">
          <span v-if="song.date_turned_in">
            {{ song.date_turned_in | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>

    <div class="field m-b-15">
      <label for="session-date-input" class="label">Session Date</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('song.session_date', song.session_date, 'Session Date', 'single')">
          <span v-if="song.session_date">
            {{ song.session_date | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>

    <div class="field m-b-30 m-t-15">
      <label for="manual-writing-credit-input" class="label">Allow Manual Songwriter Splits?</label>
      <div class="control">
        <label class="radio">
          <input class="m-r-5" type="radio" name="allowAudio" v-model="song.allow_manual_writing_credit_percentages"
            value="true" required>
          <span class="tooltip is-tooltip-right tooltip-span-label"
            data-tooltip="If 'Yes', splits will not be calculated automatically">Yes</span>
        </label>
        <label class="radio">
          <input class="m-r-5" type="radio" name="allowAudio" v-model="song.allow_manual_writing_credit_percentages"
            value="false">
          <span class="tooltip is-tooltip-right tooltip-span-label"
            data-tooltip="If 'No', splits will be calculated automatically">No</span>
        </label>
      </div>
    </div>
    <!-- TODO: need to figure who should be allowed to create account songs -->
    <div v-if="$can.includes('create-songs') && !$can.includes('create-song-submissions') && !song.account_id"
      class="field m-b-30 m-t-15">
      <input type="checkbox" class="checkbox m-r-10" v-model="localAccountSong" @change="check($event)">
      <span class="tooltip is-tooltip-right tooltip-span-label"
        data-tooltip="Songs not added to account will only be visible to you">Add To Account Catalog</span>
    </div>
    <div v-if="$can.includes('create-song-submissions') && $can.includes('create-songs') && !song.account_id"
      class="field m-b-30 m-t-15">
      <input id="manual-writing-credit-input" type="checkbox" class="checkbox m-r-10" v-model="song.song_submission">
      <span class="tooltip is-tooltip-right tooltip-span-label"
        data-tooltip="When approved, the song will be added to the account catalog.">Submit Song</span>
    </div>
  </div>
</template>

<script>
import datepicker from '@/mixins/datepicker'

export default {
  name: 'SongForm',
  model: {
    prop: 'song',
    event: 'change'
  },
  mixins: [datepicker],
  props: {
    song: Object
  },
  data() {
    return {
      localAccountSong: true
    }
  },
  created() {
    this.$props.song.account_song = this.$can.includes('create-song-submissions') ? null : this.localAccountSong
  },
  methods: {
    check: function (e) {
      this.$props.song.account_song = !this.$props.song.account_song
    }
  }
}
</script>
