import axios from 'axios'

export default {

  requestReset(email) {
    return axios({
      method: 'post',
      url: 'password/create',
      data: {
        email: email
      }
    })
  },

  findToken(token) {
    return axios({
      url: 'password/find/' + token
    })
  },

  resetPassword(token, email, password, passwordConfirmation) {
    return axios({
      method: 'post',
      url: 'password/reset',
      data: {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        token: token
      }
    })
  }

}
