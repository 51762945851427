<template>
  <div>
    <div class="field m-b-15">
      <label for="first_name" class="label">First Name</label>
      <input
        id="first_name"
        type="text"
        class="input"
        placeholder="First Name"
        @input="updateLocalUser"
        v-model="localAccountUser.first_name"
        required
      >
    </div>
    <div class="field m-b-15">
      <label for="last_name" class="label">Last Name</label>
      <input
        id="last_name"
        type="text"
        class="input"
        placeholder="Last Name"
        @input="updateLocalUser"
        v-model="localAccountUser.last_name"
      >
    </div>
    <div class="field m-b-15">
      <label for="email" class="label">Email</label>
      <input
        id="email"
        type="email"
        class="input"
        placeholder="Email"
        @input="updateLocalUser"
        v-model="localAccountUser.email"
        required
      >
    </div>
    <div class="field m-b-15" v-if="localAccountUser.role">
      <label for="role" class="label">Role</label>
      <div class="control">
        <multiselect
          v-model="localAccountUser.role"
          :options="roles"
          track-by="id"
          :custom-label="roleLabel"
          :close-on-select="true"
          @input="updateRole($event)"
          :allow-empty="false"
        ></multiselect>
      </div>
    </div>
    <div class="field m-b-15" v-else>
      <label for="role" class="label">Role</label>
      <div class="control">
        <multiselect
          v-model="localAccountUser.role"
          :options="roles"
          track-by="id"
          :custom-label="roleLabel"
          :close-on-select="true"
          @input="updateRole($event)"
          :allow-empty="false"
        ></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Active?</label>
      <div class="control">
        <label class="radio tooltip-span-label">
          <input
            type="radio"
            name="active"
            class="m-r-5"
            v-model="localAccountUser.active"
            value="true"
            @input="updateActiveStatus($event)"
            required
          >
          Yes
        </label>
        <label class="radio tooltip-span-label">
          <input
            type="radio"
            class="m-r-5 m-l-5"
            name="active"
            v-model="localAccountUser.active"
            value="false"
            @input="updateActiveStatus($event)"
          >
          No
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import RolesApi from '@/services/api/Roles'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AccountUserForm',
  model: {
    prop: 'accountUser',
    event: 'change'
  },
  props: {
    accountUser: Object
  },
  components: {
    Multiselect
  },
  data() {
    return {
      roles: [],
      localAccountUser: {}
    }
  },

  created() {
    this.localAccountUser = Object.assign({}, this.$props.accountUser)
    let self = this

    RolesApi.getRoles()
      .then(resp => {
        self.roles = resp.data
      })
      .catch(err => {
        self.$store.commit('error', 'Could not load roles.')
        console.log(err)
      })
  },
  methods: {
    updateLocalUser() {
      this.$props.accountUser.newUserData = Object.assign({}, this.localAccountUser)
    },
    updateActiveStatus(event) {
      this.localAccountUser.active = event.target.value
      this.$props.accountUser.newUserData = Object.assign({}, this.localAccountUser)
    },
    updateRole(event) {
      let newRole = this.roles.find(role => role.id === event.id)
      this.localAccountUser.role = newRole
      this.$props.accountUser.newUserData = Object.assign({}, this.localAccountUser)
    },
    roleLabel(role) {
      role = role.name.toString()
      role = role.replace(/_/g, ' ')
      var strSplit = role.split(' ')
      for (var i = 0; i < strSplit.length; i++) {
        strSplit[i] = strSplit[i].charAt(0).toUpperCase() + strSplit[i].slice(1)
      }
      return strSplit.join(' ')
    }
  }
}
</script>
