<template>
  <tr class="is-clickable">
    <td class="table-icon-play is-narrow is-hidden-mobile">
      <index-play-playlist-button
        :playlistId="playlist.id"
      ></index-play-playlist-button>
    </td>
    <!-- <td class="is-hidden-mobile">
      <div class="field">
        <input
          class="is-checkradio"
          v-bind:id="playlist.id"
          type="checkbox"
          name="selectedPlaylist"
          @change="toggleSelect"
          v-model="isSelected"
        >
        <label v-bind:for="playlist.id"></label>
      </div>
    </td> -->
    <td
      @click="openPlaylist"
      class="has-text-dark has-text-weight-bold table-block table-title"
    >{{playlist.title}}</td>
    <td class="table-block" data-th="Date Created:"><span @click="openPlaylist">{{ playlist.creation_date | moment-date }}</span>
      <a @click="openPitchMeetingView" class="table-icon tooltip playlist-mobile-actions is-pulled-right" data-tooltip="Pitch Meeting View">
        <IconRadio/>
      </a>
    </td>
    <td class="table-block" data-th="Pitching To:"><span @click="openPlaylist">{{ playlist.pitch_to_contact_name ? playlist.pitch_to_contact_name : (playlist.pitch_to_contact ? playlist.pitch_to_contact.full_name : '...' ) }}</span>
      <a
        class="table-icon playlist-mobile-actions is-pulled-right"
        v-if="playlist.type === 'Pitch' && playlist.songs && $can.includes('send song pitch')"
        @click.prevent="$emit('click-new-playlist-pitch-button', playlist)"
      ><IconMail/>
      </a>
      <a
        class="table-icon playlist-mobile-actions is-pulled-right"
        v-if="playlist.type === 'Comp' && playlist.songs && $can.includes('send song pitch')"
        @click.prevent="$emit('click-generate-playlist-comp-link-button', playlist)"
      ><IconMail/>
      </a>
    </td>
    <td @click="openPlaylist" class="is-hidden-mobile" data-th="Pitching For:">{{ playlist.pitch_for_artist_names | orBlank('name') }}</td>
    <td @click="openPlaylist" class="is-hidden-mobile" data-th="Type:">{{ playlist.type }}</td>
    <td class="is-narrow">
      <router-link
        class="table-icon tooltip m-r-10 is-hidden-mobile"
        data-tooltip="View Playlist"
        :to="{ name: 'playlists.show', params: { id: playlist.id, propped: playlist }}"
      >
        <IconEye/>
      </router-link>
      <a
        v-if="$can.includes('update-playlists')"
        class="table-icon tooltip m-r-10 is-hidden-mobile"
        data-tooltip="Quick Edit"
        @click="$emit('click-edit-button', playlist)"
      >
        <IconEdit/>
      </a>
      <!-- <a
        v-if="$can.includes('delete-playlists')"
        class="table-icon tooltip m-r-10 is-hidden-mobile"
        data-tooltip="Remove Playlist"
        @click="$emit('click-remove-playlist-button', playlist)"
      >
        <IconTrash/>
      </a> -->
      <a @click="openPitchMeetingView" class="table-icon tooltip is-hidden-mobile" data-tooltip="Pitch Meeting View">
        <IconRadio/>
      </a>
    </td>
    <td class="is-narrow is-hidden-mobile">
      <div
        class="dropdown is-right"
        id="dropdown"
        :class="{ 'is-active': isOpen }"
        v-click-outside="close"
      >
        <div class="dropdown-trigger">
          <button class="button table-icon" @click="isOpen = !isOpen">
            <span class="icon">
              <IconMoreHorizontal/>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu">
          <div class="dropdown-content">
            <a @click.prevent="$emit('add-to-queue', playlist)" class="dropdown-item">Add to Queue</a>
            <hr class="dropdown-divider">
            <a
              v-if="$can.includes('download-song-audio')"
              class="dropdown-item"
              @click.prevent="$emit('click-download-button', playlist)"
            >Download Playlist</a>
            <a
              v-if="$can.includes('create-playlists')"
              class="dropdown-item"
              @click.prevent="$emit('click-duplicate-button', playlist)"
            >Duplicate Playlist</a>
            <a
              v-if="$can.includes('delete-playlists')"
              class="dropdown-item"
              @click="$emit('click-remove-playlist-button', playlist)"
            >Delete Playlist
            </a>
            <a
              v-if="playlist.type === 'Pitch' && $can.includes('send song pitch')"
              class="dropdown-item"
              @click.prevent="$emit('click-generate-playlist-pitch-link-button', playlist)"
            >Generate Link</a>
            <a
              v-if="playlist.type === 'Comp' && $can.includes('send song pitch')"
              class="dropdown-item"
              @click.prevent="$emit('click-generate-playlist-comp-link-button', playlist)"
            >Generate Link</a>
            <hr class="dropdown-divider">
            <a
              v-if="$can.includes('send-to-pitchlog')"
              class="dropdown-item"
              @click.prevent="$emit('click-send-to-pitchlog-button', playlist)"
            >Send To Pitchlog</a>
            <hr class="dropdown-divider">
            <a
              v-if="playlist.type === 'Comp' && $can.includes('send song pitch')"
              @click.prevent="$emit('click-new-comp-email-button', playlist)"
              class="dropdown-item"
            >Send Comp</a>
            <a
              v-if="playlist.type === 'Pitch' && $can.includes('send song pitch')"
              @click.prevent="$emit('click-new-playlist-pitch-button', playlist)"
              class="dropdown-item"
            >Send Pitch</a>
            <hr class="dropdown-divider">
            <a
              @click.prevent="$emit('click-create-cd-labels-button', playlist)"
              class="dropdown-item"
            >Create CD Label</a>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { IconRadio, IconEdit, IconMoreHorizontal, IconEye, IconMail } from '@/Icons/IconBase'
import IndexPlayPlaylistButton from '@/components/IndexPlayPlaylistButton'

export default {
  name: 'TablePlaylistsRow',
  props: {
    playlist: Object,
    selectAll: Boolean
  },
  components: {
    IconRadio,
    IconMoreHorizontal,
    IconEye,
    IndexPlayPlaylistButton,
    IconEdit,
    IconMail
  },

  data() {
    return {
      isOpen: false,
      isSelected: false
    }
  },

  watch: {
    selectAll: function (newValue) {
      this.isSelected = newValue
    }
  },

  // computed: {
  //   pitch_to_contact() {
  //     if (this.playlist.pitch_to_contact) {
  //       return typeof (this.playlist.pitch_to_contact) === 'object' ? this.playlist.pitch_to_contact.name : this.playlist.pitch_to_contact
  //     }
  //   }
  // },

  methods: {
    toggleSelect() {
      this.isSelected = !this.isSelected
    },

    close() {
      this.isOpen = false
    },

    openPlaylist() {
      this.$router.push({ name: 'playlists.show', params: { id: this.playlist.id, propped: this.playlist } })
    },

    openPitchMeetingView() {
      this.$router.push({ name: 'playlists.pitch-meeting.show', params: { id: this.playlist.id, propped: this.playlist } })
    }
  }
}
</script>
