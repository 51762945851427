<template>
  <nav v-if="totalPages > 1" class="pagination" role="navigation" aria-label="pagination">
    <a class="pagination-previous" :disabled="!previousPageAvailable" @click="previousPage">Previous</a>
    <a class="pagination-next" :disabled="!nextPageAvailable" @click="nextPage">Next page</a>
    <ul class="pagination-list">
      <li v-for="index in range" :key="index">
        <a
          v-if="index !== '...'"
          class="pagination-link"
          v-bind:class="{ 'is-current': index === currentPage}"
          aria-label="Goto page"
          @click="updateCurrentPage(index)"
        >{{index}}</a>

        <span
          v-if="index === '...'"
        >{{index}}</span>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    currentPage: Number,
    totalPages: Number
  },

  computed: {
    nextPageAvailable: function () {
      return this.currentPage < this.totalPages
    },

    previousPageAvailable: function () {
      return this.currentPage > 1
    },

    range: function () {
      var current = this.currentPage

      var last = this.totalPages

      var delta = 2

      var left = current - delta

      var right = current + delta + 1

      var range = []

      var rangeWithDots = []

      var l

      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i)
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1)
          } else if (i - l !== 1) {
            rangeWithDots.push('...')
          }
        }
        rangeWithDots.push(i)
        l = i
      }

      return rangeWithDots
    }
  },

  methods: {
    updateCurrentPage: function (value) {
      this.$emit('current-page-change', value)
    },

    nextPage: function () {
      if (this.nextPageAvailable) {
        this.updateCurrentPage(this.currentPage + 1)
      }
    },

    previousPage: function () {
      if (this.previousPageAvailable) {
        this.updateCurrentPage(this.currentPage - 1)
      }
    }
  }
}
</script>
