import Vue from 'vue'
import axios from 'axios'

import {
  PROJECT_ALL,
  PROJECT_ALL_FOR_FORM,
  PROJECT_CREATE,
  PROJECT_READ,
  PROJECT_UPDATE,
  PROJECT_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  PROJECT_LOADING,
  PROJECT_SUCCESS,
  PROJECT_ERROR,
  PROJECT_SET,
  PROJECTS_SET,
  PROJECT_CURRENT_PAGE_SET,
  PROJECT_PER_PAGE_SET,
  PROJECT_TOTAL_PAGES_SET,
  PROJECT_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    projects: [],
    project: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [PROJECT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [PROJECT_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [PROJECT_ERROR]: (state) => {
      state.status = 'error'
    },
    [PROJECT_SET]: (state, resp) => {
      Vue.set(state, 'project', resp)
    },
    [PROJECTS_SET]: (state, resp) => {
      Vue.set(state, 'projects', resp)
    },
    [PROJECT_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [PROJECT_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [PROJECT_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [PROJECT_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'project', {})
      Vue.set(state, 'projects', [])
    }
  },

  actions: {
    [PROJECT_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(PROJECT_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(PROJECT_ERROR)
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage
      }

      if (state.searchValue && state.searchValue.length) {
        params.search = state.searchValue
      }

      axios({
        url: 'accounts/' + accountId + '/search/projects',
        params: params
      })
        .then(resp => {
          commit(PROJECT_SUCCESS)
          commit(PROJECTS_SET, resp.data.data)
          commit(PROJECT_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(PROJECT_ERROR)
        })
    },
    [PROJECT_ALL_FOR_FORM]: ({
      commit,
      getters
    }) => {
      commit(PROJECT_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(PROJECT_ERROR)
        return false
      }

      axios({
        url: 'accounts/' + accountId + '/projects'
      })
        .then(resp => {
          commit(PROJECT_SUCCESS)
          commit(PROJECTS_SET, resp.data.data)
        })
        .catch(resp => {
          commit(PROJECT_ERROR)
        })
    },

    [PROJECT_READ]: ({
      commit
    }, id) => {
      commit(PROJECT_LOADING)
      commit(PROJECT_SET, {})
      axios({
        url: 'projects/' + id
      })
        .then(resp => {
          commit(PROJECT_SUCCESS)
          commit(PROJECT_SET, resp.data.data)
        })
        .catch(resp => {
          commit(PROJECT_ERROR)
        })
    },

    [PROJECT_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(PROJECT_LOADING)
        commit(PROJECT_SET, {})

        let accountId = getters.getActiveAccountId

        if (data.contact) {
          data.contact_id = data.contact.id
        }

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/projects',
          data: data
        })
          .then(resp => {
            commit(PROJECT_SUCCESS)
            commit(PROJECT_SET, resp.data.data)
            resolve(resp.data.data)
            dispatch(PROJECT_ALL)
          })
          .catch(err => {
            commit(PROJECT_ERROR)
            reject(err)
          })
      })
    },

    [PROJECT_UPDATE]: ({
      commit,
      dispatch
    }, { id, data }) => {
      return new Promise((resolve, reject) => {
        commit(PROJECT_LOADING)
        commit(PROJECT_SET, {})

        if (data.contact) {
          data.contact_id = data.contact.id
        }

        axios({
          method: 'put',
          url: 'projects/' + id,
          data: data
        })
          .then(resp => {
            commit(PROJECT_SUCCESS)
            commit(PROJECT_SET, resp.data.data)
            dispatch(PROJECT_ALL)
            resolve(resp)
          })
          .catch(err => {
            commit(PROJECT_ERROR)
            reject(err)
          })
      })
    },

    [PROJECT_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(PROJECT_LOADING)

      axios({
        method: 'delete',
        url: 'projects/' + id
      })
        .then(resp => {
          commit(PROJECT_SET, {})
          dispatch(PROJECT_ALL)
        })
        .catch(resp => {
          commit(PROJECT_ERROR)
        })
    }
  }
}
