<template>
  <div>
    <index-header title="User Profile"></index-header>
    <div class="columns">
      <div class="column is-7">
        <h3 class="m-b-30">Profile Info</h3>
        <form class="form" @submit="save">
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label for="First Name" class="label">First Name</label>
                <div class="control">
                  <input class="input" type="text" v-model="currentUser.first_name">
                </div>
              </div>

              <div class="field">
                <label for="Email" class="label">Email Address</label>
                <div class="control">
                  <input class="input" type="email" v-model="currentUser.email">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label for="Last Name" class="label">Last Name</label>
                <div class="control">
                  <input class="input" type="text" v-model="currentUser.last_name">
                </div>
              </div>
              <div class="field">
                <label for="phone_number" class="label">Phone Number</label>
                <div class="control">
                  <input class="input" type="text" v-model="currentUser.phone_number">
                </div>
              </div>
            </div>
          </div>
          <div class="level m-t-15">
            <div class="level-left">
              <div class="level-item">
                <button type="submit" class="button is-primary">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-3">
        <h3 class="m-b-30">Profile Image</h3>
        <image-editor
          :image="currentUser.image"
          parentType="user"
          :parentId="currentUser.id"
          v-on:image-updated="reload"
        ></image-editor>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_SELF, USER_SELF_UPDATE } from '@/store/actions'

export default {
  name: 'UserProfile',

  data() {
    return {
      selectedFile: null
    }
  },

  computed: {
    currentUser: function () {
      return Object.assign({}, this.$store.getters.getProfile)
    }
  },

  methods: {
    save($event) {
      $event.preventDefault()
      let self = this
      this.$store.dispatch(USER_SELF_UPDATE, this.currentUser)
        .then(function (data) {
          self.$store.commit('success', 'User profile updated.')
        }, function (err) {
          self.$store.commit('error', err)
        })
    },

    reload() {
      this.$store.dispatch(USER_SELF)
    }
  }
}
</script>
