import Vue from 'vue'
import axios from 'axios'

import {
  ACCOUNT_READ_ACTIVE_ACCOUNT,
  AUTH_LOGOUT,
  USER_SELF,
  USER_ALL,
  USER_CREATE,
  USER_READ,
  USER_UPDATE,
  USER_SELF_UPDATE,
  USER_SELF_UPDATE_PASSWORD,
  USER_DELETE
} from '../actions'

import {
  AUTH_LOGGED_OUT,
  USER_LOADING,
  USER_ERROR,
  USER_SUCCESS,
  USER_SET_PROFILE,
  USER_SET,
  USERS_SET,
  USER_TOTAL_PAGES_SET
} from '../mutations'

export default {
  state: {
    status: '',
    profile: {},
    users: [],
    user: {},
    sessionId: Math.random().toString().slice(2)
  },

  getters: {
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile.full_name,
    getSessionId: state => state.sessionId
  },

  mutations: {
    [USER_LOADING]: (state) => {
      state.status = 'loading'
    },
    [USER_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [USER_ERROR]: (state) => {
      state.status = 'error'
    },
    [AUTH_LOGGED_OUT]: (state) => {
      state.profile = {}
      state.activeAccount = null
    },
    [USER_SET_PROFILE]: (state, resp) => {
      Vue.set(state, 'profile', resp)
    },
    [USER_SET]: (state, resp) => {
      Vue.set(state, 'user', resp)
    },
    [USERS_SET]: (state, resp) => {
      Vue.set(state, 'users', resp)
    }
  },

  actions: {
    [USER_SELF]: ({
      commit,
      dispatch
    }) => {
      return new Promise((resolve, reject) => {
        commit(USER_LOADING)
        axios({
          url: 'users/me'
        })
          .then(resp => {
            commit(USER_SET_PROFILE, resp.data.data)
            localStorage.setItem('active-account-id', resp.data.data.default_account_id)
            localStorage.setItem('permissions', resp.data.data.permissions)
            dispatch(ACCOUNT_READ_ACTIVE_ACCOUNT, resp.data.data.default_account_id).then(function (resp) {
              commit(USER_SUCCESS)
              resolve(resp)
            }, function (err) {
              commit(USER_ERROR)
              reject(err)
            })
          })
          .catch(err => {
            commit(USER_ERROR)
            reject(err)
            // if resp is unauthorized, logout, to
            dispatch(AUTH_LOGOUT)
          })
      })
    },

    [USER_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(USER_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(USER_ERROR)
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage
      }

      if (state.searchValue && state.searchValue.length) {
        params.search = state.searchValue
      }

      axios({
        url: 'accounts/' + accountId + '/users',
        params: params
      })
        .then(resp => {
          commit(USER_SUCCESS)
          commit(USERS_SET, resp.data.data)
          commit(USER_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(USER_ERROR)
        })
    },

    [USER_READ]: ({
      commit
    }, id) => {
      commit(USER_LOADING)
      commit(USER_SET, {})
      axios({
        url: 'users/' + id
      })
        .then(resp => {
          commit(USER_SUCCESS)
          commit(USER_SET, resp.data.data)
        })
        .catch(resp => {
          commit(USER_ERROR)
        })
    },

    [USER_CREATE]: ({
      commit,
      dispatch,
      getters
    }, data) => {
      commit(USER_LOADING)
      commit(USER_SET, {})

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(USER_ERROR)
        return false
      }

      axios({
        method: 'post',
        url: 'accounts/' + accountId + '/users',
        data: data
      })
        .then(resp => {
          commit(USER_SUCCESS)
          commit(USER_SET, resp.data.data)
          dispatch(USER_ALL)
        })
        .catch(resp => {
          commit(USER_ERROR)
        })
    },

    [USER_SELF_UPDATE]: ({
      commit,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        if (!getters.isProfileLoaded) {
          commit(USER_ERROR)
          reject(new Error('User not found'))
          return false
        }

        commit(USER_LOADING)

        axios({
          method: 'put',
          url: 'users/me',
          data: data
        })
          .then(resp => {
            commit(USER_SUCCESS)
            commit(USER_SET_PROFILE, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(USER_ERROR)
            reject(err)
          })
      })
    },

    [USER_SELF_UPDATE_PASSWORD]: ({
      commit,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        if (!getters.isProfileLoaded) {
          commit(USER_ERROR)
          reject(new Error('User not found.'))
          return false
        }

        commit(USER_LOADING)

        axios({
          method: 'put',
          url: 'users/password',
          data: data
        })
          .then(resp => {
            commit(USER_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            commit(USER_ERROR)
            reject(err)
          })
      })
    },

    [USER_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(USER_LOADING)
      commit(USER_SET, {})
      axios({
        method: 'put',
        url: 'users/' + id,
        data: data
      })
        .then(resp => {
          commit(USER_SUCCESS)
          commit(USER_SET, resp.data.data)
          dispatch(USER_ALL)
        })
        .catch(resp => {
          commit(USER_ERROR)
        })
    },

    [USER_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(USER_LOADING)

      axios({
        method: 'delete',
        url: 'users/' + id
      })
        .then(resp => {
          commit(USER_SET, {})
          dispatch(USER_ALL)
        })
        .catch(resp => {
          commit(USER_ERROR)
        })
    }
  }
}
