import Vue from 'vue'
import axios from 'axios'

import {
  SONG_SUBMISSION_CREATE,
  SONG_SUBMISSION_UPDATE
} from '../actions'

import {
  CLEAR_STORE,
  SONG_SUBMISSION_LOADING,
  SONG_SUBMISSION_SUCCESS,
  SONG_SUBMISSION_ERROR,
  SONG_SUBMISSION_DELETED,
  SONG_SUBMISSION_UPDATED,
  SONG_SUBMISSION_SET,
  SONG_SUBMISSION_SET_ALL,
  SONG_SUBMISSION_CURRENT_PAGE_SET,
  SONG_SUBMISSION_PER_PAGE_SET,
  SONG_SUBMISSION_TOTAL_PAGES_SET,
  SONG_SUBMISSION_SORT_SET
} from '../mutations'

export default {
  state: {
    status: '',
    songSubmissions: [],
    songSubmission: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    sortColumn: null,
    sortOrder: null
  },

  getters: {
    getSongSubmissions: state => state.songSubmissions,
    isSongSubmissionsLoaded: state => !!state.songSubmissions,
    getSongSubmission: state => state.songSubmission,
    isSongSubmissionLoaded: state => !!state.songSubmission
  },

  mutations: {
    [SONG_SUBMISSION_LOADING]: (state) => {
      state.status = 'loading'
    },
    [SONG_SUBMISSION_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [SONG_SUBMISSION_ERROR]: (state) => {
      state.status = 'error'
    },
    [SONG_SUBMISSION_DELETED]: (state, id) => {
      let index = state.songSubmissions.findIndex(songSubmission => songSubmission.id === id)
      state.songSubmissions.splice(index, 1)
    },
    [SONG_SUBMISSION_UPDATED]: (state, data) => {
      if (state.songSubmissions.length) {
        let index = state.songSubmissions.findIndex(songSubmission => songSubmission.id === data.id)
        state.songSubmissions[index].status = data.status
        state.songSubmissions[index].account_id = data.accountId
      }

      if (state.songSubmission && state.songSubmission.id === data.id) {
        state.songSubmission.status = data.status
        state.songSubmission.account_id = data.accountId
      }
    },
    [SONG_SUBMISSION_SET]: (state, resp) => {
      Vue.set(state, 'songSubmission', resp)
    },
    [SONG_SUBMISSION_SET_ALL]: (state, resp) => {
      Vue.set(state, 'songSubmissions', resp)
    },
    [SONG_SUBMISSION_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [SONG_SUBMISSION_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [SONG_SUBMISSION_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [SONG_SUBMISSION_SORT_SET]: (state, {
      column,
      order
    }) => {
      state.sortColumn = column
      state.sortOrder = order
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'songSubmission', {})
      Vue.set(state, 'songSubmissions', [])
    }
  },

  actions: {
    [SONG_SUBMISSION_CREATE]: ({
      commit,
      getters
    }, data) => {
      return new Promise(function (resolve, reject) {
        commit(SONG_SUBMISSION_LOADING)
        commit(SONG_SUBMISSION_SET, {})

        let accountId = getters.getActiveAccountId

        if (!accountId) {
          commit(SONG_SUBMISSION_ERROR)
          reject(new Error('no account specified'))
          return false
        }

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/song-submissions',
          data: data
        })
          .then(resp => {
            commit(SONG_SUBMISSION_SUCCESS)
            commit(SONG_SUBMISSION_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(SONG_SUBMISSION_ERROR)
            reject(err)
          })
      })
    },

    [SONG_SUBMISSION_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      return new Promise(function (resolve, reject) {
        commit(SONG_SUBMISSION_LOADING)

        axios({
          method: 'put',
          url: 'song-submissions/' + id,
          data: data
        })
          .then(resp => {
            commit(SONG_SUBMISSION_SUCCESS)
            commit(SONG_SUBMISSION_UPDATED, resp.data.data)
            commit(SONG_SUBMISSION_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(SONG_SUBMISSION_ERROR)
            reject(err)
          })
      })
    }
  }
}
