import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './registerServiceWorker'
import { API_URL, GA_TRACKING_ID } from './env-config'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import VueAnalytics from 'vue-analytics'
import { AUTH_LOGOUT } from '@/store/actions'

import $ from 'jquery'
import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widget'

import 'jquery-ui/ui/widgets/mouse'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'

import VueScrollTo from 'vue-scrollto'
import VueTextareaAutosize from 'vue-textarea-autosize'

import AsideResource from './components/AsideResource'
import AsideSettings from './components/AsideSettings'
import AsideCommunity from './components/AsideCommunity'
import AudioFileSelect from './components/AudioFileSelect'
import AudioPlayer from './components/AudioPlayer'
import ColumnSortIndicator from './components/ColumnSortIndicator'
import DeleteButton from './components/DeleteButton'
import ExportButton from './components/ExportButton'
import FormModal from './components/Modals/FormModal'
import FileFormModal from './components/Modals/FileFormModal'
import DatepickerModal from './components/Modals/DatepickerModal'
import FileEditor from './components/FileEditor'
import ImageEditor from './components/ImageEditor'
import IndexPlayButton from './components/IndexPlayButton'
import Pagination from './components/Pagination'
import QueuePlayButton from './components/QueuePlayButton'
import SessionPlayPitchGroupButton from './components/SessionPlayPitchGroupButton'
import SessionPlayPlaylistButton from './components/SessionPlayPlaylistButton'
import PlayButton from './components/PlayButton'
import SearchInput from './components/SearchInput'
import TableActions from './components/TableActions'
import TagControl from './components/TagControl'
import NoteControl from './components/NoteControl'
import ActivityViewer from './components/ActivityViewer'
import MessageDisplay from './components/MessageDisplay'
import MessageModal from './components/MessageModal'
import ConfirmDisplay from './components/ConfirmDisplay'
import SongLyrics from './components/SongLyrics'

import clickOutside from './directives/clickOutside'

import arrayString from './filters/arrayString'
import audioFileDownloadedAtFormatting from './filters/audioFileDownloadedAtFormatting'
import eventFormatting from './filters/eventFormatting'
import capitalize from './filters/capitalize'
import momentDate from './filters/momentDate'
import momentDateTime from './filters/momentDateTime'
import orBlank from './filters/orBlank'
import phpClassName from './filters/phpClassName'
import snakeToTitle from './filters/snakeToTitle'

import AdvancedSearchIndexHeader from './layouts/AdvancedSearch/AdvancedSearchIndexHeader'
import IndexHeader from './layouts/Default/IndexHeader'
import IndexAside from './layouts/Default/IndexAside'

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.component('aside-resource', AsideResource)
Vue.component('aside-settings', AsideSettings)
Vue.component('aside-community', AsideCommunity)
Vue.component('audio-player', AudioPlayer)
Vue.component('audio-file-select', AudioFileSelect)
Vue.component('column-sort-indicator', ColumnSortIndicator)
Vue.component('delete-button', DeleteButton)
Vue.component('export-button', ExportButton)
Vue.component('file-form-modal', FileFormModal)
Vue.component('file-editor', FileEditor)
Vue.component('form-modal', FormModal)
Vue.component('datepicker-modal', DatepickerModal)
Vue.component('image-editor', ImageEditor)
Vue.component('index-play-button', IndexPlayButton)
Vue.component('pagination', Pagination)
Vue.component('play-button', PlayButton)
Vue.component('queue-play-button', QueuePlayButton)
Vue.component('session-play-pitch-group-button', SessionPlayPitchGroupButton)
Vue.component('session-play-playlist-button', SessionPlayPlaylistButton)
Vue.component('search-input', SearchInput)
Vue.component('table-actions', TableActions)
Vue.component('tag-control', TagControl)
Vue.component('note-control', NoteControl)
Vue.component('activity-viewer', ActivityViewer)
Vue.component('message-display', MessageDisplay)
Vue.component('message-modal', MessageModal)
Vue.component('confirm-display', ConfirmDisplay)
Vue.component('song-lyrics', SongLyrics)

Vue.directive('click-outside', clickOutside)
Vue.directive('scroll-to', VueScrollTo)

Vue.filter('arrayString', arrayString)
Vue.filter('audioFileDownloadedAtFormatting', audioFileDownloadedAtFormatting)
Vue.filter('eventFormatting', eventFormatting)
Vue.filter('capitalize', capitalize)
Vue.filter('momentDate', momentDate)
Vue.filter('momentDateTime', momentDateTime)
Vue.filter('orBlank', orBlank)
Vue.filter('phpClassName', phpClassName)
Vue.filter('snakeToTitle', snakeToTitle)

Vue.component('advanced-search-index-header', AdvancedSearchIndexHeader)
Vue.component('index-header', IndexHeader)
Vue.component('index-aside', IndexAside)

Vue.use(VueTextareaAutosize)

Vue.use(VueAnalytics, {
  id: GA_TRACKING_ID,
  router
})

const token = localStorage.getItem('user-token')

if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.defaults.baseURL = API_URL

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch(AUTH_LOGOUT).then(() => {
        router.push('/login')
      })
    }
    return Promise.reject(error)
  }
)

Vue.config.productionTip = false

require('./assets/main.scss')

window.$ = window.jQuery = require('jquery')
window.$ = $.extend(require('jquery-ui'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
