<template>
  <div>
    <index-header title="Account Profile"></index-header>
    <div class="columns">
      <div class="column is-7">
        <h3 class="m-b-30">Account Info</h3>
        <form class="form" @submit="save">
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label for="First Name" class="label">Company Name</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.company_name"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>

              <div class="field">
                <label for="First Name" class="label">Address Line 1</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.address_line1"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
              <div class="field">
                <label for="First Name" class="label">Address Line 2</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.address_line2"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
              <div class="field">
                <label for="First Name" class="label">City</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.address_city"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
              <div class="field">
                <label for="First Name" class="label">State</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.address_state"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
              <div class="field">
                <label for="First Name" class="label">Zip Code</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.address_zip"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
              <div class="field">
                <label for="First Name" class="label">Country</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.address_country"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label for="First Name" class="label">Website</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.website"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
              <div class="field">
                <label for="First Name" class="label">Phone Number</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="currentAccount.phone"
                    :disabled="!canUpdateAccount"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="level m-t-15 m-b-30">
            <div class="level-left">
              <div class="level-item">
                <button type="submit" class="button is-primary" :disabled="!canUpdateAccount">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-3">
        <h3 class="m-b-30">Account Image</h3>
        <image-editor
          title="Upload New Logo"
          :image="currentAccount.image"
          parentType="account"
          :parentId="currentAccount.id"
          v-on:image-updated="reload"
          v-if="canUpdateAccount"
        ></image-editor>
        <div v-else class="level">
          <div class="level-item">
            <figure class="image main-figure is-128x128">
              <img
                class="is-rounded"
                v-bind:src="currentAccount.image.url"
                v-if="currentAccount.image"
              >
              <img class="is-rounded" src="http://placehold.it/200x200" v-else>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ACCOUNT_READ_ACTIVE_ACCOUNT, ACCOUNT_UPDATE_ACTIVE_ACCOUNT } from '@/store/actions'

export default {
  name: 'AccountSettings',

  computed: {
    currentAccount: function () {
      return Object.assign({}, this.$store.getters.getActiveAccount)
    },
    canUpdateAccount: function () {
      return this.$can.includes('update-accounts')
    }
  },

  methods: {
    save($event) {
      $event.preventDefault()
      this.$store.dispatch(ACCOUNT_UPDATE_ACTIVE_ACCOUNT, this.currentAccount)
      this.$store.commit('success', 'Settings updated.')
    },

    reload() {
      this.$store.dispatch(ACCOUNT_READ_ACTIVE_ACCOUNT, this.currentAccount.id)
    }
  }
}
</script>
