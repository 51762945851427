import axios from 'axios'

export default {
  searchPlaylists(accountId, page = 1, perPage = 15, titleQuery = null) {
    const params = {
      per_page: perPage,
      page: page
    }

    if (titleQuery) {
      params.title = titleQuery
    }
    return axios({
      url: 'accounts/' + accountId + '/search/playlists',
      params
    })
  },

  addSong(playlistId, songId) {
    return axios({
      url: 'playlists/' + playlistId + '/songs',
      method: 'post',
      data: {
        song_id: songId
      }
    })
  },
  addAudioFile(playlistId, audioFileId, songId) {
    return axios({
      url: 'playlists/' + playlistId + '/audio-files',
      method: 'post',
      data: {
        audio_file_id: audioFileId,
        song_id: songId
      }
    })
  },
  getPlaylist(id) {
    return axios({
      url: 'playlists/' + id
    })
  }
}
