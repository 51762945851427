import axios from 'axios'
import { CLIENT_ID } from '../../env-config'

import { AUTH_LOGIN, AUTH_LOGOUT, USER_SELF } from '@/store/actions'

import {
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGGED_OUT,
  CLEAR_STORE
} from '@/store/mutations'

export default {
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',
    hasLoadedOnce: false
  },

  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
  },

  mutations: {
    [AUTH_LOADING]: state => {
      state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = 'success'
      state.token = resp.data.access_token
      state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: state => {
      state.status = 'error'
      state.hasLoadedOnce = true
    },
    [AUTH_LOGGED_OUT]: state => {
      state.token = ''
    }
  },

  actions: {
    [AUTH_LOGIN]: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_LOADING)

        user.grant_type = 'password'
        user.client_id = CLIENT_ID

        axios({
          url: 'oauth/token',
          data: user,
          method: 'POST'
        })
          .then(resp => {
            localStorage.setItem('user-token', resp.data.access_token)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + resp.data.access_token
            dispatch(USER_SELF).then(
              userResp => {
                commit(AUTH_SUCCESS, resp)
                resolve(userResp)
              },
              err => {
                reject(err)
              }
            )
          })
          .catch(err => {
            commit(AUTH_ERROR, err)
            localStorage.removeItem('user-token')
            reject(err)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_LOGGED_OUT)
        commit(CLEAR_STORE)
        localStorage.removeItem('user-token')
        localStorage.removeItem('active-account-id')
        localStorage.removeItem('permissions')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  }
}
