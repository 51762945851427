<template>
  <div v-if="$can.includes('read-contacts')">
    <index-header
      title="Contacts"
      :newButtonText="$can.includes('create-contacts') ? 'Add Contact' : null"
      exportType="contact"
      :exportSearch="searchValue"
      v-on:click-new-button="create"
    ></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <search-input
        :searchValue="searchValue"
        placeholder="Search Contacts"
        v-on:search-input-change="handleSearchInput"
      />
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <th class="has-text-grey-light" v-if="$can.includes('view contact info')">Email</th>
          <th class="has-text-grey-light" v-if="$can.includes('view contact info')">Mobile</th>
          <th class="has-text-grey-light">Company</th>
          <th class="has-text-grey-light">Type</th>
          <th class="has-text-grey-light" v-if="$can.includes('view contact info')">Location</th>
        </tr>
      </thead>
      <tbody>
        <tr class="is-clickable" v-for="contact in contacts" :key="contact.id">
          <td
            @click="show(contact)"
            class="has-text-dark has-text-weight-bold table-title"
          >{{ contact.full_name }}</td>
          <td v-if="$can.includes('view contact info')" @click="show(contact)" class="is-hidden-mobile" data-th="Email:">{{contact.email ? contact.email : '...'}}</td>
          <td v-if="$can.includes('view contact info')" @click="show(contact)" class="is-hidden-mobile" data-th="Mobile:">{{contact.mobile_phone ? contact.mobile_phone : '...'}}</td>
          <td @click="show(contact)" class="is-hidden-mobile" data-th="Company:">{{contact.company_name ? contact.company_name : '...'}}</td>
          <td @click="show(contact)" class="is-hidden-mobile" data-th="Type:">{{contact.type ? contact.type.name : '...'}}</td>
          <td v-if="$can.includes('view contact info')" @click="show(contact)" class="is-hidden-mobile" data-th="Location:">{{contact.address_city && contact.address_state ? contact.address_city + ', ' + contact.address_state : '...'}}</td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
    <form-modal
      :type="formType"
      :title="formTitle"
      :message="modalMessage"
      :model="selectedContact"
      :isModal="isModal"
      :canDelete="$can.includes('delete-contacts')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
    ></form-modal>
  </div>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'

import { CONTACT_ALL, CONTACT_CREATE } from '@/store/actions'
import { CONTACT_CURRENT_PAGE_SET, CONTACT_PER_PAGE_SET, CONTACT_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'ContactIndex',
  mixins: [uiHelper],
  data() {
    return {
      currentPageMutation: CONTACT_CURRENT_PAGE_SET,
      perPageMutation: CONTACT_PER_PAGE_SET,
      searchValueMutation: CONTACT_SEARCH_VALUE_SET,
      formTitle: '',
      formType: null,
      formCanDelete: false,
      isModal: false,
      modalMessage: null,
      selectedContact: {}
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.contact
    },

    contacts() {
      return this.$store.state.contact.contacts
    }
  },

  methods: {
    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(CONTACT_ALL)
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New Contact'
      this.formType = 'contact'
      this.modalMessage = 'You can add more details on the next screen.'
    },

    show(contact) {
      this.$router.push({ name: 'contacts.show', params: { id: contact.id, propped: contact } })
    },

    save(data) {
      let self = this
      if (data.type) {
        data.contact_type_id = data.type.id
      }
      this.$store.dispatch(CONTACT_CREATE, data)
        .then(function (contact) {
          // self.edit(contact)
          self.clearModal()
        }, function (err) {
          self.clearModal()
          self.$store.commit('error', err)
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedContact = {}
    }
  }
}
</script>
