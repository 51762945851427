<template>
  <div>
    <div class="field m-b-15">
      <label for="requested_date" class="label">Requested Date</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('sync.requested_date', sync.requested_date, 'Requested Date', 'single')">
          <span v-if="sync.requested_date">
            {{ sync.requested_date | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>
    <div class="field m-b-15">
      <div class="level m-b-0">
        <div class="level-left">
          <label class="label">Project</label>
        </div>
        <div class="level-right">
          <div>
            <a class="is-primary tooltip is-tooltip-left" data-tooltip="Create Project" @click="addProject"
              v-if="$can.includes('create-projects')">
              <IconPlus style="height: 20px; width: 20px;" />
            </a>
          </div>
        </div>
      </div>
      <div class="control">
        <multiselect id="pitch_to_contact_id" v-model="sync.project" :options="projects" track-by="id" label="name"
          :close-on-select="true"></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label for="fee" class="label">Fee</label>
      <input id="fee" type="text" class="input" placeholder="$" v-model="sync.fee">
    </div>
    <div class="field m-b-15">
      <label for="status" class="label">Status</label>
      <multiselect v-model="sync.status" :options="['Available', 'Requested', 'Confirmed', 'Aired']"
        :close-on-select="true"></multiselect>
    </div>
    <div class="field m-b-15">
      <label for="air_date" class="label">Air Date</label>
      <div class="control">
        <div class="button is-fullwidth is-primary is-inverted has-text-left"
          @click="openDatePicker('sync.air_date', sync.air_date, 'Air Date', 'single')">
          <span v-if="sync.air_date">
            {{ sync.air_date | momentDate }}
          </span>
          <span v-else>
            Select Date
          </span>
        </div>
      </div>
    </div>

    <div class="field m-b-15">
      <label for="terms" class="label">Terms</label>
      <textarea class="textarea" v-model="sync.terms"></textarea>
    </div>
    <form-modal type="project" title="Add Project" :model="project" :isModal="isModal" :isSaving="isSaving"
      v-on:clear-modal="clearModal" v-on:save-record="saveProject"></form-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import datepicker from '@/mixins/datepicker'
import {
  IconPlus
} from '@/Icons/IconBase'
import {
  PROJECT_ALL_FOR_FORM,
  PROJECT_CREATE
} from '@/store/actions'

export default {
  name: 'CutForm',
  components: {
    Multiselect,
    IconPlus
  },
  mixins: [datepicker],
  data() {
    return {
      isModal: false,
      isSaving: false,
      project: {}
    }
  },
  model: {
    prop: 'sync',
    event: 'change'
  },
  props: {
    sync: Object
  },
  created() {
    this.$store.dispatch(PROJECT_ALL_FOR_FORM)
  },
  computed: {
    projects() {
      return this.$store.state.project.projects
    }
  },
  methods: {
    addProject() {
      this.clearModal()
      this.isModal = !this.isModal
    },
    saveProject(data) {
      let self = this
      this.isSaving = true
      this.$store.dispatch(PROJECT_CREATE, data)
        .then(resp => {
          self.isSaving = false
          self.clearModal()
          self.$set(self.sync, 'project', resp)
        }, function (err) {
          self.isSaving = false
          self.clearModal()
          alert(err)
        })
    },
    clearModal() {
      this.project = {}
      this.isModal = false
    }
  }
}
</script>
