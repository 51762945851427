import Vue from 'vue'
import axios from 'axios'

import {
  CONTRACT_ALL_FOR_SONGWRITER,
  CONTRACT_CREATE,
  CONTRACT_UPDATE,
  CONTRACT_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  CONTRACT_LOADING,
  CONTRACT_SUCCESS,
  CONTRACT_ERROR,
  CONTRACT_SET,
  CONTRACTS_SET
} from '../mutations'

export default {
  state: {
    status: '',
    contracts: [],
    contract: {}
  },

  mutations: {
    [CONTRACT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [CONTRACT_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [CONTRACT_ERROR]: (state) => {
      state.status = 'error'
    },
    [CONTRACT_SET]: (state, resp) => {
      Vue.set(state, 'contract', resp)
    },
    [CONTRACTS_SET]: (state, resp) => {
      Vue.set(state, 'contracts', resp)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'contract', {})
      Vue.set(state, 'contracts', [])
    }
  },

  actions: {
    [CONTRACT_ALL_FOR_SONGWRITER]: ({
      commit
    }, { songwriterId, date }) => {
      commit(CONTRACT_LOADING)

      let params = {}

      if (date) {
        params.date = date
      }

      axios({
        url: 'songwriters/' + songwriterId + '/contracts'
      }, params)
        .then(resp => {
          commit(CONTRACT_SUCCESS)
          commit(CONTRACTS_SET, resp.data.data)
        })
        .catch(resp => {
          commit(CONTRACT_ERROR)
        })
    },

    [CONTRACT_CREATE]: ({
      commit,
      dispatch
    }, {
      songwriterId,
      data
    }) => {
      return new Promise((resolve, reject) => {
        commit(CONTRACT_LOADING)

        axios({
          method: 'post',
          url: 'songwriters/' + songwriterId + '/contracts',
          data: data
        })
          .then(resp => {
            commit(CONTRACT_SUCCESS)
            commit(CONTRACT_SET, resp.data.data)
            dispatch(CONTRACT_ALL_FOR_SONGWRITER, {
              songwriterId: songwriterId,
              date: null
            })
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(CONTRACT_ERROR)
            reject(err)
          })
      })
    },

    [CONTRACT_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data,
      songwriterId
    }) => {
      return new Promise((resolve, reject) => {
        commit(CONTRACT_LOADING)

        axios({
          method: 'put',
          url: 'contracts/' + id,
          data: data
        })
          .then(resp => {
            commit(CONTRACT_SUCCESS)
            commit(CONTRACT_SET, resp.data.data)
            dispatch(CONTRACT_ALL_FOR_SONGWRITER, {
              songwriterId: songwriterId,
              date: null
            })
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(CONTRACT_ERROR)
            reject(err)
          })
      })
    },

    [CONTRACT_DELETE]: ({
      commit,
      dispatch
    }, {
      id,
      songwriterId
    }) => {
      commit(CONTRACT_LOADING)

      axios({
        method: 'delete',
        url: 'contracts/' + id
      })
        .then(resp => {
          commit(CONTRACT_SUCCESS)
          dispatch(CONTRACT_ALL_FOR_SONGWRITER, {
            songwriterId: songwriterId,
            date: null
          })
        })
        .catch(err => {
          console.log(err)
          commit(CONTRACT_ERROR)
        })
    }
  }
}
