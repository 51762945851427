<template>
  <div class="content">
    <progress v-if="isDownloading" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <section class="m-t-30 m-r-30 m-l-30 m-b-30">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item" style="justify-content: left;">
              <h2 class="title">{{playlist.title}}</h2>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <p>
                <a
                  @click="downloadZip(playlist)"
                  v-if="allowAudioDownload"
                  class="is-primary tooltip"
                  data-tooltip="Download All"
                >
                  <IconDownload/>
                </a>
              </p>
            </div>
            <div class="level-item">
              <p>
                <session-play-playlist-button :playlistId="playlist.id"></session-play-playlist-button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-for="audioFile in audioFiles" :key="audioFile.id" class="m-t-30">
          <div class="container">
            <div  class="card has-text-centered">
               <div class="card-content m-b-5">
              <div class="level">
                <div class="level-left"></div>
               <div class="level-right pitch-view--group-actions">
                  <div class="level-item">
                    <play-button v-if="audioFile.id" :audioFileId="audioFile.id"></play-button>
                  </div>
                  <div class="level-item">
                     <a v-if="audioFile.song.lyrics" @click="viewLyrics(audioFile.song)" class="is-primary tooltip" data-tooltip="View Lyrics"><IconEye/></a>
                     <a v-else href="#" class="tooltip disabled has-text-grey-light"  style="cursor: not-allowed;" data-tooltip="No Lyrics"><IconEye/></a>
                  </div>
                  <div class="level-item">
                     <a v-if="audioFile.song.lyrics" @click="downloadLyrics(audioFile.song)" class="is-primary tooltip" data-tooltip="Download Lyrics"><IconFileText/></a>
                     <a v-else href="#" class="tooltip disabled has-text-grey-light"  style="cursor: not-allowed;" data-tooltip="No Lyrics"><IconFileText/></a>
                  </div>
                  <div class="level-item">
                     <a @click="downloadAudio(audioFile.id)" v-if="allowAudioDownload" class="is-primary tooltip" data-tooltip="Download Song"><IconDownload/></a>
                  </div>
               </div>
            </div>
            <div class="level">
              <div class="level-item" style="max-width: 100%; word-break:break-all">
                  <h2 class="title">{{ audioFile.song.title }}</h2>
              </div>
            </div>
            <div class="columns">
                <div class="column">
                  <label for="Songwriters" class="label m-b-0">Songwriters:</label>
                  <p>{{ audioFile.song.songwriter_names }}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash'
import { IconDownload, IconEye, IconFileText } from '@/Icons/IconBase'
import { AUDIO_FILE_DOWNLOAD, AUDIO_FILE_ZIP_DOWNLOAD, FILE_DOWNLOAD } from '@/store/actions'
import { AUDIO_PLAYER_ACTIVATE_SESSION_QUEUE } from '@/store/mutations'

export default {
  name: 'PlaylistEmailView',
  components: {
    IconDownload,
    IconEye,
    IconFileText
  },
  data() {
    return {
      isDownloading: false
    }
  },

  props: {
    email: Object
  },

  beforeCreate: function () {
    this.$store.commit(AUDIO_PLAYER_ACTIVATE_SESSION_QUEUE)
    document.body.className = 'pitch-view'
  },

  computed: {
    playlist() {
      return this.email.emailable
    },

    audioFiles() {
      if (this.playlist.audio_files) {
        return _.sortBy(this.playlist.audio_files, ['position'])
      }

      return []
    },

    allowAudioDownload() {
      return this.email.options.allow_audio_download
    }
  },

  methods: {
    downloadAudio(id) {
      let self = this
      this.isDownloading = true
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, id)
        .then(function (resp) {
          self.isDownloading = false
        })
        .catch(function (err) {
          self.isDownloading = false
          self.$store.commit('error', err)
        })
    },
    downloadZip(playlist) {
      let self = this
      this.isDownloading = true
      this.$store.dispatch(AUDIO_FILE_ZIP_DOWNLOAD, {
        title: playlist.title,
        audioFileIds: playlist.audio_files.map(audioFile => audioFile.id)
      })
        .then(function (resp) {
          self.isDownloading = false
        })
        .catch(function (err) {
          self.isDownloading = false
          self.$store.commit('error', err)
        })
    },
    downloadLyrics(song) {
      let self = this
      this.isDownloading = true
      this.$store.dispatch(FILE_DOWNLOAD, song.lyrics.id)
        .then(function (resp) {
          self.isDownloading = false
        })
        .catch(function (err) {
          alert(err)
        })
    },
    viewLyrics(song) {
      if (song.lyrics.content_type === 'application/msword' || song.lyrics.content_type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        window.open('https://docs.google.com/viewerng/viewer?url=' + encodeURI(song.lyrics.url), '_blank')
        // window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURI(song.lyrics.url), '_blank')
      } else {
        window.open(song.lyrics.url, '_blank')
      }
    }
  }

}
</script>
