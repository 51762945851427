<template>
  <span></span>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'DraggableSongHandle',
  props: ['title', 'id', 'uid', 'active_hold_count', 'cut_count', 'audio_files'],

  computed: {
    hasActiveHolds: function () {
      return this.active_hold_count > 0
    },

    hasCuts: function () {
      return this.cut_count > 0
    }
  },

  mounted() {
    if (this.uid) {
      this.initDraggable(this.uid)
    }
  },

  watch: {
    uid: function (newValue) {
      if (newValue) {
        this.initDraggable(newValue)
      }
    }
  },

  methods: {
    initDraggable(uid) {
      let self = this
      $('#' + uid).draggable({
        zIndex: 10,
        helper: function () {
          return `<div class="song-drag-handle has-text-dark has-text-weight-bold" data-song-id="${self.id}" data-song-title="${self.title}" data-has-active-holds="${self.hasActiveHolds}" data-has-cuts="${self.hasCuts}" data-audio-files='${self.audio_files.replace(/'/g, '&apos;')}'> ${self.title}</div>`
        },
        appendTo: 'body',
        cursorAt: {
          left: 0,
          top: 0
        }
      })
    }
  }
}
</script>
