import axios from 'axios'

export default {

  storeRating(songId, value) {
    return axios({
      method: 'post',
      url: 'songs/' + songId + '/song-ratings',
      data: {
        value: value
      }
    })
  },

  updateRating(id, value) {
    return axios({
      method: 'put',
      url: 'song-ratings/' + id,
      data: {
        value: value
      }
    })
  }

}
