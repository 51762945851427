<template>
  <div class="content" v-if="playlist">
    <progress v-if="isDownloading" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <section class="m-t-30">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div>
                <h1 class="subtitle has-text-grey-light m-b-10">{{ playlist.title }}</h1>
                <p>
                  <span class="has-text-grey-light has-text-weight-bold">Pitching To:</span>
                  {{ playlist.pitch_to_contact | orBlank('full_name')}}
                </p>
                <p>
                  <span
                    class="has-text-grey-light has-text-weight-bold m-l-30"
                  >Pitching For:</span>
                  {{ playlist.pitch_for_artist_names | orBlank('name')}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="m-t-30">
      <div class="container" v-if="audioFiles.length">
        <div
          class="section is-small pitch-view--song"
          v-for="audioFile in audioFiles"
          :key="audioFile.id"
        >
          <div class="level song-title">
            <div class="level-left">
              <div class="level-item">
                <h2 class="title">{{ audioFile.song.title }}</h2>
              </div>
            </div>
            <div class="level-right">
              <router-link
                class="button is-medium is-rounded is-primary play"
                :to="{ name: 'playlists.pitch-meeting.audio-files.show', params: { playlist_id: playlist.id, id: audioFile.id, propped: audioFile, playlist: playlist } }"
              >
                <IconPlay/>
              </router-link>
            </div>
          </div>
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <div>
                  <label for="Songwriters" class="label m-b-0">Songwriters</label>
                  <p>{{ audioFile.song | orBlank('songwriter_names') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <p>There are no songs in this playlist.</p>
      </div>
    </section>
    <router-link class="close" :to="{ name: 'playlists.index' }">
      <IconX/>
    </router-link>
  </div>
</template>

<script>
import _ from 'lodash'
import { IconPlay, IconX } from '@/Icons/IconBase'
import { PLAYLIST_READ } from '@/store/actions'
import store from '@/store'

export default {
  name: 'PlaylistPitchMeetingShow',
  components: {
    IconPlay,
    IconX
  },

  props: {
    propped: Object
  },

  data() {
    return {
      isDownloading: false
    }
  },

  computed: {
    playlist() {
      if (this.$store.getters.getPlaylist.id) return this.$store.getters.getPlaylist
      if (this.propped) return this.propped

      return {
        title: ''
      }
    },

    audioFiles() {
      if (this.playlist.audio_files) {
        return _.sortBy(this.playlist.audio_files, ['position'])
      }

      return []
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(PLAYLIST_READ, to.params.id)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(PLAYLIST_READ, to.params.id)
    next()
  },

  beforeCreate: function () {
    document.body.className = 'pitch-view'
  }
}
</script>
