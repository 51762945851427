export default function (value) {
  if (!value) return ''
  value = value.split('\\')
  if (value.includes('RecordLabel')) {
    value[value.length - 1] = 'Record Label'
  }
  if (value.includes('PublishingCompany')) {
    value[value.length - 1] = 'Publishing Company'
  }
  return value[value.length - 1]
}
