<template>
  <ul class="menu-list">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'IndexAside'
}
</script>
