<template>
  <!-- <div v-if="$can.includes('read-syncs')"> -->
  <div>
    <section class="single-info">
      <div class="level is-mobile">
        <div class="level-left">
          <h3 class="m-b-30">Syncs</h3>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="create" v-if="$can.includes('update-songs')">
            <IconPlus/>
          </a>
        </div>
      </div>
      <div class="level" v-if="!syncs.length">
        <p>
          No syncs to display.
          <a
            @click="create"
            v-if="$can.includes('update-songs')"
          >Add a sync here.</a>
        </p>
      </div>
      <div class="level" v-else>
        <table class="table is-responsive is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Requested</th>
              <th>Project</th>
              <th>Media</th>
              <th>Fee</th>
              <th>Status</th>
              <th>Air</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sync in syncs"
              :key="sync.id"
              @click="edit(sync)"
              :class="{'is-clickable' : $can.includes('update-songs'), 'is-prohibited' : !$can.includes('update-songs') }"
            >
              <td>{{ sync.requested_date | momentDate }}</td>
              <td>{{ sync.project | orBlank('name') }}</td>
              <td>{{ sync.project | orBlank('media_type') | capitalize }}</td>
              <td>{{ sync | orBlank('fee') | capitalize }}</td>
              <td>{{ sync | orBlank('status') | capitalize }}</td>
              <td>{{ sync.air_date | momentDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <form-modal
      type="sync"
      :title="formTitle"
      :model="selectedSync"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconPlus } from '@/Icons/IconBase'

import { SYNCS_SET } from '@/store/mutations'
import { SYNC_ALL_FOR_SONG, SYNC_UPDATE, SYNC_CREATE, SYNC_DELETE, SONG_UPDATE } from '@/store/actions'

export default {
  name: 'SyncsSong',
  mixins: [uiHelper],
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      selectedSync: {},
      isSaving: false
    }
  },

  props: ['songId'],

  components: {
    IconPlus
  },

  destroyed: function () {
    this.$store.commit(SYNCS_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.sync
    },

    syncs() {
      return this.$store.state.sync.syncs
    }
  },

  methods: {
    load() {
      if (this.songId) {
        this.$store.dispatch(SYNC_ALL_FOR_SONG, this.songId)
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Sync'
      this.isModal = !this.isModal
    },

    edit(sync) {
      if (this.$can.includes('update-songs')) {
        this.formCanDelete = true
        this.formTitle = 'Update Sync'
        this.selectedSync = sync
        this.isModal = !this.isModal
      }
    },

    save(input) {
      if (input.project) {
        let data = {
          project_id: input.project ? input.project.id : null,
          media_type: input.media_type,
          fee: input.fee,
          status: input.status,
          terms: input.terms,
          requested_date: input.requested_date,
          air_date: input.air_date
        }

        if (this.selectedSync.id) {
          this.$store.dispatch(SYNC_UPDATE, {
            id: this.selectedSync.id,
            data: data,
            songId: this.songId
          })
        } else {
          this.$store.dispatch(SYNC_CREATE, {
            songId: this.songId,
            data: data
          })
        }
        this.$emit('child-updated')
        this.clearModal()
      } else {
        alert('Please select or create a project')
      }
    },

    destroy(sync) {
      let self = this
      this.$store.dispatch(SYNC_DELETE, { id: sync.id, songId: this.songId })
        .then(function () {
          self.$store.dispatch(SONG_UPDATE, {
            id: self.songId,
            data: null
          })
        })
        .then(function () {
          self.clearModal()
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedSync = {}
    }
  }
}
</script>
