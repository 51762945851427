<template>
  <div>
    <div class="form-section">
      <div class="columns is-multiline">
        <div class="column is-12">
        <div class="field m-b-15">
          <label class="label">Type</label>
          <div class="control">
            <multiselect
              v-model="contact.type"
              :options="contactTypes"
              track-by="id"
              label="name"
              :close-on-select="true"
            ></multiselect>
          </div>
        </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="first_name" class="label">First Name</label>
            <input
              id="first_name"
              type="text"
              class="input"
              placeholder="First Name"
              v-model="contact.first_name"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="last_name" class="label">Last Name</label>
            <input
              id="last_name"
              type="text"
              class="input"
              placeholder="Last Name"
              v-model="contact.last_name"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="mobile_phone" class="label">Mobile Phone</label>
            <input
              id="mobile_phone"
              type="text"
              class="input"
              placeholder="(615)555-1234"
              v-model="contact.mobile_phone"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="office_phone" class="label">Office Phone</label>
            <input
              id="office_phone"
              type="text"
              class="input"
              placeholder="(615)555-3456"
              v-model="contact.office_phone"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="email" class="label">Email</label>
            <input
              id="email"
              type="text"
              class="input"
              placeholder="contact@wrios.com"
              v-model="contact.email"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="website" class="label">Website</label>
            <input
              id="website"
              type="text"
              class="input"
              placeholder="https://www.wrios.com"
              v-model="contact.website"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="company_name" class="label">Company Name</label>
            <input
              id="company_name"
              type="text"
              class="input"
              placeholder="Company Name"
              v-model="contact.company_name"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="title" class="label">Title</label>
            <input
              id="title"
              type="text"
              class="input"
              placeholder="Title"
              v-model="contact.title"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-section p-t-30">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label for="address_line1" class="label">Address Line 1</label>
            <input
              id="address_line1"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="contact.address_line1"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_line2" class="label">Address Line 2</label>
            <input
              id="address_line2"
              type="text"
              class="input"
              placeholder="123 Main St."
              v-model="contact.address_line2"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_city" class="label">City</label>
            <input
              id="address_city"
              type="text"
              class="input"
              placeholder="Nashville"
              v-model="contact.address_city"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_state" class="label">State</label>
            <input
              id="address_state"
              type="text"
              class="input"
              placeholder="TN"
              v-model="contact.address_state"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_zip" class="label">Zip Code</label>
            <input
              id="address_zip"
              type="text"
              class="input"
              placeholder="37203"
              v-model="contact.address_zip"
            >
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="address_country" class="label">Country</label>
            <input
              id="address_country"
              type="text"
              class="input"
              placeholder="USA"
              v-model="contact.address_country"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ContactTypesApi from '@/services/api/ContactTypes'

export default {
  name: 'ContactEditForm',
  model: {
    prop: 'contact',
    event: 'change'
  },
  components: {
    Multiselect
  },
  props: {
    contact: Object
  },
  data() {
    return {
      contactTypes: []
    }
  },
  created: function () {
    ContactTypesApi.getTypes()
      .then(resp => {
        this.contactTypes = resp.data.data
      })
      .catch(resp => {
        this.$store.commit('error', 'Could not load contact types')
      })
  }
}
</script>
