import axios from 'axios'

export default {
  getActivities(type, id, perPage, page) {
    return axios({
      url: 'activity',
      params: {
        subject_type: type,
        subject_id: id,
        per_page: perPage,
        page: page
      }
    })
  }
}
