import axios from 'axios'

export default {

  getPitchGroup(pitchGroupId) {
    return axios({
      url: 'pitch-group/' + pitchGroupId
    })
  }

}
