<template>
  <div class="aside-inner">
    <section class="m-b-15">
      <image-editor :image="playlist.image" parentType="playlist" :parentId="playlist.id" size="is-96x96"></image-editor>
    </section>
    <section class="m-b-30 single-aside-details--data">
      <fieldset disabled>
        <div class="field">
          <label class="label">Created</label>
          <div class="control">
            <input class="input" type="text" v-bind:value="playlist.creation_date | moment-date">
          </div>
        </div>
        <div class="field">
          <label class="label">Pitching To</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-bind:value="playlist.pitch_to_contact | orBlank('full_name')"
            >
          </div>
        </div>
        <div class="field" v-if="playlist.pitch_artists">
          <label class="label">Pitching For</label>
          <div class="control" v-for="artist in playlist.pitch_artists" :key="artist.id">
            <input
              class="input"
              type="text"
              v-bind:value="artist.name | orBlank('name')"
            >
          </div>
        </div>
        <div class="field" v-else>
          <label class="label">Pitching For</label>
          <div class="control">
            <input
              class="input"
              type="text"
              value="..."
            >
          </div>
        </div>
        <div class="field">
          <label class="label">Type</label>
          <div class="control">
            <input class="input" type="text" v-bind:value="playlist.type">
          </div>
        </div>
      </fieldset>
    </section>
    <section class="m-b-30 single-aside-details--tags is-hidden-mobile">
      <label class="label">Tags</label>
      <tag-control taggableType="playlist" :taggableId="playlist.id" />
    </section>
  </div>
</template>

<script>
export default {
  name: 'AsidePlaylist',
  props: {
    playlist: Object
  }
}
</script>
