<template>
  <div class="navbar-item has-dropdown is-hoverable notification-control">
    <div
      class="dropdown is-right"
      v-bind:class="{'is-active': isOpen }"
      v-click-outside="close"
    >
      <a class="navbar-link dropdown-trigger" @click="open()">
        <span class="unread-indicator-large" v-if="notificationCount"></span>
        <IconBell/>
      </a>
      <div class="dropdown-menu is-right" @mouseleave="close()">
        <div class="dropdown-content is-paddingless">
          <div v-for="notification in notifications" :key="notification.id" class="dropdown-item">
            <a @click="viewResource(notification)"  class="notification-message">{{ notification.data | orBlank('message') }}</a>
          </div>
          <div v-if="!notificationCount" @click="close()" class="dropdown-item">
            <p class="is-italic has-text-grey-light">No unread notifications</p>
          </div>

          <div class="dropdown-item">
            <div class="level">
              <div class="level-left">
                  <div class="level-item" v-if="notificationCount">
                    <a class="more-link" @click="markAsRead(notifications)">Mark As Read</a>
                  </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <a class="more-link" @click="openNotifications">More...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NotificationsApi from '@/services/api/Notifications'
import { IconBell } from '@/Icons/IconBase'
import { NOTIFICATION_UPDATE } from '@/store/actions'

export default {
  name: 'NotificationControl',
  components: {
    IconBell
  },

  data() {
    return {
      notifications: [],
      isOpen: false
    }
  },

  created() {
    this.load()
  },

  computed: {
    notificationCount() {
      return this.notifications.length
    },

    notificationStore() {
      return this.$store.state.notification.notifications
    }
  },

  watch: {
    notificationStore() {
      this.load()
    }
  },

  methods: {
    open() {
      this.isOpen = !this.isOpen
    },

    close() {
      this.isOpen = false
    },

    load() {
      if (!this.$store.getters.isProfileLoaded) {
        return false
      }

      this.isLoading = true
      let self = this

      NotificationsApi.getNotifications(true)
        .then(resp => {
          self.notifications = resp.data.data
          self.isLoading = false
          self.isInitialized = true
        })
        .catch(err => {
          self.$store.commit('error', 'Could not load notifications data.')
          console.log(err)
        })
    },

    markAsRead(notifications) {
      let notificationIds = notifications.map(notification => notification.id)
      this.$store.dispatch(NOTIFICATION_UPDATE, { notification_ids: notificationIds })
    },

    openNotifications() {
      this.close()
      this.$router.push({ name: 'user.notifications' })
    },

    viewResource(notification) {
      this.close()
      this.$store.dispatch(NOTIFICATION_UPDATE, { notification_ids: [notification.id] })
      if (notification.type.indexOf('Song') > -1) {
        this.$router.push({ name: 'songs.show', params: { id: notification.data.song_id } })
      } else if (notification.type.indexOf('Pitch') > -1) {
        this.$router.push({ name: 'pitches.show', params: { id: notification.data.pitch_id } })
      } else if (notification.type.indexOf('Playlist') > -1) {
        this.$router.push({ name: 'playlists.show', params: { id: notification.data.playlist_id } })
      } else if (notification.type.indexOf('OptionPeriodExpiring') > -1) {
        this.$router.push({ name: 'songwriters.show', params: { id: notification.data.songwriter_id } })
      } else {
        return null
      }
    }
  }
}
</script>
