<template>
  <div class="tabs">
    <div class="tabs">
      <ul class="tab-list is-flex">
        <li
          @click="setScope('all')"
          :class="{'is-active' : localScope === 'all'}"
        >
          <a>All Playlists</a>
        </li>
        <li
          @click="setScope('user')"
          :class="{'is-active' : localScope === 'user'}"
        >
          <a>My Playlists</a>
        </li>
        <li
          @click="setScope('shared')"
          :class="{'is-active' : localScope === 'shared'}"
        >
          <a>Shared Playlists</a>
        </li>
        <!-- just commenting out in case this is requested -->
        <!-- <li
          @click="setScope('emailed')"
          :class="{'is-active' : localScope === 'emailed'}"
        >
          <a>Emailed Playlists</a>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scope: String
  },

  data() {
    return {
      localScope: 'all'
    }
  },

  created: function () {
    this.localScope = this.scope
  },

  watch: {
    scope: function (newValue, oldValue) {
      this.localScope = newValue
    },

    localScope: function (newValue) {
      this.setScope(newValue)
    }
  },

  methods: {
    setScope: function (value) {
      this.localScope = value
      this.$emit('set-search-scope', value)
    }
  }
}
</script>
