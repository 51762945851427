import axios from 'axios'

export default {

  getUsers(accountId) {
    return axios({
      url: 'accounts/' + accountId + '/users'
    })
  }

}
