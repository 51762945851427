import moment from 'moment'

export default function (event) {
  if (event.type === 'play') {
    return `Song <b>played</b> from position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'stop') {
    return `Song <b>stopped</b> at position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'pause') {
    return `Song <b>paused</b> at position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'fast-forward') {
    return `Song <b>fast-forwarded</b> to position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'rewind') {
    return `Song <b>rewound</b> to position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'end') {
    return `Song <b>ended</b> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'seek') {
    return `Song <b>jumped</b> to position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  } else if (event.type === 'next') {
    return `Song <b>skipped</b> at position <em>${moment().startOf('day').seconds(event.elapsed_time).format('m:ss')}</em> at ${moment.utc(event.created_at).local().format('h:mm:ss A')} on ${moment.utc(event.created_at).local().format('M/D/YYYY')}`
  }
}
