<script>
import IconArrowDown from '@/Icons/IconArrowDown'
import IconArrowLeft from '@/Icons/IconArrowLeft'
import IconArrowRight from '@/Icons/IconArrowRight'
import IconArrowUp from '@/Icons/IconArrowUp'
import IconBell from '@/Icons/IconBell'
import IconCalendar from '@/Icons/IconCalendar'
import IconCheck from '@/Icons/IconCheck'
import IconCheckSquare from '@/Icons/IconCheckSquare'
import IconChevronDown from '@/Icons/IconChevronDown'
import IconChevronDownSmall from '@/Icons/IconChevronDownSmall'
import IconChevronLeft from '@/Icons/IconChevronLeft'
import IconChevronRight from '@/Icons/IconChevronRight'
import IconChevronUp from '@/Icons/IconChevronUp'
import IconChevronUpSmall from '@/Icons/IconChevronUpSmall'
import IconCopy from '@/Icons/IconCopy'
import IconDownload from '@/Icons/IconDownload'
import IconEdit from '@/Icons/IconEdit'
import IconEye from '@/Icons/IconEye'
import IconFastForward from '@/Icons/IconFastForward'
import IconFileText from '@/Icons/IconFileText'
import IconGraph from '@/Icons/IconGraph'
import IconLayout from '@/Icons/IconLayout'
import IconLink from '@/Icons/IconLink'
import IconList from '@/Icons/IconList'
import IconLock from '@/Icons/IconLock'
import IconMail from '@/Icons/IconMail'
import IconMasterOwners from '@/Icons/IconMasterOwners'
import IconMenu from '@/Icons/IconMenu'
import IconMicrophone from '@/Icons/IconMicrophone'
import IconMoreHorizontal from '@/Icons/IconMoreHorizontal'
import IconMoreVertical from '@/Icons/IconMoreVertical'
import IconMusic from '@/Icons/IconMusic'
import IconPause from '@/Icons/IconPause'
import IconPlay from '@/Icons/IconPlay'
import IconPlaylist from '@/Icons/IconPlaylist'
import IconPlayStrikeThrough from '@/Icons/IconPlayStrikeThrough'
import IconPlus from '@/Icons/IconPlus'
import IconRadio from '@/Icons/IconRadio'
import IconRefresh from '@/Icons/IconRefresh'
import IconRewind from '@/Icons/IconRewind'
import IconSearch from '@/Icons/IconSearch'
import IconSettings from '@/Icons/IconSettings'
import IconSkipForward from '@/Icons/IconSkipForward'
import IconSquare from '@/Icons/IconSquare'
import IconStar from '@/Icons/IconStar'
import IconStarFilled from '@/Icons/IconStarFilled'
import IconStarFilledSongIndex from '@/Icons/IconStarFilledSongIndex'
import IconTrash from '@/Icons/IconTrash'
import IconUpload from '@/Icons/IconUpload'
import IconUser from '@/Icons/IconUser'
import IconUsers from '@/Icons/IconUsers'
import IconVolume from '@/Icons/IconVolume'
import IconVolume2 from '@/Icons/IconVolume2'
import IconX from '@/Icons/IconX'

export {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBell,
  IconCalendar,
  IconCheck,
  IconCheckSquare,
  IconChevronDown,
  IconChevronDownSmall,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconChevronUpSmall,
  IconCopy,
  IconDownload,
  IconEdit,
  IconEye,
  IconFastForward,
  IconFileText,
  IconGraph,
  IconLayout,
  IconLink,
  IconList,
  IconLock,
  IconMail,
  IconMasterOwners,
  IconMenu,
  IconMicrophone,
  IconMoreHorizontal,
  IconMoreVertical,
  IconMusic,
  IconPause,
  IconPlay,
  IconPlaylist,
  IconPlayStrikeThrough,
  IconPlus,
  IconRadio,
  IconRefresh,
  IconRewind,
  IconSearch,
  IconSettings,
  IconSkipForward,
  IconSquare,
  IconStar,
  IconStarFilled,
  IconStarFilledSongIndex,
  IconTrash,
  IconUpload,
  IconUser,
  IconUsers,
  IconVolume,
  IconVolume2,
  IconX
}

export default {
  name: 'IconBase'
}
</script>
