<template>
  <div v-if="$can.includes('read-songwriters')">
    <section class="single-info">
      <div class="level is-mobile">
        <div class="level-left">
          <h3>Songwriters</h3>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="create" v-if="$can.includes('update-songs') || canBeEditedBySongwriterRole">
            <IconPlus/>
          </a>
        </div>
      </div>
      <div class="level">
        <table class="table is-fullwidth is-responsive is-hoverable">
          <thead>
            <tr>
              <th>Name</th>
              <th v-if="$can.includes('read-contract-publishing-split-percentage')">Split</th>
              <th v-if="$can.includes('read-contracts')">Contract</th>
              <th v-if="$can.includes('read-contract-publishing-split-percentage')">Splits</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="writingCredit in writingCredits"
              :key="writingCredit.id"
              @click="edit(writingCredit)"
              :class="{'is-clickable' : [$can.includes('update-songs')|| canBeEditedBySongwriterRole], 'is-prohibited' : !$can.includes('update-songs')}"
            >
              <td>{{ writingCredit.songwriter.full_name }}</td>
              <td v-if="$can.includes('read-contract-publishing-split-percentage')">{{ writingCredit.percentage }}%</td>
              <td
                v-if="writingCredit.contract && $can.includes('read-contracts')"
              >{{ writingCredit.contract.start_date | momentDate }} - {{ writingCredit.contract.end_date| momentDate }}
              {{ writingCredit.contract.contract_shares_publisher_names ? '(' + writingCredit.contract.contract_shares_publisher_names + ')' : null }}
              </td>
              <td v-else>{{ writingCredit.songwriter.current_publishing_company | orBlank('name') }}</td>

              <td class="is-narrow" v-if="writingCredit.contract && $can.includes('read-contract-publishing-split-percentage')">
                <div
                  v-for="contractShare in filterPublishingCompanyContractShares(writingCredit.contract.contract_shares)"
                  :key="contractShare.id"
                >{{ contractShare.shareable.name }}: {{ contractShare.percentage }}%</div>
              </td>
              <td class="is-narrow" v-else-if="writingCredit.songwriter.current_publishing_company && $can.includes('read-contract-publishing-split-percentage')">100%</td>
              <td class="is-narrow" v-else>...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <form-modal
      type="writingCredit"
      :title="formTitle"
      :model="selectedWritingCredit"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
      v-on:add-another="addAnother"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconPlus } from '@/Icons/IconBase'

import { WRITING_CREDITS_SET } from '@/store/mutations'
import { WRITING_CREDIT_ALL_FOR_SONG, WRITING_CREDIT_UPDATE, WRITING_CREDIT_CREATE, WRITING_CREDIT_DELETE } from '@/store/actions'

export default {
  name: 'WritingCreditsSong',
  mixins: [uiHelper],
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      selectedWritingCredit: {},
      isSaving: false
    }
  },

  props: ['song', 'allowManualPercentages', 'canBeEditedBySongwriterRole'],

  components: {
    IconPlus
  },

  destroyed: function () {
    this.$store.commit(WRITING_CREDITS_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.writingCredit
    },

    writingCredits() {
      return this.$store.state.writingCredit.writingCredits
    }
  },

  methods: {
    load() {
      if (this.song) {
        this.$store.dispatch(WRITING_CREDIT_ALL_FOR_SONG, this.song.id)
      }
    },

    filterPublishingCompanyContractShares(shares) {
      if (!shares) return {}

      return shares.filter(function (share) {
        return share.shareable_type === 'Wrios\\Models\\PublishingCompany'
      })
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Songwriter'
      this.selectedWritingCredit.allow_manual_writing_credit_percentages = this.allowManualPercentages
      this.isModal = !this.isModal
    },

    edit(writingCredit) {
      if (this.$can.includes('update-songs') || this.canBeEditedBySongwriterRole) {
        this.formCanDelete = true
        this.formTitle = 'Update Songwriter'
        this.selectedWritingCredit = writingCredit
        this.selectedWritingCredit.allow_manual_writing_credit_percentages = this.allowManualPercentages
        this.isModal = !this.isModal
      }
    },

    save(input) {
      if (!this.allowManualPercentages) {
        delete input.percentage
      }

      if (this.selectedWritingCredit.id) {
        this.$store.dispatch(WRITING_CREDIT_UPDATE, {
          id: this.selectedWritingCredit.id,
          data: input,
          songId: this.song.id
        })
      } else {
        this.$store.dispatch(WRITING_CREDIT_CREATE, {
          songId: this.song.id,
          data: input
        })
      }
      this.clearModal()
    },

    addAnother(input) {
      if (!this.allowManualPercentages) {
        delete input.percentage
      }

      if (this.selectedWritingCredit.id) {
        this.$store.dispatch(WRITING_CREDIT_UPDATE, {
          id: this.selectedWritingCredit.id,
          data: input,
          songId: this.song.id
        })
      } else {
        this.$store.dispatch(WRITING_CREDIT_CREATE, {
          songId: this.song.id,
          data: input
        })
      }
      this.create()
    },

    destroy(writingCredit) {
      this.$store.dispatch(WRITING_CREDIT_DELETE, { id: writingCredit.id, songId: this.song.id })
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedWritingCredit = {}
    }
  }
}
</script>
