<template>
<transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="message-modal" class="modal is-active" v-if="message">
      <div class="modal-background"></div>

      <div class="modal-content card">
        <div class="card-content">
            <div class="level m-t-30">
              <div class="level-item has-text-centered" style="flex-shrink: 1 !important;">
                <div v-html="message"></div>
              </div>
            </div>
            <div class="level is-mobile">
              <div class="level-item">
                <button class="button is-danger" @click="close">Close</button>
              </div>
            </div>
        </div>
        <button class="modal-close is-large" @click="close"></button>
      </div>
    </div>
  </transition>

</template>

<script>

import { MESSAGE_MODAL_MESSAGE_SET } from '@/store/mutations'

export default {
  name: 'MessageModal',

  computed: {
    message() {
      return this.$store.getters.getModalMessage
    }
  },

  methods: {
    close() {
      this.$store.commit(MESSAGE_MODAL_MESSAGE_SET, null)
    }
  }
}
</script>
