import ColumnPreferencesApi from '@/services/api/ColumnPreferences'
import { USER_SET_PROFILE } from '@/store/mutations'

export default {
  data() {
    return {
      defaultPreferences: {
        songs_all: {
          date_written: {
            active: true,
            display_name: 'Date Written'
          },
          date_modified: {
            active: false,
            display_name: 'Date Modified'
          },
          song_rating: {
            active: false,
            display_name: 'Song Rating'
          }
        },
        songs_user: {
          date_written: {
            active: true,
            display_name: 'Date Written'
          },
          date_modified: {
            active: false,
            display_name: 'Date Modified'
          }
        },
        songs_submitted: {
          date_written: {
            active: true,
            display_name: 'Date Written'
          }
        }
      }
    }
  },

  computed: {
    isProfileLoaded() {
      return this.$store.getters.isProfileLoaded
    },

    userColumnPreferences() {
      if (this.$store.getters.isProfileLoaded) {
        return this.$store.getters.getProfile.column_preferences
      }

      return null
    }
  },

  methods: {
    columnIsActive(viewName, columnTitle) {
      if (!this.isProfileLoaded) {
        return false
      }

      if (this.userColumnPreferences) {
        for (let index = 0; index < this.userColumnPreferences.length; index++) {
          if (this.userColumnPreferences[index].view_name === viewName && this.userColumnPreferences[index].column_title === columnTitle) {
            return this.userColumnPreferences[index].active
          }
        }
      }

      if (this.defaultPreferences[viewName] && this.defaultPreferences[viewName][columnTitle]) {
        return this.defaultPreferences[viewName][columnTitle].active
      }

      return true
    },

    setColumnPreference(viewName, columnTitle, active) {
      let self = this
      return ColumnPreferencesApi.storeColumnPreference(viewName, columnTitle, active)
        .then(resp => {
          self.$store.commit(USER_SET_PROFILE, resp.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
