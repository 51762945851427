<template>
  <span class="playlist-song-search-link" @click="openPlaylist(playlist)">
    <div class="droppable-playlist" :id="uid">{{playlist.creation_date | momentDate }} - {{playlist.title}}</div>
  </span>
</template>

<script>
import PlaylistsApi from '@/services/api/Playlists'
import $ from 'jquery'

export default {
  name: 'DroppablePlaylist',
  props: ['playlist'],

  data() {
    return {
      uid: null
    }
  },

  mounted() {
    this.uid = this._uid
    if (this.playlist.id) {
      this.initDroppable(this.playlist)
    }
  },

  updated() {
    this.uid = this._uid
    if (this.playlist.id) {
      this.initDroppable(this.playlist)
    }
  },

  watch: {
    playlist: function (newValue) {
      if (newValue) {
        this.initDroppable(newValue)
      }
    }
  },

  beforeDestroy() {
    $('#' + this.uid).droppable('destroy')
  },

  methods: {
    initDroppable: function (playlist) {
      let self = this
      if (this.uid) {
        $('#' + this.uid).droppable({
          classes: {
            'ui-droppable-active': 'droppable-active',
            'ui-droppable-hover': 'droppable-highlight'
          },
          tolerance: 'pointer',
          drop: function (event, ui) {
            let audioFiles = JSON.parse(ui.helper[0].dataset.audioFiles)
            let songId = ui.helper[0].dataset.songId
            let songTitle = ui.helper[0].dataset.songTitle
            if (ui.helper[0].dataset.hasActiveHolds === 'true') {
              self.$store.dispatch('confirm',
                `"${songTitle}" has an active hold. Are you sure you want to add it to a playlist?`).then(
                resp => {
                  if (resp === true) {
                    self.selectAudioFile(audioFiles, songId, songTitle)
                  }
                }).catch(err => {
                console.log(err)
              })
            } else if (ui.helper[0].dataset.hasCuts === 'true') {
              self.$store.dispatch('confirm',
                `"${songTitle}" has been cut. Are you sure you want to add it to a playlist?`).then(
                resp => {
                  if (resp === true) {
                    self.selectAudioFile(audioFiles, songId, songTitle)
                  }
                }).catch(err => {
                console.log(err)
              })
            } else {
              self.selectAudioFile(audioFiles, songId, songTitle)
            }
          }
        })
      }
    },

    addSong(songId, songTitle) {
      let self = this
      PlaylistsApi.addSong(this.playlist.id, songId)
        .then(function (data) {
          self.$store.commit('success', `"${songTitle}" added to playlist.`)
        }, function (err) {
          self.$store.commit('error', err.response.data.message)
        })
    },
    addAudioFile(audioFileId, songId, songTitle) {
      let self = this
      PlaylistsApi.addAudioFile(this.playlist.id, audioFileId, songId)
        .then(function (data) {
          self.$store.commit('success', `"${songTitle}" added to playlist.`)
        }, function (err) {
          self.$store.commit('error', err.response.data.errors.audio_file_id[0])
        })
    },
    openPlaylist() {
      this.$router.push({
        name: 'playlists.show',
        params: {
          id: this.playlist.id,
          propped: this.playlist
        }
      })
    },
    selectAudioFile(audioFiles, songId, songTitle) {
      let self = this
      if (audioFiles.length > 1) {
        self.$store.dispatch('audioFileSelect',
          audioFiles).then(
          resp => {
            if (resp.message === true) {
              self.addAudioFile(resp.audioFileId, resp.songId, songTitle)
            }
          }).catch(err => {
          console.log(err)
        })
      } else {
        self.addSong(songId, songTitle)
      }
    }
  }
}
</script>
