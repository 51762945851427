<template>
  <ul class="menu-list">
    <li v-if="$can.includes('read-artists')">
      <router-link :to="{ name: 'artists.index' }">
        Artists
        <div class="menu-icon is-pulled-right">
          <IconChevronRight/>
        </div>
      </router-link>
    </li>
    <li v-if="$can.includes('read-contacts')">
      <router-link :to="{ name: 'contacts.index' }">
        Contacts
        <div class="menu-icon is-pulled-right">
          <IconChevronRight/>
        </div>
      </router-link>
    </li>
    <li v-if="$can.includes('read-publishing-comapnies')">
      <router-link :to="{ name: 'publishing-companies.index' }">
        Publishing Companies
        <div class="menu-icon is-pulled-right">
          <IconChevronRight/>
        </div>
      </router-link>
    </li>
    <li v-if="$can.includes('read-songwriters')">
      <router-link :to="{ name: 'songwriters.index' }">
        Songwriters
        <div class="menu-icon is-pulled-right">
          <IconChevronRight/>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>

import { IconChevronRight } from '@/Icons/IconBase'

export default {
  name: 'AsideCommunity',
  components: {
    IconChevronRight
  }
}
</script>
