import _ from 'lodash'
import { CLEAR_STORE } from '../store/mutations'

var ui = {

  computed: {
    perPage() {
      return parseInt(this.resourceState.perPage)
    },

    currentPage() {
      return parseInt(this.resourceState.currentPage)
    },

    isLoading() {
      return this.resourceState.status === 'loading'
    },

    total() {
      return this.resourceState.totalRecords
    },

    totalPages() {
      return this.resourceState.totalPages
    },

    searchValue() {
      return this.resourceState.searchValue
    },

    searchQuery() {
      return this.resourceState.searchQuery
    },

    sortColumn() {
      return this.resourceState.sortColumn
    },

    sortOrder() {
      return this.resourceState.sortOrder
    },

    isProfileLoaded() {
      return this.$store.getters.isProfileLoaded
    }
  },

  created: function () {
    if (this.isProfileLoaded) {
      this.load(this)
    }

    // USE THE BLOCK BELOW IF WE DON'T WANT SEARCH VALUE TO PERSIST ON PAGE LOAD

    // if (this.searchValue && this.searchValue.length) {
    //   this.$store.commit(this.searchValueMutation, '')
    // } else {
    //   this.load()
    // }
  },

  watch: {
    isProfileLoaded: function (newValue) {
      if (newValue === true) {
        this.reload(this)
      }
    },

    currentPage: function () {
      this.reload(this)
    },

    searchValue: function (newValue, oldValue) {
      this.reload(this)
    },

    searchQuery: function () {
      this.reload(this)
    },

    searchScope: function () {
      this.reload(this)
    },

    perPage: function () {
      this.reload(this)
    },

    sortColumn: function () {
      this.reload(this)
    },

    sortOrder: function () {
      this.reload(this)
    },

    searchInput: function (newValue, oldValue) {
      if (!newValue.length) {
        this.searchValue = ''
      }
    }
  },

  methods: {
    reload: _.debounce(function (self) {
      self.load()
    }, 300),

    setSearchValue: _.debounce(function (value, self) {
      self.$store.commit(self.searchValueMutation, value)
      self.$store.commit(self.currentPageMutation, 1)
    }, 250),

    setSearchQuery: _.debounce(function (query, self) {
      self.$store.commit(self.searchQueryMutation, query)
      self.$store.commit(self.currentPageMutation, 1)
    }, 250),

    updateSort(column, order) {
      this.commitSort(column, order, this)
    },

    commitSort: _.debounce(function (column, order, self) {
      self.$store.commit(self.sortMutation, {
        column,
        order
      })
    }, 500),

    setPerPage(value) {
      this.commitPerPage(value, this)
    },

    commitPerPage: _.debounce(function (value, self) {
      // need to return to page 1 when per page value updated
      self.$store.commit(self.currentPageMutation, 1)
      self.$store.commit(self.perPageMutation, value)
    }, 500),

    setCurrentPage(value) {
      this.commitCurrentPage(value, this)
    },

    commitCurrentPage: _.debounce(function (value, self) {
      self.$store.commit(self.currentPageMutation, value)
    }, 500),

    setSearchScope(value) {
      this.commitSearchScope(value, this)
    },

    commitSearchScope: _.debounce(function (value, self) {
      self.$store.commit(self.searchScopeMutation, value)
      self.$store.commit(self.currentPageMutation, 1)
      self.$store.commit(CLEAR_STORE)
    }, 100),

    notNullOrEmpty(val) {
      return val !== null && val !== ''
    }
  }
}

export default ui
