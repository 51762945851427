<template>
  <a class="index-play-button is-hidden-hover" @click="playAudioFile">
    <IconPlay/>
  </a>
</template>

<script>
import { IconPlay } from '@/Icons/IconBase'
import { AUDIO_PLAYER_LOAD } from '@/store/actions'

export default {
  name: 'IndexPlayButton',
  props: ['audioFileId'],
  components: {
    IconPlay
  },
  methods: {
    playAudioFile() {
      this.$store.dispatch(AUDIO_PLAYER_LOAD, { id: this.audioFileId })
    }
  }
}
</script>
