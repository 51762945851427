<template>
  <div>
    <div class="field m-b-15">
      <label for="upload" class="label">Upload Audio</label>
      <div class="file is-primary is-fullwidth has-name">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            name="file"
            :disabled="isSaving"
            @change="fileChange($event)"
            accept="audio/*"
            ref="fileInput"
            required
          >
          <span class="file-cta">
            <span class="file-icon">
              <IconUpload/>
            </span>
            <span class="file-label">Choose a file…</span>
          </span>
          <span class="file-name">{{ audioFile.file_name }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { IconUpload } from '@/Icons/IconBase'

export default {
  name: 'AudioFileForm',
  components: {
    IconUpload
  },
  model: {
    prop: 'audioFile',
    event: 'change'
  },
  props: {
    audioFile: Object,
    isSaving: Boolean
  },
  methods: {
    fileChange(event) {
      let fileList = event.target.files
      if (!fileList.length) return
      this.$emit('file-change', event)
      this.$emit('change', { 'file_name': fileList[0].name })
    }
  }
}
</script>
