<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="field m-b-15">
          <label for="start_date" class="label">Start Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('optionPeriod.start_date', optionPeriod.start_date, 'Option Period Start Date', 'single')">
              <span v-if="optionPeriod.start_date">
                {{ optionPeriod.start_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field m-b-15">
          <label for="end_date" class="label">End Date</label>
          <div class="control">
            <div class="button is-fullwidth is-primary is-inverted has-text-left"
              @click="openDatePicker('optionPeriod.end_date', optionPeriod.end_date, 'Option Period End Date', 'single')">
              <span v-if="optionPeriod.end_date">
                {{ optionPeriod.end_date | momentDate }}
              </span>
              <span v-else>
                Select Date
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datepicker from '@/mixins/datepicker'

export default {
  name: 'ContractOptionPeriodForm',
  mixins: [datepicker],
  model: {
    prop: 'optionPeriod',
    event: 'change'
  },
  props: {
    optionPeriod: Object
  }
}
</script>
