import Vue from 'vue'
import axios from 'axios'

import {
  EXPORT_ALL,
  EXPORT_ALL_COLUMNS,
  EXPORT_CREATE,
  EXPORT_READ,
  EXPORT_UPDATE,
  EXPORT_DELETE
} from '../actions'

import {
  CLEAR_STORE,
  EXPORT_LOADING,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  EXPORT_SET,
  EXPORTS_SET,
  EXPORT_CURRENT_PAGE_SET,
  EXPORT_PER_PAGE_SET,
  EXPORT_TOTAL_PAGES_SET,
  EXPORT_SEARCH_VALUE_SET
} from '../mutations'

export default {
  state: {
    status: '',
    exports: {},
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    searchValue: ''
  },

  mutations: {
    [EXPORT_LOADING]: (state) => {
      state.status = 'loading'
    },
    [EXPORT_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [EXPORT_ERROR]: (state) => {
      state.status = 'error'
    },
    [EXPORT_SET]: (state, resp) => {
      Vue.set(state, 'export', resp)
    },
    [EXPORTS_SET]: (state, resp) => {
      Vue.set(state, 'exports', resp)
    },
    [EXPORT_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [EXPORT_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [EXPORT_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [EXPORT_SEARCH_VALUE_SET]: (state, value) => {
      state.searchValue = value
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'export', {})
      Vue.set(state, 'exports', [])
    }
  },

  actions: {
    [EXPORT_ALL]: ({
      commit,
      state
    }) => {
      commit(EXPORT_LOADING)

      let params = {
        page: state.currentPage,
        per_page: state.perPage
      }

      if (state.searchValue && state.searchValue.length) {
        params.search = state.searchValue
      }

      axios({
        url: 'exports',
        params: params
      })
        .then(resp => {
          commit(EXPORT_SUCCESS)
          commit(EXPORTS_SET, resp.data.data)
          commit(EXPORT_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(EXPORT_ERROR)
        })
    },

    [EXPORT_ALL_COLUMNS]: ({
      commit
    }, type) => {
      return new Promise((resolve, reject) => {
        commit(EXPORT_LOADING)

        axios({
          url: 'export-columns',
          params: {
            type: type
          }
        })
          .then(resp => {
            commit(EXPORT_SUCCESS)
            resolve(resp.data.data)
          })
          .catch(err => {
            reject(err)
            commit(EXPORT_ERROR)
          })
      })
    },

    [EXPORT_READ]: ({
      commit
    }, id) => {
      commit(EXPORT_LOADING)
      commit(EXPORT_SET, {})
      axios({
        url: 'exports/' + id
      })
        .then(resp => {
          commit(EXPORT_SUCCESS)
          commit(EXPORT_SET, resp.data.data)
        })
        .catch(resp => {
          commit(EXPORT_ERROR)
        })
    },

    [EXPORT_CREATE]: ({
      commit,
      getters
    }, data) => {
      return new Promise((resolve, reject) => {
        commit(EXPORT_LOADING)

        let accountId = getters.getActiveAccountId

        if (!accountId) {
          commit(EXPORT_ERROR)
          return false
        }

        // Must ensure that data.search is an object before assigning
        // Artist and Contact exports data.search will be a string
        if (typeof data.search === 'object') {
          let params = Object.assign({}, data.search)

          if (params.accountTag !== null || params.userTag !== null || params.tempoTag !== null ||
            params.vocalTag !== null || params.audioTag !== null || params.genreTag !== null || params.emotionTag !== null) {
            // create new array of all tags if any tags exist
            let tagsArray = [
              ...params.accountTag || [], ...params.userTag || [], ...params.tempoTag || [],
              ...params.vocalTag || [], ...params.audioTag || [], ...params.genreTag || [], ...params.emotionTag || []
            ]
            params.tag_ids = tagsArray.map(function (tag) {
              return tag.id
            })
            params.accountTag = params.userTag = params.tempoTag = params.vocalTag = params.audioTag = params.genreTag = params.emotionTag = null
          }

          if (params.releases && params.releases !== null) {
            params.releases = params.releases.map(function (release) {
              return release.value
            })
          }

          if (params.activity && params.activity !== null) {
            params.activity = params.activity.map(function (activity) {
              return activity.value
            })
          }

          if (params.song_rating && params.song_rating !== null) {
            params.song_rating = params.song_rating.value
          }

          if (params.songwriters && params.songwriters !== null) {
            params.songwriter_ids = params.songwriters.map(function (songwriter) {
              return songwriter.id
            })

            params.songwriters = null
          }

          if (params.record_labels && params.record_labels !== null) {
            params.record_label_ids = params.record_labels.map(function (recordLabel) {
              return recordLabel.id
            })

            params.record_labels = null
          }

          if (params.record_label_contacts && params.record_label_contacts !== null) {
            params.record_label_contact_ids = params.record_label_contacts.map(function (recordLabelContact) {
              return recordLabelContact.id
            })

            params.record_label_contacts = null
          }

          if (params.master_owners && params.master_owners !== null) {
            params.master_owner_id_and_types = params.master_owners.map(function (masterOwner) {
              return masterOwner.id_and_type
            })

            params.master_owners = null
          }

          if (params.publishing_companies && params.publishing_companies !== null) {
            params.publishing_company_ids = params.publishing_companies.map(function (publishingCompany) {
              return publishingCompany.id
            })

            params.publishing_companies = null
          }

          if (params.artists && params.artists !== null) {
            params.artist_ids = params.artists.map(function (artist) {
              return artist.id
            })

            params.artists = null
          }

          if (params.song_titles && params.song_titles !== null) {
            params.song_ids = params.song_titles.map(function (song) {
              return song.id
            })

            params.song_titles = null
          }

          if (params.pitch_artists && params.pitch_artists !== null) {
            params.pitch_for_artist_ids = params.pitch_artists.map(function (artist) {
              return artist.id
            })

            params.pitch_artist_ids = params.pitch_artists.map(function (artist) {
              return artist.id
            })

            params.pitch_artists = null
          }

          if (params.pitch_by_user && params.pitch_by_user !== null) {
            params.pitch_by_user_ids = [params.pitch_by_user.id]
            params.pitch_by_user = null
          }

          if (params.pitch_to_contact && params.pitch_to_contact !== null) {
            params.pitch_to_contact_ids = [params.pitch_to_contact.id]
            params.pitch_to_contact = null
          }

          for (var propName in params) {
            if (params[propName] === null || params[propName] === undefined) {
              delete params[propName]
            }
          }

          data.search = params
        }

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/exports',
          data: data
        })
          .then(resp => {
            commit(EXPORT_SUCCESS)
            console.log(resp)
            resolve(resp)
          })
          .catch(err => {
            commit(EXPORT_ERROR)
            reject(err)
          })
      })
    },

    [EXPORT_UPDATE]: ({
      commit,
      dispatch
    }, {
      id,
      data
    }) => {
      commit(EXPORT_LOADING)
      axios({
        method: 'put',
        url: 'exports/' + id,
        data: data
      })
        .then(resp => {
          commit(EXPORT_SUCCESS)
          commit(EXPORT_SET, resp.data.data)
          dispatch(EXPORT_ALL)
        })
        .catch(resp => {
          commit(EXPORT_ERROR)
        })
    },

    [EXPORT_DELETE]: ({
      commit,
      dispatch
    }, id) => {
      commit(EXPORT_LOADING)

      axios({
        method: 'delete',
        url: 'exports/' + id
      })
        .then(resp => {
          commit(EXPORT_SET, {})
          dispatch(EXPORT_ALL)
        })
        .catch(resp => {
          commit(EXPORT_ERROR)
        })
    }
  }
}
