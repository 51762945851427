<template>
  <div>
    <index-header title="Account Profile"></index-header>
    <div class="columns">
      <div class="column is-7">
        <h3 class="m-b-30">Subscription</h3>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSubscription'
}
</script>
