<template>
  <div class="form">
    <div class="field m-b-15">
      <label for="title-input" class="label">Playlist Title</label>
      <input
        id="title-input"
        type="text"
        name="title"
        class="input"
        placeholder="Playlist Title"
        v-model="playlist.title"
        required
        autocomplete="off"
        autofocus="true"
        @change="inputChange($event)"
      >
    </div>

    <div class="field m-b-15">
      <label class="label">Pitching To</label>
      <div class="control">
        <multiselect
          id="pitch_to_contact_id"
          v-model="playlist.pitch_to_contact"
          :options="contacts"
          track-by="id"
          label="full_name"
          :close-on-select="true"
          @select="multiselectChange"
        ></multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Pitching For</label>
      <div class="control">
        <multiselect
          id="pitch_for_artist_id"
          v-model="playlist.pitch_for_artist"
          :options="artists"
          track-by="id"
          label="name"
          :close-on-select="true"
          @select="multiselectChange"
        ></multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <div class="field m-b-30 m-t-15">
          <label for="shared" class="label">
            <input
              id="shared"
              type="checkbox"
              class="checkbox m-r-10"
              name="shared"
              v-model="playlist.shared"
              @change="inputChange($event)"
            >
            Shared
          </label>
        </div>
      </div>
      <div class="column is-half">
        <div class="field m-b-30 m-t-15">
          <label for="comps" class="label">
            <input
              id="comps"
              type="checkbox"
              class="checkbox m-r-10"
              name="comps"
              v-model="playlist.comps"
              @change="inputChange($event)"
            >
            Comp
          </label>
        </div>
      </div>
    </div>

    <div class="field m-b-15">
      <label for="upload" class="label">Image</label>
      <div class="file is-primary is-fullwidth has-name">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            name="image"
            :disabled="isSaving"
            @change="fileChange($event)"
            accept="image/*"
            ref="fileInput"
          >
          <span class="file-cta">
            <span class="file-icon">
              <IconUpload/>
            </span>
            <span class="file-label">Browse</span>
          </span>
          <span class="file-name">{{ newFileName ? newFileName : 'Upload an image (optional)' }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { IconUpload } from '@/Icons/IconBase'

export default {
  name: 'PlaylistFileForm',
  model: {
    prop: 'playlist',
    event: 'change'
  },
  components: {
    Multiselect,
    IconUpload
  },
  props: {
    playlist: Object,
    isSaving: Boolean
  },
  data() {
    return {
      newFileName: null
    }
  },
  methods: {
    fileChange(event) {
      let fileList = event.target.files
      if (!fileList.length) return
      this.$emit('file-change', event)
      this.newFileName = fileList[0].name
    },
    inputChange(event) {
      let fieldName = event.target.name
      let value = event.target.value
      this.$emit('input-change', fieldName, value)
    },
    multiselectChange(input, id) {
      this.$emit('input-change', id, input.id)
    }
  },
  computed: {
    artists() {
      return this.$store.state.artist.artists
    },
    contacts() {
      return this.$store.state.contact.contacts
    }
  }
}
</script>
