<template>
  <div v-if="$can.includes('read-release')">
    <section class="single-info">
      <div class="level is-mobile">
        <div class="level-left">
          <h3 class="m-b-30">Releases</h3>
        </div>
        <div class="level-right">
          <a class="is-primary" @click="initializeRelease" v-if="$can.includes('create-release')">
            <IconPlus/>
          </a>
        </div>
      </div>
      <div class="level" v-if="!releases.length">
        <p>
          No releases to display.
          <a @click="initializeRelease" v-if="$can.includes('create-release')">Add a release here.</a>
        </p>
      </div>
      <div class="level" v-else>
        <table class="table is-responsive is-fullwidth is-hoverable">
          <thead>
            <tr>
              <!-- <th></th> -->
              <th>Release Date</th>
              <th>Cut Date</th>
              <th>Type</th>
              <th>Album</th>
              <th>Artists</th>
              <th>Label</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="release in releases"
              :key="release.id"
              @click="edit(release)"
              :class="{'is-clickable' : $can.includes('update-release'), 'is-prohibited' : !$can.includes('update-release') }"

            >
              <!-- <td>
                <a class="table-icon non-clickable">
                  <IconCheckSquare v-if="release.released"/>
                  <IconSquare v-if="!release.released"/>
                </a>
              </td> -->
              <td>{{ release.release_date | momentDate }}</td>
              <td>{{ release.cut.cut_date | momentDate }}</td>
              <td>{{ release.type | orBlank('name') }}</td>
              <td>{{ release.album | orBlank('title') }}</td>
              <td>
                {{ release.cut.artists | arrayString('name') | orBlank() }}
                <span v-if="release.cut.featured_artists.length"> feat. {{ release.cut.featured_artists | arrayString('name') | orBlank() }}</span>
              </td>
              <td>{{ release.record_label | orBlank('name') }}</td>
              <td>{{ release.label_contact | orBlank('full_name') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

      <form-modal
      type="release-init"
      :title="formTitle"
      :model="selectedRelease"
      :isModal="isInitModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:item-selected="create"
    ></form-modal>

    <form-modal
      type="release"
      :title="formTitle"
      :model="selectedRelease"
      :isModal="isModal"
      :canDelete="$can.includes('delete-release')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'
import { IconPlus } from '@/Icons/IconBase'

import { RELEASES_SET } from '@/store/mutations'
import { RELEASE_ALL_FOR_SONG, RELEASE_UPDATE, RELEASE_CREATE, RELEASE_DELETE, SONG_UPDATE } from '@/store/actions'

export default {
  name: 'ReleasesSong',
  mixins: [uiHelper],
  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isInitModal: false,
      isModal: false,
      isEditModal: false,
      selectedRelease: {},
      isSaving: false
    }
  },

  props: ['songId'],

  components: {
    IconPlus
  },

  destroyed: function () {
    this.$store.commit(RELEASES_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.release
    },

    releases() {
      return this.$store.state.release.releases
    }
  },

  methods: {
    load() {
      if (this.songId) {
        this.$store.dispatch(RELEASE_ALL_FOR_SONG, this.songId)
      }
    },

    initializeRelease() {
      if (!this.$store.state.cut.cuts.length) {
        this.$store.commit('alert', 'This song has no cuts to release.')
        return
      }

      this.clearModal()
      this.formTitle = 'Select Cut to Release'
      this.isInitModal = !this.isInitModal
    },

    create(cut) {
      this.clearModal()
      this.formTitle = 'Add Release'
      this.selectedRelease = {
        cut: cut
      }
      this.isModal = !this.isModal
    },

    edit(release) {
      if (this.$can.includes('update-release')) {
        this.clearModal()
        this.formCanDelete = true
        this.formTitle = 'Update Release'
        this.selectedRelease = release
        this.isModal = !this.isModal
      }
    },

    save(input) {
      let data = {
        cut_id: input.cut.id,
        album_id: input.album ? input.album.id : null,
        record_label_id: input.record_label ? input.record_label.id : null,
        label_contact_id: input.label_contact ? input.label_contact.id : null,
        release_date: input.release_date,
        type: input.type ? input.type.value : null,
        // released: input.released,
        isrc_number: input.isrc_number
      }

      if (this.selectedRelease.id) {
        this.$store.dispatch(RELEASE_UPDATE, {
          id: this.selectedRelease.id,
          data: data,
          songId: this.songId
        })
      } else {
        this.$store.dispatch(RELEASE_CREATE, {
          cutId: input.cut.id,
          data: data,
          songId: this.songId
        })
      }
      this.$emit('child-updated')
      this.clearModal()
    },

    destroy(release) {
      let self = this
      this.$store.dispatch(RELEASE_DELETE, { id: release.id, songId: this.songId })
        .then(function () {
          self.$store.dispatch(SONG_UPDATE, {
            id: self.songId,
            data: null
          })
        })
        .then(function () {
          self.clearModal()
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isInitModal = false
      this.isModal = false
      this.selectedRelease = {}
    }
  }
}
</script>
