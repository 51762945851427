<template>
  <div class="content">
    <progress v-if="isLoading" class="progress is-small is-primary main-progress-bar" max="100"></progress>
    <section class="m-t-30">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <a @click="close" class="button is-primary is-inverted back">
                <IconArrowLeft/>Back to Playlist
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="m-t-10">
      <div class="container" v-if="audioFile.id && audioFile.song">
        <div class="card is-small has-text-centered">
          <div class="card-content">
            <h2 class="title">{{ audioFile.song.title }}</h2>
            <p class="subtitle is-size-6 has-text-grey-light">{{ audioFile.audio_file_name}}</p>
            <label for="Songwriters" class="label m-b-0">Songwriters</label>
            <p>{{ audioFile.song | orBlank('songwriter_names') }}</p>

            <div v-if="playlist">
              <div class="level">
                <div v-if="playlist.pitch_to_contact" class="level-item">
                  <div class="field m-b-15">
                    <label class="label">Pitching To</label>
                    <p>{{ playlist.pitch_to_contact.full_name }}</p>
                  </div>
                </div>
                <div v-if="playlist.pitch_for_artist" class="level-item">
                  <div class="field m-b-15">
                    <label class="label">Pitching For</label>
                    <p>{{ playlist.pitch_for_artist.name }}</p>
                  </div>
                </div>
              </div>

              <form class="form" @submit="save">
                <div class="field m-b-15">
                  <label class="label">Pitch Response</label>
                  <div class="control">
                    <multiselect
                      v-model="pitch.status"
                      :options="['Pass', 'Took Copy', 'Request Hold']"
                      :value="pitch.status"
                      :close-on-select="true"
                    ></multiselect>
                  </div>
                </div>

                <div class="field m-b-15" v-if="pitch.status == 'Request Hold'">
                  <label for="primary_artist_hold" class="label">Primary Hold Artist</label>
                  <input
                    id="primary_artist_hold"
                    type="text"
                    class="input"
                    placeholder="Artist Name"
                    v-model="pitch.primary_artist_hold"
                    autocomplete="off"
                    required
                  >
                </div>
                <div class="field m-b-15" v-if="pitch.status == 'Request Hold'">
                  <label for="backup_artist_hold" class="label">Backup Hold Artist</label>
                  <input
                    id="backup_artist_hold"
                    type="text"
                    class="input"
                    placeholder="Backup Artist Name"
                    v-model="pitch.backup_artist_hold"
                    autocomplete="off"
                  >
                </div>

                <div class="field m-b-15">
                  <label for="notes" class="label">Notes</label>
                  <textarea
                    v-model="pitch.note"
                    cols="30"
                    rows="5"
                    class="textarea song-notes"
                    placeholder="Enter notes here..."
                  ></textarea>
                </div>

                <div class="field m-b-15">
                  <button type="submit" class="button is-primary">Send to Pitchlog</button>
                </div>
              </form>
                <div class="field m-b-15">
                  <button @click="sendLinkModal" class="button is-primary">Send Link</button>
                </div>
            </div>
            <div v-else>
              <p>Loading playlist data...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <p>Song not found.</p>
      </div>
    </section>
    <a @click="close" class="close">
      <IconX/>
    </a>
    <form-modal
      :type="formType"
      :message="modalMessage"
      :title="formTitle"
      :model="selectedSong"
      :isModal="isModal"
      :isSaving="isSaving"
      v-on:clear-modal="clearModal"
      v-on:save-record="sendSongLink"
    ></form-modal>
  </div>
</template>

<script>
import { IconArrowLeft, IconX } from '@/Icons/IconBase'
import { AUDIO_FILE_READ, AUDIO_FILE_DOWNLOAD, PLAYLIST_READ, PITCH_CREATE, AUDIO_PLAYER_LOAD, EMAIL_CREATE } from '@/store/actions'
import { CLEAR_STORE } from '@/store/mutations'
import store from '@/store'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PlaylistPitchMeetingAudioFileShow',
  components: {
    IconArrowLeft,
    IconX,
    Multiselect
  },
  props: {
    propped: Object
  },
  data() {
    return {
      isLoading: false,
      formTitle: 'Request Hold',
      formType: null,
      isModal: false,
      modalMessage: null,
      pitch: {},
      selectedSong: {},
      isSaving: false
    }
  },

  beforeCreate: function () {
    document.body.className = 'pitch-view song'
  },

  computed: {
    audioFile() {
      if (this.$store.getters.getAudioFile.id) return this.$store.getters.getAudioFile
      if (this.propped) return this.propped
      return {}
    },

    playlist() {
      if (this.$store.getters.getPlaylist.id) return this.$store.getters.getPlaylist
      return null
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(AUDIO_FILE_READ, to.params.id)
    store.dispatch(PLAYLIST_READ, to.params.playlist_id)
    store.dispatch(AUDIO_PLAYER_LOAD, { id: to.params.id, pitchId: null })
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(AUDIO_FILE_READ, to.params.id)
    store.dispatch(PLAYLIST_READ, to.params.playlist_id)
    store.dispatch(AUDIO_PLAYER_LOAD, { id: to.params.id, pitchId: null })
    next()
  },

  methods: {
    close() {
      this.$store.commit(CLEAR_STORE)
      this.$router.push({ name: 'playlists.pitch-meeting.show', params: { id: this.$route.params.playlist_id, propped: this.playlist } })
    },

    save($event) {
      $event.preventDefault()

      let data = this.pitch
      data.pitch_artists = this.playlist.pitch_for_artist
      data.pitch_to_contact = this.playlist.pitch_to_contact
      data.pitch_by_user = this.$store.getters.getProfile

      let self = this
      this.$store.dispatch(PITCH_CREATE, {
        songId: this.audioFile.song.id,
        data: data
      }).then(function (resp) {
        self.close()
      }, function (err) {
        self.close()
        self.$store.commit('error', err)
      })
    },

    download(id) {
      let self = this
      this.isLoading = true
      this.$store.dispatch(AUDIO_FILE_DOWNLOAD, id)
        .then(function (resp) {
          self.isLoading = false
        }).catch(function (err) {
          self.isLoading = false
          self.$store.commit('error', err)
        })
    },
    sendLinkModal() {
      this.clearModal()
      this.formTitle = `New Link For - ${this.propped.song.title}`
      this.formType = 'songLink'
      this.isModal = !this.isModal
      this.selectedSong = this.propped.song
    },
    clearModal() {
      this.isModal = false
      this.formTitle = ''
      this.selectedSong = {}
      this.modalMessage = null
      this.formType = null
      this.isSaving = false
    },
    sendSongLink(data) {
      let self = this
      this.isSaving = true
      this.$store.dispatch(EMAIL_CREATE, {
        type: 'song',
        id: data.id,
        allowAudioDownload: data.allowAudioDownload,
        linkOnly: false,
        subject: data.subject,
        body: data.message,
        contactIds: [data.send_to_contact.id],
        pitchForArtists: []
      })
        .then(function () {
          self.clearModal()
        })
    }
  }
}
</script>
