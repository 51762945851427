<template>
  <a @click="playPitchGroup" class="table-icon tooltip" data-tooltip="Play All">
    <IconPlay/>
  </a>
</template>

<script>
import { IconPlay } from '@/Icons/IconBase'
import { AUDIO_PLAYER_LOAD, QUEUED_AUDIO_FILE_SESSION_ALL, QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PITCH_GROUP, QUEUED_AUDIO_FILE_SESSION_UPDATE, NOTIFY_PITCHED_SONG_LISTENED_TO } from '@/store/actions'
import moment from 'moment'

export default {
  name: 'SessionPlayPitchGroupButton',
  props: ['pitches', 'pitchGroupId'],
  components: {
    IconPlay
  },
  methods: {
    playPitchGroup() {
      let self = this
      let sessionId = this.$store.getters.getSessionId
      this.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_CREATE_BY_PITCH_GROUP, {
        session_id: sessionId,
        pitch_group_id: this.pitchGroupId,
        overwrite: true
      })
        .then(function () {
          self.playQueuedAudioFiles(sessionId)
        })
        .catch(function (err) {
          self.$store.commit('error', `Could not play playlist.`)
          console.log(err)
        })
    },

    playQueuedAudioFiles(sessionId) {
      let self = this
      this.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_ALL, sessionId)
        .then(function (data) {
          if (data && data.length) {
            self.$store.dispatch(AUDIO_PLAYER_LOAD, { id: data[0].audio_file_id, pitchId: data[0].pitch_id })
            self.$store.dispatch(NOTIFY_PITCHED_SONG_LISTENED_TO, { song: data[0].audio_file.song.id, pitch: data[0].pitch_id })
            self.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_UPDATE, {
              id: data[0].id,
              data: {
                played_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
              }
            }).then(function () {
              self.$store.dispatch(QUEUED_AUDIO_FILE_SESSION_ALL, sessionId)
            })
          }
        }, function (err) {
          self.$store.commit('error', `Could not play playlist.`)
          console.log(err)
        })
    }
  }
}
</script>
