<template>
  <div class="content login">
    <div class="columns is-centered">
      <div class="column is-6" v-if="isInitialized">
        <form @submit.prevent="submit" v-if="isValid">
          <h1>RESET PASSWORD</h1>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                required
                v-model="email"
                type="email"
                placeholder="sam@wrios.com"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input class="input" required v-model="password" type="password">
            </div>
          </div>

          <div class="field">
            <label class="label">Confirm Password</label>
            <div class="control">
              <input class="input" required v-model="passwordConfirmation" type="password">
            </div>
          </div>

          <hr>
          <button type="submit" class="button is-primary">Reset Password</button>
        </form>
        <div v-else>
          <h1>PASSWORD RESET EXPIRED</h1>
          <p>
            This password reset request is no longer valid.
            <br>Click below to request a new password reset.
          </p>
          <p>
            <router-link :to="{name: 'request-password-reset'}">Request Password Reset</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordsApi from '@/services/api/Passwords'

export default {
  data() {
    return {
      token: null,
      email: null,
      password: null,
      passwordConfirmation: null,
      isValid: false,
      isInitialized: false
    }
  },

  created() {
    this.validateToken(this.$route.params.token)
  },

  methods: {
    validateToken(token) {
      let self = this

      PasswordsApi.findToken(token)
        .then(resp => {
          self.token = resp.data.token
          self.isInitialized = true
          self.isValid = true
        })
        .catch(err => {
          self.isInitialized = true
          console.log(err)
        })
    },

    submit() {
      const { email, password, passwordConfirmation, token } = this
      let self = this

      PasswordsApi.resetPassword(token, email, password, passwordConfirmation)
        .then(resp => {
          self.$store.commit('success', 'Password reset successfully')
          self.$router.push({ name: 'login' })
        })
        .catch(err => {
          self.$store.commit('error', err.response.data.message)
        })
    }
  }
}
</script>
