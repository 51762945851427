export const ACCOUNT_ERROR = 'ACCOUNT_ERROR'
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING'
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS'
export const ACCOUNT_SET_ACTIVE_ACCOUNT = 'ACCOUNT_SET_ACTIVE_ACCOUNT'
export const ACCOUNT_SET_PROFILE = 'ACCOUNT_SET_PROFILE'
export const ACCOUNT_SET = 'ACCOUNT_SET'

export const ACCOUNT_TAG_ADDED = 'ACCOUNT_TAG_ADDED'
export const ACCOUNT_TAG_ERROR = 'ACCOUNT_TAG_ERROR'
export const ACCOUNT_TAG_LOADING = 'ACCOUNT_TAG_LOADING'
export const ACCOUNT_TAG_SUCCESS = 'ACCOUNT_TAG_SUCCESS'
export const ACCOUNT_TAG_SET = 'ACCOUNT_TAG_SET'
export const ACCOUNT_TAGS_SET = 'ACCOUNT_TAGS_SET'
export const ACCOUNT_TAG_CURRENT_PAGE_SET = 'ACCOUNT_TAG_CURRENT_PAGE_SET'
export const ACCOUNT_TAG_PER_PAGE_SET = 'ACCOUNT_TAG_PER_PAGE_SET'
export const ACCOUNT_TAG_TOTAL_PAGES_SET = 'ACCOUNT_TAG_TOTAL_PAGES_SET'
export const ACCOUNT_TAG_SEARCH_VALUE_SET = 'ACCOUNT_TAG_SEARCH_VALUE_SET'

export const ACCOUNT_USER_ADDED = 'ACCOUNT_USER_ADDED'
export const ACCOUNT_USER_ERROR = 'ACCOUNT_USER_ERROR'
export const ACCOUNT_USER_LOADING = 'ACCOUNT_USER_LOADING'
export const ACCOUNT_USER_SUCCESS = 'ACCOUNT_USER_SUCCESS'
export const ACCOUNT_USER_SET = 'ACCOUNT_USER_SET'
export const ACCOUNT_USERS_SET = 'ACCOUNT_USERS_SET'
export const ACCOUNT_USER_CURRENT_PAGE_SET = 'ACCOUNT_USER_CURRENT_PAGE_SET'
export const ACCOUNT_USER_PER_PAGE_SET = 'ACCOUNT_USER_PER_PAGE_SET'
export const ACCOUNT_USER_TOTAL_PAGES_SET = 'ACCOUNT_USER_TOTAL_PAGES_SET'
export const ACCOUNT_USER_SEARCH_VALUE_SET = 'ACCOUNT_USER_SEARCH_VALUE_SET'

export const ALBUM_ERROR = 'ALBUM_ERROR'
export const ALBUM_LOADING = 'ALBUM_LOADING'
export const ALBUM_SUCCESS = 'ALBUM_SUCCESS'
export const ALBUM_SET = 'ALBUM_SET'
export const ALBUMS_SET = 'ALBUMS_SET'
export const ALBUM_SET_ALL = 'ALBUM_SET_ALL'
export const ALBUM_CURRENT_PAGE_SET = 'ALBUM_CURRENT_PAGE_SET'
export const ALBUM_PER_PAGE_SET = 'ALBUM_PER_PAGE_SET'
export const ALBUM_TOTAL_PAGES_SET = 'ALBUM_TOTAL_PAGES_SET'
export const ALBUM_SEARCH_VALUE_SET = 'ALBUM_SEARCH_VALUE_SET'

export const ARTIST_ERROR = 'ARTIST_ERROR'
export const ARTIST_LOADING = 'ARTIST_LOADING'
export const ARTIST_SUCCESS = 'ARTIST_SUCCESS'
export const ARTIST_SET = 'ARTIST_SET'
export const ARTIST_SET_ALL = 'ARTIST_SET_ALL'
export const ARTIST_CURRENT_PAGE_SET = 'ARTIST_CURRENT_PAGE_SET'
export const ARTIST_PER_PAGE_SET = 'ARTIST_PER_PAGE_SET'
export const ARTIST_TOTAL_PAGES_SET = 'ARTIST_TOTAL_PAGES_SET'
export const ARTIST_SEARCH_VALUE_SET = 'ARTIST_SEARCH_VALUE_SET'

export const AUDIO_FILE_ERROR = 'AUDIO_FILE_ERROR'
export const AUDIO_FILE_LOADING = 'AUDIO_FILE_LOADING'
export const AUDIO_FILE_SUCCESS = 'AUDIO_FILE_SUCCESS'
export const AUDIO_FILE_DELETED = 'AUDIO_FILE_DELETED'
export const AUDIO_FILE_SET = 'AUDIO_FILE_SET'
export const AUDIO_FILES_SET = 'AUDIO_FILES_SET'

export const AUDIO_FILE_SELECT_LOADING = 'AUDIO_FILE_SELECT_LOADING'
export const AUDIO_FILE_SELECT_READY = 'AUDIO_FILE_SELECT_READY'
export const AUDIO_FILE_SELECT_SET = 'AUDIO_FILE_SELECT_SET'
export const AUDIO_FILE_SELECT_RESPONSE_SET = 'AUDIO_FILE_SELECT_RESPONSE_SET'
export const AUDIO_FILE_SELECT_CLEAR = 'AUDIO_FILE_SELECT_CLEAR'

export const AUDIO_FILE_TYPE_ADDED = 'AUDIO_FILE_TYPE_ADDED'
export const AUDIO_FILE_TYPE_ERROR = 'AUDIO_FILE_TYPE_ERROR'
export const AUDIO_FILE_TYPE_LOADING = 'AUDIO_FILE_TYPE_LOADING'
export const AUDIO_FILE_TYPE_SUCCESS = 'AUDIO_FILE_TYPE_SUCCESS'
export const AUDIO_FILE_TYPE_SET = 'AUDIO_FILE_TYPE_SET'
export const AUDIO_FILE_TYPES_SET = 'AUDIO_FILE_TYPES_SET'

export const AUDIO_PLAYER_ERROR = 'AUDIO_PLAYER_ERROR'
export const AUDIO_PLAYER_LOADING = 'AUDIO_PLAYER_LOADING'
export const AUDIO_PLAYER_SUCCESS = 'AUDIO_PLAYER_SUCCESS'
export const AUDIO_PLAYER_SET_AUDIO_FILE = 'AUDIO_PLAYER_SET_AUDIO_FILE'
export const AUDIO_PLAYER_CLEAR_STORE = 'AUDIO_PLAYER_CLEAR_STORE'
export const AUDIO_PLAYER_ACTIVATE_SESSION_QUEUE = 'AUDIO_PLAYER_ACTIVATE_SESSION_QUEUE'
export const AUDIO_PLAYER_ACTIVATE_USER_QUEUE = 'AUDIO_PLAYER_ACTIVATE_USER_QUEUE'

export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOADING = 'AUTH_LOADING'
export const AUTH_LOGGED_OUT = 'AUTH_LOGOUT'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'

export const CLEAR_STORE = 'CLEAR_STORE'

export const CONFIRM_LOADING = 'CONFIRM_LOADING'
export const CONFIRM_READY = 'CONFIRM_READY'
export const CONFIRM_MESSAGE_SET = 'CONFIRM_MESSAGE_SET'
export const CONFIRM_RESPONSE_SET = 'CONFIRM_RESPONSE_SET'
export const CONFIRM_CLEAR = 'CONFIRM_CLEAR'

export const CONTACT_ERROR = 'CONTACT_ERROR'
export const CONTACT_LOADING = 'CONTACT_LOADING'
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CONTACT_SET = 'CONTACT_SET'
export const CONTACTS_SET = 'CONTACTS_SET'
export const CONTACT_CURRENT_PAGE_SET = 'CONTACT_CURRENT_PAGE_SET'
export const CONTACT_PER_PAGE_SET = 'CONTACT_PER_PAGE_SET'
export const CONTACT_TOTAL_PAGES_SET = 'CONTACT_TOTAL_PAGES_SET'
export const CONTACT_SEARCH_VALUE_SET = 'CONTACT_SEARCH_VALUE_SET'

export const CONTRACT_LOADING = 'CONTRACT_LOADING'
export const CONTRACT_SUCCESS = 'CONTRACT_SUCCESS'
export const CONTRACT_ERROR = 'CONTRACT_ERROR'
export const CONTRACT_SET = 'CONTRACT_SET'
export const CONTRACTS_SET = 'CONTRACTS_SET'

export const CONTRACT_SHARE_LOADING = 'CONTRACT_SHARE_LOADING'
export const CONTRACT_SHARE_SUCCESS = 'CONTRACT_SHARE_SUCCESS'
export const CONTRACT_SHARE_ERROR = 'CONTRACT_SHARE_ERROR'
export const CONTRACT_SHARE_SET = 'CONTRACT_SHARE_SET'

export const CUT_LOADING = 'CUT_LOADING'
export const CUT_SUCCESS = 'CUT_SUCCESS'
export const CUT_ERROR = 'CUT_ERROR'
export const CUT_SET = 'CUT_SET'
export const CUTS_SET = 'CUTS_SET'

export const DATEPICKER_TYPE_SET = 'DATEPICKER_TYPE_SET'
export const DATEPICKER_DATA_SET = 'DATEPICKER_DATA_SET'
export const DATEPICKER_CLEAR = 'DATEPICKER_CLEAR'

export const EMAIL_ERROR = 'EMAIL_ERROR'
export const EMAIL_DELETED = 'EMAIL_DELETED'
export const EMAIL_LOADING = 'EMAIL_LOADING'
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS'
export const EMAIL_UPDATED = 'EMAIL_UPDATED'
export const EMAIL_SET = 'EMAIL_SET'
export const EMAILS_SET = 'EMAILS_SET'
export const EMAIL_CURRENT_PAGE_SET = 'EMAIL_CURRENT_PAGE_SET'
export const EMAIL_PER_PAGE_SET = 'EMAIL_PER_PAGE_SET'
export const EMAIL_TOTAL_PAGES_SET = 'EMAIL_TOTAL_PAGES_SET'
export const EMAIL_SORT_SET = 'EMAIL_SORT_SET'

export const EXPORT_ERROR = 'EXPORT_ERROR'
export const EXPORT_LOADING = 'EXPORT_LOADING'
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS'
export const EXPORT_SET = 'EXPORT_SET'
export const EXPORTS_SET = 'EXPORTS_SET'
export const EXPORT_CURRENT_PAGE_SET = 'EXPORT_CURRENT_PAGE_SET'
export const EXPORT_PER_PAGE_SET = 'EXPORT_PER_PAGE_SET'
export const EXPORT_TOTAL_PAGES_SET = 'EXPORT_TOTAL_PAGES_SET'
export const EXPORT_SEARCH_VALUE_SET = 'EXPORT_SEARCH_VALUE_SET'

export const FILE_LOADING = 'FILE_LOADING'
export const FILE_SUCCESS = 'FILE_SUCCESS'
export const FILE_ERROR = 'FILE_ERROR'

export const HOLD_LOADING = 'HOLD_LOADING'
export const HOLD_SUCCESS = 'HOLD_SUCCESS'
export const HOLD_ERROR = 'HOLD_ERROR'
export const HOLD_SET = 'HOLD_SET'
export const HOLDS_SET = 'HOLDS_SET'

export const IMAGE_LOADING = 'IMAGE_LOADING'
export const IMAGE_SUCCESS = 'IMAGE_SUCCESS'
export const IMAGE_ERROR = 'IMAGE_ERROR'

export const MESSAGE_LOADING = 'MESSAGE_LOADING'
export const MESSAGE_READY = 'MESSAGE_READY'
export const MESSAGE_ALERT_SET = 'MESSAGE_ALERT_SET'
export const MESSAGE_ERROR_SET = 'MESSAGE_ERROR_SET'
export const MESSAGE_SUCCESS_SET = 'MESSAGE_SUCCESS_SET'
export const MESSAGE_EXPORT_SET = 'MESSAGE_EXPORT_SET'
export const MESSAGE_QUEUE_SET = 'MESSAGE_QUEUE_SET'
export const MESSAGE_MODAL_MESSAGE_SET = 'MESSAGE_MODAL_MESSAGE_SET'
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR'

export const NOTIFICATION_LOADING = 'NOTIFICATION_LOADING'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR'
export const NOTIFICATION_SET = 'NOTIFICATION_SET'
export const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET'
export const NOTIFICATION_PER_PAGE_SET = 'NOTIFICATION_PER_PAGE_SET'
export const NOTIFICATION_CURRENT_PAGE_SET = 'NOTIFICATION_CURRENT_PAGE_SET'
export const NOTIFICATION_TOTAL_PAGES_SET = 'NOTIFICATION_TOTAL_PAGES_SET'

export const OPTION_PERIOD_LOADING = 'OPTION_PERIOD_LOADING'
export const OPTION_PERIOD_SUCCESS = 'OPTION_PERIOD_SUCCESS'
export const OPTION_PERIOD_ERROR = 'OPTION_PERIOD_ERROR'
export const OPTION_PERIOD_SET = 'OPTION_PERIOD_SET'

export const PITCH_ERROR = 'PITCH_ERROR'
export const PITCH_DELETED = 'PITCH_DELETED'
export const PITCH_LOADING = 'PITCH_LOADING'
export const PITCH_SUCCESS = 'PITCH_SUCCESS'
export const PITCH_UPDATED = 'PITCH_UPDATED'
export const PITCH_SET = 'PITCH_SET'
export const PITCHES_SET = 'PITCHES_SET'
export const PITCH_CURRENT_PAGE_SET = 'PITCH_CURRENT_PAGE_SET'
export const PITCH_PER_PAGE_SET = 'PITCH_PER_PAGE_SET'
export const PITCH_TOTAL_PAGES_SET = 'PITCH_TOTAL_PAGES_SET'
export const PITCH_SORT_SET = 'PITCH_SORT_SET'
export const PITCH_SEARCH_QUERY_SET = 'PITCH_SEARCH_QUERY_SET'
export const PITCH_SEARCH_SCOPE_SET = 'PITCH_SEARCH_SCOPE_SET'
export const PITCH_REMOVE_QUERY_ARRAY_VALUE = 'PITCH_REMOVE_QUERY_ARRAY_VALUE'

export const PLAYLIST_ERROR = 'PLAYLIST_ERROR'
export const PLAYLIST_LOADING = 'PLAYLIST_LOADING'
export const PLAYLIST_SUCCESS = 'PLAYLIST_SUCCESS'
export const PLAYLIST_CREATED = 'PLAYLIST_CREATED'
export const PLAYLIST_UPDATED = 'PLAYLIST_UPDATED'
export const PLAYLIST_DELETED = 'PLAYLIST_DELETED'
export const PLAYLIST_SET = 'PLAYLIST_SET'
export const PLAYLISTS_SET = 'PLAYLISTS_SET'
export const PLAYLIST_CURRENT_PAGE_SET = 'PLAYLIST_CURRENT_PAGE_SET'
export const PLAYLIST_PER_PAGE_SET = 'PLAYLIST_PER_PAGE_SET'
export const PLAYLIST_TOTAL_PAGES_SET = 'PLAYLIST_TOTAL_PAGES_SET'
export const PLAYLIST_SORT_SET = 'PLAYLIST_SORT_SET'
export const PLAYLIST_SEARCH_QUERY_SET = 'PLAYLIST_SEARCH_QUERY_SET'
export const PLAYLIST_SEARCH_SCOPE_SET = 'PLAYLIST_SEARCH_SCOPE_SET'
export const PLAYLIST_REMOVE_QUERY_ARRAY_VALUE = 'PLAYLIST_REMOVE_QUERY_ARRAY_VALUE'

export const PROJECT_ERROR = 'PROJECT_ERROR'
export const PROJECT_LOADING = 'PROJECT_LOADING'
export const PROJECT_SUCCESS = 'PROJECT_SUCCESS'
export const PROJECT_SET = 'PROJECT_SET'
export const PROJECTS_SET = 'PROJECTS_SET'
export const PROJECT_CURRENT_PAGE_SET = 'PROJECT_CURRENT_PAGE_SET'
export const PROJECT_PER_PAGE_SET = 'PROJECT_PER_PAGE_SET'
export const PROJECT_TOTAL_PAGES_SET = 'PROJECT_TOTAL_PAGES_SET'
export const PROJECT_SEARCH_VALUE_SET = 'PROJECT_SEARCH_VALUE_SET'

export const PUBLISHING_COMPANY_ERROR = 'PUBLISHING_COMPANY_ERROR'
export const PUBLISHING_COMPANY_LOADING = 'PUBLISHING_COMPANY_LOADING'
export const PUBLISHING_COMPANY_SUCCESS = 'PUBLISHING_COMPANY_SUCCESS'
export const PUBLISHING_COMPANY_SET = 'PUBLISHING_COMPANY_SET'
export const PUBLISHING_COMPANIES_SET = 'PUBLISHING_COMPANIES_SET'
export const PUBLISHING_COMPANY_CURRENT_PAGE_SET = 'PUBLISHING_COMPANY_CURRENT_PAGE_SET'
export const PUBLISHING_COMPANY_PER_PAGE_SET = 'PUBLISHING_COMPANY_PER_PAGE_SET'
export const PUBLISHING_COMPANY_TOTAL_PAGES_SET = 'PUBLISHING_COMPANY_TOTAL_PAGES_SET'
export const PUBLISHING_COMPANY_SEARCH_VALUE_SET = 'PUBLISHING_COMPANY_SEARCH_VALUE_SET'

export const QUEUED_AUDIO_FILE_LOADING = 'QUEUED_AUDIO_FILE_LOADING'
export const QUEUED_AUDIO_FILE_ERROR = 'QUEUED_AUDIO_FILE_ERROR'
export const QUEUED_AUDIO_FILE_SUCCESS = 'QUEUED_AUDIO_FILE_SUCCESS'
export const QUEUED_AUDIO_FILE_SET_ALL = 'QUEUED_AUDIO_FILE_SET_ALL'

export const RECORD_LABEL_ERROR = 'RECORD_LABEL_ERROR'
export const RECORD_LABEL_LOADING = 'RECORD_LABEL_LOADING'
export const RECORD_LABEL_SUCCESS = 'RECORD_LABEL_SUCCESS'
export const RECORD_LABEL_SET = 'RECORD_LABEL_SET'
export const RECORD_LABELS_SET = 'RECORD_LABELS_SET'
export const RECORD_LABEL_CURRENT_PAGE_SET = 'RECORD_LABEL_CURRENT_PAGE_SET'
export const RECORD_LABEL_PER_PAGE_SET = 'RECORD_LABEL_PER_PAGE_SET'
export const RECORD_LABEL_TOTAL_PAGES_SET = 'RECORD_LABEL_TOTAL_PAGES_SET'
export const RECORD_LABEL_SEARCH_VALUE_SET = 'RECORD_LABEL_SEARCH_VALUE_SET'

export const RELEASE_LOADING = 'RELEASE_LOADING'
export const RELEASE_SUCCESS = 'RELEASE_SUCCESS'
export const RELEASE_ERROR = 'RELEASE_ERROR'
export const RELEASE_SET = 'RELEASE_SET'
export const RELEASES_SET = 'RELEASES_SET'

export const SONG_ERROR = 'SONG_ERROR'
export const SONG_DELETED = 'SONG_DELETED'
export const SONG_UPDATED = 'SONG_UPDATED'
export const SONG_LOADING = 'SONG_LOADING'
export const SONG_SUCCESS = 'SONG_SUCCESS'
export const SONG_SET = 'SONG_SET'
export const SONG_SET_ALL = 'SONG_SET_ALL'
export const SONG_CURRENT_PAGE_SET = 'SONG_CURRENT_PAGE_SET'
export const SONG_PER_PAGE_SET = 'SONG_PER_PAGE_SET'
export const SONG_TOTAL_PAGES_SET = 'SONG_TOTAL_PAGES_SET'
export const SONG_SORT_SET = 'SONG_SORT_SET'
export const SONG_SEARCH_QUERY_SET = 'SONG_SEARCH_QUERY_SET'
export const SONG_SEARCH_SCOPE_SET = 'SONG_SEARCH_SCOPE_SET'
export const SONG_REMOVE_QUERY_ARRAY_VALUE = 'SONG_REMOVE_QUERY_ARRAY_VALUE'

export const SONG_SUBMISSION_ERROR = 'SONG_SUBMISSION_ERROR'
export const SONG_SUBMISSION_DELETED = 'SONG_SUBMISSION_DELETED'
export const SONG_SUBMISSION_UPDATED = 'SONG_SUBMISSION_UPDATED'
export const SONG_SUBMISSION_LOADING = 'SONG_SUBMISSION_LOADING'
export const SONG_SUBMISSION_SUCCESS = 'SONG_SUBMISSION_SUCCESS'
export const SONG_SUBMISSION_SET = 'SONG_SUBMISSION_SET'
export const SONG_SUBMISSION_SET_ALL = 'SONG_SUBMISSION_SET_ALL'
export const SONG_SUBMISSION_CURRENT_PAGE_SET = 'SONG_SUBMISSION_CURRENT_PAGE_SET'
export const SONG_SUBMISSION_PER_PAGE_SET = 'SONG_SUBMISSION_PER_PAGE_SET'
export const SONG_SUBMISSION_TOTAL_PAGES_SET = 'SONG_SUBMISSION_TOTAL_PAGES_SET'
export const SONG_SUBMISSION_SORT_SET = 'SONG_SUBMISSION_SORT_SET'

export const SONGWRITER_ERROR = 'SONGWRITER_ERROR'
export const SONGWRITER_LOADING = 'SONGWRITER_LOADING'
export const SONGWRITER_SUCCESS = 'SONGWRITER_SUCCESS'
export const SONGWRITER_SET = 'SONGWRITER_SET'
export const SONGWRITERS_SET = 'SONGWRITERS_SET'
export const SONGWRITER_CURRENT_PAGE_SET = 'SONGWRITER_CURRENT_PAGE_SET'
export const SONGWRITER_PER_PAGE_SET = 'SONGWRITER_PER_PAGE_SET'
export const SONGWRITER_TOTAL_PAGES_SET = 'SONGWRITER_TOTAL_PAGES_SET'
export const SONGWRITER_SEARCH_VALUE_SET = 'SONGWRITER_SEARCH_VALUE_SET'

export const SYNC_LOADING = 'SYNC_LOADING'
export const SYNC_SUCCESS = 'SYNC_SUCCESS'
export const SYNC_ERROR = 'SYNC_ERROR'
export const SYNC_SET = 'SYNC_SET'
export const SYNCS_SET = 'SYNCS_SET'

export const TAG_LOADING = 'TAG_LOADING'
export const TAG_SUCCESS = 'TAG_SUCCESS'
export const TAG_ERROR = 'TAG_ERROR'
export const TAG_SET = 'TAG_SET'
export const TAGS_SET = 'TAGS_SET'

export const TAGGED_ITEM_LOADING = 'TAGGED_ITEM_LOADING'
export const TAGGED_ITEM_SUCCESS = 'TAGGED_ITEM_SUCCESS'
export const TAGGED_ITEM_ERROR = 'TAGGED_ITEM_ERROR'

export const USER_ERROR = 'USER_ERROR'
export const USER_LOADING = 'USER_LOADING'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_SET_PROFILE = 'USER_SET_PROFILE'
export const USER_SET = 'USER_SET'
export const USERS_SET = 'USERS_SET'
export const USER_CURRENT_PAGE_SET = 'USER_CURRENT_PAGE_SET'
export const USER_PER_PAGE_SET = 'USER_PER_PAGE_SET'
export const USER_TOTAL_PAGES_SET = 'USER_TOTAL_PAGES_SET'
export const USER_SEARCH_VALUE_SET = 'USER_SEARCH_VALUE_SET'

export const USER_TAG_ADDED = 'USER_TAG_ADDED'
export const USER_TAG_ERROR = 'USER_TAG_ERROR'
export const USER_TAG_LOADING = 'USER_TAG_LOADING'
export const USER_TAG_SUCCESS = 'USER_TAG_SUCCESS'
export const USER_TAG_SET = 'USER_TAG_SET'
export const USER_TAGS_SET = 'USER_TAGS_SET'
export const USER_TAG_CURRENT_PAGE_SET = 'USER_TAG_CURRENT_PAGE_SET'
export const USER_TAG_PER_PAGE_SET = 'USER_TAG_PER_PAGE_SET'
export const USER_TAG_TOTAL_PAGES_SET = 'USER_TAG_TOTAL_PAGES_SET'
export const USER_TAG_SEARCH_VALUE_SET = 'USER_TAG_SEARCH_VALUE_SET'

export const WRITING_CREDIT_ERROR = 'WRITING_CREDIT_ERROR'
export const WRITING_CREDIT_LOADING = 'WRITING_CREDIT_LOADING'
export const WRITING_CREDIT_SUCCESS = 'WRITING_CREDIT_SUCCESS'
export const WRITING_CREDIT_DELETED = 'WRITING_CREDIT_DELETED'
export const WRITING_CREDIT_SET = 'WRITING_CREDIT_SET'
export const WRITING_CREDITS_SET = 'WRITING_CREDITS_SET'
