<template>
  <div>
    <index-header
      title="Notifications"
      newButtonText="Mark As Read"
      v-on:click-new-button="markAsRead"
    ></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <h3>My Notifications</h3>
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th></th>
          <th class="has-text-grey-light">Date</th>
          <th class="has-text-grey-light">Notification</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="notification in notifications" :key="notification.id">
          <td class="is-narrow">
            <span v-if="!notification.read_at" class="unread-indicator-large-index"></span>
            <span v-else class="unread-indicator-fill"></span>
          </td>
          <td
            v-bind:class="{'has-text-dark has-text-weight-bold': !notification.read_at }"
          >{{ notification.created_at | momentDateTime }}</td>
          <td v-bind:class="{'has-text-dark has-text-weight-bold': !notification.read_at }">
            <a @click="viewResource(notification)">{{ notification.data | orBlank('message') }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>
  </div>
</template>

<script>

import uiHelper from '@/mixins/uiHelper'

import { NOTIFICATION_ALL, NOTIFICATION_UPDATE } from '@/store/actions'
import { NOTIFICATION_CURRENT_PAGE_SET, NOTIFICATION_PER_PAGE_SET } from '@/store/mutations'

export default {
  name: 'Notifications',
  mixins: [uiHelper],
  data() {
    return {
      currentPageMutation: NOTIFICATION_CURRENT_PAGE_SET,
      perPageMutation: NOTIFICATION_PER_PAGE_SET
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.notification
    },

    notifications() {
      return this.$store.state.notification.notifications
    }
  },

  methods: {
    load() {
      this.$store.dispatch(NOTIFICATION_ALL, {})
    },

    markAsRead() {
      this.$store.dispatch(NOTIFICATION_UPDATE, { all: true })
    },

    viewResource(notification) {
      if (!notification.read_at) {
        this.$store.dispatch(NOTIFICATION_UPDATE, { notification_ids: [notification.id] })
      }
      if (notification.type.indexOf('Song') > -1) {
        this.$router.push({ name: 'songs.show', params: { id: notification.data.song_id } })
      } else if (notification.type.indexOf('Pitch') > -1) {
        this.$router.push({ name: 'pitches.show', params: { id: notification.data.pitch_id } })
      } else if (notification.type.indexOf('Playlist') > -1) {
        this.$router.push({ name: 'playlists.show', params: { id: notification.data.playlist_id } })
      } else if (notification.type.indexOf('OptionPeriodExpiring') > -1) {
        this.$router.push({ name: 'songwriters.show', params: { id: notification.data.songwriter_id } })
      } else {
        return null
      }
    }
  }
}
</script>
