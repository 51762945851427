<template>
  <div class="form">
    <div class="field m-b-15">
      <div class="level m-b-0">
        <div class="level-left">
          <label class="label">Contact(s)</label>
        </div>
        <div class="level-right">
          <div>
            <a
              class="is-primary tooltip is-tooltip-left"
              data-tooltip="Create Contact"
              @click="addContact"
              v-if="$can.includes('create-contacts')"
            >
              <IconPlus style="height: 20px; width: 20px;"/>
            </a>
          </div>
        </div>
      </div>
      <div class="control">
        <multiselect
        v-model="playlist.pitch_to_contact"
        :multiple="true"
        :options="contacts"
        track-by="id"
        label="full_name"
        :close-on-select="true"
        >
        </multiselect>
      </div>
    </div>
      <div class="field m-b-15">
      <label class="label">Subject</label>
        <div class="control">
          <input class="input" v-model="playlist.subject" type="text" placeholder="Enter subject here...">
      </div>
      </div>
      <div class="field m-b-15">
      <label class="label">Message</label>
        <div class="control">
          <textarea class="input" v-model="playlist.message" placeholder="Enter message here..."></textarea>
      </div>
      </div>
      <div class="field m-b-15">
      <label class="label">Allow Audio Download?</label>
        <div class="control tooltip-span-label">
            <label class="radio">
              <input type="radio" class="m-r-5" name="allowAudio" v-model="playlist.allowAudioDownload" value="1" required>
              Yes
            </label>
            <label class="radio tooltip-span-label">
              <input type="radio" class="m-r-5" name="allowAudio" v-model="playlist.allowAudioDownload" value="0">
              No
            </label>
        </div>
    </div>
    <form-modal
      type="contact"
      title="Add Contact"
      :model="contact"
      :isModal="isModal"
      v-on:clear-modal="clearModal"
      v-on:save-record="saveContact"
    ></form-modal>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { IconPlus } from '@/Icons/IconBase'

import {
  CONTACT_ALL_FOR_FORM,
  CONTACT_CREATE
} from '@/store/actions'

export default {
  name: 'CompEmailForm',
  model: {
    prop: 'playlist',
    event: 'change'
  },
  data() {
    return {
      isModal: false,
      contact: {}
    }
  },
  components: {
    Multiselect,
    IconPlus
  },
  props: {
    playlist: Object,
    isSaving: Boolean
  },
  created: function () {
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
  },
  computed: {
    contacts() {
      return this.$store.state.contact.contacts.filter(contact => contact.email !== null)
    }
  },
  mounted() {
    if (this.playlist.pitch_to_contact_id) {
      let selectedContact = this.contacts.find(contact => contact.id === this.playlist.pitch_to_contact_id)
      this.$set(this.playlist, 'pitch_to_contact', selectedContact)
    } else if (this.playlist.pitch_to_contact) {
      let selectedContact = this.contacts.find(contact => contact.id === this.playlist.pitch_to_contact.map(function (contact) { return contact.id }))
      this.$set(this.playlist, 'pitch_to_contact', [selectedContact])
    }
  },
  methods: {
    addContact() {
      this.clearModal()
      this.isModal = !this.isModal
    },
    saveContact(data) {
      let self = this
      this.$store.dispatch(CONTACT_CREATE, data)
        .then(function (contact) {
          if (self.playlist.pitch_to_contact) {
            let index = self.playlist.pitch_to_contact.length
            self.$set(self.playlist.pitch_to_contact, index, contact)
          } else {
            self.$set(self.playlist, 'pitch_to_contact', [])
            self.playlist.pitch_to_contact.push(contact)
          }
          self.clearModal()
        }, function (err) {
          self.clearModal()
          alert(err)
        })
    },

    clearModal() {
      this.contact = {}
      this.isModal = false
    }
  }
}
</script>
