<template>
  <div>
    <index-header title="Users" :newButtonText="$can.includes('create-users') ? 'Add User' : null" v-on:click-new-button="create"></index-header>
    <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
      <!-- <search-input
        :searchValue="searchValue"
        placeholder="Search Users"
        v-on:search-input-change="handleSearchInput"
      />-->
    </table-actions>
    <table class="table is-hoverable is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th class="has-text-grey-light">Name</th>
          <th class="has-text-grey-light">Email</th>
          <th class="has-text-grey-light">Role</th>
          <th class="has-text-grey-light">Active</th>
          <th class="has-text-grey-light"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
          :class="{'is-clickable' : $can.includes('update-users'), 'is-prohibited' : !$can.includes('update-users') }"
        >
          <td @click="edit(user)" class="has-text-dark has-text-weight-bold">{{user.full_name}}</td>
          <td @click="edit(user)">{{ user.email }}</td>
          <td @click="edit(user)">{{ user.role ? user.role.name : '...' | snakeToTitle}}</td>
          <td @click="edit(user)">{{ user.active ? 'Yes' : 'No' }}</td>
          <td class="is-narrow">
            <a
              class="table-icon tooltip"
              data-tooltip="Edit User"
              @click="edit(user)"
              v-if="$can.includes('update-users')"
            >
              <IconEdit/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:current-page-change="setCurrentPage"
    ></pagination>

    <form-modal
      type="accountUser"
      :title="formTitle"
      :model="selectedUser"
      :isModal="isModal"
      :isSaving="isSaving"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'
import { IconEdit } from '@/Icons/IconBase'

import {
  ACCOUNT_USER_ALL,
  ACCOUNT_USER_CREATE,
  ACCOUNT_USER_UPDATE,
  ACCOUNT_USER_DELETE
} from '@/store/actions'

import { ACCOUNT_USER_CURRENT_PAGE_SET, ACCOUNT_USER_PER_PAGE_SET, ACCOUNT_USER_SEARCH_VALUE_SET } from '@/store/mutations'

export default {
  name: 'AccountUsers',
  mixins: [uiHelper],
  components: {
    IconEdit
  },

  data() {
    return {
      currentPageMutation: ACCOUNT_USER_CURRENT_PAGE_SET,
      perPageMutation: ACCOUNT_USER_PER_PAGE_SET,
      searchValueMutation: ACCOUNT_USER_SEARCH_VALUE_SET,
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isSaving: false,
      selectedUser: {}
    }
  },

  computed: {
    resourceState() {
      return this.$store.state.accountUser
    },

    users() {
      return this.$store.state.accountUser.accountUsers
    }

  },

  methods: {

    handleSearchInput(value) {
      this.setSearchValue(value, this)
    },

    load() {
      this.$store.dispatch(ACCOUNT_USER_ALL)
    },

    create() {
      this.clearModal()
      this.isModal = !this.isModal
      this.formTitle = 'New User'
    },

    edit(user) {
      if (this.$can.includes('update-users')) {
        this.formCanDelete = this.$can.includes('delete-users')
        this.formTitle = 'Edit User'
        this.isModal = !this.isModal
        this.selectedUser = user
        this.selectedUser.newUserData = {}
      }
    },

    save(data) {
      let self = this
      this.isSaving = true
      if (data.role || data.newUserData.role) {
        if (this.selectedUser.id) {
          this.$store.dispatch(ACCOUNT_USER_UPDATE, { id: this.selectedUser.id, data: data })
            .then(resp => {
              self.isSaving = false
              self.clearModal()
            })
            .catch(err => {
              self.$store.commit('error', 'Could not update user' + err)
            })
        } else {
          this.$store.dispatch(ACCOUNT_USER_CREATE, data.newUserData)
            .then(resp => {
              self.isSaving = false
              self.clearModal()
            })
            .catch(err => {
              self.$store.commit('error', 'Could not create user' + err)
            })
        }
      } else {
        alert('Please assign a role.')
        this.isSaving = false
      }
    },

    destroy(user) {
      let self = this
      this.$store.dispatch(ACCOUNT_USER_DELETE, user.id)
        .then(resp => {
          self.load()
          self.$store.commit('alert', user.full_name + ' Removed Successfully')
          self.clearModal()
        })
        .catch(err => {
          self.$store.commit('error', 'Could not delete user' + err)
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedUser = {}
    }
  }
}
</script>
